import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomElementComponent } from './custom-element.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PriceListRegionDetailComponent } from './price-list-region-detail/price-list-region-detail.component';
import { ProductStatusComponent } from './product-status/product-status.component';
import { ColorbaseStatusComponent } from './colorbase-status/colorbase-status.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { PickListModule } from 'primeng/picklist';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DunsCompanyInfoComponent } from './duns-company-info/duns-company-info.component';
import { DunsOwnershipInsightsLevel2Component } from './duns-ownership-insights-level-2/duns-ownership-insights-level-2.component';
import { PrimaryInksComponent } from './primary-inks/primary-inks.component';
import { LightInksComponent } from './light-inks/light-inks.component';
import { ExtendedGamutInksComponent } from './extended-gamut-inks/extended-gamut-inks.component';
import { SpecialityInksComponent } from './speciality-inks/speciality-inks.component';
import { ColorPicklistComponent } from './color-picklist/color-picklist.component';
import { FormBuilderOptionsComponent } from './form-builder-options/form-builder-options.component';
import {ChipsModule} from 'primeng/chips';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import { FormBuilderLookupTableComponent } from './form-builder-lookup-table/form-builder-lookup-table.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomCotrolsComponent } from './custom-controls/custom-controls.component';
import { FormBuilderDynamicOptionsComponent } from './form-builder-dynamic-options/form-builder-dynamic-options.component';

@NgModule({
	declarations: [
        PriceListRegionDetailComponent,
        CustomElementComponent,
        ProductStatusComponent,
        ColorbaseStatusComponent,
		DunsCompanyInfoComponent,
		DunsOwnershipInsightsLevel2Component,
		PrimaryInksComponent,
		LightInksComponent,
		ExtendedGamutInksComponent,
		SpecialityInksComponent,
		ColorPicklistComponent,
		FormBuilderOptionsComponent,
		FormBuilderLookupTableComponent,
		CustomCotrolsComponent,
		FormBuilderDynamicOptionsComponent

	],
	imports: [
		CommonModule,
		FormsModule,
		TranslateModule,
		ReactiveFormsModule,
        NgxSelectModule,
        PickListModule,
		NgMultiSelectDropDownModule.forRoot(),
		ChipsModule,
		InputSwitchModule,
		DropdownModule,
		NgbModule
	],
	exports: [
		CustomElementComponent
	]
})
export class CustomFormModule { }