import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilService } from '@app/core/util.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DropNotificationService {

  private notificationCount: number = 0;
  private notifications: any[] = [];

  private countSource = new BehaviorSubject(this.notificationCount);
  public countChanges = this.countSource.asObservable();

  private nSource = new BehaviorSubject(this.notifications);
  public nChanges = this.nSource.asObservable();

  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) { }

  getNotifications(count) {
    return this.http.get('assets/data/dnData.json')
  }

  getRandomIndex(arr) {
    return Math.round(this.utilService.getRandomNum() * (arr.length - 1));
  }

  getDummyNotifications(count) {
    const type = ['LEGAL_DOC_ADDED', 'LEGAL_DOC_REMOVED', 'LEGAL_DOC_UPDATED'];
    const header = ['Update completed', 'Lorem Ipsum', 'Login from 192.186.1.1'];
    const body = ['Lorem ipsum satur set', '', 'Anna accepted your request.', 'Some new messages'];
    const time = [new Date().getTime(), 1623651019000, 1620972619000, 1623564619000];
    const notifications = [];
    for (let i = 0; i < count; i++) {
      notifications.push({
        "type": type[this.getRandomIndex(type)],
        "msgHeader": header[this.getRandomIndex(header)],
        "msgDetail": body[this.getRandomIndex(body)],
        "createdDate": time[this.getRandomIndex(time)],
        "dataMap": {
          documentName : "Test Document"
        }
      });
    }

    const subject = new Observable(observer => {

      observer.next({results: notifications});

    });

    return subject;
  }

  getNotificationCount() {
    return Math.floor((this.utilService.getRandomNum() * 10) + 1);
  }


  createNotifications() {

    this.getDummyNotifications(this.getNotificationCount()).subscribe((d: any[]) => {
      this.nSource.next(d);
    })

  }

  changeCount() {
    this.countSource.next(this.getNotificationCount());
  }

  updateSynctime() {
    const url = '/rest/usernotificationsyncinfo/updatesynctime';
    return this.http.put(url, {});
  }

  getAllNotifications(dtRequestHeader) {
    const url = "/rest/notifications/notifications/datatable";
    /* var form_data = new FormData();

    for (var key in dtRequestHeader) {
      form_data.append(key, dtRequestHeader[key]);
    } */

    const headers = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'})
    }

    return this.http.post(url, $.param(dtRequestHeader), headers)
  }



}
