import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[tableMax]',
  providers: [{ provide: NG_VALIDATORS, useExisting: TableMaxDirective, multi: true }]
})
export class TableMaxDirective implements Validator {
  @Input()
  tableMax: number;

  validate(c: FormControl): { [key: string]: any } {
    const v = c.value;

    c.valueChanges.subscribe(val => {
      let existingErrors = c.errors ? c.errors : {};
      let maxError = this.checkAndSetError(val);
      existingErrors = this.getUpdatedError(maxError, existingErrors);
      c.setErrors(existingErrors);
    });

    // tslint:disable-next-line:object-literal-key-quotes
    let existingErrors = c.errors ? c.errors : {};
    let maxError = this.checkAndSetError(v);
    return this.getUpdatedError(maxError, existingErrors);
  }

  checkAndSetError(val) {
    if (this.tableMax != null && val && val.length > 0) {
      for (let row of val) {
        for (let col of Object.keys(row)) {
          if (col != "key" && row[col] != null && typeof row[col] == 'number') {
            return { 'tableMax': row[col] as unknown as number > this.tableMax ?  true : null } 
          }
        }
      }
    }
    return {};
  }

  getUpdatedError(maxError: { tableMax: boolean; } | { tableMax?: undefined; }, existingErrors) {
    if (maxError.tableMax) {
      existingErrors.tableMax = true;
    } else {
      if (existingErrors.tableMax) {
        delete existingErrors.tableMax;
      }
    }
    if (Object.keys(existingErrors).length == 0) {
      existingErrors = null;
    }
    return existingErrors;
  }
}

