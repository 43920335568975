import { Injectable, SecurityContext } from '@angular/core';
import { formatDate, formatNumber, formatCurrency, getCurrencySymbol } from '@angular/common';
import { AppConstants } from '@custom/core/app-constants';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import {decode} from 'html-entities';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private hasCamera: boolean = false;

  private pageContainsCamera = new BehaviorSubject(this.hasCamera);
  public pageHasCamera = this.pageContainsCamera.asObservable();

  constructor(private translate: TranslateService, private titleService: Title, public domSanitizer: DomSanitizer) { }

  public formatDate(date: number, format?: string, locale?: string): string {
    if (typeof date !== 'number') {
      return date;
    }
    format = format || AppConstants.dateFormat;
    locale = locale || AppConstants.defaultLocale;

    return date ? formatDate(date, format, locale) : '';
  }

  public formatDateTime(date: number, format?: string, locale?: string): string {
    format = format || AppConstants.dateTimeFormat;
    locale = locale || AppConstants.defaultLocale;

    return date ? formatDate(date, format, locale) : '';
  }

  public getUTCTime(date: number, format?: string, locale?: string): string {

    const timeZone = 'UTC';
    format = format || AppConstants.dateFormat;
    locale = locale || AppConstants.defaultLocale;

    return date ? formatDate(date, format, locale, timeZone) : '';
  }

  public getBoolean(value: number | string | boolean): boolean {
    return typeof value === 'boolean' ? value
      : ((typeof value === 'string' && value.toLowerCase() === 'yes') || (typeof value === 'number' && value === 1)) ? true
        : false;
  }
  public isValidUrl(userInput) {
    // tslint:disable-next-line:max-line-length
    userInput = userInput.trim();
    const regexQuery = '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
    const url = new RegExp(regexQuery, 'i');
    return url.test(userInput);
  }

  public isValidEmail(email: string): boolean {
    const regexQuery = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    email = email.trim();
    return regexQuery.test(email);
  }

  public isValidNumber(num: any): boolean {
    const regexQuery = /^\d*$/;
    return regexQuery.test(num);
  }

  public formatNumber(value: number, digitsInfo: string, locale?: string): string {

    locale = locale || AppConstants.defaultLocale;
    return value ? formatNumber(value, locale, digitsInfo) : '';
  }

  public getCurrencySymbol(code, format?, locale?) {

    format = format || 'narrow';
    locale = locale || AppConstants.defaultLocale;

    return code ? getCurrencySymbol(code, format, locale) : '';
  }

  public formatCurrency(value: number, currencyCode: string, digitsInfo?: string, currency?: string, locale?: string): string {

    locale = locale || AppConstants.defaultLocale;
    currency = currency || getCurrencySymbol(currencyCode, null, locale);

    return value ? formatCurrency(value, locale, currency, currencyCode, digitsInfo) : '';
  }

  public getNameFromEmail(email: string): string {
    if (email) {
      return email.replace(/@[^@]+$/, '').replace(/[^a-zA-Z0-9]/g, ' ');
    }
    return '';
  }

  public splitFileAndData(data, fileFields) {
    const returnData: any = {
      data: {},
      files: {}
    };
    if (!fileFields || !fileFields.length) {
      returnData.data = data;
      return returnData;
    }
    const dataKeys = Object.keys(data);
    dataKeys.forEach(d => {
      if (fileFields.indexOf(d) > -1) {
        returnData.files[d] = data[d];
      } else {
        returnData.data[d] = data[d]==="" ? null : data[d];
      }
    });

    return returnData;
  }
  public checkCase(data: any, isCaseSensitive: boolean) {
    if (!data || !data.toUpperCase) { return data; }
    return isCaseSensitive ? data : data.toUpperCase();
  }
  /**
   * checks whether the two values are equal
   */
  public isEqualIgnoreCase(data1: any, data2: any, ignoreProperties?: any, isCaseSensitive?: boolean) {

    if (this.checkCase(data1, isCaseSensitive) === this.checkCase(data2, isCaseSensitive) || data1 === data2) { return true; }
    if ((typeof data1 !== 'object' || typeof data2 !== 'object')) {
      return this.checkCase(data1, isCaseSensitive) === this.checkCase(data2, isCaseSensitive);
    }
    const isValueEmpty1 = (data1 == null || data1 === '' || data1 === undefined || $.isEmptyObject(data1));
    const isValueEmpty2 = (data2 == null || data2 === '' || data2 === undefined || $.isEmptyObject(data2));
    if (isValueEmpty1 && isValueEmpty2) { return true; }

    if (isValueEmpty1 && !isValueEmpty2) { return false; }
    if (!isValueEmpty1 && isValueEmpty2) { return false; }

    ignoreProperties = ignoreProperties || [];
    let keys = Object.keys(data1 || {});
    const data2Keys = Object.keys(data2 || {});
    keys = keys.concat(Object.keys(data2 || {}));
    for (let index = 0; index < keys.length; index++) {

      const key = keys[index];
      const value1 = this.checkCase(data1[key], isCaseSensitive);
      const value2 = this.checkCase(data2[key], isCaseSensitive);
      if (ignoreProperties.indexOf(key) !== -1) { continue; }
      if (value1 === value2
        || ((value1 === null || value1 === '' || value1 === undefined)
          && (value2 === null || value2 === '' || value2 === undefined))) { continue; }

      const isValue1Array = Array.isArray(value1);
      const isValue2Array = Array.isArray(value2);
      if ((isValue1Array && !isValue2Array)
        || (!isValue1Array && isValue2Array)
        || (isValue1Array && isValue2Array
          && value1.length !== value2.length)) { return false; }
      let retVal = false;
      if (isValue1Array && isValue2Array) {
        if (value1.length === 0 && value2.length === 0) {
          retVal = true;
        } else {
          for (let arrIndex = 0; arrIndex < value1.length; arrIndex++) {
            retVal = this.isEqualIgnoreCase(value1[arrIndex], value2[arrIndex], ignoreProperties, isCaseSensitive)
            if (!retVal) { return false; }
          }
        }
      } else if (typeof value1 === 'object' && typeof value2 === 'object') {
        retVal = this.isEqualIgnoreCase(value1, value2, ignoreProperties, isCaseSensitive)
      } else if ((typeof value1 === 'string' && typeof value2 === 'string') || (typeof value1 === 'number' && typeof value2 === 'number')) {
        retVal = (value1 === value2);
      }
      if (!retVal) {
        return false;
      }
    }

    return true;
  };

  public enableCamera(data: boolean) {
    this.pageContainsCamera.next(data);
  }

  public setTitle() {
    this.translate.get('APP_NAME').subscribe(title => {
      this.titleService.setTitle(title);
    });
  }

  public setFavIcon() {
    document.getElementById('appFavIcon').setAttribute('href', './assets/icons/favicon.png');
  }

  getFontBaseClass(iconfrom) {
    switch (iconfrom) {
      case 'Font Awesome': return 'fa';
      case 'Material': return 'material-icons';
      case 'Glyphicon': return 'glyphicon';
      case 'AppDirectory': return 'app-directory';
      default: return 'fa';
    }
  }

  public setGoogleImageText(options) {
    const colors = ["#1abc9c", "#16a085", "#f1c40f", "#f39c12", "#2ecc71", "#27ae60", "#e67e22", "#d35400", "#3498db", "#2980b9", "#e74c3c", "#c0392b", "#9b59b6", "#8e44ad", "#bdc3c7", "#34495e", "#2c3e50", "#95a5a6", "#7f8c8d", "#ec87bf", "#d870ad", "#f69785", "#9ba37e", "#b49255", "#b49255", "#a94136"];
    const settings = $.extend({
      // Default settings
      name: 'Name',
      charCount: 1,
      textColor: '#ffffff',
      height: 100,
      width: 100,
      fontSize: 60,
      fontWeight: 400,
      fontFamily: 'HelveticaNeue-Light,Helvetica Neue Light,Helvetica Neue,Helvetica, Arial,Lucida Grande, sans-serif'
    }, options);

    // making the text object
    let c = settings.name.substr(0, 1).toUpperCase();
    if (settings.charCount > 1) {
      const split = settings.name.split(' ');
      c = "";
      for (let i = 0; i < settings.charCount; i++) {
        if (split[i])
          c = c +  split[i].substr(0, 1).toUpperCase();
      }

    }
    const cobj = $('<text text-anchor="middle"></text>').attr({
      'y': '50%',
      'x': '50%',
      'dy': '0.35em',
      'pointer-events': 'auto',
      'fill': settings.textColor,
      'font-family': settings.fontFamily
    }).html(c).css({
      'font-weight': settings.fontWeight,
      'font-size': settings.fontSize + 'px',
    });
    const color = colors[c.charCodeAt(0) - 65];
    const svg = $('<svg></svg>').attr({
      'xmlns': 'https://www.w3.org/2000/svg',
      'pointer-events': 'none',
      'width': settings.width,
      'height': settings.height
    }).css({
      'background-color': color,
      'width': settings.width + 'px',
      'height': settings.height + 'px'
    });

    svg.append().append(cobj);
    const svgHtml = encodeURIComponent($('<div>').append(svg.clone()).html());

    return 'data:image/svg+xml;utf8,' + svgHtml
  }
  
  public getRandomNum(){
    const cryptoObj = window.crypto || window['msCrypto'];
    const randomBuffer = new Uint32Array(1);
    cryptoObj.getRandomValues(randomBuffer);
    return randomBuffer[0] / (0xffffffff + 1);
  }
/**
 * Checks and removes if the given object is having any unsafe parameters XSS
 * @param input 
 * @returns 
 */
  public removeUnSafeParams(input){
    if(!input || typeof input !== 'object') return;
    for(let key of Object.keys(input)){
      if(!input[key]) continue;
      if(typeof input[key] === 'object'){
        this.removeUnSafeParams(input[key])
      }else if(typeof input[key] == 'string'){
        // decode is done to skip encoding any special characters, Only the HTML tags are removed if needed.
        input[key] = decode(this.domSanitizer.sanitize(SecurityContext.HTML, input[key]));
      }
    }
  }

  /**
	 * generate groups of 4 random characters
	 * @example getUniqueId(1) : 607f
	 * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
	 */
	getUniqueId(parts: number): string {
		return uuidv4();		
	}

}
