import { Component, OnInit, Input, HostListener, ElementRef, AfterViewInit, OnChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from '@custom/core/app-constants';
import { VsTabmenuIntegrationService } from '@app/widget/vs-tabmenu-integration.service';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NavigationEvent } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';
import { browserRefresh } from '@app/app.component';

@Component({
  selector: 'app-vsmenunew',
  templateUrl: './vsmenunew.component.html',
  styleUrls: ['./vsmenunew.component.scss'],
  animations: [
    trigger('fade', [
      state('close', style({ width: 0, display: 'none' })),
      state('open', style({ opacity: 1, display: 'block' })),
      transition('close => open, void => open', [
        style({ width: '0px' }),
        animate(`${AppConstants.animationDuration} linear`, style({ width: '350px' }))
      ]),
      transition('open => close, close => void', [
        style({ width: '350px' }),
        animate(`${AppConstants.animationDuration} linear`, style({ width: '0px' }))
      ]),
    ])
  ]
})
export class VsmenunewComponent implements OnInit, AfterViewInit, OnChanges {


  @Input() menuConfig: any = [];
  @Input() isMenuOpen: boolean;

  loadCount = 0;
  menuState: string;
  isInit = true;
  currentMenu: any;
  parentMenu: any;
  isMobile: boolean;
  hasLoadedOnRefrsh: boolean;
  currentActiveMenu: any;

  getHeight(elem, id) {
    const curelem = document.getElementById(elem + id);
    const height = window.innerHeight - curelem.offsetTop;
    return height;
  }

  handleMouseEnter(e) {
    const curTarget = $(e.currentTarget).children('.submenu-wrap');
    $(curTarget).addClass('active');
    const ht = $(curTarget).height();
    const top = $(curTarget).offset() ? $(curTarget).offset().top : 0;
    if (ht + top > $(window).height()) {
      $(curTarget).css({
        top: 'auto',
        bottom: '0px'
      });
    }
    $('.opened-tabs').css('z-index', 9);
  }

  handleMouseLeave(e) {
    $(e.currentTarget).children('.submenu-wrap').removeClass('active');
    $('.opened-tabs').css('z-index', 9999);
  }

  constructor(private tabMenuIntegration: VsTabmenuIntegrationService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.watchRouteChange();
  }
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    // console.log(browserRefresh);
    // console.log(this.hasLoadedOnRefrsh);
    if (browserRefresh && !this.hasLoadedOnRefrsh) {
      const urlHash = window.location.hash;
      const url = urlHash.substring(1);
      if (!this.menuConfig)
        this.menuConfig = {};
      this.hasToLoadTabs(this.menuConfig.menuItems, url);
      this.hasLoadedOnRefrsh = true;
    }
    // console.log(changes);
  }

  checkIfDetailPage(path, url) {
    if (!path) return false;
    const detailPagePath = path.substring(path.lastIndexOf('fp=') + 3);
    if (detailPagePath.length && url.indexOf("/" + detailPagePath) > -1) {
      return true;
    }
    return false;
  }

  isTabMenu(menu) {
    return menu.filter(m => m.tab === true);
  }

  isUrlInMenu(menu, url) {
    return menu.filter(m => {
      if (m.subTabs) {
        this.isUrlInMenu(m, url);
      }
      return url.indexOf(m.path);
    });
  }

  shouldLoadTab(menu, url) {
    let tabToLoad = [];
    for (let i = 0; i < menu.length; i++) {
      const curMenu = menu[i];
      if (curMenu.path === url) {
        tabToLoad = this.isTabMenu(menu).length ? menu : curMenu;
      } else if (this.checkIfDetailPage(curMenu.path, url)) {
        tabToLoad = this.isTabMenu(menu).length ? menu : curMenu;
        break;
      } else if (curMenu.subMenus && curMenu.subMenus.length) {
        this.currentMenu = curMenu;
        this.shouldLoadTab(curMenu.subMenus, url);
      } else if (curMenu.subTabs && curMenu.subTabs.length) {
        this.currentMenu = curMenu;
        this.shouldLoadTab(curMenu.subTabs, url);
      }
    }
    if (tabToLoad.length) {
      this.tabMenuIntegration.changeTab({
        subTabs: [this.currentMenu]
      });
    } else {
      if (!this.loadCount) {
        this.router.navigateByUrl(window.location.hash.substring(1));
        this.loadCount++;
      }
    }

  }

  hasToLoadTabs = function (menu, url) {
    let tabToLoad = [];
    if (!menu)
      return
    for (let i = 0; i < menu.length; i++) {
      const currentMenu = menu[i];
      if (currentMenu.pathsInvolved && currentMenu.pathsInvolved.length > 0) {
        for (let j = 0; j < currentMenu.pathsInvolved.length; j++) {
          if (currentMenu.pathsInvolved[j] === url || this.checkIfDetailPage(url, currentMenu.pathsInvolved[j])) {
            this.currentMenu = currentMenu;
            tabToLoad = currentMenu;
          }
        }
      }
      if (tabToLoad && tabToLoad['subTabs'] && tabToLoad['subTabs'].length) {
        this.tabMenuIntegration.changeTab({
          subTabs: this.currentMenu.subTabs
        });
      } else {
        if (!this.loadCount) {
          this.router.navigateByUrl(window.location.hash.substring(1));
          this.loadCount++;
        }
      }
    }
  };

  watchRouteChange() {
    this.router.events.subscribe((event: any) => {
      // if (event.url !== event.urlAfterRedirects)
      if (event instanceof NavigationCancel)
        this.router.navigate([event.url]);
      if (event instanceof NavigationEnd)
        this.hasToLoadTabs(this.menuConfig.menuItems, event.url);
    });
  }

  setActiveForParent(child, parent) {
    const parentId = child.parentId;
    let isParentFound = false;
    parent.map(item => {
      if (item.id === parentId) {
        item.active = true;
        isParentFound = true;
        if (item.parentId) {
          this.setActiveForParent(item.parentId, parent);
        }
      }
      return item;
    });

    if (!isParentFound) {
      // do logic to find parent
    }

  }

  setActiveMenus(menus: any[]) {
    const href = window.location.href;
    if (menus && menus.length > 0) {
      menus.forEach(menu => {
        if (menu.path && href.indexOf(menu.path) > -1) {
          menu.active = true;
          this.setActiveForParent(menu, menus);
        } else if (menu.subMenus && menu.subMenus.length) {
          this.setActiveMenus(menu.subMenus);
        } else if (menu.subTabs && menu.subTabs.length) {
          this.setActiveMenus(menu.subTabs);
        }
        return menu;
      });
    }
  }
  isActive(menu) {
    //console.log('Menu: ', menu);

    this.currentActiveMenu = menu;
  }
  checkIfReloaded() {
    if (window.performance) {
      // if (performance.navigation.type == 1) {
      //   return true;
      // } else {
      //   return true;
      // }
    }
    return false;
  }

  watchMenuOpenState() {
    this.tabMenuIntegration.menuOpenChanges.subscribe(data => {
      this.isMenuOpen = data;
    });
  }

  closeMenu() {
    // this.isMenuOpen = false;
    this.tabMenuIntegration.changeMenuOpenState(false);
  }

  ngOnInit() {
    if (!this.menuConfig)
      this.menuConfig = {};
    this.isMobile = AppConstants.isMobile;
    this.hasLoadedOnRefrsh = false;
    this.watchMenuOpenState();
  }

  ngAfterViewInit(): void {
    this.setActiveMenus(this.menuConfig.menuItems);
  }
  toggleMenuActive(menu) {
    console.log(menu.active);
    menu.active = !menu.active;
  }


}
