import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { environment } from '@env/environment';
import { AppGlobalService } from '@app/app-global.service';
import { NotificationService } from '@app/shared/services/notification.service';

@Injectable()
export class AuthenticationResolver implements Resolve<any> {

  constructor(
    public auth: AuthenticationService,
    public router: Router,
    public appGlobalService: AppGlobalService,
    public notify: NotificationService
  ) { }
  public authenticate(): Observable<any> {
    const promise = [];
    promise.push(this.auth.authenticate());
    //if (!environment.prototype)
    // promise.push(this.auth.getfsToken());
    return combineLatest(promise);
  }
  resolve() {
    this.appGlobalService.write('authCheck', true);
    return this.authenticate().pipe(
      take(1),
      catchError((error) => {
        let redirectUrl = window.location.hash;
        let loginUrl = '/login/logindetail';
        if (redirectUrl.indexOf("login") == -1) {
          loginUrl += "?redirectUrl=" + encodeURIComponent(redirectUrl);
        }
        this.router.navigateByUrl(loginUrl);
        return of('No data');
      })
    )
  }
}