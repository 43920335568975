// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppGlobalService } from '@app/app-global.service';
export const envInfo = {
  config: getConfig()
};
function getConfig() {
  let config;
  let request = new XMLHttpRequest();
  try {
 /*  return {
        apiKey: "AIzaSyC-VkZM1L21wl6ywLx6yuQF7FYVHCtQRXQ",
        authDomain: "cc-devc66543af.firebaseapp.com",
        projectId: "cc-devc66543af",
        storageBucket: "cc-devc66543af.appspot.com",
        messagingSenderId: "680402654762",
        appId: "1:680402654762:web:a7790ac84b5e85d0f89553",
        measurementId: "G-P386YWM12W"
      };*/
     
      
      
      request.open('GET', 'rest/environmentinfo', false);  // `false` makes the request synchronous
      request.send(null);

      if (request.status === 200) {
          const res = JSON.parse(request.responseText)
          if(res && res.envInfo && res.envInfo.firebase_auth_json)
          res.envInfo.firebase_auth_json = JSON.parse(res.envInfo.firebase_auth_json);
          return res.envInfo;
      } else if(request.status === 401){
        location.href = '/logout';
      }

      
  } catch (e) {
      console.error('environment:getConfig: unable to get api key : ', e);
  }

  return config;
}

