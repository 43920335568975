import { Router, NavigationStart, NavigationEnd, Event } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UpdateServiceService } from './update-service.service';
import { AppConstants } from '@custom/core/app-constants';
export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private sw: UpdateServiceService, private router: Router) {
    this.sw.checkForUpdates();
  }

  onResize(e) {
    if (AppConstants.isMobile !== window.matchMedia('only screen and (max-width: 760px)').matches) {
      window.location.reload();
    }
  }
  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !this.router.navigated;
      }
    });
  }
}