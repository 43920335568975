import {
  Directive,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Attribute,
  Input,
  OnChanges,
  SimpleChanges,
  Renderer2,
  OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { DOWN_ARROW, UP_ARROW } from '@angular/cdk/keycodes';
import * as numeral from 'numeral';
import { Observable } from 'rxjs';

function isNil(value: any): value is null | undefined {
  return value === null || value === undefined;
}

class NumberBuilder {
  constructor(private _value: number) { }

  limit(decimals: number): NumberBuilder {
    if (decimals <= 0) {
      return this;
    }

    const [firstPart, decimalPart] = String(this._value).split('.');
    const { decimal } = numeral.localeData().delimiters;

    if (decimalPart && decimalPart.length > decimals) {
      return new NumberBuilder(parseFloat(`${firstPart}.${decimalPart.substr(0, decimals)}`));
    }

    return this;
  }

  between(min: number, max: number): NumberBuilder {
    return new NumberBuilder(Math.max(Math.min(this._value, max), min));
  }

  build(): number {
    return this._value;
  }
}

function createNumericRegex(hasDecimal: boolean, hasSign: boolean, delimiters?:string): RegExp {
  const { decimal } = delimiters ? {'decimal' :  delimiters}  : numeral.localeData().delimiters;

  return new RegExp(`^${hasSign ? '-?' : ''}(?:(?:\\d+${hasDecimal ? `(\\${decimal}\\d*)?` : ''})|(?:\\${decimal}\\d*))?\$`);
}

const noop = () => { };

@Directive({
  selector: 'input[appCustomNumberInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomNumberInputDirective),
      multi: true
    }
  ],
  exportAs: 'appNumberInput'
})
export class CustomNumberInputDirective implements ControlValueAccessor, OnChanges, OnInit {
  @Input('min') public set minInput(value: string) {
    if (typeof value === 'number')
      this.min = coerceNumberProperty(value);
  }

  @Input('max') public set maxInput(value: string) {
    if (typeof value === 'number')
      this.max = coerceNumberProperty(value);
  }

  @Input('decimals') public set decimalsInput(value: string) {
    if (typeof value === 'number')
      this.decimals = coerceNumberProperty(value);
  }
  @Input('onStepPress') onStepPress:Observable<any>

  @Input() public format = '0,0';
  @Input() public multiple = 1;

  private modelValue?: any;
  private displayValue?: string;

  private decimals = 100;
  private min = Number.MIN_SAFE_INTEGER;
  private max = Number.MAX_SAFE_INTEGER;

  private focused = false;
  private decimalSeperator = '.';
  private positionSeperator = ',';
  private customFormatPresent = false;
  private onChange: (value?: number) => void = noop;

  @HostBinding('disabled') private disabled = false;

  constructor(
    private readonly element: ElementRef<HTMLInputElement>,
    private readonly renderer: Renderer2,
    // tslint:disable:no-attribute-parameter-decorator
    @Attribute('type') private readonly type: string
  ) { }
  ngOnInit(): void {
    this.onStepPress.subscribe(res => {
      this.onKeyDownCall(res == 'up' ? 38 : 40)
    });
   
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.verifySettings();

    if (changes.format) {
      this.setFormats();
      this.renderDisplayValue();
    }
  }

  public writeValue(value: number): void {
    this.updateModelValue(value);
    this.renderDisplayValue();
  }

  public registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(): void { }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @HostListener('input', ['$event']) public onInput(event: Event): void {
    const element = this.element.nativeElement;
    const selectionStart = element.selectionStart as number;
    const selectionEnd = element.selectionEnd as number;
    let value = element.value;

    if (!this.isValidInput(value)) {
      this.renderDisplayValue();
      this.setSelection(selectionStart - 1, selectionEnd - 1);
    } else {
      value = this.standardizeDecimalFormat(value);
      const parsedNumber = numeral.default(value).value();
      this.updateModelValue(parsedNumber);

      if (parsedNumber !== this.modelValue) {
        this.renderDisplayValue();
        this.setSelection(selectionStart, selectionEnd);
      }
    }
  }

  @HostListener('focus') public onFocus(): void {
    this.focused = true;
    this.renderDisplayValue();
    setTimeout(() => this.setSelection(0, this.displayValue!.length));
  }

  @HostListener('blur') public onBlur(): void {
    this.focused = false;
    this.renderDisplayValue();
  }

  @HostListener('keydown', ['$event']) public onKeyDown(event: KeyboardEvent): void {
    this.onKeyDownCall(event.keyCode)
    
  }

  private parseNumber(){

  }

  private onKeyDownCall(keyCode){
    if (this.disabled || this.type === 'number') {
      return;
    }
    const multiple = (this.multiple == null || typeof this.multiple == 'undefined') ? 1 : this.multiple;

    // tslint:disable:deprecation
    switch (keyCode) {
      case DOWN_ARROW:
        this.addStep(-1 * Math.abs(multiple));
        break;
      case UP_ARROW:
        this.addStep(Math.abs(multiple));
        break;
    }
  }

  private setFormats(){
    if(this.format && this.format.replace(/[,.\s]/g,'').includes('123456')){
      this.customFormatPresent = true;
      const format = this.format;
      this.decimalSeperator = !Number(format.charAt(format.length-3)) ? format.charAt(format.length-3) : '';
      this.positionSeperator = !Number(format.charAt(1)) ? format.charAt(1) : '';
    }
  }

  private getDecimal(){
    const { decimal } = this.customFormatPresent ? {decimal : this.decimalSeperator} : numeral.localeData().delimiters;
    return decimal;
  }

  private standardizeDecimalFormat(value){
    const regex = new RegExp(`[${this.getDecimal()}]`,"g");
    return (value + '').replace(regex, '.');
  }

  private verifySettings(): void {
    if (this.min > this.max) {
      throw new Error('The max modelValue should be bigger than the minInput modelValue');
    }

    if (this.decimals < 0) {
      throw new Error('The decimals modelValue should be bigger than 0');
    }
  }

  private isValidInput(input: string): boolean {
    const hasDecimal = this.decimals > 0;
    const hasSign = this.min < 0;

    return createNumericRegex(hasDecimal, hasSign, this.decimalSeperator).test(input);
  }

  private addStep(step: number): void {

    this.updateModelValue((this.modelValue || 0) + step);
    this.renderDisplayValue();
  }

  private updateModelValue(value): void {
    if (isNil(value)) {
      this.modelValue = null;
      this.onChange(this.modelValue);

      return;
    }
    if (value === "") { this.modelValue = ""; } else {
      if (typeof value === "string") {
        if(this.focused){
          //value = Number(value.replace(/,/g, ''));
        }else{
          value = Number(this.normalizeNumber(value));
        }
        
      }
      let decimals = this.decimals;
      if(this.customFormatPresent && this.decimalSeperator){
        decimals = 2;
      }
      this.modelValue = new NumberBuilder(value)
        .limit(decimals)
        .between(this.min, this.max)
        .build();
    }
    this.onChange(this.modelValue);
  }

  private renderDisplayValue(): void {
    this.displayValue = '';
    if (!isNil(this.modelValue)) {
      const decimal = this.getDecimal();
      let dispVal = this.modelValue;
      if (this.format.indexOf('%') > -1)
        dispVal = this.modelValue / 100;
      if (this.focused){
        this.displayValue = String(this.modelValue).replace('.', decimal);

      }
      else {
        if (dispVal === "") {
          this.displayValue = "";
        } else {
          this.displayValue = dispVal.toString();
          let format = this.format;
          let decimals;
          if (this.format.indexOf('.*') > -1) {
            format = this.format.replace('.*', '');
            const split = this.displayValue.split('.');
            decimals = split[1];
            dispVal = Number(split[0]);
          }
          const sformat:any = this.format;
          let displayValue = dispVal;
          if(sformat.replace(/[,.\s]/g,'').includes('123456')){  
            displayValue = this.customNumberFormat(displayValue, this.format);
          }else{
            displayValue = numeral.default(dispVal).format(format);
            if (decimals){
              displayValue = displayValue + '.' + decimals;
            }
          }
          this.displayValue = displayValue;
          
        }
      }
    }
    this.renderer.setProperty(this.element.nativeElement, 'value', this.displayValue);
  }

  private hasDecimal(num){
    return !!(num % 1);
  }

  private customNumberFormat(value, format){    
    let sFormat = format;
    let isdecimal = this.hasDecimal(value);
    if(this.decimalSeperator){
      let formatParts = sFormat.split(this.decimalSeperator);
      sFormat = [
        formatParts[0].replaceAll(this.positionSeperator, ','),
        formatParts[1]
      ].filter(item => item).join('.')
    }else{
      if(!Number(format.charAt(1))){
        sFormat = sFormat.replace(format.charAt(1), ',');
        value = Number((value+ '').replace(format.charAt(1), ''))
      }
    }
    value = numeral.default(value).format(sFormat);
    const decimal = (this.decimalSeperator && isdecimal) ? value.split('.')[1] : ''
    const intPart = this.decimalSeperator ? 
            value.split('.')[0]
            .replaceAll(',', this.positionSeperator) 
            : value.replaceAll(',',this.positionSeperator)
    value = [intPart, decimal].filter(item => item).join(this.decimalSeperator);
    return value;
  }

  private normalizeNumber(value){
    const decimal = this.decimalSeperator ? value.split(this.decimalSeperator )[1] : '';
    const positionSeperator = this.positionSeperator || ',';
    const intPart = this.decimalSeperator ? 
            value.split(this.decimalSeperator)[0]
            .replaceAll(positionSeperator, '') 
            : value.replaceAll(positionSeperator,'')
    value = [intPart, decimal].filter(item => item).join('.');
    return value;
  }

  private setSelection(start: number, end: number): void {
    if (this.type === 'number') {
      return;
    }

    this.element.nativeElement.setSelectionRange(start, end);
  }
}
