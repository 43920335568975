import { Component, OnInit, Input } from '@angular/core';
import { CustomService } from '@custom/services/custom.service';

@Component({
	selector: 'app-duns-company-info',
	templateUrl: './duns-company-info.component.html',
	styleUrls: ['./duns-company-info.component.scss']
})
export class DunsCompanyInfoComponent implements OnInit {

	@Input() currentForm: any;
	@Input() currentElement: any;

	data: any = {};
	constructor(
		private customService: CustomService,
	) { }

	ngOnInit() {
		this.currentForm.valueChanges.subscribe(data => {	
			if(this.currentForm.value.dunsCompanyInfo){		
				this.data = JSON.parse(this.currentForm.value.dunsCompanyInfo);
			}
		});

		this.customService.registerDunsCompanyInfoComponent(this);
	}

	public setValue(value: string) {
		if(value){
			this.data = JSON.parse(value);
		}
    }
}
