import { Injectable } from "@angular/core";
import { BaseService } from "@app/core/base.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class ExportServices {
    constructor(private baseServices: BaseService) { }
    public exportTestResults(exportSid: string): any {
        const serviceOpts = {
            url: 'testresultsexports/exportdoc/{exportSids}',
            method: 'POST',
            showloading: true,
            showMessage: true,
            successMsg:"REPORT_TRIGGRED"
        };
        let params = {
            exportSids: exportSid
        }
        const subject = new Observable(observer => {
            this.baseServices[serviceOpts.method.toLowerCase()](serviceOpts, params).subscribe((response) => {
                observer.next(response);
            },
                (err) => {
                    observer.error(err);
                });
        });
        return subject;
    }
}