import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { CardModule } from 'primeng/card';
import { PaginatorModule } from 'primeng/paginator';
import { CompareComponent } from './shoppingcart/compare/compare.component';

import { SharedWidgetModule } from './shared-widget.module';
import { FormBuilderDetailComponent } from './labs-custom/form-builder-detail/form-builder-detail.component';
import { TestSuiteCopyComponent } from './labs-custom/test-suite-copy/test-suite-copy.component';

@NgModule({
    declarations: [
        CompareComponent,
        FormBuilderDetailComponent,
        TestSuiteCopyComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        CardModule,
        PaginatorModule,
        SharedWidgetModule,
        
    ],
    exports: [
        TranslateModule,
        CardModule,
        PaginatorModule
    ],
    entryComponents: [CompareComponent, FormBuilderDetailComponent, TestSuiteCopyComponent]
})
export class CustomModule { }