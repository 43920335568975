import { NotificationService } from './../../shared/services/notification.service';
import { Component, OnInit, forwardRef, Input, ViewEncapsulation, Renderer, ElementRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ImageCaptureService } from '../image-capture/image-capture.service';
import { Observable } from 'rxjs';
import { FileUploaderService } from './file-uploader.service';
import { ApiConstants } from '@app/api-constants';
import { AppConstants } from '@custom/core/app-constants';
import { UtilService } from '@app/core/util.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploaderComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class FileUploaderComponent implements ControlValueAccessor, OnInit {

  @Input() objectKey: string;
  @Input() multivalue: boolean;
  @Input() fieldName: string;
  @Input() element: any = {};
  @Input() fromCamera: boolean;
  @Input() extensions: string;

  private _model: any;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;

  selectedFile: any = undefined;
  fileName: string;
  fileNameArr = [];
  apiHost = AppConstants.apihost;
  public disabled: boolean;
  private Imgregex = new RegExp('(.*?)\.(jpg|png|jpeg)$');

  constructor(private renderer: Renderer, private elementRef: ElementRef, private imageCaptureService: ImageCaptureService, private notification: NotificationService, private fileUploaderService: FileUploaderService, private utilService: UtilService) { }

  ngOnInit() {
    if (this.fromCamera) {
      if (!this.fileUploaderService.getData('imageSubscribe')) {
        const subscribe = this.imageCaptureService.subject.subscribe(event => {
          this.onFileSelected(event);
        });
        this.fileUploaderService.setData(subscribe, 'imageSubscribe');
      }
    }
    if (this.extensions === '' || this.extensions === null) {
      this.extensions = '*';
    }
  }
  openImage(url) {
    window.open(url);
  }
  get model() {
    return this._model;
  }

  writeValue(value: any): void {
    if(typeof value !== "object")
      value = [];
    this.showUrlsFromData(value);
    this._model = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  set(value: any) {
    this._model = value;
    this.onChange(this._model);
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setElementProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    this.disabled = isDisabled;
  }

  checkFileSizeWithinLimits(file) {
    const maxAllowedSize = 32 * 1024 * 1024;
    return !(file.size > maxAllowedSize);
  }

  onFileSelected(event) {
    if (!this.multivalue) {
      this.fileNameArr = [];
      this._model = [];
      this.selectedFile = event.target.files[0] as File;
      if (!this.checkFileSizeWithinLimits(this.selectedFile)) {
        this.notification.warn('Upload file size cannot be greater than 32 MB');
        this.selectedFile = '';
        event.target.value = '';
        return;
      }
      if (this.selectedFile) {
        this.fileNameArr.push(this.selectedFile);
        this.fileName = this.selectedFile.name;
        // tslint:disable-next-line:no-string-literal
        this.selectedFile['objectKey'] = this.objectKey;
        // tslint:disable-next-line:no-string-literal
        this.selectedFile['fieldName'] = this.fieldName;
        // tslint:disable-next-line: no-string-literal
        this.selectedFile['fileName'] = this.fileName;
        this._model = this.fileNameArr;
        this.onChange(this._model);
      }
    } else {
      if (this.selectedFile === undefined) {
        this.selectedFile = [];
      }
      const files = event.target.files;

      if (files) {
        const tempArr = [];
        for (const file of files) {
          if (!this.checkFileSizeWithinLimits(file)) {
            this.notification.warn('Upload file size cannot be greater than 32 MB');
            return;
          }
          this.fileNameArr.push(file);
          // tslint:disable-next-line:no-string-literal
          file['objectKey'] = this.objectKey;
          // tslint:disable-next-line:no-string-literal
          file['fieldName'] = this.fieldName;
          // tslint:disable-next-line: no-string-literal
          file['fileName'] = file.name;

          if (this.Imgregex.test(file.name.toLowerCase())) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
              // tslint:disable-next-line: no-string-literal
              file['url'] = e.target.result;
            };
            reader.readAsDataURL(file);
          } else {
            let baseAssetUrl = './assets/';
            if (this.element.useCustomAssets) {
              baseAssetUrl = './custom/assets/';
            }
            let imgUrl = '';
            switch (file.type) {
              case 'application/pdf':
                imgUrl = `${baseAssetUrl}/images/pdf.png`;
                break;
              case 'application/zip':
                imgUrl = `${baseAssetUrl}/images/zip.png`;
                break;
              case 'application/doc':
              case 'application/docx':
              case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                imgUrl = `${baseAssetUrl}/images/doc.png`;
                break;
              case 'application/xls':
              case 'application/xlsx':
              case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                imgUrl = `${baseAssetUrl}/images/xls.png`;
                break;
              case 'application/plain':
                imgUrl = `${baseAssetUrl}/images/txt.png`;
                break;
              default:
                imgUrl = `${baseAssetUrl}/images/txt.png`;
                break;
            }
            // tslint:disable-next-line: no-string-literal
            file['url'] = imgUrl;
          }
          file.id = this.utilService.getRandomNum();
          tempArr.push(file);
        }
        if (!this._model) {
          this._model = [];
        }
        this._model = [...this._model, ...tempArr];
        this.onChange(this._model);
      }
    }
  }

  removeFile(file) {
    let tempArr = this._model;
    this.fileNameArr = [];
    tempArr = tempArr.filter(f => {
      if ((f.id !== file.id && f.id) || (!f.id && f.id !== file.id)) {
        this.fileNameArr.push(f);
      }
      return (f.id && f.id !== file.id) || (!f.id && f.id !== file.id);
    });
    this._model = tempArr.filter(arr => !!arr);
    this.onChange(this._model);
    $('.custom-file-upload input').val('');
  }

  removeSingleFile(file) {
    this.fileNameArr = [];
    this._model = [];
    this.onChange(this._model);
    $('.custom-file-upload input').val('');
  }

  private showUrlsFromData(urls) {
    this.fileNameArr = [];
    if (Array.isArray(urls) && urls && urls.length) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < urls.length; i++) {
        if (urls[i]) {
          this.fileNameArr.push(urls[i]);
        }
      }
    }
  }





}
