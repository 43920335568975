import { Component, Input, OnInit } from "@angular/core";
import { LabsCustomService } from "@custom/modules/labs-custom/labs-custom.service";
import { CustomElementService } from "../custom-element.service";

@Component({
    selector: 'app-custom-controls',
    templateUrl: './custom-controls.component.html',
    styleUrls: ['./custom-controls.component.scss'],
})

export class CustomCotrolsComponent implements OnInit{

    @Input() currentForm: any;
	@Input() currentElement: any;

    srcPrinterModels:any = [];
    destPrinterModels:any = [];
    printerModelLimitchecked = false;
    isShowPrinterModels = false;
    disabled = false;
    invalid = false;

    elementTypes:any = { }

    constructor(
        private labsCustomService:LabsCustomService,
        private customElementService:CustomElementService
    ){}

    ngOnInit(): void {
        const _that = this;
        this.customElementService.componentFactory = {
            name : _that.currentElement.name,
			comp : _that
        };
        this.elementTypes.printerModelLimit = this.currentElement.label == 'PRINTER_MODEL_LIMIT' ;
        if(this.elementTypes.printerModelLimit){
            this.srcPrinterModels = this.labsCustomService.printerModels.slice(0);
        }
    }

    setData(data){
        if(this.elementTypes.printerModelLimit){
            const printerModels = this.labsCustomService.printerModels.slice(0);
            this.printerModelLimitchecked = data.printerModelLimited || false;
            this.destPrinterModels = printerModels.filter(item => (data.printers || []).includes(item.value));
            this.srcPrinterModels = printerModels.filter(item => !(data.printers || []).includes(item.value));
        }
    }

    getData(data){
        if(this.elementTypes.printerModelLimit){
            return {
                printerModelLimited : this.printerModelLimitchecked || false,
                printers : this.destPrinterModels.map(item => item.value) || []
            }
        }
    }

    onChange(type){
        switch (type) {
            case 'printerModelLimitchecked':
                if(!this.printerModelLimitchecked){
                    this.destPrinterModels = [];                    
                    this.srcPrinterModels = this.labsCustomService.printerModels.slice(0);
                }
                this.setValid();
                break;
        
            default:
                break;
        }
    }

    setDisabled(val){
        this.disabled = val || false;
    }

    onMovePickListItem(){
        this.setValid();
    }

    setValid(){
        if(this.elementTypes.printerModelLimit){
            if(!this.printerModelLimitchecked){
                this.invalid = false
            }else{
                this.invalid = this.destPrinterModels.length ? false : true;
            }
        }
    }
    

}