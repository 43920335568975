import { Injectable } from '@angular/core';
import { AppGlobalService } from '@app/app-global.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
    static settings: any;
    constructor( private globalService: AppGlobalService ) {}
    getFirebaseConfig() {
        return window['firebaseConfig'];
    }
  };