import { UtilService } from '@app/core/util.service';
import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { AppConstants } from '@custom/core/app-constants';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { VsChangelogMobileComponent } from './vs-changelog-mobile/vs-changelog-mobile.component';

@Component({
  selector: 'app-vs-changelog',
  templateUrl: './vs-changelog.component.html',
  styleUrls: ['./vs-changelog.component.scss']
})
export class VsChangelogComponent implements OnInit, OnChanges {
  @Input() changelogConfig: any;
  previousState: any;
  previousStateParams: any;
  comparegridDT: any;
  compareDTApi: any;
  compareGridData: any;
  listgridDT: any;
  listgridApi: any;
  filterList = [];
  selectedFilter: any;
  @ViewChild('comparegrid') private comparegrid: TemplateRef<any>;
  dialog: any;
  isMobile = AppConstants.isMobile;
  public config = {
    changeLogListGrid: { data: {} },
    changeLogCompareGrid: {}
  };
  leftGridLoaded: boolean;

  constructor(
    private utilService: UtilService,
    private element: ElementRef,
    private modalService: NgbModal
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterList = changes.changelogConfig.currentValue.filters;
  }

  ngOnInit() {
    this.config.changeLogListGrid = this.loadGridConfig();
    this.config.changeLogCompareGrid = this.loadCompareGridConfig();
  }

  selectFilter(value) {
    if (!value) return;
    this.selectFilter = value;
  }

  // TODO
  public setDefaultFields(field, showLabel, classes, mobileContent, data) {
    mobileContent.mainContent[field] = {
      data: data[field],
      showLabel,
      classes
    };
  }
  public setAdditionalFields(mobileContent, data) {
    mobileContent.innerContent = {};
    Object.keys(data).forEach(item => {
      if (mobileContent.mainContent.hasOwnProperty(item)) {
        mobileContent.innerContent[item] = {
          data: data[item]
        };
      }
      return item;
    });
  }

  mobileContent(row: Node | any, data: any[] | object, index: number, mobileContent: any) {
    mobileContent.mainContent = {};
    this.setDefaultFields('createdDate', false, 'col-12 custom', mobileContent, data);
    this.setDefaultFields('createdBy', false, 'col-12', mobileContent, data);
    this.setDefaultFields('remarks', false, 'col-12', mobileContent, data);
    this.setAdditionalFields(mobileContent, data);
    return mobileContent;
  }

  mobileContentCompareGrid(row: Node | any, data: any[] | object, index: number, mobileContent: any) {
    mobileContent.mainContent = {};
    this.setDefaultFields('attributeLabel', false, 'col-12 custom mobile-column', mobileContent, data);
    this.setDefaultFields('old_value', false, 'col-6 mobile-column', mobileContent, data);
    this.setDefaultFields('new_value', false, 'col-6 mobile-column', mobileContent, data);
    this.setAdditionalFields(mobileContent, data);
    return mobileContent;
  }

  private loadGridConfig(): any {
    const self = this;
    let gridHeight: number;
    const currentEl = this.element.nativeElement;
    const gridEl = currentEl.querySelector('app-vs-grid');
    const gridElOffsetTop = gridEl.querySelector('div').offsetTop + (AppConstants.isMobile ? 40 : 80);
    gridHeight = window.innerHeight - gridElOffsetTop;
    const gridConfig: any = {
      ordering: false,
      customOrdering: true,
      uniqueIdField: 'sid',
      mobileTemplate: VsChangelogMobileComponent,
      mobileContent: this.mobileContent.bind(this),
      columns: [
        {
          data: 'createdDate',
          name: 'createdDate',
          title: 'MODIFIED_ON',
          type: 'date',
        },
        {
          data: 'createdBy',
          name: 'createdBy',
          title: 'MODIFIED_BY',
          type: 'date',
        },
        {
          data: 'remarks',
          name: 'remarks',
          title: 'REMARKS',
          type: 'string',
        }
      ],
      disableSelection: true,
      onRowSelect: (selectedRows: any, id: any) => {
        console.log(selectedRows, id);
        console.log('on row click');
      },
      onInitComplete: (dtTable: any, settings: any, json: any) => {
        this.listGridInitComplete(dtTable, settings, json, false);
      },
      onBeforeGridServiceRequest: (params: any, isFromSort: boolean, isFromRefresh: boolean) => {

      },
      onAfterGridServiceResponse: (params: any, isFromSort: boolean, isFromRefresh: boolean, data: any) => {

      },
      onRowCreate: (row: any, data: any, dataIndex: any) => {

      },
      onRowClick: (event: any, id: any) => {
        const curElem = $(event.currentTarget).parents('tr');
        this.changeLogShow(curElem);
      },
      drawCallback: (settings: any) => {

      },
      headerCallback: (thead: any, data: any, start: any, end: any, display: any) => {
      },
      preDrawCallback: (settings: any) => {

      },
      rowCallback: (row: any, data: any, index: any) => {
        console.log('on row callback');
        this.listGridRowCallback(row, data, index);
      },
      maxRowsAllowedForSelection: AppConstants.maxRowsAllowedToSelect,
      rowMenuOptions: [],
      beforeRowMenuShow: (option: any, data: any, row: any) => {
      },
      onRowMenuClick: (option: any, row: any, data: any) => {
      },
      onMobileRowDisplay: (row: Node, data: any[] | object, index: number) => {
        return row;
      },
      complexHeader: []
    };
    gridConfig.ajaxMethod = 'POST';
    gridConfig.ajaxUrl = this.getChangeLogListGridUrl();
    return gridConfig;
  }


  listGridRowCallback(row, data, rowIndex, colIndex?: any) {
    if (rowIndex !== 0) return;
    $(row).addClass('selected-tr');
  }
  private changeLogShow(curElem) {
    if ($(curElem).hasClass('selected')) {
      $(curElem).removeClass('selected');
    } else {
      $('tr.selected').removeClass('selected');
      $(curElem).addClass('selected');
    }
    const cRowId = $(curElem).prop('id');
    const nRowId = $($(curElem).next()[0]).prop('id') || '';
    const data1 = this.listgridApi.row($(curElem)).data() || {};
    const data2 = nRowId ? this.listgridApi.row($(curElem).next()[0]).data() || {} : {};
    this.getChangeLog(cRowId, nRowId, data1, data2);
    if (this.isMobile)
      this.showChangelog();
  }
  private loadCompareGridConfig(): any {
    const self = this;
    let gridHeight: number;
    const currentEl = this.element.nativeElement;
    const gridEl = currentEl.querySelector('app-vs-grid');
    const gridElOffsetTop = gridEl.querySelector('div').offsetTop + (AppConstants.isMobile ? 40 : 80);
    gridHeight = window.innerHeight - gridElOffsetTop;



    const gridConfig: any = {
      ordering: false,
      customOrdering: true,
      mobileTemplate: VsChangelogMobileComponent,
      mobileContent: this.mobileContentCompareGrid.bind(this),
      columns: [
        {
          data: 'attributeLabel',
          name: 'attributeLabel',
          title: 'ATTRIBUTE',
          type: 'string',
        },
        {
          data: 'old_value',
          name: 'old_value',
          title: 'OLD_VALUE',
          type: 'string',
        },
        {
          data: 'new_value',
          name: 'new_value',
          title: 'NEW_VALUE',
          type: 'string',
        }
      ],
      disableSelection: true,
      onRowSelect: (selectedRows: any, id: any) => {
      },
      onInitComplete: (dtTable: any, settings: any, json: any) => {
        this.compareGridInitComplete(dtTable, settings, json, false);
      },
      onBeforeGridServiceRequest: (params: any, isFromSort: boolean, isFromRefresh: boolean) => {
      },
      onAfterGridServiceResponse: (params: any, isFromSort: boolean, isFromRefresh: boolean, data: any) => {
      },
      onRowCreate: (row: any, data: any, dataIndex: any) => {
      },
      onRowClick: (event: any, id: any) => {
        console.log('on row click');
      },
      drawCallback: (settings: any) => {
      },
      headerCallback: (thead: any, data: any, start: any, end: any, display: any) => {
      },
      preDrawCallback: (settings: any) => {
      },
      rowCallback: (row: any, data: any, index: any) => {
        console.log('on row select');
      },
      maxRowsAllowedForSelection: AppConstants.maxRowsAllowedToSelect,
      rowMenuOptions: [
      ],
      beforeRowMenuShow: (option: any, data: any, row: any) => {
      },
      onRowMenuClick: (option: any, row: any, data: any) => {
      },
      onMobileRowDisplay: (row: Node, data: any[] | object, index: number) => {
        return row;
      },
      complexHeader: []
    };
    gridConfig.data = [];
    return gridConfig;
  }

  getChangeLogListGridUrl() {
    if (!this.changelogConfig) return '';
    const configUrl = (this.selectedFilter && this.selectedFilter.listUrl ? this.selectedFilter.listUrl : this.changelogConfig.listUrl);
    if (!configUrl) return '';
    let urlPrefix: any;
    let urlSuffix = '';
    const url = urlPrefix = configUrl;
    if (url.indexOf('?') !== -1) {
      const split = url.split('?');
      urlPrefix = split[0];
      urlSuffix = split[1];
    }
    if (!this.changelogConfig.useModifiedDate) {
      // urlPrefix += '?record_id=' + (($stateParams.record_id) ? $stateParams.record_id : '');
    }
    return urlPrefix + ((urlSuffix) ? '&' + urlSuffix : '');
  }

  onListGridDataLoaded(json, param, oSettings) {
    if (param.start === 0 && json.data && json.data.length > 0) {
      let id1: any;
      let id2: any;
      if (json.data[0]) id1 = json.data[0].DT_RowId || json.data[0].id;
      if (json.data[1]) id2 = json.data[1].DT_RowId || json.data[1].id;
      this.getChangeLog(id1, id2, json.data[0], json.data[1]);
    } else if (param.start === 0) {
      this.getChangeLog();
    }
  }

  refreshCompareGrid(url) {
    this.compareDTApi.clear();
    setTimeout(() => this.compareDTApi.fnReloadAjax(url));
  }

  clearCompareGrid() {
    setTimeout(() => {
      const rows = this.compareDTApi.rows().nodes();
      this.compareDTApi.clear();
      $(rows).remove();
    });
  }

  compareGridInitComplete(grid, settings, json, reOrder) {
    this.compareDTApi = grid;
    this.compareDTApi.rows.add(this.compareGridData).draw();
  }

  refreshListGrid() {
    this.listgridApi.clear();
    setTimeout(() => {
      this.listgridDT.fnReloadAjax(this.getChangeLogListGridUrl());
    });
  }

  clearListGrid() {
    setTimeout(() => {
      const rows = this.listgridApi.rows().nodes();
      this.listgridApi.clear();
      $(rows).remove();
    });
  }

  listGridInitComplete(grid, settings, json, reOrder) {
    this.listgridDT = settings;
    this.listgridApi = grid;
    /* if (this.getChangeLogListGridUrl() !== this.listgridDT.oInit.ajax.url) {
      this.refreshListGrid();
    } */
    if (this.adjustGridHeight) this.adjustGridHeight();
    const curElem = $($(settings.nTBody).find('tr')[0]);
    this.changeLogShow(curElem);
    this.leftGridLoaded = true;
  }

  adjustGridHeight() {
    setTimeout(() => {
      const currentEl = this.element.nativeElement;
      console.log(currentEl);
      const changelog = currentEl.querySelector('.vs-changelog .dataTables_scrollBody');
      const offset = changelog.offset() || { top: 0 };
      const windowHeight = $(window).height() || 600;
      changelog.css('min-height', (windowHeight - offset.top - 100) + 'px');
    }, 100);
  }

  prepareCompareGridData(data1, data2) {
    if (!data1) data1 = {};
    if (!data2) data2 = {};
    const json1 = data1.value || {};
    const json2 = data2.value || {};
    const value1 = typeof json1 === 'object' ? json1 : JSON.parse(json1);
    const value2 = typeof json2 === 'object' ? json2 : JSON.parse(json2);
    const finalData = this.manipulateObject(value1, value2);
    finalData.sort((a, b) => {
      const val1 = a.attribute;
      const val2 = b.attribute;
      if (val1 === val2) return 0;
      if (val1 > val2) return 1;
      if (val1 < val2) return -1;
    });
    return finalData;
  }

  manipulateObject(value1, value2, prefix?: string) {
    if (!value1) value1 = {};
    if (!value2) value2 = {};

    if (typeof value1 === 'string' || typeof value1 === 'number' || typeof value1 === 'boolean'
      || typeof value2 === 'string' || typeof value2 === 'number' || typeof value2 === 'boolean') {
      return [this.getObject(prefix, value1, value2)];
    }

    let finalData = [];
    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);

    for (let index = 0; index < keys1.length; index++) {
      finalData = finalData.concat(this.processValues(keys1[index], value1[keys1[index]], value2[keys1[index]], prefix));
      const key2Index = keys2.indexOf(keys1[index]);
      if (key2Index === -1) continue;
      keys2.splice(key2Index, 1);
    }

    for (let index = 0; index < keys2.length; index++) {
      finalData = finalData.concat(this.processValues(keys2[index], value1[keys2[index]], value2[keys2[index]], prefix));
    }
    return finalData;
  }

  getFieldConfigs(fieldType: any, defaultData?: any) {
    if (this.selectedFilter && this.selectedFilter[fieldType]) return this.selectedFilter[fieldType];
    if (this.changelogConfig && this.changelogConfig[fieldType]) return this.changelogConfig[fieldType];
    return defaultData || [];
  }

  getJson(jsonString: any) {
    if (!jsonString || typeof jsonString === 'number') return false;
    if (typeof jsonString === 'object') return jsonString;
    try {
      return JSON.parse(jsonString);
    } catch (e) { }
    return false;
  }

  processValues(key: any, value1: any, value2: any, prefix: any) {
    let result = [];
    if (!prefix) prefix = '';
    const newKey = prefix + key;
    if (this.getFieldConfigs('ignoreFields').indexOf(newKey) !== -1) return result;
    const json = this.getJson(value1);
    const json2 = this.getJson(value2);
    if (json || json2) {
      if (Array.isArray(json) || Array.isArray(json2)) {
        const cmp1 = json || [];
        const cmp2 = json2 || [];
        let cmp2Index = 0;
        for (let index = 0; index < cmp1.length; index++) {
          result = result.concat(this.manipulateObject(cmp1[index], cmp2[index], newKey + '[' + index + '].'));
          cmp2Index = (index + 1);
        }

        for (let index = cmp2Index; index < cmp2.length; index++) {
          result = result.concat(this.manipulateObject({}, cmp2[index], newKey + '[' + index + '].'));
        }
      } else if ((json === undefined || json == null || typeof json === 'number' || typeof json === 'string' || typeof json === 'boolean')
        && (json2 === undefined || json2 == null || typeof json2 === 'number' || typeof json2 === 'string' || typeof json2 === 'boolean')) {
        result.push(this.getObject(newKey, value1, value2));
      } else {
        result = this.manipulateObject(json, json2, newKey + '.');
      }
      return result;
    }
    result.push(this.getObject(newKey, value1, value2));
    return result;
  }

  getObject(key: any, value1: any, value2: any) {
    key = key.replace(/[\.]*$/, '');
    const object = { attribute: key, old_value: '', new_value: '', formattedKey: '', attributeLabel: '', class: '', DT_RowClass: '' };
    object.formattedKey = key.replace(/(\[\d+\])/g, '');
    object.attributeLabel = this.getFieldConfigs('translations', {})[object.formattedKey] || key;

    if (value1 != null && typeof value1 === 'object') {
      value1 = JSON.stringify(value1);
      if (value1 === '{}') value1 = '';
    }
    if (value2 != null && typeof value2 === 'object') {
      value2 = JSON.stringify(value2);
      if (value2 === '{}') value2 = '';
    }

    if (this.getFieldConfigs('dateFields').indexOf(object.formattedKey) !== -1) {
      value1 = this.utilService.formatDate(value1, this.getFieldConfigs('dateFormat', AppConstants.dateFormat));
      value2 = this.utilService.formatDate(value2, this.getFieldConfigs('dateFormat', AppConstants.dateFormat));
    } else if (this.getFieldConfigs('dateTimeFields').indexOf(object.formattedKey) !== -1) {
      value1 = this.utilService.formatDateTime(value1, this.getFieldConfigs('dateTimeFormat', AppConstants.dateTimeFormat));
      value2 = this.utilService.formatDateTime(value2, this.getFieldConfigs('dateTimeFormat', AppConstants.dateTimeFormat));
    }

    const formatter = this.getFieldConfigs('formatter');
    if (formatter && typeof formatter === 'function') {
      value1 = formatter(value1, key, object.formattedKey);
      value2 = formatter(value2, key, object.formattedKey);
    }

    if (value1 !== undefined && value1 !== null) object.new_value = value1;
    if (value2 !== undefined && value2 !== null) object.old_value = value2;
    object.class = object.DT_RowClass = (value1 !== value2) ? 'differ' : 'no-differ';
    return object;
  }
  getChangeLog(cRowId?: any, nRowId?: any, data1?: any, data2?: any) {
    const compareIds = [];
    if (!cRowId && !nRowId) {
      this.clearCompareGrid();
      return;
    }
    if (cRowId) compareIds.push(cRowId);
    if (nRowId) compareIds.push(nRowId);
    const finalData = this.prepareCompareGridData(data1, data2);
    this.compareGridData = finalData;
    if (!this.isMobile) {
      this.compareDTApi.clear();
      this.compareDTApi.rows.add(finalData).draw();
    }
  }

  showChangelog(): void {
    const options: NgbModalOptions = {
      backdrop: 'static',
      centered: true,
      windowClass: 'modal-changelog-comparegrid',
      size: 'lg',
    };
    this.dialog = this.modalService.open(this.comparegrid, options);
  }

  cancelModal() {
    if (this.dialog) {
      this.dialog.dismiss();
      this.dialog = null;
    } else {
      this.modalService.dismissAll();
    }
  }
}
