import { Component, OnInit, Input, ViewChild, Output, ElementRef, ViewEncapsulation, HostListener, OnChanges, ComponentFactoryResolver, Injector, ApplicationRef, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { cleanSession } from 'selenium-webdriver/safari';
import { NgbDateStruct, NgbCalendar, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '@app/core/util.service';
import { VsFormControl } from './TestSuiteFormControl';
import { NotificationService } from '@app/shared/services/notification.service';
import * as _ from 'lodash';
import { CustomTabService } from '@custom/modules/gen/custom-tab/custom-tab.service';
import { ComponentCanDeactivate } from './ComponentCanDeactivate';
import { AppConstants } from '@custom/core/app-constants';
import { FormStaticSecurityService } from './form-static-security.service';
import { ModalPopupService } from '@app/shared/services/modal-popup.service';
import { AuthenticationService } from '@app/auth/authentication.service';
import { multipleValidator } from './multipleValidator';
import expressions from 'angular-expressions';

@Component({
  selector: 'app-test-suite-form',
  templateUrl: './test-suite-form.component.html',
  styleUrls: ['./test-suite-form.component.scss']
})

export class TestSuiteFormComponent implements OnInit, OnChanges {

  @Input() formConfig: any;
  @Input() formData: any = {};
  @Input() commentData: any;
  @Input() attachmentData: any = {};
  @Input() splitSection: boolean;
  @Input() onWizardChange: any = () => { };
  @Input() formModalChange: (data: any, resMeth: any) => void;
  @Input() showSectionButtons: boolean = false;
  @Input() readOnly: boolean = false;

  


  @ViewChild('formRef') formRef: FormGroupDirective;
  @ViewChild('submitButton') submitButton: ElementRef<HTMLButtonElement>;

  @Output() formButtonClick = new EventEmitter();
  @Output() sectionAddClick = new EventEmitter();
  @Output() sectionRemoveClick = new EventEmitter();

  form: FormGroup;
  formGroup: any = [];
  unsubcribe: any;
  layoutData: any;
  bootStrapCol: any;
  workFlowMatrixJson: any;
  tabControls: any = [];
  backupData: any;
  isInlineForm = false;
  isMobile: boolean;
  formSubmitted = false;
  primarySections = [];
  primarySectionsIds = [];
  conflictedPrimarySectionsIds = {};
  fieldToSectionIdMap = {};
  wizardLayout = false;
  scrollSection: {};
  scrollTimer: any;
  popoverAttachments: any;
  collapsedSection: any = {};
  currentSection: number = 0;

  concurrentData:any = {};
  myData:any = {};
  fieldsToMerge:any = {};
  commentTomerge:any = {};
  attachmentsTomerge:any = {};
  mergeunResolvedFields = {
    data : {},
    comments : {},
    attachments : {}
  };
  displayFormatter;
  mergeMode = false;
  isCheckingConflicts = false;
  mergedData = {
    data : {},
    comments : {},
    attachments : {}
  };
  mergeButtonsDisabled = {};
  mergeMetaData:any = {};
  //private tableFields = [];
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private notification: NotificationService,
    private staticSecurityService: FormStaticSecurityService,
    private customTab: CustomTabService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private app: ApplicationRef,
    private modalPopupService: ModalPopupService,
    private auth: AuthenticationService
  ) { }

  previousSection() {
    //Save data if needed.
    let formData = this.form.getRawValue();
    this.currentSection--;
    this.form.patchValue(formData);
  }

  nextSection() {
    let formData = this.form.getRawValue();
    this.currentSection++;
    this.form.patchValue(formData);
  }
  resetSection() {
    const currentFormGroup = this.formConfig.groups[this.currentSection];
    const fields = this.getFieldsInFormGroup(currentFormGroup);
    fields.forEach(fieldName =>{
      const control = this.form.get(fieldName);
      delete this.commentData[fieldName];
      delete this.attachmentData[fieldName];
      if(control) {
        control.reset();
      }
    });
    
   // this.currentSection;
  }



  getFieldsInFormGroup(formGroup, fields = []){
    if(formGroup){
      if(formGroup.hasOwnProperty('items')){
        formGroup.items.forEach(item => {
          this.getFieldsInFormGroup(item, fields);
        });
      }else{
        if(formGroup.name){
          fields.push(formGroup.name)
        }
      }
    }
    return fields;
  }

  validateForm(): boolean {
    const submitButton: HTMLButtonElement = this.submitButton.nativeElement;
    submitButton.click();
    return this.form.valid;
  }

  attachmentPopoverClick(element) {
    this.popoverAttachments = element.attachments;
  }

  getAttachmentURL(attachmentId) {
    return `${AppConstants.apihost}/attachments/download/attachment/${attachmentId}`;
  }

  attachmentPopupClick(element) {
    let popupFormConfig = {
      initCallback: () => { },
      collapsible: false,
      submit: 'saveBasicDetails',
      class: 'no-margin form-elements-group',
      securityEvaluation: false,
      staticSecurityJson: "",
      disabledForm: element.disabled,
      showPrimarySectionWizard: false,
      columns: 100,
      groups: [
        {
          groupHeaderClass: 'form-group-subheader display-none',
          groupContentClass: 'paddingZero',
          collapsible: 'false',
          columns: '100',
          label: '',
          disableRights: false,
          columnsWidth: 12,
          items: [
            {
              security_code: 'attachment',
              label: '',
              type: 'file',
              name: 'attachment',
              placeholder: '',
              infoBubble: '',
              multivalue: true,
              values: [],
              options: [],
              disabled: element.disabled,
              tableConfig: {
                columns: [],
                name: 'locationName'
              },
              toolbar: false,
              displayonly: false,
              validations: {
                required: false,
              },
            }
          ]
        }
      ]
    };
    // this.attachmentData = {lab_bfdf_3883_85da_f011_74d9_attachment:[{id:"sdfsd","fileName":"file name"}]}
    this.modalPopupService.openModalPopupWithForm(popupFormConfig, 
      { 'attachment': this.attachmentData[element.name] },
      { size: 'md', windowClass: "test-suite-field-attachment" },
      element.label + " - Attachments",
      { primary: 'OK', secondary: 'Cancel'},
      null,
      element.disabled).subscribe(data => {
      if (data && data.attachment) {
        this.attachmentData[element.name] = data.attachment;
      }
    })
  }

  openCommentPopup(element) {
    let locationDetailFormConfig = {
      initCallback: () => { },
      collapsible: false,
      submit: 'saveBasicDetails',
      class: 'no-margin form-elements-group gs-location-detail-form',
      securityEvaluation: false,
      staticSecurityJson: "",
      disabledForm: element.disabled,
      showPrimarySectionWizard: false,
      columns: 100,
      groups: [
        {
          groupHeaderClass: 'form-group-subheader display-none',
          groupContentClass: 'paddingZero',
          collapsible: 'false',
          columns: '100',
          label: '',
          disableRights: false,
          columnsWidth: 12,
          items: [
            {
              security_code: 'comment',
              label: '',
              type: 'textarea',
              name: 'comment',
              placeholder: '',
              infoBubble: '',
              values: [],
              options: [],
              disabled: element.disabled,
              tableConfig: {
                columns: [],
                name: 'locationName'
              },
              toolbar: false,
              displayonly: false,
              validations: {
                required: false,
              },
            }
          ]
        }
      ]
    };
    let prop = { size: 'md', windowClass: "test-suite-field-comment" };
    const modalSubject = this.modalPopupService.openModalPopupWithForm(
      locationDetailFormConfig,
      {
        comment: this.commentData[element.id]
      },
      prop,
      "COMMENT",
      null,
      null,
      element.disabled);
    modalSubject.subscribe(response => {
      if (response) {
        this.commentData[element.id] = response.comment
      }
    })
  }

  public isRequired(validators, control?) {
    if (validators && validators.required && this.utilService.getBoolean(validators.required)) {
      return true;
    }
    return false;
  }

  private getPatternValidations(validators) {

    return validators.accepted_characters ? new RegExp(validators.accepted_characters) : new RegExp(validators.not_accepted_characters);
  }

  private setAllValidations(control: any) {
    const validations = [];
    const validators = control.validations || {};
    if (this.isRequired(validators) && !validators.mandatory_Condition) {
      validations.push(Validators.required);
    }
    const isNumberField = control.type !== 'number' || control.type !== 'customNumber';
    if (validators.max_length && isNumberField) { validations.push(Validators.maxLength(validators.max_length)); }
    if (validators.min_length && isNumberField) { validations.push(Validators.minLength(validators.min_length)); }
    if (validators.max_value) { validations.push(Validators.max(validators.max_value)); }
    if (validators.min_value) { validations.push(Validators.min(validators.min_value)); }
    if (validators.accepted_characters || validators.not_accepted_characters) {
      validations.push(Validators.pattern(this.getPatternValidations(validators)));
    }
    if(typeof control.multiple == 'number' && isNumberField){
      validations.push(multipleValidator(control.multiple || 1));
    }

    if (control.type === 'email') { validations.push(Validators.email); }

    return validations;
  }

  private setFormControl(data) {
    if (data.length > 0) {
      data.forEach((item: any) => {
        if (item.name) {
          const allValidations = this.setAllValidations(item);
          const name = this.getFieldName(item.type, item.name);
          const isDisabled = item.disabled || item.disableRights;
          if (name instanceof Object === true) {
            for (const key in name) {
              if (name.hasOwnProperty(key)) {
                this.formGroup[name[key]] = new FormControl({ value: '', disabled: isDisabled }, allValidations);
              }
            }
          } else if (item.type === 'tab') {
            const controls = this.getAllControls(item);
            controls.forEach(ctrl => {
              if (ctrl.type !== 'tab' && ctrl.type !== 'childList' && ctrl.type !== 'formbutton' && ctrl.type !== 'customelement' && ctrl.type) {
                this.formGroup[ctrl.name] = new FormControl({ value: '', disabled: ctrl.disabled || ctrl.disableRights }, allValidations);
              }
            });
          } else if (!item.hasOwnProperty('items') && item.type !== 'childList' && item.type !== 'formbutton' && item.type !== 'tab') {
            this.formGroup[item.name] = new FormControl({ value: '', disabled: isDisabled }, allValidations);
            this.formGroup[item.name].validatorList = allValidations;
          }


        }
        if (item.items && item.items.length > 0) {
          this.setFormControl(item.items);
        }

        if (item.tabs && item.tabs.length > 0) {
          this.setFormControl(item.tabs);
        }

      });
    }
  }

  private getElementsRecursively(element) {

    if (element.name && !element.hasOwnProperty('items')) {
      if (element.name.indexOf('__tab') === -1) {
        this.tabControls.push(element);
      }
    }
    if (element.items) {
      element.items.forEach(elem => {
        this.getElementsRecursively(elem);
      });
    }
    if (element.tabs) {
      element.tabs.forEach(elem => {
        this.getElementsRecursively(elem);
      });
    }
    if (element.form && element.form.items) {
      element.form.items.forEach(elem => {
        this.getElementsRecursively(elem);
      });
    }
    if (element instanceof Array) {
      element.forEach(elem => {
        this.getElementsRecursively(elem);
      });
    }

  }

  private getAllControls(element) {
    console.log(element);
    this.tabControls = [];
    const items = element.tabs.forEach(tab => {
      return tab.items;
    });
    this.getElementsRecursively(items);

    return this.tabControls;
  }

  private setFormData() {
    if (this.formData) {
      this.form.patchValue(this.formData);
    }
  }

  submitForm(f: FormGroup) {
    this.getFormValidationErrors();
    this.formSubmitted = true;
    console.log(f.value);
  }

  isOptionsWithObject(options: Array<any>): boolean {
    return options && options[0].name && options[0].value ? true : false;
  }

  getSelectedOption(name, options) {
    if (!this.formData[name]) {
      this.formData[name] = options[0];
      this.form.patchValue({
        [name]: options[0]
      });
      return options[0];
    }
    return this.formData[name];
  }

  collapseClick(data, eve) {
    if (data.collapsible) {
    }
  }

  handleTabChange(event: NgbTabChangeEvent) {
    console.log('tab changed');
    this.customTab.handleTabChange(event);
  }

  getDefaultSelectedValue(fieldName, options) {
    if (this.form.value[fieldName]) {
      return this.form.value[fieldName];
    }
    return options[0];
  }

  getFieldName(type, modelName) {
    switch (type) {
      case 'currency':
        return {
          code: `${modelName}Currency`,
          value: `${modelName}Value`
        };
      default:
        return modelName;
    }
  }

  getFieldLabel(field) {
    const elem = document.querySelectorAll(`label[for=${field}]`)[0];
    if (!elem) { return field; }
    if (!elem.textContent && document.querySelectorAll(`label[for=${field}]`)[1] && document.querySelectorAll(`label[for=${field}]`)[1].textContent) {
      return document.querySelectorAll(`label[for=${field}]`)[1].textContent.replace(/[*]/g, '');
    }
    return elem.textContent.replace(/[*]/g, '');
  }

  getFieldLabelForMandatoryCondition(condition) {
    const conditionSplitterRegex = new RegExp('([\\w]+(\\s)*)(==|!=|<|>|<=|>=){1,1}((\\s)*[\\w]+)');
    const tempSplit = condition.split(conditionSplitterRegex);
    const finalSplit = tempSplit.filter(item => !!item.trim());

    const operatorString = {
      '==': 'is',
      '===': 'is',
      '!=': 'is not',
      '!==': 'is not',
      '<': 'is less than',
      '>': 'is greater than',
      '<=': 'is less & equal to',
      '>=': 'is greater & equal to'
    };

    const boolToString = {
      true: 'Yes',
      false: 'No'
    };

    return {
      lhs: finalSplit[0].trim(),
      operator: operatorString[finalSplit[1].trim()],
      rhs: boolToString[finalSplit[2].trim()] ? boolToString[finalSplit[2].trim()] : finalSplit[2].trim()
    };
  }

  getErrorLabel(error) {
    switch (error) {
      case 'mandatoryCondition':
        return 'not matching its mandatory condition';
      case 'customMax':
      case 'max':
        return 'not matching its max-value condition';
      case 'customMin':
      case 'min':
        return 'not matching its min-value condition';
      case 'maxLength':
      case 'max_length':
      case 'maxlength':
        return 'not matching its max-length condition';
      case 'minLength':
      case 'min_length':
      case 'minlength':
        return 'not matching its min-length condition';
      case 'pattern':
        return 'not matching its accepted pattern';
      case 'email':
        return 'not valid';
      case 'tableMax':
        return 'not matching max-value condition';
      case 'tableMin':
        return 'not matching min-value condition';
      case 'multiple':
      case 'tableMultiple':
        return 'not matching multiple condition';
      default:
        return error;
    }
    // return error;
  }

  

  getFormValidationErrors(fieldsToValidate = ['*']) {
    let isValid = true;
    const finalArr = [];
    const allErrors = {};
    const sectionErrors: any = {};
    const sectionFieldConfig = this.getFieldsInSplitSections();
    const sectionFields = sectionFieldConfig.sectionFields;
    Object.keys(this.form.controls).forEach(key => {
      if(fieldsToValidate.includes(key) || fieldsToValidate.includes('*')){
        const fieldSectionId = this.getSectionIdOfField(sectionFields, key);
        if(fieldSectionId){
  
          sectionErrors[fieldSectionId] = sectionErrors[fieldSectionId] || {};
    
          const controlErrors: ValidationErrors = this.form.get(key).errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).filter(key => controlErrors[key]).forEach(keyError => {
              if (!allErrors[keyError]) { allErrors[keyError] = []; }
              if (!sectionErrors[fieldSectionId][keyError]) { sectionErrors[fieldSectionId][keyError] = []; }
              allErrors[keyError].push(this.getFieldLabel(key));
    
              const fieldName = sectionFieldConfig.fieldNames[key]
    
              if (!sectionErrors[fieldSectionId][keyError].includes(fieldName)) {
                sectionErrors[fieldSectionId][keyError].push(fieldName);
              }
            });
        }
        }
      }
    });

    for (const error in allErrors) {
      if (allErrors.hasOwnProperty(error)) {
        const element = allErrors[error];
        finalArr.push(`${allErrors[error].join(', ')} ${allErrors[error].length > 1 ? 'are' : 'is'} ${this.getErrorLabel(error)}`);
      }
    }
    const $msg = $('<div>');

    for (const key in sectionErrors) {
      const section = sectionErrors[key];
      const errors = Object.keys(section);
      if (errors && errors.length) {
        const $innerList = $('<ul>').addClass('error-list-inner');
        const $outerList = $('<ul>')
          .addClass('error-list-outer')
          .append(
            $('<li>')
              .append($('<h6>').text(sectionFieldConfig.primarySectionNames[key] || ''))
              .append($innerList)
          );
        for (const iKey in section) {
          const errFields: any = section[iKey];
          if (errFields) {
            $innerList.append(
              $('<li>')
                .html(`${errFields.join(', ')} ${errFields.length > 1 ? 'are' : 'is'} ${this.getErrorLabel(iKey)}`)
            )
          }
        }
        $msg.append($outerList);
      }
    }    
    isValid = finalArr.length > 0;

    this.notification.error($msg.html());//finalArr.join(', ')
    return isValid;
  }

  getSectionIdOfField(sectionFields, fieldId) {
    const sectionIds = Object.keys(sectionFields);
    let sectionId = '';
    for (let index = 0; index < sectionIds.length; index++) {
      const fields = sectionFields[sectionIds[index]];
      if (fields.includes(fieldId)) {
        sectionId = sectionIds[index];
        break;
      }

    }

    return sectionId;
  }

  getFieldsInSplitSections() {
    const primarySectionNames = {};
    const sectionFields = {};
    const fieldNames = {};

    this.formConfig.groups.forEach(item => {
      primarySectionNames[item.id] = item.label;
      sectionFields[item.id] = [];
      this.getAllFieldsFromGroup(item.items, sectionFields[item.id], fieldNames);
    });
    return {
      primarySectionNames: primarySectionNames,
      sectionFields: sectionFields,
      fieldNames: fieldNames
    }
  }

  getAllFieldsFromGroup(groupItems, fields, fieldNames) {
    groupItems.forEach(item => {
      if (item.items && item.items.length) {
        this.getAllFieldsFromGroup(item.items, fields, fieldNames);
      } else {
        fields.push(item.name);
        fieldNames[item.name] = item.label;
      }
    });
    return fields;
  }


  getSwitchCaseForDate(type) {
    const dTypes = ['date', 'month', 'year', 'datetime', 'time'];
    const index = dTypes.indexOf(type);
    return dTypes[index];
  }
  /**
   * Method to add spacing for each sections
   * @param index
   * @param section/field object
   */
  applyPaddSpace(index, elem) {
    let retVal = 'p-0';
    if (elem.name) {
      retVal = 'p-1 pl-2 pr-2';
    }
    return retVal;
  }
  /**
   * Method to calculate section and subsections width
   * @param layoutData - layout object
   * @param totalColumns - Number of bootstrap columns
   * @param isShareExcess
   * @param disabledForm - Form enable/disable flag
   */
  calculateLayout(layoutData, totalColumns, isShareExcess, disabledForm) {
    if (!layoutData) {
      return;
    }
    totalColumns = totalColumns || 1;
    const totalGroups = layoutData.length;
    let field;
    let totalGroupWidth = 0;
    let layoutWidth;
    const loopCount = Math.floor(totalGroups / totalColumns); // <Number
    for (let index = 0, totalFullGroup = (loopCount * totalColumns); index < totalGroups; index++) {
      field = layoutData[index];
      if (!isShareExcess || index < totalFullGroup) {

        if ((index + 1) % totalColumns !== 0) {
          layoutWidth = Math.floor(12 / totalColumns);
          totalGroupWidth += layoutWidth;
        } else {
          layoutWidth = 12 - totalGroupWidth;
          totalGroupWidth = 0;
        }

      } else {

        const remaining = totalGroups - totalFullGroup;
        if (remaining > 1) {
          layoutWidth = Math.floor(12 / remaining);
          totalGroupWidth += layoutWidth;
        } else {
          layoutWidth = 12 - totalGroupWidth;
          totalGroupWidth = 0;
        }
      }
      field.columnsWidth = layoutWidth;
      field.padSpace = this.applyPaddSpace(index, field);
      if (field.type === 'tab') {
        if (field.tabs && field.tabs.length > 0) {
          for (const tab in field.tabs) {
            if (field.tabs.hasOwnProperty(tab)) {
              this.calculateLayout(field.tabs[tab].items, this.bootStrapCol[field.tabs[tab].columns], false, disabledForm);
            }
          }
        }
      } else {
        this.calculateLayout(field.items, this.bootStrapCol[field.columns], false, disabledForm);
      }
      if (totalGroupWidth === 0 && layoutData[index + 1]) {
        layoutData[index + 1].nextRow = true;
      }
    }
  }
  /**
   * Method is to build form layout
   * @param layoutData - layout object
   */
  buildLayout(layoutData) {
    if (!layoutData) {
      return;
    }
    this.layoutData = layoutData;

    if (layoutData.showPrimarySectionWizard) {
      this.primarySections = [];
      this.primarySectionsIds = [];

      if (layoutData.groups) {
        let layoutGrp = layoutData.groups;
        if (layoutGrp.length > 0) {
          let item: any;
          for (let i in layoutGrp) {
            if (layoutGrp[i].label != "") {
              this.primarySections.push({
                label : layoutGrp[i].repetitionIndex ? (layoutGrp[i].label + ' ' + layoutGrp[i].repetitionIndex) : layoutGrp[i].label,
                id : layoutGrp[i].id
              });
              this.primarySectionsIds.push(layoutGrp[i].id);
            }
          }
        }
      }

      if (this.primarySections.length > 0)
        this.wizardLayout = true;
        this.getFieldsInPrimarySections(layoutData);
      }

    if (layoutData.securityEvaluation !== false && layoutData.staticSecurityJson) {
      this.workFlowMatrixJson = layoutData.staticSecurityJson;
      // tslint:disable-next-line:max-line-length
      const layoutWithSecurity = this.staticSecurityService.evaluateSecurityRights(layoutData.groups, this.workFlowMatrixJson, layoutData.disabledForm);
      //console.log(layoutWithSecurity);
      this.calculateLayout(layoutData.groups, this.bootStrapCol[layoutData.columns], true, layoutData.disabledForm);
      this.setFormControl(this.layoutData.groups);
    } else {
      this.calculateLayout(layoutData.groups, this.bootStrapCol[layoutData.columns], true, layoutData.disabledForm);
      this.setFormControl(this.layoutData.groups);
    }
  }

  getFieldsInPrimarySections(layoutData){
    if (layoutData.showPrimarySectionWizard && layoutData.groups) {    

      let layoutGrp = layoutData.groups;
      if (layoutGrp.length > 0) {
        let item: any;
        layoutGrp.forEach(group => {
          this.iterateAndGetFieldsInPrimarySection(group, group.id)
        })
      
      }
    }
  }

  iterateAndGetFieldsInPrimarySection(group, gropId){
    if(group.items && group.items.length){
      group.items.forEach(groupItem => {
        this.iterateAndGetFieldsInPrimarySection(groupItem, gropId)
      })
    }else{
      this.fieldToSectionIdMap[group.name] = gropId;
    }
  }

  normalizeDescription(val) {
    return (val || "").replace(/\n/g, '<br>')
  }
  loadSubTable(element, parent) {
    const detailTarget = document.getElementById(parent);
    const componentName = element.component; // this.gridConfig.mobileTemplate;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentName);
    const componentRef = componentFactory.create(this.injector, [], detailTarget);
    // this.app.attachView(componentRef.hostView);
    return componentRef.hostView;
  }
  setScrollHeight() {
    this.scrollSection = [];
    this.primarySections.forEach((item, i) => {
      const l = item.label;
      const sec = l.toLowerCase();
      const el = $('.form-section-subgroup-' + sec);
      const ht = Math.round(el.height());
      this.scrollSection[sec] = ((i === 0) ? ht : (this.scrollSection[this.primarySections[i - 1].label.toLowerCase()]) + ht) + 26;
    });
  }
  ngOnInit() {
    this.isMobile = AppConstants.isMobile;   
    this.bootStrapCol = { 100: 1, 75: 1.33, 50: 2, 33: 3, 25: 4 };
    this.buildLayout(this.formConfig);
    //     this.setFormControl(this.layoutData.groups);
    this.form = this.formBuilder.group(this.formGroup);
    if (this.formConfig && this.formConfig.initCallback && typeof this.formConfig.initCallback === 'function') {
      this.formConfig.initCallback(this.form);
    }
    this.setFormData();
    this.backupData = { ...this.form.value };

    window.addEventListener('scroll', this.scrollEvent, true);

    /* if (this.form) {
      this.unsubcribe = this.form.valueChanges.subscribe((data) => {
        this.formModalChange(data, res => {
          this.form.patchValue(res, { emitEvent: false, onlySelf: true });
        });
      });
    } */
    this.scrollTimer = null;
  }
  setActiveSection(scroll) {
    // if (!(Object.keys(this.scrollSection || {}).length > 0)) {
    this.setScrollHeight();
    //}
    scroll = Math.round(scroll) - 198;
    const values = Object.values(this.scrollSection);
    const keys = Object.keys(this.scrollSection);
    const windArea = window.innerHeight / 3;
    values.forEach((num: any, i) => {
      if (scroll > num - windArea) {
        $('.wizard-section').removeClass('active');
        $('.wizard-section.' + keys[i + 1]).addClass('active');
        return;
      } else if (scroll < Number(values[0]) - windArea) {
        $('.wizard-section').removeClass('active');
        $('.wizard-section.' + keys[0]).addClass('active');
      }
    })
  }
  scrollEvent = (event: any): void => {
    if (this.scrollTimer !== null) {
      clearTimeout(this.scrollTimer);
    }
    if (!event.srcElement || !event.srcElement.scrollingElement) return

    const scrollTop = event.srcElement.scrollingElement.scrollTop;
    this.scrollTimer = setTimeout(() => {
      this.setActiveSection(scrollTop);
    }, 150);
    if (scrollTop > 200) {
      $('.wizard-layout').addClass('on-scroll');
    } else {
      $('.wizard-layout').removeClass('on-scroll');
    }
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (Object.keys(changes).length == 1 && changes.onWizardChange) {
      return;
    }
    console.log(changes);
    this.isMobile = AppConstants.isMobile;
    this.bootStrapCol = { 100: 1, 75: 1.33, 50: 2, 33: 3, 25: 4 };
    this.buildLayout(this.formConfig);
    this.form = this.formBuilder.group(this.formGroup);
    if (this.formConfig && this.formConfig.initCallback && typeof this.formConfig.initCallback === 'function') {
      this.formConfig.initCallback(this.form);
    }
    this.setFormData();
    this.backupData = { ...this.form.value };
  }

  handleFormBtnClick(element) {
    const details = {
      data: this.form.value,
      action: element,
      form: this.form
    };
    element.change(details);
    // this.formButtonClick.emit(details);
    console.log(element);
  }

  addSection(sectionId) {
    this.sectionAddClick.emit(sectionId);
    if(this.primarySectionsIds[this.currentSection] == sectionId){
      //if repeating parent section redirect to next page
      this.onWizardClick((this.currentSection + 1), null);
    }
  }

  removeSection(sectionId) {
    let modelMsg = 'Do you want to remove this section?';
    this.modalPopupService.openConfirmationModal('Confirm', modelMsg, null, null, null).subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.sectionRemoveClick.emit(sectionId);
      }
    });
   
  }

  getJsonItems(jsonConfig: any[]): any[] {
    const items = [];
    jsonConfig.forEach(json => {
      Object.keys(json).forEach(jkey => {
        if (Array.isArray(json[jkey])) {
          items.push({
            groupHeaderClass: 'form-group-subheader',
            groupContentClass: 'paddingZero',
            collapsible: 'false',
            columns: '0',
            label: jkey,
            disableRights: false,
            columnsWidth: 12,
            items: this.getJsonItems(json[jkey])
          });
        } else {
          items.push({
            label: jkey,
            name: jkey,
            type: json[jkey]
          });
        }
      });
    });
    return items;
  }

  getJsonElement(jsonConfig) {
    return {
      groupHeaderClass: 'hidden hidden',
      groupContentClass: 'paddingZero',
      collapsible: 'false',
      columns: '0',
      label: '',
      disableRights: false,
      columnsWidth: 12,
      items: this.getJsonItems(jsonConfig)
    };
  }

  togglePasswordVisibility(e, elem, isVisible) {
    const target = e.currentTarget;
    const input = $($(target).parents('.input-group')[0]).children('.form-control');
    if (isVisible) {
      $(input).attr('type', 'text')
    } else {
      $(input).attr('type', 'password')
    }
  }

  getSpaceTrimmed(label) {
    if (!label) {
      return;
    }
    return label.replace(/ /g, '').toLowerCase();
  }

  getIconDisplay(icon) {
    if (icon.indexOf('material-icons') > -1) {
      return icon.replace(/material-icons/g, '');
    } else if (icon.indexOf('fa ') > -1 && icon.indexOf('material-icons') === -1) {
      return '';
    }
    return '';
  }

  onWizardClick(index, event) {
    // $('.wizard-section.active').removeClass('active');
    // $(event.currentTarget).addClass('active');
    setTimeout(() => {
      this.currentSection = index;
      this.onWizardChange && this.onWizardChange();
    }, 20)
  }

  ngDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
    this.unsubcribe();
  }

  getElementNameOfMergeControl(elementname){
    return elementname
    .replace('__MY_COMMENT__','')
    .replace('__THEIR_COMMENT__','')
    .replace('__MY_ATTACHMENT__','')
    .replace('__THEIR_ATTACHMENT__','')
    .replace('__THEIR_DATA__','');
  }

  mergeChanges(type, element){
    const mergeData = type == 'myData' ? this.myData: this.concurrentData;
    const elementName = this.getElementNameOfMergeControl(element.name);

    if(this.mergeunResolvedFields.data[elementName] && 
      !this.mergeunResolvedFields.data[elementName].unresolved && 
      this.mergeunResolvedFields.data[elementName].resolvedUsing ==  element.name
    ){
      return false;

    }

    if(!this.checkForParentChildRelationBeforeMerge(type, element, mergeData)){
      return false;
    }
      
    if(element.commentControl){
      this.mergedData.comments[elementName] = mergeData.comments[elementName];
      this.mergeunResolvedFields.comments[elementName] = {unresolved : false, resolvedUsing : element.name}
    }else if(element.attachmentControl){
      this.mergedData.attachments[elementName] = mergeData.attachments[elementName];
      this.mergeunResolvedFields.attachments[elementName] = {unresolved : false, resolvedUsing : element.name}
    }else{
      const value = mergeData.data[elementName];
      this.mergedData.data[elementName] = value;
      //this.form.controls[elementName].setValue(value || '');
      this.mergeunResolvedFields.data[elementName] = {unresolved : false, resolvedUsing : element.name};
    }

  }
  
  checkForParentChildRelationBeforeMerge(type, element, mergeData){
    //checking multidropdown dependency while merging
    let canResolve = true;
    const elementName = this.getElementNameOfMergeControl(element.name);
    if(this.mergeMetaData){
      
      if(
        this.mergeMetaData.dropdownFields && 
        this.mergeMetaData.dropdownFields[elementName]
      ){           

        this.mergeMetaData.parentDropdowns[elementName].forEach(key =>{
          const buttonKeyToDisable = type == 'theirData' ? key : key + '__THEIR_DATA__';
          const buttonKeyEnable = type == 'myData' ? key : key + '__THEIR_DATA__';
          this.mergeButtonsDisabled[buttonKeyToDisable] = true;
          delete this.mergeButtonsDisabled[buttonKeyEnable];
          if(this.mergeunResolvedFields.data[elementName] && !this.mergeunResolvedFields.data[elementName].unresolved){
           this.mergeunResolvedFields.data[key] = {unresolved : true};
           delete this.mergedData.data[key];
          }
        });
        
      }else if(
        this.mergeMetaData.multiDropdownFields && 
        this.mergeMetaData.multiDropdownFields[elementName]
      ){
        const parentField = this.mergeMetaData.multiDropdownFields[elementName].parentField;

        if(this.mergeunResolvedFields.data[parentField]){
          const parentFieldName = this.mergeMetaData.dropdownFields[parentField].name
          if(this.mergeunResolvedFields.data[parentField].unresolved){
            this.notification.warn('Please resolve parent field "'+parentFieldName +'"');
            canResolve = false;
          }
        }else{
          const parentFieldName = this.mergeMetaData.dropdownFields[parentField].name
          const parentValue = this.form.controls[parentField].value;
          try{
            const possible_values = this.mergeMetaData.multiDropdownFields[elementName].multiLevelDropDownValues.find(item => item.key == parentValue);
            const value = mergeData.data[elementName];
            if(!possible_values.options.split(',').includes(value)){
              this.notification.warn('Selected value is not availble option of parent field "'+parentFieldName +'"');
              canResolve = false;
            }
          }catch(e){

          }
        }

      }
 
      return canResolve;

    }

  }

  isAllConflictsResolved(){
    let resolved  = true;
    if(this.mergeMode){
      const items = Object.values(this.mergeunResolvedFields);
      const checkResolved = (item:any)=>{
        const values = Object.values(item);
        if(values.length && values.filter((i:any) => i.unresolved).length){
          resolved = false
          return false;
        }
        return true;
      }

      for (let index = 0; index < items.length; index++) {
        const item = items[index];
        if(!checkResolved(item)){
          break;
        }

      }
    }
    return resolved;
  } 

  initMergeProperties(){
    this.conflictedPrimarySectionsIds = {};
    this.fieldsToMerge = {}
    this.commentTomerge = {}
    this.attachmentsTomerge = {}
    this.mergeButtonsDisabled = {}
    this.mergeunResolvedFields = {
      comments : {},
      data : {},
      attachments : {},
    }
    this.mergedData = {
      comments : {},
      data : {},
      attachments : {},
    }

  }

  exitMergeForm(){
    this.mergeMode = false;
    this.concurrentData = null;
    this.myData = null;
    this.isCheckingConflicts = null;
    this.initMergeProperties();
    
  }
  checkForConflicts(myData, concurrentData, backupData, formLoaderComponent){
    
    this.concurrentData = concurrentData;
    this.myData = myData
    this.initMergeProperties();
    this.isCheckingConflicts = true;
    const myDataResultData = this.myData.data;
    const myComments = this.myData.comments;
    const myAttachments = this.myData.attachments;

    const myBackupDataResultData = backupData.data;
    const myBackupComments = backupData.comments;
    const myBackupAttachments = backupData.attachments;


    const changedFields = [];
    const changedComments = [];
    const changedAttchments = [];

    let isConflicted = false;

    Object.keys(myDataResultData).forEach(key => {
      const myValue = myDataResultData[key];
      const backupValue = backupData.data[key];
      if(!this.utilService.isEqualIgnoreCase(myValue, backupValue,[],true)){
        changedFields.push(key);
      }
    });
    Object.keys(myAttachments).forEach(key => {
      const myValue = myAttachments[key];
      const backupValue = backupData.attachments[key];
      if(!this.utilService.isEqualIgnoreCase(myValue, backupValue,[],true)){
        changedAttchments.push(key);
      }
    });
    Object.keys(myComments).forEach(key => {
      const myValue = myComments[key];
      const backupValue = backupData.comments[key];
      if(!this.utilService.isEqualIgnoreCase(myValue, backupValue,[],true)){
        changedComments.push(key);
      }
    });

  

    Object.keys(this.concurrentData.data).forEach(key => {
      const theiralue = this.concurrentData.data[key];
      if(changedFields.includes(key)){
        const myValue = myBackupDataResultData[key];
        const myNewValue = myDataResultData[key];
        if(!this.utilService.isEqualIgnoreCase(myValue, theiralue,[],true) && !this.utilService.isEqualIgnoreCase(myNewValue, theiralue,[],true)){
          this.fieldsToMerge[key] = true;
          this.fieldsToMerge[key+'__THEIR_DATA__'] = true;
          this.mergeunResolvedFields.data[key] = {unresolved : true};
          formLoaderComponent.setPossibleValuesOfMultilevelDropdown(key+'__THEIR_DATA__', this.concurrentData.data)
          this.form.controls[key+'__THEIR_DATA__'] && this.form.controls[key+'__THEIR_DATA__'].setValue(theiralue);
          if(this.fieldToSectionIdMap[key]){
            this.conflictedPrimarySectionsIds[this.fieldToSectionIdMap[key]] = true;
          }
          isConflicted = true;
        }else{
          //const newValue = ;
          this.form.controls[key] && this.form.controls[key].setValue(myDataResultData[key]);
        }
        
      }else{
        this.form.controls[key] && this.form.controls[key].setValue(theiralue);
      }
    });
    Object.keys(this.concurrentData.comments).forEach(key => {
      if(changedComments.includes(key)){
        const theirCommentKey = key + '__THEIR_COMMENT__';
        const myCommentKey = key + '__MY_COMMENT__';
        const theiralue = this.concurrentData.comments[key]
        const mybkValue = myBackupComments[key];
        const myNewValue = myComments[key];
        if(!this.utilService.isEqualIgnoreCase(mybkValue, theiralue,[],true)){
          this.commentTomerge[theirCommentKey] = true;
          this.commentTomerge[myCommentKey] = true;
          this.mergeunResolvedFields.comments[key] = {unresolved : true};
          this.form.controls[theirCommentKey] && this.form.controls[theirCommentKey].setValue(theiralue);
          this.form.controls[myCommentKey] && this.form.controls[myCommentKey].setValue(myNewValue);
          if(this.fieldToSectionIdMap[key]){
            this.conflictedPrimarySectionsIds[this.fieldToSectionIdMap[key]] = true;
          }
          isConflicted = true;
        } else{          
          this.commentData[key] = myComments[key];
        }      

      }else{
        this.commentData[key] = this.concurrentData.comments[key];
      }
    
    });
    Object.keys(this.concurrentData.attachments).forEach(key => {
      if(changedAttchments.includes(key)){
        const theirKey = key + '__THEIR_ATTACHMENT__';
        const myKey = key + '__MY_ATTACHMENT__';
        const theiralue = this.concurrentData.attachments[key]
        const mybkValue = myBackupAttachments[key];
        const myNewValue = myAttachments[key];
        if(!this.utilService.isEqualIgnoreCase(mybkValue, theiralue,[],true)){
          this.attachmentsTomerge[theirKey] = true;
          this.attachmentsTomerge[myKey] = true;
          this.mergeunResolvedFields.attachments[key] = {unresolved : true};
          this.form.controls[theirKey] && this.form.controls[theirKey].setValue(theiralue);
          this.form.controls[myKey] && this.form.controls[myKey].setValue(myNewValue);
          if(this.fieldToSectionIdMap[key]){
            this.conflictedPrimarySectionsIds[this.fieldToSectionIdMap[key]] = true;
          }
        }   else{          
          this.attachmentData[key] = myAttachments[key];
        }    
        isConflicted = true; 

      }else{
        this.attachmentData[key] = this.concurrentData.attachments[key];
      }
    
    });

    return isConflicted;
  }

  openMergeForm(mergeMetaData = {}){
    this.mergeMode = true;
    this.isCheckingConflicts = false;
    Object.assign(this.mergeMetaData,mergeMetaData);
  }

  getMergeButtonText(type, element){
    let label = ''
    if(element.commentControl){ 
      label = type == 'my' ? 'Use My Comment' : 'Use Their Comment';
    }else if(element.attachmentControl){ 
      label = type == 'my' ? 'Use My Attachment' : 'Use Their Attachment';
    }else{
      label = type == 'my' ? 'Use My Data' : 'Use Their Data';
    }
    return label
    //Use their Data
  }

  isShowResolved(element){
    let isResolved = false;
    if(!element.name){
      return;
    }
    const elementName = element.name
      .replace('__MY_COMMENT__','')
      .replace('__THEIR_COMMENT__','')
      .replace('__MY_ATTACHMENT__','')
      .replace('__THEIR_ATTACHMENT__','')
      .replace('__THEIR_DATA__','');
    
    if(this.mergeMode){
      let resolvedState:any = {};
      if(element.commentControl){
        resolvedState = this.mergeunResolvedFields.comments[elementName];
        if(this.commentTomerge[element.name] && !resolvedState.unresolved && resolvedState.resolvedUsing == element.name){
          isResolved = true;
        }
      }else if(element.attachmentControl){
        resolvedState = this.mergeunResolvedFields.attachments[elementName];
        if(this.attachmentsTomerge[element.name] && !resolvedState.unresolved && resolvedState.resolvedUsing == element.name){
          isResolved = true;
        }
      }else{
        resolvedState = this.mergeunResolvedFields.data[elementName];
        if(this.fieldsToMerge[element.name] && !resolvedState.unresolved && resolvedState.resolvedUsing == element.name){
          isResolved = true;
        }
      }
    }

    return isResolved;
   
  }

  getDataFromForm() {
    const data = this.form.getRawValue();
    const commentData = Object.assign({},this.commentData);
    const attachmentData = Object.assign({},this.attachmentData);
    const procesedData= {};
    Object.keys(data).forEach(key =>{
      if(
        !key.includes('__THEIR_DATA__') && 
        !key.includes('__MY_COMMENT__') && 
        !key.includes('__THEIR_COMMENT__') &&
        !key.includes('__MY_ATTACHMENT__') && 
        !key.includes('__THEIR_ATTACHMENT__')
      ){
        procesedData[key] = data[key]

      }
    });
    if(this.mergeMode){
      Object.assign(procesedData, this.mergedData.data);
      Object.assign(commentData,this.mergedData.comments);
      Object.assign(attachmentData,this.mergedData.attachments);
     
    }
    return { data: procesedData, comments: Object.assign({},commentData), attachments: attachmentData };
  }
}
