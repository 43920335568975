import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-primary-inks',
	templateUrl: './primary-inks.component.html',
	styleUrls: ['./primary-inks.component.scss']
})
export class PrimaryInksComponent implements OnInit {

	@Input() currentForm: any;
	@Input() currentElement: any;

	constructor() { }

	ngOnInit() {
	}
}
