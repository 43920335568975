import { AppGlobalService } from './../app-global.service';
import { Component, Injector, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { Subscription, combineLatest, Observable } from 'rxjs';

import { VsMenuService } from './../widget/vs-menu/vs-menu.service';
import { AuthenticationService } from './../auth/authentication.service';
import { User } from './../auth/user.model';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { AppConstants } from '@custom/core/app-constants';
import { UtilService } from './../core/util.service';
import { FirebaseAuthenticationService } from './../auth/firebase-authentication.service';
import { environment } from '@env/environment';
import { HomeComponentHandler } from '@custom/modules/gen/home/home-component.handler';
import { NotificationService } from '@app/shared/services/notification.service';
import { CustomService } from '@custom/services/custom.service';
import { VsTabmenuIntegrationService } from '@app/widget/vs-tabmenu-integration.service';
import { Title } from '@angular/platform-browser';
import { ApiConstants } from '@app/api-constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseService } from '@app/core/base.service';

// tslint:disable-next-line:ban-types
declare let ga: Function;


@Component({
  selector: 'app-home',
  templateUrl: '../../custom/modules/gen/home/home.component.html'
})
export class HomeComponent extends HomeComponentHandler implements OnInit, OnDestroy {
  title = 'Angular Libraries';


  public topbarConfig: any;

  isExpandedMenu: boolean;
  /** Check menu is sticky or not */
  isStickyMenu: boolean;

  menuStateChangeSub: Subscription;
  userSubscription: Subscription;
  isTab: boolean;
  isMobile: boolean;
  tabs: any = [];
  isShowTabList = false;
  isCollapsedMenu = false;
  authenticateSub: Subscription;
  tokenSubstription: Subscription;
  userRole: string;
  userImgSrc: string;


  constructor(
    public menuService: VsMenuService,
    public translate: TranslateService,
    public auth: AuthenticationService,
    public route: ActivatedRoute,
    public router: Router,
    public appGlobalService: AppGlobalService,
    public utilService: UtilService,
    public notification: NotificationService,
    public customService: CustomService,
    public tmIntegration: VsTabmenuIntegrationService,
    public titleService: Title,
    public modalService: NgbModal,
    public baseService: BaseService) {
    super(menuService, translate, auth, route, router, appGlobalService, utilService, notification, customService, tmIntegration, titleService, modalService, baseService);
    /* fallback language when a translation isn't found in the current language */
    translate.setDefaultLang('en');
    translate.use('en');

    /**
     * Expand and collpase page container area based on the menustate change value
     */
    this.menuStateChangeSub = this.menuService.menuStateChange.subscribe(data => {
      this.isExpandedMenu = (data === 'open');
    });

  }

  public setUserData(data) {
    if (data) {
      this.auth.setCurrentUser(data);
      let userName = '';
      userName = data.firstName ? (userName + data.firstName) : userName;
      userName = data.lastName ? (userName + ' ' + data.lastName) : userName;

      if (!userName) {
        userName = 'User';
      }

      this.topbarConfig.data = {};
      this.topbarConfig.data.userName = userName;
      this.topbarConfig.logoutConfig = {
        isAvailable: true,
        icon: "fa fa-power-off",
        labelClass: 'hidden'
      };
      this.topbarConfig.logoutConfig = this.extendLogoutConfig(this.topbarConfig.logoutConfig);
      this.topbarConfig.menuConfig = data.menu;
      this.menuService.emitMenuDta(data.menu);
      this.isStickyMenu = this.topbarConfig.menuConfig.sticky;
      if (this.extendUserData) {
        this.extendUserData(data);
      }
    }
  }

  ngOnInit() {
    this.utilService.setTitle();
    this.utilService.setFavIcon();
    this.loadTopbarConfig();
    this.isMobile = AppConstants.isMobile;
    this.authenticateSub = this.route.data.subscribe((res) => {
      if (res && res.authResolver && res.authResolver[0]) {
        this.setUserData(res.authResolver[0]);
      }
    });

    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {
        // TODO: add file for hook handler and show spinner
        this.notification.hide();
      }

      if (event instanceof NavigationEnd) {
        // TODO: add file for hook handler and hide spinner
        this.isTab = false;
        if (event.urlAfterRedirects.indexOf('/tab/') > -1 || event.urlAfterRedirects === '/tab') {
          this.isTab = true;
        }

        // ga('set', 'page', event.urlAfterRedirects);
        //ga('send', 'pageview');

        this.handleNavigationEnd(event);
      }

      if (event instanceof NavigationError) {
        // TODO: add file for hook handler and show notification
        console.log(event.error);
      }
    });
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  handleNavigationEnd(event) {
    let route = this.route.firstChild;
    let child = route;

    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
        route = child;
      } else {
        child = null;
      }
    }

    route.data.subscribe(data => {
      this.resetAllTabs();
      console.log(route);
      if (!AppConstants.isFromTab && !this.checkIfTabExists(data, event).length) {

        this.tabs.push({
          label: data.label || event.urlAfterRedirects,
          url: window.location.hash.substr(1), // event.urlAfterRedirects,
          component: route.component,
          fromTab: true,
          active: true,
          params: route.snapshot.params || ''
        });
      } else {
        this.loadTabFromList(data.label || event.urlAfterRedirects);
      }
      AppConstants.isFromTab = false;
    });
  }
  scrollEvent = (event: any): void => {
    if (!event.srcElement || !event.srcElement.scrollingElement) return

    const scrollTop = event.srcElement.scrollingElement.scrollTop;
    //console.log(scrollTop)
    if (scrollTop > 60) {
      $('.app-menu').addClass('on-scroll');
    } else {
      $('.app-menu').removeClass('on-scroll');
    }
  }
  checkIfTabExists(data, event) {
    return this.tabs.filter(tab => {
      return tab.label === data.label || tab.label === event.urlAfterRedirects;
    });
  }

  resetAllTabs() {
    this.tabs.map(tab => {
      tab.active = false;
      return tab;
    });
  }

  loadTabFromList(label) {
    this.tabs.map(tab => {
      if (tab.label === label) {
        tab.active = true;
      }
      return tab;
    });
  }

  loadThisTab(tab) {
    AppConstants.isFromTab = true;
    this.resetAllTabs();
    tab.active = true;
    if (tab.url.indexOf(';')) {
      tab.url = tab.url.split(';')[0];
    }
    this.router.navigate([tab.url, tab.params]);
    this.isShowTabList = false;
  }

  removeThisTab(tab) {
    this.tabs = this.tabs.filter(item => item.label !== tab.label);
  }

  toggleLeftMenu() {
    this.isCollapsedMenu = !this.isCollapsedMenu;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }

  move(direction) {
    const elem = $('#openedTabsHorzWrap');
    const scrollLeft = elem.scrollLeft();
    if (direction === 'left') {
      //elem.scrollLeft(scrollLeft - 100);
      elem.animate({ scrollLeft: '-=200' }, 500);
    } else if (direction === 'right') {
      //elem.scrollLeft(scrollLeft + 100);
      elem.animate({ scrollLeft: '+=200' }, 500);
    }
  }

  private loadTopbarConfig(): void {
    this.topbarConfig = {
      langConfig: [
        {
          label: 'EN',
          flagIcon: 'en',
          value: 'en'
        },
        {
          label: 'NL',
          flagIcon: 'nl',
          value: 'nl'
        },
      ]
    };
  }


  ngOnDestroy() {
    this.menuStateChangeSub.unsubscribe();
    this.authenticateSub.unsubscribe();
    window.removeEventListener('scroll', this.scrollEvent, true);
  }
}
