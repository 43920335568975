import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
/* let bootstrap;

platformBrowserDynamic().bootstrapModule(AppModule).then((d) => {
  bootstrap = d;
  if ('serviceWorker' in navigator && environment.production) {
    navigator.serviceWorker.register('ngsw-worker.js');
  }
}).catch(err => console.log(err)); */
const loadApp = () => {
  /*if (environment.hmr) {
    if (module['hot']) {
      hmrBootstrap(module, bootstrap);
    } else {
      console.error('HMR is not enabled for webpack-dev-server!');
      console.log('Are you using the --hmr flag for ng serve?');
    }
  } else {*/
    bootstrap()
      .then(() => {
        if ('serviceWorker' in navigator && environment.production) {
          navigator.serviceWorker.register('/ngsw-worker.js');
        }
      })
      .catch((err) => console.log(err));
 // }
};
/*
if (environment.prototype) {
  loadApp();
} else {
  fetch('rest/environmentinfo').then(resp => resp.json()).then(config => {
    window['firebaseConfig'] = config.envInfo.realtime_db_config;
    loadApp();
  });
}*/
loadApp();