import { Component, OnInit, Input, forwardRef, Renderer, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { formatNumber } from '@angular/common';
import { NotificationService } from '@app/shared/services/notification.service';

@Component({
  selector: 'vs-number-range',
  templateUrl: './number-range.component.html',
  styleUrls: ['./number-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberRangeComponent),
      multi: true
    }
  ]
})
export class NumberRangeComponent implements ControlValueAccessor, OnInit {


  // tslint:disable-next-line:no-input-rename
  @Input('numericType') numericType: string;
  @Input() customMin: number;
  @Input() customMax: number;
  @Input() mandatoryCondition: any;
  @Input() element: any;
  @Input() displayFormat: string;
  @Input() validations: any;

  private _model: any;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;

  public number: [any, any] = [null, null];

  private defaultRegex = /^[0-9]+[\.]{0,1}[0-9]*$/g;
  public disabled: boolean;

  private specialKeys = {
    number: ['Backspace', 'Delete', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
    decimal: ['Backspace', 'Delete', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
  };

  private getDecimalSeparator = (1.1).toLocaleString().substring(1, 2);

  constructor(private renderer: Renderer, private elementRef: ElementRef, private notify: NotificationService) {

  }

  get model() {
    return this._model;
  }

  writeValue(value: any): void {
    this.setValueToField(value);
    this._model = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  set(value: any) {
    this._model = value;
    this.onChange(this._model);
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setElementProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    this.disabled = isDisabled;
  }

  private getRegExBasedOnUserLocale = () => {
    const n = this.getDecimalSeparator;
    if (n === ',') {
      return new RegExp('^[0-9]+[,]{0,1}[0-9]*$', 'g');
    } else {
      return new RegExp('^[0-9]+[\.]{0,1}[0-9]*$', 'g');
    }
    // return new RegExp('^[0-9]+\.[0-9]*$', 'g');
  }

  private regex = () => {
    return {
      number: new RegExp(/^\d+$/),
      decimal: this.getRegExBasedOnUserLocale()
    };
  }

  private getUserLocaleFromBrowser = () => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.language || 'en';
    }
  }

  private getFormattedValue = (value) => {
    if (value === "" || value === undefined) { return null; }
    value = String(value).replace(/,/g, '.');
    let formatted;
    try {
      formatted = formatNumber(value, this.getUserLocaleFromBrowser());
    } catch (e) {
      formatted = formatNumber(value, 'en');
    }

    return formatted;
  }

  private getDBTypedValue = (value) => {
    let valueToSet;
    if (this.getDecimalSeparator === ',') {
      valueToSet = String(value).replace(/\./g, '').replace(/,/g, '.');
    } else {
      valueToSet = String(value).replace(/,/g, '');
    }
    if (valueToSet === "null")
      valueToSet = "";
    return valueToSet;
  }

  onFocus = (event, w) => {
    if (!this.number) {
      this.number = ["", ""];
    }
    if (this.number[w] == null) {
      this.number[w] = "";
    }
    const value = this.number[w];
    const decimal = this.getDecimalSeparator;
    let valueToSet;
    if (decimal === ',') {
      valueToSet = String(value).replace(/\./g, '');
    } else {
      valueToSet = String(value).replace(/,/g, '');
    }
    this.number[w] = valueToSet;
  }

  onBlur = (event, w) => {
    const value = this.number[w];
    this.number[w] = this.getFormattedValue(value);
    if (w === 0) {
      this._model = [this.getDBTypedValue(value), this.number[1]];
    } else if (w === 1) {
      this._model = [this.number[0], this.getDBTypedValue(value)];
    }
    if (parseFloat(this._model[1]) < parseFloat(this._model[0])) {
      this.number[1] = "";
      this.notify.warn('Second value should not be less than first value');
      this.number[1] = this.number[0];
      this._model[1] = this._model[0];
    }
    if (this._model[0] !== "")
      this._model[0] = Number(this._model[0]);
    if (this._model[1] !== "")
      this._model[1] = Number(this._model[1]);
    // this._model = this.getDBTypedValue(value);
    this.onChange(this._model);
  }

  onKeyDown = (event, w) => {
    if (this.specialKeys[this.numericType].indexOf(event.key) !== -1) {
      return;
    }

    const current: any = this.number[w];
    const next: any = current.concat(event.key);
    if (next && !String(next).match(this.regex()[this.numericType])) {
      event.preventDefault();
    }
  }

  setValueToField(value) {
    this.number = [this.getFormattedValue(value[0]), this.getFormattedValue(value[1])];
  }

  onAccept(mask) {
  }

  onComplete(mask) {
  }

  ngOnInit() {
  }
}
