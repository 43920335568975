/**
 * Write all your custom api constants here
 */
export class BaseApiConstants {

    public static readonly getexchangerate: any = {
        url: 'exchangerates/getexchangerate/{fromCurrency}/{toCurrency}',
        method: 'GET',
        showloading: true
    };

    public static readonly getFilters: any = {
        url: 'cart/filters',
        method: 'GET',
        showloading: true
    };

    public static readonly getAllProducts: any = {
        url: 'cart/getallproducts/datatable',
        method: 'POST',
        showloading: true
    };

    public static readonly getProductDetails: any = {
        url: 'cart/getproduct/{sid}',
        method: 'GET',
        showloading: true
    };

    public static readonly updateProductRequirements: any = {
        url: 'cart/updateproduct/{sid}',
        method: 'POST',
        showloading: true
    };

    public static readonly getMyprofile: any = {
        url: 'appuserprivileges/myprofile',
        method: 'GET',
        showloading: true
    };
}
