import { BrowserModule } from '@angular/platform-browser';
import { NgModule, OnInit } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContextMenuModule } from 'ngx-contextmenu';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { SharedWidgetModule } from '@custom/modules/shared-widget.module';
import { AuthModule } from './auth/auth.module';
import { CustomModule } from '@custom/modules/custom.module';
import { AppLoaderComponent } from '@shared/components/app-loader.component';
import { NotificationComponent } from '@shared/components/notification.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeNl from '@angular/common/locales/nl';
import localeZh from '@angular/common/locales/zh';
import localeRu from '@angular/common/locales/ru';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import localeIT from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule, SwPush, SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './cache-route-reuse.strategy';
import * as _ from 'lodash';
import { VsSchedulerComponent } from './widget/vs-scheduler/vs-scheduler.component';
import { HomeComponent } from './home/home.component';
import { interval } from 'rxjs';

import { AppConfigService } from './shared/services/app-config.service';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { envInfo } from '../firebase/firebase';
import { AppGlobalService } from './app-global.service';
import { AuthenticationResolver } from './auth/authentication.resolver';
import { PrivacyModalComponent } from '@custom/modules/gen/home/privacy-modal/privacy-modal.component';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeZh, 'zh');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeEs, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeIT, 'it');
registerLocaleData(localeJa, 'ja');

/*export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}*/

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
      {prefix: './assets/i18n/', suffix: '.json'},
      {prefix: './custom/assets/i18n_Custom/', suffix: '.json'},
  ]);
}

@NgModule({
  declarations: [
    AppComponent,
    AppLoaderComponent,
    NotificationComponent,
    HomeComponent,
    PrivacyModalComponent
    

  ],
  imports: [
    BrowserModule,
    FormsModule,
        ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ]
      }
    }),
    FontAwesomeModule,
	AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(envInfo.config.firebase_auth_json),  
    AngularFirestoreModule,
	NgbModule,
    BrowserAnimationsModule,
    ContextMenuModule.forRoot(),
    HttpClientModule,
    CoreModule,
    SharedWidgetModule,
    AuthModule,
    CustomModule,  	
    AppRoutingModule,
	ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  exports: [
    NgbModule,
    TranslateModule,
    ContextMenuModule,
    CoreModule
  ],
  providers: [
	AuthenticationResolver,
    AppGlobalService,
    AppConfigService
  ],
  entryComponents: [PrivacyModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
