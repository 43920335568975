import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/auth/authentication.service';
import { CbxGeneralSettingsLocationService } from '@app/cbx-general-settings-location/cbx-general-settings-location.service';
import { BaseService } from '@app/core/base.service';
import { UtilService } from '@app/core/util.service';
import { AppLoaderService } from '@app/shared/services/app-loader.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { BreadcrumbInterfaceService } from '@app/widget/breadcrumb/breadcrumb-interface.service';
import { AppConstants } from '@custom/core/app-constants';
import { ColorbaseStatusComponent } from '@custom/modules/gen/custom_element/colorbase-status/colorbase-status.component';
import { DunsCompanyInfoComponent } from '@custom/modules/gen/custom_element/duns-company-info/duns-company-info.component';
import { DunsOwnershipInsightsLevel2Component } from '@custom/modules/gen/custom_element/duns-ownership-insights-level-2/duns-ownership-insights-level-2.component';
import { PriceListRegionDetailComponent } from '@custom/modules/gen/custom_element/price-list-region-detail/price-list-region-detail.component';
import { ProductStatusComponent } from '@custom/modules/gen/custom_element/product-status/product-status.component';
import { LabsCustomService } from '@custom/modules/labs-custom/labs-custom.service';
import { CustomFileUploaderService } from '@custom/modules/shoppingcart/services/custom-file-uploader.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { envInfo } from 'firebase/firebase';
import { Observable, of} from 'rxjs';
import { CustomUtilsService } from './custom-utils.service';
import { ExportServices } from './export.service';
import { PriceListUtilsService } from './price-list-utils.service';
import { TestSutieServices } from './test-suite-services';
import { UnitConverterService } from './unit-converter.service';

@Injectable({
  providedIn: 'root'
})
export class CustomService {

    private readonly _customAPIConstants = {
        ORGANIZATION_GBL_PRICE : {
            url: `pricelists/globalpricelist/{ORG_SID}`,
            method: 'GET',
            showloading: false
        },
        VARIATION_GBL_PRICE : {           
            url: `variationpricelists/globalvariationpricelist/{MATERIAL_VARIATION_SID}?orgSid={ORG_SID}`,
            method: 'GET',
            showloading: false
        },
        VARIATION_PRICE_LIST : {
            url: `${AppConstants.apihost}/variationpricelists/pricelist/datatable/{PRICE_LIST_ID}`,
            method: 'POST',
            showloading: true
        },
        VARIATION_PRICE_LIST_CREATE : {
            url: `${AppConstants.apihost}/variationpricelists/variationpricelists/create/datatable/{ORG_SID}`,
            method: 'POST',
            showloading: true
        },
       GET_VPL_FOR_CURRENCY : {
            url: `variationpricelists/variationpricelists/{CURRENCY}/{PRICE_LIST_ID}`,
            method: 'GET',
            showloading: true
        },
        
        CREATE_PRICE_LIST : {
            url: `pricelists/consolidatedcreate`,
            method: 'POST',
            showloading: true
        },
        UPDATE_PRICE_LIST : {
            url: `pricelists/consolidatedupdate`,
            method: 'PUT',
            showloading: true
        },
        REGION_COUNTRIES : {
            url: `countries/region/{REGION_SID}`,
            method: 'GET',
            showloading: false
        },
        UPDATE_RELATIONSHIP : {
            url: `relationships/consolidatedupdate`,
            method: 'PUT',
            showloading: true
        },
        GET_ALL_COUNTRIES : {
            url: `countries/all`,
            method: 'GET',
            showloading: true
        }
    }

    public readonly colorbaseStatus = {
        'APPROVED': {name: 'APPROVED', hex: '#8cc63f', label: 'Approved'},
        'PENDING_APPROVAL': {name: 'PENDING_APPROVAL', hex: '#ff9900', label: 'Pending Approval'},
        'REJECTED': {name: 'REJECTED', hex: '#ff0000', label: 'Rejected'},
        'DRAFT': {name: 'DRAFT', hex: '#3B7DDD', label: 'Draft'}
    };
    
  	constructor(
        public baseService: BaseService, 
        private utilService: UtilService,
        public auth: AuthenticationService,
        public cbxGeneralSettingsLocationService: CbxGeneralSettingsLocationService,
        public notitfication: NotificationService,
        public unitConverter: UnitConverterService,
        public pricelistUtil: PriceListUtilsService,
        public customUtil: CustomUtilsService,
        public fileUploaderService: CustomFileUploaderService,
        public appLoaderService: AppLoaderService,
        public testSuiteServices: TestSutieServices,
        public labsCustomService: LabsCustomService,
        public customSutieServices: TestSutieServices,
        public bcService: BreadcrumbInterfaceService,
        public exportServices: ExportServices,
        public firestoreService: AngularFirestore
    ) {
       // super()
    }

    public productStatusObj: ProductStatusComponent;
    public colorBaseStatusObj: ColorbaseStatusComponent;
    public priceListRegionComponent: PriceListRegionDetailComponent;
    public dunsCompanyInfoComponent: DunsCompanyInfoComponent;
    public dunsOwnershipInsightsLevel2Component: DunsOwnershipInsightsLevel2Component;
    
    get customAPIConstants(){
       return this._customAPIConstants;
    }

    public registerProductStatusObj(productStatusObj: ProductStatusComponent) {
        this.productStatusObj = productStatusObj;
    }

    registerDunsCompanyInfoComponent(component : DunsCompanyInfoComponent){
        this.dunsCompanyInfoComponent = component;
    }
    registerDunsOwnerInsigtsLevel2Component(component : DunsOwnershipInsightsLevel2Component){
        this.dunsOwnershipInsightsLevel2Component = component;
    }

    public registerColorBaseStatusObj(colorBaseStatusObj: ColorbaseStatusComponent) {
        this.colorBaseStatusObj = colorBaseStatusObj;
    }
    public registerPriceListRegionDetailComponent(priceListRegionComponent: PriceListRegionDetailComponent) {
        this.priceListRegionComponent = priceListRegionComponent;
    }

    public getProductStatus(): string {
        return this.productStatusObj.getProductStatus();
    }

    public setProductStatus(status: string) {
        this.productStatusObj.setProductStatus(status);
    }

    public getColorBaseStatus(): string {
        return this.colorBaseStatusObj.getColorBaseStatus();
    }

    public setColorBaseStatus(status: string) {
        this.colorBaseStatusObj.setColorBaseStatus(status);
    }

    public getPriceListRegionData(): string {
        return this.priceListRegionComponent.getData();
    }

    public setPriceListRegionData(data: any) {
        this.priceListRegionComponent && this.priceListRegionComponent.setData(data);
    }

    public getPriceListRegionDetailComponent():PriceListRegionDetailComponent {
        return this.priceListRegionComponent;
    }

    public getCustomServiceConfig(serviceKey:string, params:any):any{
        const serviceOpts = Object.assign({},this._customAPIConstants[serviceKey]);       
        serviceOpts.url = serviceOpts.url
            .replace('{ORG_SID}', params.orgSid)
            .replace('{MATERIAL_VARIATION_SID}', params.materialVariationSid)
            .replace('{PRICE_LIST_ID}', params.priceListId)
            .replace('{REGION_SID}', params.regSid)
            .replace('{CURRENCY}', params.currency);
        if(params.addHost){
            serviceOpts.url = AppConstants.apihost + '/' + serviceOpts.url;
        }

        return serviceOpts;
    }


    public getGlobalPriceList(params:any):Observable<any>{
        const serviceOpts = this.getCustomServiceConfig(params.serviceKey, params);
        return this.baseService[serviceOpts.method.toLocaleLowerCase()](serviceOpts,{});
    }

    public getVariationPriceList(params:any):Observable<any>{
        const serviceOpts = this.getCustomServiceConfig(params.serviceKey, params);
        return this.baseService[serviceOpts.method.toLocaleLowerCase()](serviceOpts,{});
    }
    public getVariationPriceListBasedOnCurrency(params:any):Observable<any>{
        const serviceOpts = this.getCustomServiceConfig(params.serviceKey, params);
        return this.baseService[serviceOpts.method.toLocaleLowerCase()](serviceOpts,{});
    }

    public getCountriesByRegion(params:any):Observable<any>{
        const serviceOpts = this.getCustomServiceConfig('REGION_COUNTRIES', params);
        return this.baseService[serviceOpts.method.toLocaleLowerCase()](serviceOpts,{});
    }

    getAllCountries(){
        const serviceOpts = this.getCustomServiceConfig('GET_ALL_COUNTRIES',{});
        return this.baseService[serviceOpts.method.toLocaleLowerCase()](serviceOpts,{});
	}

    public isPricingListEditable(){
        const currentUser:any = this.auth.currentUser;
        if(currentUser.ccFinance){
            return false;
        }
        return true
    }

    formatCreatedData(data){
		const createdDate = new Date(data.createdDate);
        const modifiedDate = new Date(data.modifiedDate);
        if(data.createdDate && data.createdBy && !data.createdBy.includes(createdDate.toDateString())) {
            data.createdBy = (data.createdBy? data.createdBy: '') + ' ' + createdDate.toDateString() + ' @ ' + createdDate.toLocaleTimeString();
        }

        if(data.modifiedDate && data.modifiedBy && !data.modifiedBy.includes(modifiedDate.toDateString())) {
            const modifiedDate = new Date(data.modifiedDate);
            data.modifiedBy = (data.modifiedBy? data.modifiedBy: '') + ' ' + modifiedDate.toDateString() + ' @ ' + modifiedDate.toLocaleTimeString();
        }
	}

    public enableDisableGridColumn(columnsToHide: String[], advancedSearchConfig){
        // ownerOrganization
        let numberOfRows = $('td.' + columnsToHide[0]).length;
        let hide = false;
        hide = numberOfRows<=1;
        advancedSearchConfig.advSearch.forEach(element => {
          element.hidden=hide;
        });
        columnsToHide.forEach(column => {
          if(hide){
            $('td.' + column + ',th.' + column ).hide();
          }else{
            $('td.' + column + ',th.' + column ).show();
          }
        })
      }

      isHPUser(): boolean {
       // return true;
        const currentUser: any = this.auth.getcurrentUser();
        const currentOrgId = currentUser.organization ? currentUser.organization.id : ''
        const hpOrgSid = (envInfo && envInfo.config && envInfo.config.hpOrgSid) ? envInfo.config.hpOrgSid : [];
        if(hpOrgSid.includes(currentOrgId)){
          return true;
        }
        
        return false;
      }
      isSPecificOrgUser(): any {
        const specificOrgUserMap = {
            hpUser : false,
            rolandUser : false,
            ccUser : false,
        };
        const currentUser: any = this.auth.getcurrentUser();
        const currentOrgId = currentUser.organization ? currentUser.organization.id : ''
        const hpOrgSids = (envInfo && envInfo.config && envInfo.config.hpOrgSid) ? envInfo.config.hpOrgSid : [];
        const rolandOrgSids = (envInfo && envInfo.config && envInfo.config.rolandOrgSid) ? envInfo.config.rolandOrgSid : [];
        const ccOrgSids = (envInfo && envInfo.config && envInfo.config.ccOrgSid) ? envInfo.config.ccOrgSid : [];
        if(hpOrgSids.includes(currentOrgId)){
            specificOrgUserMap.hpUser = true;
        }
        if(rolandOrgSids.includes(currentOrgId)){
            specificOrgUserMap.rolandUser = true;
        }
        if(ccOrgSids.includes(currentOrgId)){
            specificOrgUserMap.ccUser = true;
        }
        
        return specificOrgUserMap;
      }

    isMultipleOrgAvailableForUser(){
		const currnetUser:any = this.auth.currentUser;
		return currnetUser.devadmin || currnetUser.ccSystemAdmin || (currnetUser.childOrgSids && currnetUser.childOrgSids.length > 0 ) ? true : false
	}

   

    isMultiple(value:number, multiple:number){
        value = Math.abs(value);
        multiple = Math.abs(multiple);
        let decimalPlace = Math.max(((value + '').split('.')[1] || '').length, ((multiple + '').split('.')[1] || '').length);
        let decimalPlacesShifted = 1;
        while(decimalPlace > 0 ){
            value *= 10;
            multiple *= 10;
            decimalPlacesShifted *= 10;
            decimalPlace--;
        }

        if((value / multiple) % 1 == 0 ){
            return true;
        }

        return false;
    }

    
}
