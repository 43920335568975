import { Injectable } from '@angular/core';
import { UtilService } from '@app/core/util.service';
import { AppConstants } from '@custom/core/app-constants';


@Injectable({
  providedIn: 'root'
})
export class CustomUtilsService {

  	constructor(private utilServices: UtilService) { }
	isNumber(value,){
		if(typeof value === 'string' && value.length){
			value = Number(value);
		}
		if(typeof value == 'number'){
			return true;
		}
		return false
	}

	isEmpty(value){
		if(value === ''  || value === null || typeof value === 'undefined'){
			return true
		}

		return false;
	}

	isValidQuantity(value, checkType = 'POS_INT', mandatory = false){
		let isValid = false;
		if(mandatory && this.isEmpty(value)){
			isValid = false;

		}else if(this.isNumber(value)){
			value = Number(value);
			if(checkType == 'POS_INT'){
				isValid = value > 0 && Number.isInteger(value); 
			}else if(checkType == 'NON_NEG_INT'){
				isValid = value >= 0 && Number.isInteger(value); 
			}else if(checkType == 'POS_DEC'){
				isValid = value >= 0; 
			}
		}else {
			isValid = true;
		}
		return  isValid;
	}
	
	getCusotmGridData(dTable, sid?:string){
		let data:any = [];
		dTable.rows().every((index)=>{
			data.push(dTable.row(index).data());
		});
		if(sid){
			data = data.find(item => item.sid == sid);
		}
		return data;
	}

	getAttachmentURL(attachmentId){
		return `${AppConstants.apihost}/attachments/download/attachment/${attachmentId}`;
	}

	roundTo(value, decimalPlaces){
		if (value == null) { return value; }
		if (decimalPlaces == 0) {
			return Math.round(value);
		}
		let decimalPower = Math.pow(10.00, decimalPlaces);
		return (Math.round(value * decimalPower) / decimalPower);
	}

	/**
	 * @description
	 * Takes an Array<V>, and a grouping function,
	 * and returns a Map of the array grouped by the grouping function.
	 *
	 * @param list An array of type V.
	 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
	 *                  K is generally intended to be a property key of V.
	 *
	 * @returns Map of the array grouped by the grouping function.
	 */
	groupBy(list, keyGetter) {
		const map = {};
		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map[key];
			if (!collection) {
				map[key]= [item];
			} else {
				collection.push(item);
			}
		});
		return map;
	}

	/**
	 * generate groups of 4 random characters
	 * @example getUniqueId(1) : 607f
	 * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
	 */
	getUniqueId(parts: number): string {
		return this.utilServices.getUniqueId(parts);
	}

	createAttachDownloadElem(file:any){
		const $attachmentElem = $('<a download>')
			.addClass('link attach-name')
			.html(file.fileName);
		let url = file.url ? file.url : this.getAttachmentURL(file.id);
		 
		$attachmentElem.attr({
			target : '_blank',
			href : url
		  })

		return $attachmentElem;
	}

	addDummyFieldToFormConfig(currentConfig, localPlaceHolder){
		localPlaceHolder.dummy={
			security_code: 'dummy',
			label: 'DUMMY',
			type: 'checkbox',
			name: 'dummy',
			placeholder: '',
			hidden: true,
			infoBubble: '',
			values:[],
			options:[],
			disabled: false,
			 tableConfig: { 
				 columns : [],
			  name: 'dummy' 
			 },
			toolbar: true,
			displayonly: false,
			validations: {
					  },
			formAttributes: {
			},
		};
		currentConfig.groups[0].items.push(localPlaceHolder.dummy);
	}
}
