import { Injectable } from '@angular/core';
import { FormBuilderDynamicOptionsComponent } from './form-builder-dynamic-options/form-builder-dynamic-options.component';
import { FormBuilderLookupTableComponent } from './form-builder-lookup-table/form-builder-lookup-table.component';
import { FormBuilderOptionsComponent } from './form-builder-options/form-builder-options.component';

@Injectable({
  providedIn: 'root'
})
export class CustomElementService {

  private _componentFactory:any = {};

  private _fbOptionsComponent:FormBuilderOptionsComponent;
  private _fbLookupTableComponent:FormBuilderLookupTableComponent;
  private _fbLookupTableDynamicComponent:FormBuilderDynamicOptionsComponent;

  constructor() { }

  get fbOptionsComponent(){
    return this._fbOptionsComponent
  }

  get componentFactory(){
    return this._componentFactory
  }

  set fbOptionsComponent(comp){
    this._fbOptionsComponent = comp;
  }
  set componentFactory(params){
    this._componentFactory[params.name] = params.comp;
  }

  get fbLookupTableComponent(){
    return this._fbLookupTableComponent
  }
  
  set fbLookupTableComponent(comp){
    this._fbLookupTableComponent = comp;
  }
}
