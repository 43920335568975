import { Component, OnInit, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { RegionsService } from '@app/regions/regions.service';
import { SearchService } from '@app/widget/vs-autosuggest/search.service';
import { of, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiConstants } from '@app/api-constants';
import { CustomService } from '@custom/services/custom.service';
import { BaseService } from '@app/core/base.service';
import { AuthenticationService } from '@app/auth/authentication.service';

interface PickDataModel {
    region: any;
    countries:[]
}
interface RegionModel {
    tittle: string;
    data:PickDataModel[];
    selectedItems: String[];
}

@Component({
	selector: 'app-price-list-region-detail',
	templateUrl: './price-list-region-detail.component.html',
	styleUrls: ['./price-list-region-detail.component.scss']
})
export class PriceListRegionDetailComponent implements OnInit {

	@Input() currentForm: any;
	@Input() currentElement: any;

    @ViewChild('regionSelect') regionSelectElem:ElementRef;

    subject: Subject<any> = new Subject();
    regionList = [];
    selectedItems = [];
    isCtrlPressed = false;
    bkData:any;
    isEditable:any;
    settings:any = {
        singleSelection: false,
        idField: 'sid',
        textField: 'name',
        enableCheckAll : false,
        allowSearchFilter: true ,
        allowRemoteDataSearch : true ,
        clearSearchFilter : true,
        defaultOpen : true
    };

    sourceList:any = {
        title : 'Available Countries',
        data : [
            // {
            //     region: {
            //         regionId : 'Africa',
            //         name : 'Africa'
            //     },
            //     countries:[
            //         {
            //             sid: 'Angola',
            //             name : 'Angola'
            //         },
            //         {
            //             sid: 'Kenya',
            //             name : 'Kenya'
            //         },
            //         {
            //             sid: 'Uganda',
            //             name : 'Uganda'
            //         }
            //     ]
            // },
            // {
            //     region: {
            //         regionId : 'Asia',
            //         name : 'Asia'
            //     },
            //     countries:[
            //         {
            //             sid: 'China',
            //             name : 'China'
            //         },
            //         {
            //             sid: 'India',
            //             name : 'India'
            //         },
            //         {
            //             sid: 'Myanmar',
            //             name : 'Myanmar'
            //         }
            //     ]
            // }
        ],
        selectedItems : []
        
    };

    destnList = {
        title : 'Selected Countries',
        data : [
            
        ],
        selectedItems : []        
    }

    @HostListener('window:keydown.control', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
      this.onctrlkeydown(event, true);
    }

    @HostListener('window:keyup.control', ['$event'])
    handleKeyup(event: KeyboardEvent) {
      this.onctrlkeydown(event, false);
    }

    @HostListener('window:arrowup.control', ['$event'])
    handlearrowup(event: KeyboardEvent) {
      this.onArrowKeyMove();
    }

	constructor(
        private customService : CustomService,
        private baseService : BaseService,
        private auth : AuthenticationService,
    ) { }
    
    ngOnInit() {     
        this.isEditable = this.customService.isPricingListEditable();
        this.customService.registerPriceListRegionDetailComponent(this);
        this.subject
            .pipe(debounceTime(500))
            .subscribe((text) => {
                const searchOpt = Object.assign({},ApiConstants.autoSuggestService_Regions);
                const q = '?q=' + (text || '') + '&pgNo=0&pgLen=1000';
                searchOpt.url = searchOpt.url + q;               
                this.baseService[searchOpt.method.toLocaleLowerCase()](searchOpt,{}).subscribe(
                    res=>{
                        this.regionList = res;
                    }
                )
               
            }
        );
    }

    onResponse(){
    }

    onItemSelect(selitem: any) {
        let existingRegion = this.sourceList.data.find(item => item.region.regionId == selitem.name);
        if(!existingRegion){
            existingRegion = {
                region : {
                    regionId : selitem.name,
                    name : selitem.name
                },
                countries : []
            };
            this.sourceList.data.push(existingRegion);           
        }

        this.customService.getCountriesByRegion({
            regSid : selitem.sid
        }).subscribe(
            res=> {
                let contries:any = this.destnList.data.map(item => item.countries);
                contries = contries.flat();
                res.forEach(newCountry => {
                    const exCountry = contries.find(exCountryItem => exCountryItem.sid == newCountry.sid);
                    if(exCountry){
                        newCountry.hidden = true;
                    }
                });
                existingRegion.countries = res
            }
        )
    }
    onItemDeselect(selitem: any){
        let existingIndex = this.sourceList.data.findIndex(item => item.region.regionId == selitem.name);
        if(existingIndex >= 0){           
           let item:any = this.sourceList.data.splice(existingIndex,1);  
           if(item && item[0]){
               item = item[0];
           }
           const countries = item.countries.map(item => item.sid);
           for (let index = 0; index < countries.length; index++) {
               if(this.sourceList.selectedItems.length){
                if(this.sourceList.selectedItems.includes(countries[index])){
                    this.sourceList.selectedItems = this.sourceList.selectedItems.filter(item=>item!= countries[index])
                }
               }else{
                   break;
               }
               
           }
        }
    }
    onSelectAll(items: any) {
        console.log(items);
    }
    onFilterChange(items: any) {
        this.subject.next(items);
       // console.log(items);
    }

    selectItem(item, list){
        if(!this.isEditable) return ;

        if(!this.isCtrlPressed){
            list.selectedItems.splice(0);
        }
        list.selectedItems.push(item.sid)
    }

    onctrlkeydown(event, value){
        if(!this.isEditable) return ;
        this.isCtrlPressed = value;
    }

    ondblClick(item, isSource){
        if(!this.isEditable) return ;
        const list = isSource ? this.sourceList : this.destnList;
        this.selectItem(item, list);
        if(isSource){
            this.moveToDestination();
        }else{
            this.moveToSource();
        }
    }

    onArrowKeyMove(){

    }
    moveToDestination(){
        const list1 = this.sourceList;
        const list2 = this.destnList;

        list1.data.forEach((srcData:any) =>{
            const srcRegion = srcData.region;
            srcData.countries.forEach(srcCountry =>{
                if(list1.selectedItems.includes(srcCountry.sid)){
                    srcCountry.hidden = true;
    
                    const destCountry = Object.assign({},srcCountry,{hidden:false});
                    let existingRegion = list2.data.find((destData:any) => {
                        if(destData && destData.region && destData.region.regionId == srcRegion.regionId){
                            return destData;
                        }
                    });
                    if(!existingRegion){
                        existingRegion = Object.assign({},{countries:[], region:srcRegion})
                        list2.data.push(existingRegion)
                    }
                    if(!existingRegion.countries.find(con=> con.sid == destCountry.sid)){
                        existingRegion.countries.push(destCountry);
                    }

                }
            });

            if(!srcData.countries.filter(item => !item.hidden).length){
                srcData.region.hidden = true;
            }
        });
        list1.selectedItems.splice(0)
    }

    moveToSource(){
        const list1 = this.destnList;
        const list2 = this.sourceList;

        list1.data.forEach((srcData:any, dataIndex) =>{
            const srcRegion = srcData.region;
            
            srcData.countries.forEach((srcCountry, index) =>{
                if(list1.selectedItems.includes(srcCountry.sid)){                   
                   
                    let existing = list2.data.find(list2Data => srcRegion.regionId == list2Data.region.regionId);
                    if(!existing){
                        existing = Object.assign({}, srcData, {countries:[]})
                        list2.data.push(existing)
                    }
                    existing.region.hidden = false;
                   const exCountry = existing.countries.find(item => item.sid == srcCountry.sid);
                   if(!exCountry){
                        existing.countries.push(Object.assign({},srcCountry))
                   }
                    existing.countries.forEach(item =>{
                        if(item.sid == srcCountry.sid){
                            item.hidden = false;
                        }
                    });                   

                }
            });
            srcData.countries = srcData.countries.filter(srcCountry => !list1.selectedItems.includes(srcCountry.sid))
            if(srcData.countries.length == 0){
                list1.data.splice(dataIndex, 1)
            }
        });
        list1.selectedItems.splice(0)
    }

    moveAllToight(){
       // this.destnList.data.splice(0);
       
        this.sourceList.data.forEach((item, index) => {

            const countries = item.countries;
            countries.forEach(country => {
                if(!this.sourceList.selectedItems.includes(country.sid)){
                    country.hidden = true;
                    this.sourceList.selectedItems.push(country.sid)
                }
            });
            item.region.hidden = true;
        });
        this.moveToDestination();        

    }
    moveAllToLeft(){
       // this.sourceList.data.splice(0);
        this.sourceList.data.forEach(srcItem => {
            srcItem.region.hidden = false;
            srcItem.countries.forEach(item => {
                item.hidden = false;
            })
        });
        this.destnList.data.splice(0);
        this.destnList.selectedItems.splice(0)
    }

    moveUp(list){
        const lastItem = list.selectedItems[list.selectedItems.length-1];
        const contries = list.data.map(item => item.countries).flat();
        const index = contries.findIndex(item => item.sid == lastItem );
        if(contries[index - 1]){
            list.selectedItems.splice(0);
            list.selectedItems.push(contries[index - 1].sid);
        }

    }
    moveToTop(list){
        const contries = list.data.map(item => item.countries).flat();
        if(contries[0]){
            list.selectedItems.splice(0);
            list.selectedItems.push(contries[0].sid);
        }
    }
    moveDown(list){
        const lastItem = list.selectedItems[list.selectedItems.length-1];
        const contries = list.data.map(item => item.countries).flat();
        const index = contries.findIndex(item => item.sid == lastItem );
        if(contries[index + 1]){
            list.selectedItems.splice(0);
            list.selectedItems.push(contries[index + 1].sid);
        }
    }
    movetoBottom(list){
        const contries = list.data.map(item => item.countries).flat();
        if(contries[contries.length -1]){
            list.selectedItems.splice(0);
            list.selectedItems.push(contries[contries.length -1].sid);
        }
    }

    getData():any{
        //[{id: "5fc1faf3-0cad-4821-80d2-5ea2b893702e", value: {alpha2: "x", name: "aaa", id: "2", region:regionName}}]

        const selectedCountries:any = this.destnList.data.map(data =>{
            return data.countries.map(country =>{
                return {
                    id:country.sid,
                    value: {
                        id: country.id,
                        alpha2 : country.alpha2,
                        name : country.name,
                        region : country.region.value.name
                    }
                }
            }).flat()
        });

        return selectedCountries.flat();

    }

    checkIsDirty(){
        const newData = this.getData().map(item => item.id).join('#');
        const oldData = this.bkData ? this.bkData.map(item => item.id).join('#') : '';
        if(oldData != newData){
            return true
        }
        return false;
    }

    clear(){
        this.destnList.data.splice(0);
        this.destnList.selectedItems.splice(0);
        this.sourceList.data.splice(0);
        this.sourceList.selectedItems.splice(0);
        this.bkData = [];
        this.selectedItems.splice(0);
    }


    setData(data:any){       
        this.bkData = data || [];
        if(!data.forEach) return;
        this.destnList.data.splice(0);
        data.forEach(country => {
            const regionName = country.value.region;            
            let exstingRegionData = this.destnList.data.find(item => item.region.regionId == regionName);
            if(!exstingRegionData){
                exstingRegionData = {
                    region : {
                        regionId : regionName,
                        name : regionName
                    },
                    countries : []
                }
                this.destnList.data.push(exstingRegionData);
            }
            exstingRegionData.countries.push({
                sid: country.id,
                id: country.value.id,
                alpha2: country.value.alpha2,
                name: country.value.name,
                region : {
                    value: {
                        name : regionName
                    }
                }
            });
        });
    }

    onMultiselectClick(){
        const subject$ = this.subject;
        setTimeout(()=>{
            const $searchInput = $('.multiselect-dropdown input[type=text]');
            $('.multiselect-dropdown input[type=text]').focus();
            subject$.next($searchInput.val() || '');
        },100)
    }

    ondblClickRegion(item, list){
        const selectList = this.sourceList;
        item.countries.forEach(country => {
            if(!selectList.selectedItems.includes(country.sid)){
                selectList.selectedItems.push(country.sid);
            }
        });

        this.moveToDestination();
    }
        
}
