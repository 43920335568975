import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-light-inks',
	templateUrl: './light-inks.component.html',
	styleUrls: ['./light-inks.component.scss']
})
export class LightInksComponent implements OnInit {

	@Input() currentForm: any;
	@Input() currentElement: any;

	constructor() { }

	ngOnInit() {
	}
}
