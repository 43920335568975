import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ModalPopupService } from '@app/shared/services/modal-popup.service';

/**
 * OPTIONS
 * historyUrl: '',
 * historyDetailUrl: '',
 * viewHistoryFormConfig: '',
 */
interface Classes {
  headerClass?: string;
  wrapTableClass?: string;
  innerTableWrapClass?: string;
  innerTableClass?: string;
};


@Component({
  selector: 'vs-workflow-history',
  templateUrl: './workflow-history.component.html',
  styleUrls: ['./workflow-history.component.scss']
})
export class WorkflowHistoryComponent implements OnInit {

  @Input() wfHistoryConfig: any = {};
  classes: Classes = {};

  wfHistory: any;
  openedWf: any;
  activeRow;

  constructor(private http: HttpClient, private modalService: ModalPopupService) { }

  getData(url): Observable<any> {
    return this.http.get(url);
  }

  getWFHistory(): Observable<any> {
    return this.getData(this.wfHistoryConfig.historyUrl);
  }

  LoadWorkflowHistoryDetail(index) {
    
    if (this.activeRow === index) {
      this.activeRow = undefined;
    } else {
      this.activeRow = index;
    }
    if (this.activeRow != null && this.activeRow != undefined) {
      this.getWorkflowHistoryDetail(index);
    }
  }

  getWorkflowHistoryDetail(index) {
    if (!this.wfHistory[index]) {
      return;
    }

    this.getData(`${this.wfHistoryConfig.historyDetailUrl}/${this.wfHistory[index].id}`).subscribe(data => {
      this.wfHistory[index].workflowHistoryDetail = data;
    });

  }

  openRequest(e: Event, wfh) {
    if (!Object.keys(this.wfHistoryConfig.formConfig).length) {
      return;
    }

    const formConfig = this.wfHistoryConfig.formConfig;
    this.modalService.openModalPopupWithForm(formConfig, JSON.parse(wfh.user_profile_changes_requested), null, null, null, null);
    e.stopPropagation();
  }

  openAttachment(wfh){
    
  }

  loadHistory() {
    this.getWFHistory().subscribe(data => {
      this.wfHistory = data;
      this.LoadWorkflowHistoryDetail(0);
    });
  }


  ngOnInit() {

    this.classes = this.wfHistoryConfig.classes;
    this.loadHistory();
  }

}
