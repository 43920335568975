import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VsTopbarComponent } from '@app/widget/vs-topbar/vs-topbar.component';
import { VsActionbarComponent } from '@app/widget/vs-actionbar/vs-actionbar.component';
import { VsCaptionbarComponent } from '@app/widget/vs-captionbar/vs-captionbar.component';
import { VsAutosuggestComponent } from '@app/widget/vs-autosuggest/vs-autosuggest.component';
import { VsGridComponent } from '@app/widget/vs-grid/vs-grid.component';
import { VsSpreadsheetComponent } from '@app/widget/vs-spreadsheet/vs-spreadsheet.component';
import { VsTabComponent } from '@app/widget/vs-tab/vs-tab.component';
import { ConditionalMandatoryDirective } from '@app/widget/directives/conditional-mandatory-directive';
import { ConditionalDisableDirective } from '@app/widget/directives/conditional-disable-directive';
import { VsFormComponent } from '@app/widget/vs-form/vs-form.component';
import { VsMenuComponent } from '@app/widget/vs-menu/vs-menu.component';
import { VsNavItemComponent } from '@app/widget/vs-menu/vs-nav-item/vs-nav-item.component';
import { FormDateComponent } from '@app/widget/form-date/form-date.component';
import { DateRangeComponent } from '@app/widget/date-range/date-range.component';
import { CheckboxComponent } from '@app/widget/checkbox-group/checkbox.component';
import { CheckboxGroupComponent } from '@app/widget/checkbox-group/checkbox-group.component';
import { ImageUploaderComponent } from '@app/widget/image-uploader/image-uploader.component';
import { InputLinkComponent } from '@app/widget/input-link/input-link.component';
import { FileUploaderComponent } from '@app/widget/file-uploader/file-uploader.component';
import { VsAutocompleteComponent } from '@app/widget/vs-autocomplete/vs-autocomplete.component';
import { FormAttachmentsComponent } from '@app/widget/form-attachments/form-attachments.component';
import { NumericDirective } from '@app/widget/directives/numeric.directive';
import { CustomMinDirective } from '@app/widget/directives/custom-min-validator.directive';
import { CustomMaxDirective } from '@app/widget/directives/custom-max-validator.directive';
import { IsEllipsisActiveDirective } from '@app/widget/directive/is-ellipsis-active.directive';
import { FormAttributeDirective } from '@app/widget/directives/CustomAttributeDirective';
import { InputNumberComponent } from '@app/widget/input-number/input-number.component';
import { InputCurrencyComponent } from '@app/widget/input-currency/input-currency.component';
import { ImageCarouselComponent } from '@app/widget/image-carousel/image-carousel.component';
import { ModalPopupComponent } from '@app/widget/modal-popup/modal-popup.component';
import { VsSearchComponent } from '@app/widget/vs-search/vs-search.component';
import { FormTabsComponent } from '@app/widget/form-tabs/form-tabs.component';
import { VsMultiselectComponent } from '@app/widget/vs-multiselect/vs-multiselect.component';
import { LayoutbuilderComponent } from '@app/widget/layoutbuilder/layoutbuilder.component';
import { VsKanbanComponent } from '@app/widget/vs-kanban/vs-kanban.component';
import { KanbanItemComponent } from '@app/widget/vs-kanban/kanban-item/kanban-item.component';
import { VsmenunewComponent } from '@app/widget/vsmenunew/vsmenunew.component';
import { VsmenunewitemComponent } from '@app/widget/vsmenunew/vsmenunewitem/vsmenunewitem.component';
import { VsReportsComponent } from '@app/widget/vs-reports/vs-reports.component';

import { VsFreehandComponent } from '@app/widget/vs-freehand/vs-freehand.component';
import { VsTabNewComponent } from '@app/widget/vs-tab-new/vs-tab-new.component';
import { VsTabNewItemComponent } from '@app/widget/vs-tab-new/vs-tab-new-item/vs-tab-new-item.component';
import { VsErrorReporterComponent } from '@app/widget/vs-error-reporter/vs-error-reporter.component';
import { VsCollaborationComponent } from '@app/widget/vs-collaboration/vs-collaboration.component';
import { SafePipe } from '@app/safe.pipe';
import { ImageCaptureComponent } from '@app/widget/image-capture/image-capture.component';
import { VsSchedulerComponent } from '@app/widget/vs-scheduler/vs-scheduler.component';
import { SliderComponent } from '@app/widget/slider/slider.component';
import { WorkflowHistoryComponent } from '@app/widget/workflow-history/workflow-history.component';
import { VsChangelogComponent } from '@app/widget/vs-changelog/vs-changelog.component';
import { VsChangelogMobileComponent } from '@app/widget/vs-changelog/vs-changelog-mobile/vs-changelog-mobile.component';
import { NumberRangeComponent } from '@app/widget/number-range/number-range.component';
import { StepFormsComponent } from '@app/widget/step-forms/step-forms.component';
import { VsViewersComponent } from '@app/widget/vs-viewers/vs-viewers.component';
import { ShowAllViewersComponent } from '@app/widget/vs-viewers/show-all-viewers/show-all-viewers.component';
import { CommentsComponent } from '@app/widget/comments/comments.component';
import { CustomtextComponent } from '@app/widget/customtext/customtext.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ContextMenuModule } from 'ngx-contextmenu';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { CustomFormModule } from './gen/custom_element/custom-element.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {CardModule} from 'primeng/card';

import { Ng5SliderModule } from 'ng5-slider';
import { NgxMaskModule } from 'ngx-mask';


import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import {PaginatorModule} from 'primeng/paginator';
import { VsTableEditComponent } from '@app/widget/vs-table-edit/vs-table-edit.component';
import { VsMultilevelTableComponent } from '@app/widget/vs-multilevel-table/vs-multilevel-table.component';
import { VsTreeComponent } from '@app/widget/vs-tree/vs-tree.component';
import { BreadcrumbComponent } from '@app/widget/breadcrumb/breadcrumb.component';
import { VsScannerComponent } from '@app/widget/vs-scanner/vs-scanner.component';

import { DropNotificationComponent } from '@app/widget/drop-notification/drop-notification.component';
import { NumberInputDirective } from '@app/widget/number-input.directive';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { TestSuiteFormLoaderComponent } from './labs-custom/test-suite-form-loader/test-suite-form-loader.component';
import { TestSuiteFormComponent } from './labs-custom/widgets/test-suite-form/test-suite-form.component';
import { TestSuiteCheckboxComponent } from './labs-custom/widgets/test-suite-checkbox-group/test-suite-checkbox.component';
import { TestSuiteCheckboxGroupComponent } from './labs-custom/widgets/test-suite-checkbox-group/test-suite-checkbox-group.component';
import { TestSuiteTableEditComponent } from './labs-custom/widgets/test-suite-table-edit/test-suite-table-edit.component';
import { TableMaxDirective } from './labs-custom/directives/table-max-validator.directive';
import { TableMinDirective } from './labs-custom/directives/table-min-validator.directive';
import { TableRequiredDirective } from './labs-custom/directives/table-required-validator.directive';
import { CustomNumberInputComponent } from '@app/widget/custom-number-input/custom-number-input.component';
import { CustomNumberInputDirective } from '@app/widget/custom-number-input.directive';
import { TableMultipleDirective } from './labs-custom/directives/table-multiple-validator.directive';
import {TooltipModule} from 'primeng/tooltip';
import { PossibleValuesComponent } from '@app/widget/possible-values/possible-values.component';
import { ChipsModule } from 'primeng/chips';


@NgModule({
  declarations: [
    VsTopbarComponent,
    VsActionbarComponent,
    VsCaptionbarComponent,
    VsAutosuggestComponent,
    VsGridComponent,
    VsSpreadsheetComponent,
    VsTabComponent,
    ConditionalMandatoryDirective,
    ConditionalDisableDirective,
    VsFormComponent,
    TestSuiteFormComponent,
    VsMenuComponent,
    VsNavItemComponent,
    FormDateComponent,
    DateRangeComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    ImageUploaderComponent,
    InputLinkComponent,
    FileUploaderComponent,
    VsAutocompleteComponent,
    FormAttachmentsComponent,
    NumericDirective,
    CustomMinDirective,
    CustomMaxDirective,
    IsEllipsisActiveDirective,
    FormAttributeDirective,
    InputNumberComponent,
    InputCurrencyComponent,
    ImageCarouselComponent,
    ModalPopupComponent,
    VsSearchComponent,
    FormTabsComponent,
    VsMultiselectComponent,
    LayoutbuilderComponent,
    VsKanbanComponent,
    KanbanItemComponent,
    VsmenunewComponent,
    VsmenunewitemComponent,
    VsReportsComponent,
    VsFreehandComponent,
    VsTabNewComponent,
    VsTabNewItemComponent,
    VsErrorReporterComponent,
    VsCollaborationComponent,
    SafePipe,
    ImageCaptureComponent,
    VsSchedulerComponent,
    SliderComponent,
    WorkflowHistoryComponent,
    VsChangelogComponent,
    VsChangelogMobileComponent,
    NumberRangeComponent,
    StepFormsComponent,
    VsViewersComponent,
    ShowAllViewersComponent,
    CommentsComponent,
    CustomtextComponent,
    VsTableEditComponent,
    VsMultilevelTableComponent,
    VsTreeComponent,
    BreadcrumbComponent,
    VsScannerComponent,
    DropNotificationComponent,
    NumberInputDirective,
    CustomNumberInputDirective,
    TestSuiteFormLoaderComponent,
    TestSuiteCheckboxComponent,
    TestSuiteCheckboxGroupComponent,
    TestSuiteTableEditComponent,
    TableMaxDirective,
    TableMinDirective,
    TableMultipleDirective,
    TableRequiredDirective,
    CustomNumberInputComponent,
    PossibleValuesComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgbModule,
    RouterModule,
    ContextMenuModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    CustomFormModule,
    DragDropModule,

    Ng5SliderModule,
    TableModule,
    TreeModule,
    CalendarModule,
    MultiSelectModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    ToolbarModule,
    BreadcrumbModule,
    CardModule,
    PaginatorModule,
    AutoCompleteModule,
    InputTextareaModule,
    TooltipModule,
    ChipsModule,
    
    NgxMaskModule.forRoot({})
  ],
  exports: [
    TranslateModule,
    NgbModule,
    VsTopbarComponent,
    VsActionbarComponent,
    VsCaptionbarComponent,
    VsAutosuggestComponent,
    VsGridComponent,
    VsSpreadsheetComponent,
    VsTabComponent,
    ConditionalMandatoryDirective,
    ConditionalDisableDirective,
    VsFormComponent,
    TestSuiteFormComponent,
    VsMenuComponent,
    VsNavItemComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    VsAutocompleteComponent,
    ModalPopupComponent,
    VsSearchComponent,
    VsKanbanComponent,
    VsmenunewComponent,
    VsReportsComponent,
    VsTabNewComponent,
    VsErrorReporterComponent,
    VsCollaborationComponent,
    ImageCaptureComponent,
    WorkflowHistoryComponent,
    StepFormsComponent,
    VsChangelogComponent,
    VsChangelogMobileComponent,
    VsViewersComponent,
    ShowAllViewersComponent,
    VsTableEditComponent,
    VsMultilevelTableComponent,
    VsTreeComponent,
    BreadcrumbComponent,
    VsScannerComponent,
    ImageCarouselComponent,
    DropNotificationComponent,
    TestSuiteFormLoaderComponent,
    TestSuiteCheckboxComponent,
    TestSuiteCheckboxGroupComponent,
    TestSuiteTableEditComponent,
    CustomNumberInputComponent
  ],
  entryComponents: [
    ModalPopupComponent,
    VsErrorReporterComponent,
    VsCollaborationComponent,
    ImageCaptureComponent,
    VsChangelogMobileComponent,
    ImageCarouselComponent,
    VsFormComponent,
    TestSuiteFormComponent,
    BreadcrumbComponent,
    DropNotificationComponent,
    TestSuiteFormLoaderComponent
  ]
})
export class SharedWidgetModule { }
