import { Component, OnInit, Input, forwardRef, ElementRef, Renderer } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-possible-values',
	templateUrl: './possible-values.component.html',
	styleUrls: ['./possible-values.component.scss'],
	providers: [
		{
		  provide: NG_VALUE_ACCESSOR,
		  useExisting: forwardRef(() => PossibleValuesComponent),
		  multi: true
		}
	  ]
})
export class PossibleValuesComponent implements OnInit,ControlValueAccessor {

	@Input() element: any;
	input: any = [];
	errorMessage:any = '';
	isErrorBoolean:any = false;
	disabled = false
	onPasteEvent = false;
	placeholder = 'Add Options';
	isIntegerOnly = false;

	private _model: any;
	private onChange: (m: any) => void;
	private onTouched: (m: any) => void;

	constructor(
		private translateService : TranslateService,
		private renderer: Renderer, 
		private elementRef: ElementRef
	) { }
	writeValue(value: any): void {
		this._model = value;
		this.setValue(this._model);
		//throw new Error('Method not implemented.');
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.renderer.setElementProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
		this.disabled = isDisabled;
	}

	get model() {
		return this._model;
	}

	set(value: any) {
		this._model = value;
		this.onChange(this._model);
	}

	ngOnInit() {	
		this.isIntegerOnly = this.element.constraints && this.element.constraints.type == 'integer';
	}

	onKeyPress(event){
		const specialKeys = ['Enter']
		if(event.key == ','){
			let val:any = $(event.target).val() || '';
			//;
			if(val){
				if(this.onPasteEvent){
					this.normalizeAndAddValue(val);
				}else{
					val = val.replace(/,/g, '');
					if(val){
						this.input.push(val);
					}
				}
				$(event.target).val('');
			}else{
				$(event.target).val('');
			}
		}else if(this.isIntegerOnly){
			if(!specialKeys.includes(event.key) && !this.isNumber(event.keyCode)){
				let val:any = ($(event.target).val() + '').replace(/\D/g, '');
				$(event.target).val(val)
			}
		}
	}
	onKeyDown(event){
		const specialKeys = ['Enter']
		if(this.isIntegerOnly){
			if(!specialKeys.includes(event.key) && !this.isNumber(event.keyCode)){
				event.preventDefault();
				event.stopPropagation();
				/* let val:any = ($(event.target).val() + '').replace(/\D/g, '');
				$(event.target).val(val) */
			}
		}
	}

	isNumber(keycode:Number){
		return (keycode >= 48 && keycode <= 57) || (keycode >= 96 && keycode <= 105)
	}

	onPaste(event){
		//1,2,3,4,5,6
		const $inp = $("app-possible-values input"); 
		this.onPasteEvent = true;
		const value = event.clipboardData.getData('Text');
		this.normalizeAndAddValue(value);
		event.clipboardData.clearData();
		$inp.hide()
		setTimeout(() => {
			$inp.val('');			
			$inp.show();			
			$inp.trigger('focus');			
		}, 5);

	}

	onAdd(event){		
		//this.normalizeAndAddValue(event.value)
		this._model = this.input.join(',');
    	this.onChange(this._model);
	}
	onRemove(event){		
		//this.normalizeAndAddValue(event.value)
		this._model = this.input.join(',');
    	this.onChange(this._model);
	}

	normalizeAndAddValue(value){
		if(this.onPasteEvent){
			
			value = (value || '').split(',');
			value.forEach(item => {
				item = item.trim();
				if(item){
					this.input.push(item);
				}
			});
			this.onPasteEvent = false;
		}
	}

	setValue(value:any){
		if(!value){
			this.input = [];
		}else{
			if(typeof value == 'string'){
				value = value.split(',')
			}
			this.input = value;

		}
	}
}
