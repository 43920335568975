import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, forwardRef, ViewChild, ElementRef, Output } from '@angular/core';
import { ModalPopupService } from '@app/shared/services/modal-popup.service';
import { UtilService } from '@app/core/util.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseAppConstants } from '@app/base-app-constants';
import { I } from '@angular/cdk/keycodes';
import * as numeral from 'numeral';
import { CustomService } from '@custom/services/custom.service';
/**
 * Simple table component with inline editing
 * Paginations not supported
 */
@Component({
  selector: 'app-test-suite-table-edit',
  templateUrl: './test-suite-table-edit.component.html',
  styleUrls: ['./test-suite-table-edit.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TestSuiteTableEditComponent),
      multi: true
    }
  ]
})
export class TestSuiteTableEditComponent implements OnInit, ControlValueAccessor {
  items: any[] = [];
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;
  public isDisabled = false;
  private touchedRows:any = {};
  @Input() tableConfig: any;
  @Input() element: any;

  constructor(private utilService: UtilService, public customService : CustomService){

  }

  writeValue(value: any): void {
    this.setData(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  setData(data: any[]) {
    let fixedRows = this.getFixedRows();
    if(!data || data.length==0){
      this.items = fixedRows;
    } else {
      if(fixedRows.length != data.length) {
        this.items = [];
        let dataMapped = this.getMappedData(data);
        for(let i=0; i<fixedRows.length; i++){
          if(!dataMapped[fixedRows[i].key]){
            this.items.push(fixedRows[i])
          }else{
            this.items.push(dataMapped[fixedRows[i].key])
          }
        }
      } else {
        this.items = data;
      }
    }
  }

  getMappedData(data){
    let output = {};
    data.forEach(element => {
      output[element.key] = element;
    });
    return output;
  }

  numberBlur(key, col, event){
    this.onTouched(event)
    this.touchedRows[key + col] = true;
  }

  onDataChange(items,col){
    col;
    this.onChange(this.filterFilledRows(col));
  }

  filterFilledRows(col?:any){
    let filledItems = this.items.filter(item=>{
      for(let key of Object.keys(item)){
        if(key!="key" && item[key] != null && item[key] !== ""){
          /* if(col.type == 'decimal'){
            item[col.name] = 1234.56;
          } */
          return true;
        }
        col
      }
      return false;
    })
    if(filledItems.length==0) return null;
    return filledItems;
  }

  getFixedRows(): any[]{
    let output = [];
    for(let i = 0; i<this.tableConfig.rows.length; i++){
      output.push({key: this.tableConfig.rows[i]});
    }
    return output;
  }

  private isNil(value: any): value is null | undefined {
    return value === null || value === undefined;
  }

  private formatNumberValue(data: any, field: any, type?: any, col?: any): string {
    if(data[field] == 0) return "0";
    let value = data[field];
    let displayValue:any = '';
    let format = col.format || '0,0.*';
    if (!this.isNil(value)) {
      let dispVal = value;
      displayValue = dispVal.toString()
      const sformat:any = format;
      if(sformat.replace(/[,.\s]/g,'').includes('123456')){            
        displayValue = this.customNumberFormat(displayValue, format);
      }else{
        displayValue = numeral.default(displayValue).format(format);
      }
    } 

    return displayValue;
  }
  private hasDecimal(num){
    return !!(num % 1);
  }
  private customNumberFormat(value, format){   
    
    let decimalSeperator = !Number(format.charAt(format.length-3)) ? format.charAt(format.length-3) : '';
    const positionSeperator = !Number(format.charAt(1)) ? format.charAt(1) : '';
    let sFormat = format;
    let isdecimal = this.hasDecimal(value);
    if(decimalSeperator){
      let formatParts = sFormat.split(decimalSeperator);
      sFormat = [
        formatParts[0].replaceAll(positionSeperator, ','),
        formatParts[1]
      ].filter(item => item).join('.')
    }else{
      if(!Number(format.charAt(1))){
        sFormat = sFormat.replace(format.charAt(1), ',');
        value = Number((value+ '').replace(format.charAt(1), ''))
      }
    }
    value = numeral.default(value).format(sFormat);
    const decimal = (decimalSeperator && isdecimal) ? value.split('.')[1] : ''
    const intPart = decimalSeperator ? 
            value.split('.')[0]
            .replaceAll(',', positionSeperator) 
            : value.replaceAll(',',positionSeperator)
    value = [intPart, decimal].filter(item => item).join(decimalSeperator);
    return value;
  }



  getVal(data: any, field: any, type?: any, col?: any) {
    if(type==="integer" || type==="decimal"){
      return this.formatNumberValue(data, field, type, col);
    }else{
      return data[field] ? data[field] : '';
    }
  }

  getDropDownVal(data: any, field: any, col: any){
    const val = data[field];
    let selectedOption;
    if(col.options) {
      selectedOption = col.options.filter(opt => opt.value == val);
    }
    if(selectedOption && selectedOption[0]){
      if(!selectedOption[0].value){
        return "";
      }else{
        return selectedOption[0].label
      }
    }else{
      return val;
    }
  }


  ngOnInit() {
  }
}
