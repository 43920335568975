import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {
  data: any;
  constructor() { }
  setData(data, key) {
    if (!this.data)
      this.data = {};
    this.data[key] = data;
  }
  getData(key) {
    if (!this.data)
      this.data = {};
    return this.data[key];
  }

}
