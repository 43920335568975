import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VsMenuService {
  menuStateChange: BehaviorSubject<string> = new BehaviorSubject<string>('open');
  public menuDataLoaded: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor() { }

  emit(value: string) {
    this.menuStateChange.next(value);
  }

  public emitMenuDta(value: any) {
    this.menuDataLoaded.next(value);
  }
}

