import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-extended-gamut-inks',
	templateUrl: './extended-gamut-inks.component.html',
	styleUrls: ['./extended-gamut-inks.component.scss']
})
export class ExtendedGamutInksComponent implements OnInit {

	@Input() currentForm: any;
	@Input() currentElement: any;

	constructor() { }

	ngOnInit() {
	}
}
