export function extendAppRoutingModuleRoutes(routes: any) {
    
    routes[2].children[0] = {
        path: "",
        pathMatch: "full",
        redirectTo: "organization",
    }

    routes[2].children[1] = {
        path: 'landing',
        loadChildren: () => import('@app/organization/organization.module').then(m => m.OrganizationModule),	
        data: {
    		breadcrumb: 'Landing',
    		label: 'ORGANIZATION'
        }
    }

   routes[2].children.push({
        path: 'shopping',
        //loadChildren: () => import('@app/shoppingcart/shoppingcart.module').then(m => m.ShoppingcartModule),
        loadChildren: () => import('@custom/modules/shoppingcart/shoppingcart.module').then(m => m.ShoppingcartModule),
	    data: {
      		label: 'COLORBASE_EXCHANGE'
		}
    });

    routes[2].children.push({
        path: 'suite',
        loadChildren: () => import('@custom/modules/labs-custom/labs-custom.module').then(m => m.LabsCustomModule),
	    data: {
      		label: 'TEST_SUITE'
		}
    });

    routes[2].children.push({
        path: 'developer',
        loadChildren: () => import('@custom/modules/developer/developer.module').then(m => m.DeveloperModule),
	    data: {
      		label: 'TEST_SUITE'
		}
    });

    let exchangeInvitationPath = {
        path: 'exchangeinvitation',
        loadChildren: './exchange-invitation/exchange-invitation.module#ExchangeInvitationModule'
    }

    routes.push(exchangeInvitationPath);

    return routes;
}
