import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { type } from 'os';
import { CustomElementService } from '../custom-element.service';

@Component({
	selector: 'app-form-builder-options',
	templateUrl: './form-builder-options.component.html',
	styleUrls: ['./form-builder-options.component.scss'],
	
})
export class FormBuilderOptionsComponent implements OnInit {

	@Input() currentForm: any;
	@Input() currentElement: any;
	input: any = [];
	errorMessage:any = '';
	isErrorBoolean:any = false;
	disabled = false
	onPasteEvent = false;
	placeholder = 'Add Options';
	isIntegerOnly = false;

	constructor(
		private custElementService : CustomElementService,
		private translateService : TranslateService
	) { }

	ngOnInit() {
	//	this.custElementService.fbOptionsComponent = this;
		const _that = this;
		this.custElementService.componentFactory = {
			name : _that.currentElement.name,
			comp : _that
		};
		this.isIntegerOnly = this.currentElement.constraints && this.currentElement.constraints.type == 'integer'
	}

	setValue(value:any){
		if(!value){
			this.input = [];
		}else{
			if(typeof value == 'string'){
				value = value.split(',')
			}
			this.input = value;

		}
	}
	setError(value){
		this.errorMessage = value;
		this.isErrorBoolean = typeof this.errorMessage == 'boolean';
	}
	getValue(){
		return this.input;
	}

	setDisabled(value){
		this.disabled = value;
	}

	onKeyPress(event){
		const specialKeys = ['Enter']
		if(event.key == ','){
			let val:any = $(event.target).val() || '';
			//;
			if(val){
				if(this.onPasteEvent){
					this.normalizeAndAddValue(val);
				}else{
					val = val.replace(/,/g, '');
					if(val){
						this.input.push(val);
					}
				}
				$(event.target).val('');
			}else{
				$(event.target).val('');
			}
		}else if(this.isIntegerOnly){
			if(!specialKeys.includes(event.key) && !this.isNumber(event.keyCode)){
				let val:any = ($(event.target).val() + '').replace(/\D/g, '');
				$(event.target).val(val)
			}
		}
	}
	onKeyDown(event){
		const specialKeys = ['Enter']
		if(this.isIntegerOnly){
			if(!specialKeys.includes(event.key) && !this.isNumber(event.keyCode)){
				event.preventDefault();
				event.stopPropagation();
				/* let val:any = ($(event.target).val() + '').replace(/\D/g, '');
				$(event.target).val(val) */
			}
		}
	}

	isNumber(keycode:Number){
		return (keycode >= 48 && keycode <= 57) || (keycode >= 96 && keycode <= 105)
	}

	onPaste(event){
		//1,2,3,4,5,6
		const $inp = $("app-form-builder-options input"); 
		this.onPasteEvent = true;
		const value = event.clipboardData.getData('Text');
		this.normalizeAndAddValue(value);
		event.clipboardData.clearData();
		$inp.hide()
		setTimeout(() => {
			$inp.val('');			
			$inp.show();			
			$inp.trigger('focus');			
		}, 5);

	}

	onAdd(event){		
		//this.normalizeAndAddValue(event.value)
	}

	normalizeAndAddValue(value){
		if(this.onPasteEvent){
			
			value = (value || '').split(',');
			value.forEach(item => {
				item = item.trim();
				if(item){
					this.input.push(item);
				}
			});
			this.onPasteEvent = false;
		}
	}

	checkValueExist(){
		
	}
}
