import { formatNumber } from '@angular/common';
import { Component, ElementRef, forwardRef, Input, OnInit, Renderer, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-custom-number-input',
  templateUrl: './custom-number-input.component.html',
  styleUrls: ['./custom-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomNumberInputComponent),
      multi: true
    }
  ]
})
export class CustomNumberInputComponent implements ControlValueAccessor, OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('numericType') numericType: string;
  @Input() customMin: number;
  @Input() customMax: number;
  @Input() mandatoryCondition: any;
  @Input() element: any;
  @Input() displayFormat: string;
  @Input() validations: any;
  @Input() multiple: any;
  private _model: any;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;

  public number: any = null;
  private defaultRegex = /^[0-9]+[\.]{0,1}[0-9]*$/g;
  public disabled: boolean;
  public buttonClickSubject = new Subject();

  @ViewChild('numberInput') numberInput:ElementRef;

  private specialKeys = {
    number: ['Backspace', 'Delete', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
    decimal: ['Backspace', 'Delete', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
  };

  private getDecimalSeparator = (1.1).toLocaleString().substring(1, 2);

  constructor(private renderer: Renderer, private elementRef: ElementRef) {

  }

  get model() {
    return this._model;
  }

  writeValue(value: any): void {
    if (value === "")
      value = null;
    this.setValueToField(value);
    this._model = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  set(value: any) {
    this._model = value;
    this.onChange(this._model);
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setElementProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    this.disabled = isDisabled;
  }

  private getRegExBasedOnUserLocale = () => {
    const n = this.getDecimalSeparator;
    if (n === ',') {
      return new RegExp('^[0-9]+[,]{0,1}[0-9]*$', 'g');
    } else {
      return new RegExp('^[0-9]+[\.]{0,1}[0-9]*$', 'g');
    }
    // return new RegExp('^[0-9]+\.[0-9]*$', 'g');
  }

  private regex = () => {
    return {
      number: new RegExp(/^\d+$/),
      decimal: this.getRegExBasedOnUserLocale()
    };
  }

  private getUserLocaleFromBrowser = () => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.language || 'en';
    }
  }

  private getFormattedValue = (value) => {
    if (!value) { return value; }
    value = String(value).replace(/,/g, '.');
    let formatted = value;
    try {
     // formatted = formatNumber(value, this.getUserLocaleFromBrowser());
    } catch (e) {
      formatted = formatNumber(value, 'en');
    }

    return Number(formatted);
  }

  private getDBTypedValue = (value) => {
    let valueToSet = String(value);
    if (this.getDecimalSeparator === ',') {
      //valueToSet = String(value).replace(/\./g, '').replace(/,/g, '.');
    } else {
      //valueToSet = String(value).replace(/,/g, '');
    }
    if (valueToSet === "null")
      valueToSet = "";
    return valueToSet;
  }

  onFocus = (event) => {
    if (this.number == null) {
      this.number = "";
    }
    const value = this.number;
    const decimal = this.getDecimalSeparator;
    let valueToSet = String(value);
    if (decimal === ',') {
      //valueToSet = String(value).replace(/\./g, '');
    } else {
      //valueToSet = String(value).replace(/,/g, '');
    }
    this.number = valueToSet;
  }

  onBlur = (event) => {
    const value = this.number;
    //this.number = this.getFormattedValue(value);
    this._model = this.getDBTypedValue(value);
    if (this._model !== "")
      this._model = Number(this._model);
    this.onChange(this._model);
    this.onTouched(this._model);
  }

  onKeyDown = (event) => {
    if (this.specialKeys[this.numericType].indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.number;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex()[this.numericType])) {
      event.preventDefault();
    }
  }

  setValueToField(value) {
    this.number = this.getFormattedValue(value);
  }

  onAccept(mask) {
  }

  onComplete(mask) {
  }

  ngOnInit() {
    if(!(this.validations.decimal === "" || this.validations.decimal === null || this.validations.decimal === undefined)){
      this.validations.decimal = Number(this.validations.decimal);
    }else{
      this.validations.decimal = "";
    }
  }

  onStepPress(type){
    $(this.numberInput.nativeElement).focus();
    setTimeout(()=>{
      this.buttonClickSubject.next(type); 

    });
   
  }


}
