import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnitConverterService {
  
  private readonly CONVERSION_MAP = {
    'MM-M' : 0.001,
    'CM-M': 0.01,
    'M-M': 1,
    'IN-M': 0.0254,
    'FT-M': 0.3048,
    'YD-M': 0.9144
  };

  constructor() { }

  convertToM(froUnit, value){
    
    const factor = this.CONVERSION_MAP[(`${froUnit}-M`).toLocaleUpperCase()];
    return value*factor;
  }

  precision(value, decimal = 4){
    return Number(value).toFixed(decimal);
  }
}
