import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from '@app/core/util.service';
import { ModalPopupService } from '@app/shared/services/modal-popup.service';
import { NotificationService } from '@app/shared/services/notification.service';

@Component({
  selector: 'app-vs-multilevel-table',
  templateUrl: './vs-multilevel-table.component.html',
  styleUrls: ['./vs-multilevel-table.component.scss']
})
export class VsMultilevelTableComponent implements OnInit {

  @Input() tableConfig: any;
  items: any[];
  levelUrlMap: any = {};
  backupData = {};
  loading = false;
  globalSearch = false;
  expandedRows:any;

  constructor(
    private http: HttpClient,
    private modal: ModalPopupService,
    private util: UtilService,
    private notify: NotificationService
  ) { }

  onRowEditInit(item, name, children, parent, parentitem) {
    if (item.sid) {
      this.backupData[item.sid] = { ...item };
    } else if (item.data && item.data.sid) {
      this.backupData[item.data.sid] = { ...item.data };
    }
  }

  onRowEditSave(item, name, children, parent, parentitem) {
    debugger;
    console.log(item);
    if (item.sid && item.sid != 'new') {

      if (this.util.isEqualIgnoreCase(item, this.backupData[item.sid], null, null)) {
        return;
      }
      if (!this.isValidData(item, name, children, parent)) {
        return;
      }
      delete this.backupData[item.sid];
      this.updateData(item, name, children, parent, parentitem);
    } else {
      if (!this.isValidData(item, name, children, parent)) {
        return;
      }
      delete item.sid;
      this.saveData(item, name, children, parent, parentitem)
    }
  }

  onRowEditCancel(item: any, index: number) {
  }

  handleEditComplete(event: any, name, children, parent, parentitem) {
    this.onRowEditSave(event.data, name, children, parent, parentitem);
  }

  onRowDelete(tbl, item, children, parent, parentitem) {
    this.modal.openConfirmationModal('Confirm', 'Are you sure you want to delete this record?', null, null, null).subscribe(d => {
      this.deleteData(tbl, item, children, parent, parentitem);
    });
  }

  addRow(item, name, children, parent) {
    debugger;
    if (item[name]) {
     item[name].push({sid: 'new'});
    } else {
      item.push({sid: 'new'});
    }
    this.onRowEditInit(item, name, children, parent, null)
  }

  handleRowExpand(event, name) {
    this.getLevelData(event.data, name)
  }

  /* Get data for the expanded level
    TODO: actual integration to be done.
  */
  getLevelData(item, name) {
    const url = this.levelUrlMap[name];
    if (url) {
      this.http.get(url).subscribe((data: any) => item[name] = data.results);
    }
  }

  getData() {
    if (this.tableConfig.cols.url) {
      this.http.get(this.tableConfig.cols.url).subscribe((d: any) => {
        
        this.setData(d.results);
      }, err => {
        console.log('err : ', err);
      });
    }
  }

  setData(data: any[]) {
    this.items = data;
  }

  saveData(item, name, children, parent, parentitem) {
    if (children.url) {
      this.http.post(children.url, item).subscribe(d => {
        console.log(d);
      }, err => {
        console.log(err);
      });
    } else if(parent.url && parentitem) {
      this.http.post(parent.url, parentitem).subscribe(d => {
        console.log(d);
      }, err => {
        console.log(err);
      });
    }
  }

  //TODO
  updateData(item, name, children, parent, parentitem) {
    debugger
    if (children.url) {
      this.http.put(children.url, item).subscribe(d => {
        console.log(d);
        // TODO: item = d;
      }, err => {
        console.log(err);
        
      });
    } else if(parent.url && parentitem) {
      this.http.put(parent.url, parentitem).subscribe(d => {
        console.log(d);
      }, err => {
        console.log(err);
      });
    }
  }

  //TODO
  deleteData(tbl, item, children, parent, parentitem) {
    if (children.url) {
      this.http.delete(children.url, item.sid).subscribe(d => {
        console.log(d);
        this.removeDeletedRecord(tbl, item);
      }, err => {
        console.log(err);
        this.removeDeletedRecord(tbl, item);
      });
    }
  }

  removeDeletedRecord(tbl, item) {
    tbl.value = tbl.value.filter(i => i.sid != item.sid);
  }

  getInitialData() {
    if (this.tableConfig.cols.url) {
      this.loading = true;
      this.http.get(this.tableConfig.cols.url).subscribe((data: any) => {
        this.items = data.results;
        this.loading = false;
      });
    }
  }

  /* Create a map of all the urls in different levels */
  getLevelUrlMap(columns) {
    for (const col in columns) {
      if (Object.prototype.hasOwnProperty.call(columns, col)) {
        const element = columns[col];
        if (col === 'url' && !this.levelUrlMap.hasOwnProperty(columns.name)) {
          this.levelUrlMap[columns.name] = element;
        }
        if (columns.hasOwnProperty('children')) {
          this.getLevelUrlMap(columns.children);
        }
      }
    }
  }

  isValidData(item, name, children, parent) {
    debugger;
    let errors = [];
    const cols = children.columns;
    cols.map(col => {
      if (col.validations) {
        if (col.validations.required && !item[col.name]) {
          errors.push(`${col.label} is mandatory. `)
        }
      }
      return col;
    });

    if (errors.length) {
      this.notify.error(errors.join(','));
      return false;
    }
    return true;
  }

  // TODO
  searchTable(event, item, name, children, parent, parentitem) {
    
    if(event.which == 13) {
      debugger;
      const srchStr = event.currentTarget.value;
      if(children.url) {
        this.http.get(children.url).subscribe((data: any) => {
          item.value = data.results;
          this.loading = false;
        }, err => {
          console.log(err)
        });
      } else if(parentitem && parent.url) {
        this.http.get(parent.url).subscribe((data: any) => {
          item.value = data.results;
          this.loading = false;
        }, err => {
          console.log(err)
        });
      }
      event.stopPropagation();
      event.preventDefault();
    }

  }

  lazyLoad(e) {
    console.log('lazy ', e);
  }

  getGlobalFilters(cols) {
    return cols.map(c => c.name);
  }

  doGlobalSearch(event) {
    console.log(event.currentTarget.value);
    if(event.which == 13){
      event.preventDefault();
      event.stopPropagation();
    }
  }

  expandAllRows(event, dt){
   /*  for (let data of dt.value) {
      this.expandedRows[data.sid] = true;
    } */
  }

  ngOnInit() {
    this.globalSearch = this.tableConfig.globalSearch;
    this.getLevelUrlMap(this.tableConfig.cols);
    this.getInitialData();
  }
}
