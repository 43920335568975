import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbInterfaceService {
  private pageId: string = '';
  private pageIdSource = new BehaviorSubject(this.pageId);
  public pageIdChanges = this.pageIdSource.asObservable();

  private breadcrumbCustomConf: any = '';
  private breadcrumbCustomConfSource = new BehaviorSubject(this.breadcrumbCustomConf);
  public breadcrumbCustomConfChanges = this.breadcrumbCustomConfSource.asObservable();

  constructor() { }

  changeBreadcrumbBasedOnPageId(id?: string) {
    this.pageIdSource.next(id);
  }

  updateBreadcrumb(conf?: any) {
    this.breadcrumbCustomConfSource.next(conf);
  }
}
