import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { VsFormComponent } from '@app/widget/vs-form/vs-form.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { CustomElementService } from '@custom/modules/gen/custom_element/custom-element.service';
import { LabsCustomService } from '../labs-custom.service';
import { distinctUntilChanged, pairwise, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-form-builder-detail',
  templateUrl: './form-builder-detail.component.html',
  styleUrls: ['./form-builder-detail.component.scss']
})
export class FormBuilderDetailComponent implements OnInit {
  @Input() data:any = {};
  @Input() formNodes:any = [];
  @Input() isDefaultSuit = false;
  @Input() formDisabled = false;
  @Input() previousRowIndex:any = null;
  @Input() onSave:any;
  @Input() pagename = 'Form Builder Detail';
  public formConfig:any
  public form:any;
  values: string[];
  public readonly FIELD_TYPES =this.labsCustomService.fieldTypes
  public readonly CELL_TYPES =this.labsCustomService.cellTypes
  isShowButton = false;
  isPageLoading = true;
  initCalled = false;
  possibleValueDependsOnFields = {};
  errorMessage = '';
  
  constructor(
    private modalService: NgbModal,
    private custElemntService: CustomElementService,
    private labsCustomService: LabsCustomService,

  ) { }
  @ViewChild(VsFormComponent) child;
  ngOnInit() {
    this.loadFormConfig();
    //this.getData();
  }

  getData(){
    if(this.data.sid){
      of({
        sid : 'aaaa',
        sectionName : 'sectionName',
        description : 'description',
        repeatable : true,
        legalDocument : 'legalDocument',
        fieldType : 'lookup',
        required : true,
        options : 'options1,options2 ',
  
      }).subscribe(res=>{
        this.data = res;
        //this.child.form.patchValue(this.data);
  
      })

    }
  }
  
  getDomElem(){
    return {
      $lookupElm : $('.form-builder-lookup-table'),
      $possibleElm : $('.form-section-subgroup-possible_values'),
      $multiValueElm : $('.form-section-subgroup-possible_values_depends'),
      $possibleValueDependsOn : $('.form-section-subgroup-possible_values_depends_on'),
      $columsElm : $('.form-section-subgroup-columns'),
      $rowsElm : $('.form-section-subgroup-rows'),
      $cellTypeElm : $('.form-section-subgroup-cell_type'),
      $intMinElm : $('.form-section-subgroup-min').eq(0),
      $intmaxElm : $('.form-section-subgroup-max').eq(0),
      $intMultipleElm : $('.form-section-subgroup-multiple').eq(0),
      $decMinElm : $('.form-section-subgroup-min').eq(1),
      $decMaxElm : $('.form-section-subgroup-max').eq(1),
      $decMultipleElm : $('.form-section-subgroup-multiple').eq(1),
    }
  }

  loadFormConfig(){

    const data = this.data || {};
    const isSection = data.type == 'SECTION';
    const isScoreNode = data.name == 'Score' && this.isDefaultSuit;
    const isCertificationNode = data.name == 'Certification Result' && this.isDefaultSuit;
   // this.formDisabled = true;
    if(this.formDisabled){
      this.isShowButton = false;
    }else {
      if(!this.isDefaultSuit || isScoreNode || isCertificationNode){
        this.isShowButton = true;
      }
    }
    
    const formConfig = {
      
      name : {	
        security_code: 'name',
        label: isSection ? 'SECTION_NAME' : 'FIELD_NAME',        
        type: 'text',
        name: 'name',
        placeholder: '',
        
        infoBubble: '',
        values:[],
        options:[],
        disabled: this.isDefaultSuit || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: {
          required : true
        },
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        }
      },
      description : {
        security_code: 'description',
        label: 'DESCRIPTION',

        type: 'textarea',
        name: 'description',
        placeholder: '',
        
        infoBubble: '',
        values:[],
        options:[],
        disabled: this.isDefaultSuit || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: { },
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        },
      },			
      repeatable : {
   
        security_code: 'repeatable',
        label: 'REPEATABLE',
        type: 'checkbox',
        name: 'repeatable',
        placeholder: '',
        hidden: !isSection,
        infoBubble: '',
        values:[],
        options:[],
        disabled: this.isDefaultSuit || this.formDisabled,
        tableConfig: { 
          columns : [],
          name: 'repeatable' 
        },
        toolbar: true,
        displayonly: false,
        validations: {
                },
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        },
      },
      attachments:{
        security_code: 'attachments',
        label: 'TEST_DOCUMENTS',
        type: 'file',
        objectKey: 'attachments',
        name: 'attachments',
        placeholder: '',
        hidden: !isSection,
        infoBubble: '',
        values:[], 
        multivalue : true,       
        options:[],
        disabled: this.isDefaultSuit || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: {},
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        }
      },
      fieldType : {
  
        security_code: 'fieldType',
        label: 'FIELD_TYPE',
        

        type: 'select',
        name: 'fieldType',
        placeholder: '',
        
        infoBubble: '',
        values:[],
        options:this.FIELD_TYPES,
        hidden: isSection,
        disabled: this.isDefaultSuit || this.formDisabled || this.checkFieldTypeDisabled(),
        toolbar: true,
        displayonly: false,
        validations: { required: !isSection },
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        },
      },
      lookupTable : {	
        security_code: 'lookupTable',
        label: 'LOOKUP',
        type: 'customelement',
        name: 'lookupTable',
        placeholder: '',
        multiple : true,
        hasCheckbox : true,
        infoBubble: '',
        hidden: isSection,
        values:[],
        options:[],
        disabled: this.isDefaultSuit || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: { },
        component: 'form-builder-lookup-table',
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        }
      },
      required : {
        security_code: 'required',
        label: 'REQUIRED',
        type: 'checkbox',
        name: 'required',
        placeholder: '',
        
        infoBubble: '',
        values:[],
        options:[],
        disabled: this.isDefaultSuit || this.formDisabled,
        hidden: isSection,
        tableConfig: { 
          columns : [],
          name: 'required' 
        },
        toolbar: true,
        displayonly: false,
        validations: {
                },
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        },
      },
      cellType : {
        security_code: 'cellType',
        label: 'CELL_TYPE',
        

        type: 'select',
        name: 'cellType',
        placeholder: '',
        
        infoBubble: '',
        values:[],
        options:this.CELL_TYPES,
        hidden: isSection,
        disabled: this.isDefaultSuit || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: {  },
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        },
      },
      intMin:{
        security_code: 'min',
        label: 'MIN',
        

        type: 'number',
        name: 'min',
        placeholder: '',
        
        infoBubble: '',
        values:[],
        options:[],
        hidden: isSection,
        disabled: this.isDefaultSuit || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: { 
          decimal : 0
         },
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        },
      },
      intMax:{
        security_code: 'max',
        label: 'MAX',
        

        type: 'number',
        name: 'max',
        placeholder: '',
        
        infoBubble: '',
        values:[],
        options:[],
        hidden: isSection,
        disabled: this.isDefaultSuit || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: { 
          decimal : 0
         },
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        },
      },
      intMultiple:{
        security_code: 'multiple',
        label: 'MULTIPLE',
        

        type: 'number',
        name: 'multiple',
        placeholder: '',
        
        infoBubble: '',
        values:[],
        options:[],
        hidden: isSection,
        disabled: this.isDefaultSuit || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: { 
          decimal : 0
         },
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        },
      },
      decMin:{
        security_code: 'min',
        label: 'MIN',
        

        type: 'number',
        name: 'min',
        placeholder: '',
        
        infoBubble: '',
        values:[],
        options:[],
        hidden: isSection,
        disabled: this.isDefaultSuit || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: { 
          decimal : 1000
         },
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        },
      },
      decMax:{
        security_code: 'max',
        label: 'MAX',
        

        type: 'number',
        name: 'max',
        placeholder: '',
        
        infoBubble: '',
        values:[],
        options:[],
        hidden: isSection,
        disabled: this.isDefaultSuit || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: { 
          decimal : 1000
         },
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        },
      },
      decMultiple:{
        security_code: 'multiple',
        label: 'MULTIPLE',
        

        type: 'number',
        name: 'multiple',
        placeholder: '',
        
        infoBubble: '',
        values:[],
        options:[],
        hidden: isSection,
        disabled: this.isDefaultSuit || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: { 
          decimal : 1000
         },
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        },
      },
      parentField : {	
        security_code: 'parentField',
        label: 'POSSIBLE_VALUES_DEPENDS_ON',
        type: 'select',
        name: 'parentField',
        placeholder: 'NONE',
        multiple : false,
        hasCheckbox : false,
        values:[],
        hidden: isSection,
        disabled: (!isScoreNode && this.isDefaultSuit) || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: { },
        options:this.getDependsOnFields(),
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        }
      },
      possibleValues : {	
        security_code: 'possibleValues',
        label: 'POSSIBLE_VALUES',
        type: 'customelement',
        name: 'possibleValues',
        placeholder: '',
        multiple : true,
        hasCheckbox : true,
        infoBubble: isCertificationNode ? '' : 'OPTIONS_HELP_TEXT',
        values:[],
        hidden: isSection,
        options:[],
        disabled: (!isScoreNode && this.isDefaultSuit) || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: { },
        component: 'form-builder-options',
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        }
      },
     
      multiLevelDropDownValues : {	
        security_code: 'multiLevelDropDownValues',
        label: 'POSSIBLE_VALUES_DEPENDS',
        type: 'customelement',
        name: 'multiLevelDropDownValues',
        placeholder: '',
        multiple : true,
        hasCheckbox : true,
        infoBubble: '',
        values:[],
        hidden: isSection,
        options:[],
        disabled: (!isScoreNode && this.isDefaultSuit) || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: { },
        component: 'form-builder-dynamic-options',
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        }
      },
      columns : { 
        security_code: 'columns',
        label: 'COLUMNS',
        type: 'customelement',
        name: 'columns',
        placeholder: '',
        multiple : true,
        hasCheckbox : true,
        infoBubble: 'OPTIONS_HELP_TEXT',
        values:[],
        hidden: isSection,
        options:[],
        disabled: this.isDefaultSuit || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: { },
        component: 'form-builder-options',
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        }
      },
      rows : {  
        security_code: 'rows',
        label: 'ROWS',
        type: 'customelement',
        name: 'rows',
        placeholder: '',
        multiple : true,
        hasCheckbox : true,
        infoBubble: 'OPTIONS_HELP_TEXT',
        values:[],
        hidden: isSection,
        options:[],
        disabled: this.isDefaultSuit || this.formDisabled,
        toolbar: true,
        displayonly: false,
        validations: { },
        component: 'form-builder-options',
        formAttributes: {
          change: this.handleCustomEvents.bind(this)
        }
      },
    };

    this.formConfig = {
      initCallback: (form)=>{ 
        this.isPageLoading = true;
        this.form = form;
        setTimeout(()=>{
          const doms = this.getDomElem(); 

          let possibleValuesComp = this.custElemntService.componentFactory.possibleValues;
          let multiLevelDropDownValuesComp = this.custElemntService.componentFactory.multiLevelDropDownValues;
          let columnsComp = this.custElemntService.componentFactory.columns;
          let rowsComp = this.custElemntService.componentFactory.rows;
          Object.keys(doms).forEach(key => doms[key].hide());       
          $('.modal-footer button:last-child').css('min-width','65px');

          if(this.formDisabled){
            this.custElemntService.fbLookupTableComponent.setDisabled(true);
            possibleValuesComp.setDisabled(true);
            rowsComp.setDisabled(true);
            columnsComp.setDisabled(true);
            const fieldType = this.data.fieldType ? this.data.fieldType.toUpperCase() : '';
            const cellType = this.data.cellType ? this.data.cellType.toUpperCase() : '';
            switch (fieldType) {
              case 'LOOKUP':
                doms.$lookupElm.show(); break;
              case 'TABLE':
                doms.$cellTypeElm.show()
                doms.$columsElm.show();
                doms.$rowsElm.show();
                switch(cellType){
                  case 'DROP_DOWN':
                    doms.$possibleElm.show(); break;
                  case 'DECIMAL':
                    doms.$decMinElm.show();
                    doms.$decMaxElm.show();
                    doms.$decMultipleElm.show();
                    break;
                  case 'INTEGER':
                    doms.$intMinElm.show();
                    doms.$intmaxElm.show();
                    doms.$intMultipleElm.show();
                    break;
                }      
                break;
              case 'DROP_DOWN':
              case 'RADIO_BUTTON':
              case 'CHECK_BOX':
                doms.$possibleElm.show(); break;
              case 'DECIMAL':
                doms.$decMinElm.show();
                doms.$decMaxElm.show();
                doms.$decMultipleElm.show();
                break;
              case 'INTEGER':
                doms.$intMinElm.show();
                doms.$intmaxElm.show();
                doms.$intMultipleElm.show();
                break;
            
              default:
                break;
            }
          }else{
            if(!isSection){

              if(this.isDefaultSuit){
                this.custElemntService.fbLookupTableComponent.setDisabled(true);
                if(!isScoreNode && !isCertificationNode){
                  possibleValuesComp.setDisabled(true);
                }
              }            

              this.form.get('fieldType')
                .valueChanges
                .pipe(
                  startWith(null),
                  distinctUntilChanged(),
                  pairwise()                  
                )
                .subscribe(([oldValue, value]) => {
                  const selectedVal = value ? value.toUpperCase() : '';
                  //oldValue = oldValue || this.form.
                  doms.$multiValueElm.hide();
                  doms.$possibleValueDependsOn.hide();
                  doms.$lookupElm.hide();
                  doms.$possibleElm.hide();
                  doms.$cellTypeElm.hide();
                  doms.$rowsElm.hide();
                  doms.$columsElm.hide();
                  doms.$intMinElm.hide();
                  doms.$intmaxElm.hide();
                  doms.$intMultipleElm.hide();
                  doms.$decMinElm.hide();
                  doms.$decMaxElm.hide();
                  doms.$decMultipleElm.hide();
                  form.controls.cellType.clearValidators();
                 // form.get('parentField').clearValidators();
                  this.clearErrorState();
                  
                 


                  if(selectedVal != oldValue){
                    if(selectedVal == 'LOOKUP'){
                      doms.$lookupElm.show();
                      form.controls.cellType.setValue('');
                      form.controls.min.setValue('');
                      form.controls.max.setValue('');
                      form.controls.multiple.setValue('');
                      columnsComp.setValue('');
                      rowsComp.setValue('');
                      if(!this.isPageLoading){
                        possibleValuesComp.setValue('');
                      }

                    }else if(selectedVal == 'TABLE'){
                      doms.$cellTypeElm.show()
                      doms.$columsElm.show();
                      doms.$rowsElm.show();
                      /*   form.controls.min.setValue('');
                      form.controls.max.setValue(''); */
                      form.controls.cellType.setValidators([Validators.required]);                    
                      if(form.controls.cellType.value == 'DROP_DOWN'){
                        doms.$possibleElm.show();
                        form.controls.min.setValue('');
                        form.controls.max.setValue('');
                        form.controls.multiple.setValue('');
                      }else{
                        possibleValuesComp.setValue('');
                      }
                      if(form.controls.cellType.value == 'INTEGER'){
                        doms.$intMinElm.show();
                        doms.$intmaxElm.show();
                        doms.$intMultipleElm.show();
                      }else{
                        doms.$intMinElm.hide();
                        doms.$intmaxElm.hide();
                        doms.$intMultipleElm.hide();                      
                      }
                      if(form.controls.cellType.value == 'DECIMAL'){
                        doms.$decMinElm.show();
                        doms.$decMaxElm.show();
                        doms.$decMultipleElm.show();
                      }else{
                        doms.$decMinElm.hide();
                        doms.$decMaxElm.hide();
                        doms.$decMultipleElm.hide();
                      }

                    }else if(['DROP_DOWN','RADIO_BUTTON','CHECK_BOX'].includes(selectedVal)){
                      doms.$possibleElm.show();
                      form.controls.cellType.setValue('');
                      columnsComp.setValue('');
                      rowsComp.setValue('');
                      form.controls.min.setValue('');
                      form.controls.max.setValue('');
                      form.controls.multiple.setValue('');
                    }else if(['MULTILEVEL_DROP_DOWN'].includes(selectedVal)){
                      doms.$multiValueElm.show();
                      doms.$possibleValueDependsOn.show();
                      form.controls.parentField.setValue('');
                      possibleValuesComp.setValue('');
                      form.controls.cellType.setValue('');
                      columnsComp.setValue('');
                      rowsComp.setValue('');
                      form.controls.min.setValue('');
                      form.controls.max.setValue('');
                      form.controls.multiple.setValue('');
                     // form.get('parentField').setValidators([Validators.required])
                    }else{
                      form.controls.cellType.setValue('');
                      columnsComp.setValue('');
                      rowsComp.setValue('');
                      possibleValuesComp.setValue('');

                      if(selectedVal === 'DECIMAL'){                      
                        doms.$decMinElm.show();
                        doms.$decMaxElm.show();
                        doms.$decMultipleElm.show();
                      }else if(selectedVal === 'INTEGER'){
                        doms.$intMinElm.show();
                        doms.$intmaxElm.show();
                        doms.$intMultipleElm.show();
                      }else{
                        form.controls.min.setValue('');
                        form.controls.max.setValue('');
                        form.controls.multiple.setValue('');
                      }
                    }
                  }

                  form.controls.cellType.updateValueAndValidity()

                 // form.get('parentField').updateValueAndValidity();

              });

              this.form.get('cellType').valueChanges.subscribe(value => {
                const selectedVal = value ? value.toUpperCase() : '';
                if(selectedVal && form.controls.fieldType.value == 'TABLE'){
                  doms.$possibleElm.hide();
                  if(selectedVal == 'DROP_DOWN'){
                    doms.$possibleElm.show();
                    form.controls.min.setValue('');
                    form.controls.max.setValue('');
                  }else if(selectedVal === 'DECIMAL'){
                    doms.$decMinElm.show();
                    doms.$decMaxElm.show();
                    doms.$decMultipleElm.show();
                    doms.$intMinElm.hide();
                    doms.$intmaxElm.hide();
                    doms.$intMultipleElm.hide();
                  }else if(selectedVal === 'INTEGER'){
                    doms.$intMinElm.show();
                    doms.$intmaxElm.show();
                    doms.$intMultipleElm.show();
                    doms.$decMinElm.hide();
                    doms.$decMaxElm.hide();
                    doms.$decMultipleElm.hide();
                  }else{
                    possibleValuesComp.setValue('');
                    form.controls.min.setValue('');
                    form.controls.max.setValue('');
                    form.controls.multiple.setValue('');
                  }

                  if(selectedVal == 'INTEGER'){
                    doms.$intMinElm.show();
                    doms.$intmaxElm.show();
                    doms.$intMultipleElm.show();
                  }else{
                    doms.$intMinElm.hide();
                    doms.$intmaxElm.hide();
                    doms.$intMultipleElm.hide();
                  }
                  if(selectedVal == 'DECIMAL'){
                    doms.$decMinElm.show();
                    doms.$decMaxElm.show();
                    doms.$decMultipleElm.show();
                  }else{
                    doms.$decMinElm.hide();
                    doms.$decMaxElm.hide();
                    doms.$decMultipleElm.hide();
                  }
                }
              });
              this.form.get('parentField')
                .valueChanges
                .pipe(
                  startWith(null),
                  distinctUntilChanged(),
                  pairwise()                  
                )
                .subscribe(([oldValue, value]) => {
                  const values = multiLevelDropDownValuesComp.getValue();
                  if(values.length && this.possibleValueDependsOnFields[oldValue]){
                    values.forEach(val =>{
                      const exstingItem = this.possibleValueDependsOnFields[oldValue].find(i => i.key == val.key)
                      if(exstingItem){
                        exstingItem.options = val.options;
                      }
                    })
                    //Object.assign(this.possibleValueDependsOnFields[oldValue], values)
                  }
                  multiLevelDropDownValuesComp.setValue(this.possibleValueDependsOnFields[value]);
                });
            }
          }
          setTimeout(()=>{
          
           
            this.form.controls.fieldType.setValue(this.data.fieldType);
            this.form.controls.cellType.setValue(this.data.cellType);
            this.form.controls.parentField.setValue(this.data.parentField || '');
            if(this.data.lookupTable){
              this.custElemntService.fbLookupTableComponent.setValue(this.data.lookupTable);
            }
            if(this.data.possibleValues && this.data.possibleValues.length){
              possibleValuesComp.setValue(this.data.possibleValues);
            }         
            if(this.data.columns && this.data.columns.length){
              columnsComp.setValue(this.data.columns);
            }
            if(this.data.rows && this.data.rows.length){
              rowsComp.setValue(this.data.rows);
            }
  
            if(this.data.multiLevelDropDownValues){
              multiLevelDropDownValuesComp.setValue(this.data.multiLevelDropDownValues);
            }
            this.isPageLoading = false;
          },100)
          

        },50)
      },
      collapsible: false,
      submit: 'saveBasicDetails',
      class: 'no-margin form-elements-group fb-detail-form',
      securityEvaluation: false,
      staticSecurityJson: '',
      disabledForm: false,
      columns: 100,
      groups: [
        {
    
          groupHeaderClass: 'form-group-subheader display-none',  		  
          groupContentClass: 'paddingZero',
          collapsible: 'false',
          columns: '100',
          label: '',
          disableRights: false,
          columnsWidth: 12,
          items: [
            {
      
              groupHeaderClass: 'hidden hidden',  		  
              groupContentClass: 'paddingZero',
              collapsible: 'false',
              columns: '0',
              label: '',
              disableRights: false,
              columnsWidth: 12,
              items: Object.values(formConfig)
            }
          ]
        }
      ]
    };

  }

  dataChanged = (data) => { 
    //this.customDataChanged(data);
   };

  handleCustomEvents(){

  }
  closeModel(){
    this.modalService.dismissAll();
  }
  saveData(){
    const submitButton: HTMLButtonElement = this.child.submitButton.nativeElement;
    submitButton.click();
    const data = this.getRowData();
    if(this.validate(data)){
      this.onSave && this.onSave(data);
      this.modalService.dismissAll();
    }

  }
  
  clearErrorState(){
    const possibleValuesComp = this.custElemntService.componentFactory.possibleValues;
    const columsComp = this.custElemntService.componentFactory.columns;
    const rowsComp = this.custElemntService.componentFactory.rows;
    const lookupTableComp = this.custElemntService.fbLookupTableComponent;
    $('.form-section-subgroup.name app-customtext').removeClass('error-border');
    $('.form-section-subgroup.fieldType .form-control').removeClass('error-border');
    $('.form-section-subgroup.possibleValues .field-holder').removeClass('error-border');
    $('.form-section-subgroup.parentField .field-holder').removeClass('error-border');
    $('.form-section-subgroup.columns .field-holder').removeClass('error-border');
    $('.form-section-subgroup.rows .field-holder').removeClass('error-border');
    $('.form-section-subgroup.lookupTable .field-holder').removeClass('error-border');
    $('.form-section-subgroup.min .form-control').removeClass('error-border');
    $('.form-section-subgroup.max .form-control').removeClass('error-border');
    possibleValuesComp.setError(false);    
    columsComp.setError(false);    
    rowsComp.setError(false);    
    lookupTableComp.setError(false);    
    this.errorMessage = '';   
  }

  validate(data){
    let  isvalid = true;
    const possibleValuesComp = this.custElemntService.componentFactory.possibleValues;
    const columsComp = this.custElemntService.componentFactory.columns;
    const rowsComp = this.custElemntService.componentFactory.rows;
    const lookupTableComp = this.custElemntService.fbLookupTableComponent;
    this.clearErrorState();

    if(!data.name){
      $('.form-section-subgroup.name app-customtext').addClass('error-border');
      isvalid = false;
    }

    if(data.type == 'FIELD'){
      const minValue = typeof data.min == 'number' ? data.min : null;
      const maxValue = typeof data.max == 'number' ? data.max : null;
      const invalidNumberRange = (minValue !== null && maxValue !== null) && minValue > maxValue;
      if(!data.fieldType){
        $('.form-section-subgroup.fieldType .form-control').addClass('error-border');
        isvalid = false;
      }else if(data.fieldType == 'LOOKUP' && !data.lookupTable){
        $('.form-section-subgroup.lookupTable .field-holder').addClass('error-border');
        lookupTableComp.setError(true);
        isvalid = false;

      }else if(data.fieldType == 'MULTILEVEL_DROP_DOWN' && !data.parentField){
        //$('.form-section-subgroup.parentField .field-holder').addClass('error-border');
       // isvalid = false;

      }else if(data.fieldType == 'TABLE'){
        if(data.cellType == 'DROP_DOWN' && !data.possibleValues){
          $('.form-section-subgroup.possibleValues .field-holder').addClass('error-border');
          possibleValuesComp.setError(true);
          isvalid = false;
        }
        if(invalidNumberRange){
          $('.form-section-subgroup.min .form-control').addClass('error-border');
          $('.form-section-subgroup.max .form-control').addClass('error-border');
          this.errorMessage = 'Min value should not be greater than Max value';
          isvalid = false;
        }

        if(!data.rows){
          $('.form-section-subgroup.rows .field-holder').addClass('error-border');
          rowsComp.setError(true);
          isvalid = false;
        }
        if(!data.columns){
          $('.form-section-subgroup.columns .field-holder').addClass('error-border');
          columsComp.setError(true);
          isvalid = false;
        }else if((data.columns || '').split(',').length < 1){
          $('.form-section-subgroup.columns .field-holder').addClass('error-border');
          columsComp.setError('Add at least 2 columns.');
          isvalid = false;
        }

      }else if(['INTEGER', 'DECIMAL'].includes(data.fieldType)){
        
        if(invalidNumberRange){
          $('.form-section-subgroup.min .form-control').addClass('error-border');
          $('.form-section-subgroup.max .form-control').addClass('error-border');
          this.errorMessage = 'Min value should not be greater than Max value';    
          isvalid = false;
        }

      }else if(['CHECK_BOX','RADIO_BUTTON','DROP_DOWN'].includes(data.fieldType) && !data.possibleValues){
        $('.form-section-subgroup.possibleValues .field-holder').addClass('error-border');
        possibleValuesComp.setError(true);
        isvalid = false;
      }
    }

    return isvalid
  }

  getRowData(){
    const possibleValuesComp = this.custElemntService.componentFactory.possibleValues;
    const columnsComp = this.custElemntService.componentFactory.columns;
    const rowsComp = this.custElemntService.componentFactory.rows;
    const multiLevelDropDownValuesComp = this.custElemntService.componentFactory.multiLevelDropDownValues;
    const possibleValues = possibleValuesComp.getValue() || [];
    const columns = columnsComp.getValue() || [];
    const rows = rowsComp.getValue() || [];
    const multiLevelDropDownValues = multiLevelDropDownValuesComp.getValue();
    const data = Object.assign(
      this.data, 
      this.child.form.getRawValue(),
      {
        possibleValues : (possibleValues.join(',') || null),
        columns : (columns.join(',') || null),
        rows : (rows.join(',') || null),
        lookupTable : this.data.type == 'SECTION' ? null : this.custElemntService.fbLookupTableComponent.getValue(),
        multiLevelDropDownValues : multiLevelDropDownValues
      }
    );

    if(data.fieldType != 'MULTILEVEL_DROP_DOWN'){
      data.parentField = null;
    }

    return data;
  }

  getDependsOnFields(){
    const fields = []
    let existingParentLoaded = false; 
    let currentNodeReached = false;
    let existingParent  = null;

    for (let index = 0; index < this.formNodes.length; index++) {
      const node = this.formNodes[index];
      if(this.data.parentField && ( node.data.id == this.data.parentField)){
        existingParent = node;
      }

      if(!currentNodeReached){
        if((node.data.id && (node.data.id == this.data.id)) || (!this.data.id && (this.previousRowIndex ==( index +1)))){
          currentNodeReached = true
        }
        if(node.data.fieldType == 'DROP_DOWN' ){
          if(this.data.parentField && (node.data.id == this.data.parentField)) {
            existingParentLoaded = true;
          }
          this.fillPossibleValueDependsOnFields(fields,node,existingParentLoaded);
        }
      }

      
    }

    if(!existingParentLoaded && existingParent){
      this.fillPossibleValueDependsOnFields(fields,existingParent, true);
    }
    return fields
  }

  fillPossibleValueDependsOnFields(target, node, existingParentLoaded){
    target.push({
      label : node.title,
      value : node.data.id,
      isTranslated : true,
    });

    this.possibleValueDependsOnFields[node.data.id] = [];
    (node.data.possibleValues || '').split(',').forEach(key => {
        const dependsField = {
          key : key,
          options : ''
        }
        if(existingParentLoaded && Array.isArray(this.data.multiLevelDropDownValues)){
          dependsField.options = ((this.data.multiLevelDropDownValues.find(i => i.key == key) || {}).options) || ''
        }
      this.possibleValueDependsOnFields[node.data.id].push(dependsField)
    })
  }

  checkFieldTypeDisabled():boolean{
    let isDisabled = false;
    for (let index = 0; index < this.formNodes.length; index++) {
      const node = this.formNodes[index];
      if(this.data.id && (node.data.parentField == this.data.id)){
        isDisabled = true;
        break;
      }      
    }
    return isDisabled
  }
}
