import { Component, OnInit, Input } from '@angular/core';
import { CustomElementService } from '../custom-element.service';
@Component({
	selector: 'app-form-builder-lookup-table',
	templateUrl: './form-builder-lookup-table.component.html',
	styleUrls: ['./form-builder-lookup-table.component.scss']
})
export class FormBuilderLookupTableComponent implements OnInit {
	
	@Input() currentForm: any;
	@Input() currentElement: any;

	errorMessage:any = '';
	filteredOptions:any = [];
	hiddenOptions = [
		'LABS',
		'ORGANIZATION',
		'LAB_PRINTERS',
		'CBL_APPLICATION_USER'
	]

	
	
	options:any =  [
		{
			label: '', 
			items: [
				{label: 'Choose Option', value: ''},
			]
		},
		{
			label: '', 
			items: [
				{label: 'Materials', value: 'MATERIAL'},
			]
		},		
		{
			label: '', 
			items: [
				{label: 'Material Variations', value: 'MATERIAL_VARIATION'},
			]
		},
		{
			label: 'LABS', 
			items: [
				{label: 'Labs', value: 'LABS'},
			]
		},
		{
			label: 'ORGANIZATION', 
			items: [
				{label: 'Material Manufacturer', value: 'ORGANIZATION'},
			]
		},
		{
			label: '', 
			items: [
				{label: 'Application Users', value: 'CBL_APPLICATION_USER'},
			]
		},
		{
			label: 'LAB_PRINTERS', 
			items: [
				{label: 'Printers', value: 'LAB_PRINTERS'},
			]
		},
		{
			label: 'Material Admin', 
			items: [
				{label: 'Adhesive Activation Method', value: 'ADHESIVE_ACTIVATION_METHOD'},
				{label: 'Adhesive Formulation', value: 'ADHESIVE_FORMULATION'},
				{label: 'Applications', value: 'APPLICATION'},
				{label: 'Coated Side', value: 'COATED_SIDE'},
				{label: 'Color', value: 'COLOR'},
				{label: 'Core Fixation', value: 'CORE_FIXATION'},
				{label: 'Finishes', value: 'FINISHES'},
				{label: 'Liner Substances', value: 'LINER_SUBSTANCE'},
				{label: 'Surface Shape Application', value: 'SURFACE_SHAPE_APPLICATION'},
				{label: 'Test Method', value: 'TEST_METHOD'}
			]
		},
		{
			label: 'Printer Admin', 
			items: [
				//{label: 'Printers', value: 'LAB_PRINTERS'},
				{label: 'Ink Technology', value: 'INK_TECHNOLOGY'},
			]
		},
		
	];
	selectedOption:any ='';
	disabled = false;
	constructor(
		private custElementService: CustomElementService
	) { }

	ngOnInit() {
		this.custElementService.fbLookupTableComponent = this;
		this.filteredOptions = this.options;
	}

	onShowMenu(){
		$('li:contains("empty")').hide();
	}
	onClick(){
		this.filteredOptions = this.options.filter(i => !this.hiddenOptions.includes(i.label));
	}

	getValue(){
		return this.selectedOption;
	}

	setValue(value){
		this.selectedOption = value;
	}
	setDisabled(value){
		this.disabled = value;
	}

	setError(value){
		this.errorMessage = value;
	}
}
