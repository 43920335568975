import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { ModalPopupService } from '@app/shared/services/modal-popup.service';
import { UtilService } from '@app/core/util.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseAppConstants } from '@app/base-app-constants';
/**
 * Simple table component with inline editing
 * Paginations not supported
 */
@Component({
  selector: 'app-vs-table-edit',
  templateUrl: './vs-table-edit.component.html',
  styleUrls: ['./vs-table-edit.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VsTableEditComponent),
      multi: true
    }
  ]
})
export class VsTableEditComponent implements OnInit {
  items: any[] = [];

  @Input() tableConfig: any;
  @Input() element: any;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;
  backupData = {};
  _model: any;
  renderer: any;
  disabled: boolean;
  autusuggestResults: any[] = [];

  @ViewChild('eTable') elementRef:ElementRef
  constructor(
    private http: HttpClient,
    private modal: ModalPopupService,
    private util: UtilService,
    private notify: NotificationService
  ) { }

  get model() {
    return this._model;
  }
  setValueToField(value) {
    this.items = value;
  }
  writeValue(value: any): void {
    if (!this.tableConfig.bindForm)
      return
    if (value === "" || !Array.isArray(value))
      value = [];
    this.setValueToField(value);
    this._model = value;
    this.setData(this._model);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    setTimeout(() => {
      this.beforeOnchange(this._model);
    })
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  beforeOnchange(data) {
    if (data === "" || !Array.isArray(data))
      data = null;
    else {
      const tdata = data.filter(value => Object.keys(value).length !== 0);
      data = tdata.length === 0 ? null : tdata;
    }

    let cloneData = JSON.parse(JSON.stringify(data));
    

    this.onChange(data);
  }
  set(value: any) {
    this._model = value;
    this.beforeOnchange(this._model);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.tableConfig.disabled = isDisabled;
    if(this.elementRef.nativeElement){
      this.renderer.setElementProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    }
  }

  handleOnChange(e, product, col) {

    let nameField = col.displayField ? col.displayField : col.name;
    let valueField = col.valueField ? col.valueField : 'sid';
    product[col.name] = {
      id: e[valueField],
      [col.displayField]: e[col.displayField],
      value: {
        [nameField]: e[nameField] || e[col.displayField]
      }
    }
  }

  onRowEditInit(item: any) {
    if (item.jid) {
      this.backupData[item.jid] = { ...item };
    } else if (item.data && item.data.jid) {
      this.backupData[item.data.jid] = { ...item.data };
    }

    if(item && Object.keys(item).length > 0 && item.constructor === Object) {
        this.assignTempModal(item);
    }
  }

  assignTempModal(item){
    this.tableConfig.columns.map(col => {
      if(col.type == 'autosuggest' && item && item[col.name] && item[col.name].value[col.displayField]){
        item[col.name][col.displayField] = item[col.name].value[col.displayField];
      } 
    });
  }

  onRowEditSave(item: any) {

    for(let key of Object.keys(item)){
      this.tableConfig.columns.map(col => {
        if(key == col.name && col.displayField) {
          delete item[key][col.displayField];
        }
      });
    }

    if (item.jid) {

      if (this.util.isEqualIgnoreCase(item, this.backupData[item.jid], null, null)) {
        return;
      }
      if (!this.isValidData(item)) {
        return;
      }
      delete this.backupData[item.jid];
      this.updateData(item);
    } else {
      if (!this.isValidData(item)) {
        return;
      }
      this.saveData(item)
    }
  }

  onRowEditCancel(item: any, index: number) {
    const dataItem = item.jid ? item : item.data;

    if (dataItem.jid && this.backupData[dataItem.jid] ) {
      const model = this.items.find(el => el.jid == dataItem.jid);
      if(model){
        const backupDataItem = this.backupData[dataItem.jid];
        Object.keys(model).forEach(key =>{
          model[key] = backupDataItem[key] || '';
        });
      }
    }
  }

  blurEvent(ev) {
    this.beforeOnchange(this._model);
  }

  handleEditComplete(event: any) {
    this.onRowEditSave(event.data);
  }

  onRowDelete(item: any, index: any) {
    //this.editing = false;
    this.modal.openConfirmationModal('Confirm', 'Are you sure you want to delete this record?', null, null, null).subscribe(isConfirmed => {
      if(isConfirmed){
        this.deleteData(item, index);
      }
    });
  }

  getVal(data: any, field: any, type?: any) {
    return data[field] ? data[field] : '';
  }

  getDropDownVal(data: any, field: any, col: any){
    const val = data[field];
    let selectedOption;
    if(col.options) {
      selectedOption = col.options.filter(opt => opt.value == val);
    }
    return selectedOption && selectedOption[0] ? selectedOption[0].label : val;
  }

  getAutosuggestVal(data: any, field: any, displayField?: any) {
    if(data[field] && data[field][displayField]){
      return data[field][displayField];
    }
    return data[field] && data[field]['value'] ? data[field]['value'][displayField] : '';
  }

  addRow(e, tbl) {
    let id = this.tableConfig.enableUUID? this.util.getUniqueId(5) : new Date().getTime();
    const itemToPush = {jid: id}; 
    this.tableConfig.onAction && this.tableConfig.onAction('AddRow', itemToPush);
    if (this.items) {
      this.items.push(itemToPush);
    } else {
      this.items = [itemToPush];
    }
    this.beforeOnchange(this._model);
    this.scrollToBottom(e, tbl);
    setTimeout(() => {
      $(tbl.el.nativeElement).find('tr:last .edit-btn').trigger('click');
    }, 100);
  }

  scrollToBottom(e, tbl) {
    const div = $(e.currentTarget).parents('.parent-div').find('.ui-table-scrollable-body')
    setTimeout(() => {
      $(div).scrollTop($(div)[0].scrollHeight);
    }, 500);
  }

  setInitialValue() {
    const cols = this.tableConfig || [];
    const colData = [];
    Object.keys(cols).forEach(c => {
      colData.push({ [c]: null });
    });
    this.items.push(colData);
  }

  setData(data: any[]) {
    this.items = data;
  }

  getData() {
    if (this.tableConfig.getUrl) {
      this.http.get(this.tableConfig.getUrl).subscribe((d: any) => {

        this.setData(d.results);
      }, err => {
        console.log('err : ', err);
      });
    }
  }

  saveData(item: any) {
    if (this.tableConfig.saveUrl || this.tableConfig.getUrl) {
      const url = this.tableConfig.saveUrl || this.tableConfig.getUrl;
      this.http.post(url, item).subscribe(d => {
        console.log(d);
      }, err => {
        console.log(err);
      });
    } else if (this.tableConfig.bindForm) {
      //debugger
    }
  }

  //TODO
  updateData(item: any) {
    if (this.tableConfig.updateUrl || this.tableConfig.getUrl) {
      const url = this.tableConfig.updateUrl || this.tableConfig.getUrl
      this.http.put(url, item).subscribe(d => {
        console.log(d);
        this.items = this.items.map(i => {
          if (i.jid == item.jid) {
            i = item;
          }
          return i;
        });
      }, err => {
        console.log(err);
        this.items = this.items.map(i => {
          if (i.jid == item.jid) {
            i = item;
          }
          return i;
        });
      });
    }
  }

  //TODO
  deleteData(item: any, index: any) {
    if (this.tableConfig.deleteUrl) {
      this.http.delete(this.tableConfig.deleteUrl, item.jid).subscribe(d => {
        this.removeDeletedRecord(item, index);
      }, err => {
        console.log(err);
        this.removeDeletedRecord(item, index);
      });
    } else if (this.tableConfig.bindForm) {
      this.removeDeletedRecord(item, index);
    }
  }

  removeDeletedRecord(item, index) {
    if (item.jid)
      this.items = this.items.filter(i => i.jid != item.jid);
    else
      this.items.splice(index, 1);
    this._model = this.items;  
    this.beforeOnchange(this._model);
  }

  isValidData(item) {
    let errors = [];
    const cols = this.tableConfig.columns;
    cols.map(col => {
      if (col.validations) {
        if (col.validations.required && !item[col.name]) {
          errors.push(`${col.label} is mandatory. `)
        }
      }
      return col;
    });

    if (errors.length) {
      this.notify.error(errors.join(','));
      return false;
    }
    return true;
  }

  searchAutocomplete(event, col) {
 
    let url = `${BaseAppConstants.apihost}/${col.url}`
    if(url.indexOf('?') > -1){
      url += `&q=${event.query}&pgNo=0&pgLen=50&sortColumn=name&sortOrder=ASC`;
    } else {
      url += `?q=${event.query}&pgNo=0&pgLen=50&sortColumn=name&sortOrder=ASC`;
    }
   
    this.http.get(url).subscribe((d: any) => {
      this.autusuggestResults = d;
    }, (err) => {
      console.error(err);
    /* this.autusuggestResults = [{
        createdBy: "darunkumar@vanenburg.com",
        createdDate: 1624267030117,
        id: "17",
        modifiedBy: "darunkumar@vanenburg.com",
        modifiedDate: 1624267030117,
        name: "English",
        recDeleted: false,
        sid: new Date().getTime().toString()
      }]; */
    });
  }

  ngOnInit() {
    this.setInitialValue();
    this.getData();
  }
}
