import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { TestSuitesService } from '@app/test-suites/test-suites.service';
import { BreadcrumbInterfaceService } from '@app/widget/breadcrumb/breadcrumb-interface.service';
import { VsFormComponent } from '@app/widget/vs-form/vs-form.component';
import { CustomUtilsService } from '@custom/services/custom-utils.service';
import { TestSutieServices } from '@custom/services/test-suite-services';
import { environment } from '@env/environment.hmr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as loadash from 'lodash';
import { of } from 'rxjs';
import { AppGlobalService } from '@app/app-global.service';
import { TestSuiteFormComponent } from '../widgets/test-suite-form/test-suite-form.component';
import { AuthenticationService } from '@app/auth/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, pairwise, startWith } from 'rxjs/operators';
import { CustomService } from '@custom/services/custom.service';
import { envInfo } from 'firebase/firebase';

@Component({
  selector: 'app-test-suite-form-loader',
  templateUrl: './test-suite-form-loader.component.html',
  styleUrls: ['./test-suite-form-loader.component.scss']
})
export class TestSuiteFormLoaderComponent implements OnInit {

  @Input() isFormDisabled: boolean;
  @Input() splitSection: boolean;
  @Input() showSectionButtons: boolean;
  @Input() isPreview: boolean = false;
  @Input() testSuiteId: boolean = false;
  @Input() testsuiteDetail: any = {};
  @Input() testResultDetail: any = {};
  @Input() onWizardChange: any = () => { };

  local: any = {};
  formConfig: any = {}
  data: any = {}
  form:any;
  commentData: any = {}
  attachmentData: any = {}
  formNodes: any;
  testSuiteName: string;
  defaultNodeIds = {};
  formItems: any = {}
  onPageLoad = true;
  tempValues: any = {}
  defaultNumberFormat = '';
  defaultIntegerFormat = '';
  dropdownFields = {};
  multiDropdownFields = {};
  parentDropdowns = {};
  errorCheckFields:string[] = [];
  fieldsToEnableWhenHPEditingCCREsult:string[] = ['Published in MSL', 'MSL ID','Uploader Name in MSL'];
  nodeRemoved = false;
  

  @ViewChild(TestSuiteFormComponent) childForm: TestSuiteFormComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private testSuiteServices: TestSutieServices,
    public activeModal: NgbActiveModal,
    public customUtilServices: CustomUtilsService,
    public testsuiteBaseService: TestSuitesService,
    public breadCrumb: BreadcrumbInterfaceService,
    public appGlobalService: AppGlobalService,
    public auth: AuthenticationService,
    public translateService: TranslateService,
    public customService: CustomService,
  ) { }

  dataChanged = (data) => {
    console.log(data)
  };
  ngOnInit() {
    const user:any = this.auth.getcurrentUser();
    if(user && user.numberFormatting){
      this.defaultNumberFormat = user.numberFormatting;
      this.defaultIntegerFormat = user.numberFormatting;
      if(!Number(this.defaultIntegerFormat.charAt(this.defaultIntegerFormat.length - 3))){
        this.defaultIntegerFormat = this.defaultIntegerFormat.replace(this.defaultIntegerFormat.charAt(this.defaultIntegerFormat.length - 3), '')
      }
    }
    this.attachListnerForTestSuitIds();
  }

  handleCustomEvents() {

  }

  validateAndGetData() {
    if (!this.childForm.validateForm()) {
      return false;
    }
    return this.getDataFromForm();
  }



  public sectionAddClick(sectionId) {
    this.addFormNode(sectionId);
  };

  public sectionRemoveClick(sectionId) {
    this.removeFormNode(sectionId);
  };

  addFormNode(sectionId) {
    this.getFormData();
    this.searchAndAppendSection(sectionId, { id: "", type: "SECTION", children: this.formNodes });
    this.loadform();
    this.setFormData();
  }

  removeFormNode(sectionId) {
    this.getFormData();
    this.searchAndRemoveSection(sectionId, { id: "", type: "SECTION", children: this.formNodes });
    this.loadform();
    this.setFormData();
    this.nodeRemoved = true;
  }
  /**
   * Searches the the form node configuration and appens a new section next to the section with the given section ID. 
   * Recursively searches through the children of this node.
   * @param sectionId The section which has to be cloned and new section will be added next to it. 
   * @param node FormNode in which the section has to be searched
   */
  searchAndAppendSection(sectionId, node): void {
    if (!node.children || node.children.length == 0) return
    // for each child of this node.
    for (let index = 0; index < node.children.length; index++) {
      let childNode = node.children[index]
      // section is found
      if (childNode.id === sectionId) {
        //copy of the section is created.
        let copiedNode = this.getChangedId(childNode);
        // if its the first time that the repeatition is clicked. repetition index is set as 1 for the existing section
        if (!childNode.repetitionIndex) {
          childNode.repetitionIndex = 1;
        }
        // repetitionIndex of added node is set as 1+ previous node repetition index
        copiedNode.repetitionIndex = childNode.repetitionIndex + 1;
        node.children.splice(index + 1, 0, copiedNode)
        // previous node repetition is set a false to remove repeat button.
        childNode.repeatable = false;
        break;
      } else {
        // if section is not found, recursivly check for the section inside the child.
        this.searchAndAppendSection(sectionId, childNode);
      }
    };
  }

  /**
   * Searches the the form node configuration and removes the section with given section ID. 
   * Recursively searches through the children of this node.
   * 
   * @param sectionId ID of the section to be removed
   * @param node - Node to be searched in
   */
  searchAndRemoveSection(sectionId, node): void {
    if (!node.children || node.children.length == 0) return
    for (let index = 0; index < node.children.length; index++) {
      let childNode = node.children[index]
      if (childNode.id === sectionId) {
        // the section is found. and removing the section
        node.children.splice(index, 1)
        // adjusting the repeation index
        this.setRepetitionIndex(node.children, index);
        break;
      } else {
        // recursively search for the section in child nodes
        this.searchAndRemoveSection(sectionId, childNode);
      }
    };
    // remove comment of the given section
    this.removeCommentsForSection(sectionId)
  }
  /**
   * Removes the comment for the given section from comments data map
   * @param sectionId Section id for which comment has to be deleted.
   */
  removeCommentsForSection(sectionId) {
    delete this.commentData[sectionId];
  }

  /**
   * Adjusts the repeatition index in the given list of node compensating the removed node.
   * 
   * @param nodes List of nodes from which the node is removed
   * @param removedIndex - node was removed from this index.
   */
  setRepetitionIndex(nodes, removedIndex) {
    let previousNode = nodes[removedIndex - 1];
    /**
     * The repetition index of the node just before the removed node.
     */
    let lastIndex = 0;
    /**
     * If the node is removed from the end of the repeatable section list
     */
    let removedFromEnd: boolean = false;
    if (this.isRemovedNodeIsAtTheEnd(nodes, removedIndex)) {
      // repeatable is set true in previous node to show the repeat button
      previousNode.repeatable = true;
      lastIndex = previousNode.repetitionIndex;
      removedFromEnd = true;
    } else {
      lastIndex = previousNode && previousNode.repetitionIndex ? previousNode.repetitionIndex : 0;
      if (nodes[removedIndex].repetitionIndex == 2) {
        // removed node is first node in the list
        lastIndex = 0;
      }
      let index = 0;
      while (true) {
        // setting the repeatition index untill end of the section is reached
        nodes[removedIndex + index].repetitionIndex = ++lastIndex;
        // if the next node is not having repetition index one greater than previous one, then its the end of the repeatable section
        if (!nodes[removedIndex + index + 1] || nodes[removedIndex + index].repetitionIndex + 2 != nodes[removedIndex + index + 1].repetitionIndex) {
          break;
        }
        index++;
      }
    }
    // if only one more node exists after removal repetitionIndex has to be deleted. if there is only 1 node, last index will be 1
    if (lastIndex == 1) {
      if (removedFromEnd) {
        delete previousNode.repetitionIndex
      } else {
        delete nodes[removedIndex].repetitionIndex
      }
    }
  }
  /**
   * Checks if the removed is from the end of the repeatable section.
   * 
   * @param nodes List of nodes in the same level of removed node.
   * @param removedIndex node removed form this index.
   * @returns True if the node was removed from the end else false.
   */
  isRemovedNodeIsAtTheEnd(nodes, removedIndex): boolean {
    return (!nodes[removedIndex] || (nodes[removedIndex - 1] && nodes[removedIndex - 1].repetitionIndex && nodes[removedIndex].repetitionIndex != nodes[removedIndex - 1].repetitionIndex + 2))
  }
  /**
   * Changes the ID of every nodes inside the given node recursively
   * @param node 
   * @returns node after changing all id inside the node
   */
  getChangedId(node) {
    let copiedNode = loadash.cloneDeep(node);
    copiedNode.id = this.testSuiteServices.getFormNodeId(copiedNode.name);
    copiedNode.repeatedFromNode = node.repeatedFromNode != null && node.repeatedFromNode !== "" ?  node.repeatedFromNode : node.id;
    if (copiedNode.children && copiedNode.children.length > 0) {
      for (let index = 0; index < copiedNode.children.length; index++) {
        copiedNode.children[index] = this.getChangedId(copiedNode.children[index])
      }
    }
    return copiedNode;
  }

  attachListnerForTestSuitIds() {
    this.formNodes = this.testSuiteServices.formNodes;
    if (this.formNodes) {
      this.loadform();
    }
    this.testSuiteServices.formNodesObserver.subscribe(formNodes => {
      this.formNodes = formNodes;
      this.loadform();
    })

    this.data = this.testSuiteServices.testData;
    this.loadData();
    this.testSuiteServices.testDataObserver.subscribe(testData => {
      this.data = testData;
      this.loadData();
    })
  }

  closePopup() {
    this.activeModal.dismiss(false);
  }

  loadform() {
    this.loadFormConfiguration();
  }

  getFormData() {
    const data = this.childForm.form.getRawValue();
    this.data = {};
    Object.keys(data).forEach(key =>{
      if(!key.includes('__THEIR_DATA__')){
        this.data[key] = data[key]

      }
    });

    return this.data;
  }

  getDataFromForm() {
    return this.childForm.getDataFromForm();
  }
  getFormNodes() {
    return this.formNodes;
  }

  setFormData() {
    this.childForm.form.patchValue(this.data);
  }
  setCommentData(data) {
    this.commentData = data;
  }

  setAttachmentData(data) {
    this.attachmentData = data;
  }

  loadData() {
    // this.getTestResult();
    // this.loadFormData();
  }

  formInitComplete(form) {
    this.form = form;
    if(!this.isFormDisabled){
      setTimeout(()=>{
        if (this.defaultNodeIds['Lab']) {
          if (!this.data[this.defaultNodeIds['Lab']] || !this.data[this.defaultNodeIds['Lab']].value) {
            form.controls[this.defaultNodeIds['Printer']].disable();
            form.controls[this.defaultNodeIds['Printer']].setValue('');
          }else if(this.data[this.defaultNodeIds['Lab']].id){
            const config: any = this.getLookupConfiguration('LAB_PRINTERS', { labId: this.data[this.defaultNodeIds['Lab']].id || '' })
            this.formItems['Printer'].url = config.url;
          }
          form.controls[this.defaultNodeIds['Lab']]
            .valueChanges
            .subscribe((res:any) => {
            if (res) {
              const config: any = this.getLookupConfiguration('LAB_PRINTERS', { labId: res.id || '' })
                this.formItems['Printer'].url = config.url;
                if (!this.isFormDisabled) {
                  form.controls[this.defaultNodeIds['Printer']].enable();
                }
                if (this.tempValues.labId != res.id && !this.childForm.mergeMode && !this.childForm.isCheckingConflicts) {
                  form.controls[this.defaultNodeIds['Printer']].setValue('');
                }
                this.tempValues.labId = res.id;
            } else {
              this.tempValues.labId = ''
              form.controls[this.defaultNodeIds['Printer']].disable();
              form.controls[this.defaultNodeIds['Printer']].setValue('');
            }
          });
        }
        if (this.defaultNodeIds['Material Manufacturer'] && form.controls[this.defaultNodeIds['Material Manufacturer']]) {
    
          form.controls[this.defaultNodeIds['Material Manufacturer']]
            .valueChanges
            .subscribe((res:any) => {
            if (res) {
                const config: any = this.getLookupConfiguration('MATERIAL', res)
      
                this.formItems['Material'].url = config.url;
                if (!this.isFormDisabled && form.controls[this.defaultNodeIds['Material']]) {
                  form.controls[this.defaultNodeIds['Material']].enable();
                }
                if (this.tempValues.mOrgId != res.id && form.controls[this.defaultNodeIds['Material']] && !this.childForm.mergeMode && !this.childForm.isCheckingConflicts) {
                  form.controls[this.defaultNodeIds['Material']].setValue('');
                }
                this.tempValues.mOrgId = res.id
            } else {
              this.tempValues.mOrgId = ''
              form.controls[this.defaultNodeIds['Material']].disable();
              form.controls[this.defaultNodeIds['Material']].setValue('');
            }
          });
    
          if (!this.data[this.defaultNodeIds['Material Manufacturer']] || !this.data[this.defaultNodeIds['Material Manufacturer']].value ) {
            form.controls[this.defaultNodeIds['Material']].disable();
            form.controls[this.defaultNodeIds['Material']].setValue('');
          }else if(this.data[this.defaultNodeIds['Material Manufacturer']].id){
            const config: any = this.getLookupConfiguration('MATERIAL', { id: this.data[this.defaultNodeIds['Material Manufacturer']].id || '' })
            this.formItems['Material'].url = config.url;
          }
    
          if ((!this.data[this.defaultNodeIds['Material Manufacturer']] || !this.data[this.defaultNodeIds['Material Manufacturer']].value) && !this.isFormDisabled) {
            form.controls[this.defaultNodeIds['Material Manufacturer']].setValue(this.testSuiteServices.getOrganization('MATERIAL_MANUFACTURER', 'PRINTER_MANUFACTURER'));
          }
        }
        
        Object.keys(this.parentDropdowns).forEach(key =>{
          if(form.controls[key]){

            form.controls[key].valueChanges.pipe(
              distinctUntilChanged(),
              startWith(null),
              pairwise()
            ).subscribe(([oldValue, res])=>{
              if(res !== oldValue && !this.childForm.mergeMode && !this.childForm.isCheckingConflicts){
                const childs = this.parentDropdowns[key];
                childs.forEach(childKey =>{
                  this.setPossibleValuesOfMultilevelDropdown(childKey, res)
                  form.controls[childKey].setValue('');
                })
              }
            });
          }
        });

        Object.keys(this.parentDropdowns).forEach(key => {
          if(form.controls[key]){
            form.controls[key].setValue(this.data[key]);
          }
          setTimeout(()=>{
            const childs = this.parentDropdowns[key];
              childs.forEach(childKey =>{
                form.controls[childKey] && form.controls[childKey].setValue(this.data[childKey]);
              })
          },50)
        })

        
        this.enableDisableSharedResultFields();

      },20)
    }

    this.onPageLoad = false;

  }

  enableDisableSharedResultFields(testResultDetail?:any){
    const form = this.form;
    this.testResultDetail = this.testResultDetail || testResultDetail;
    const ccOrgSid = (envInfo && envInfo.config && envInfo.config.ccOrgSid) ? envInfo.config.ccOrgSid : [];

    if(
      this.testResultDetail.performedOrganization && 
      ccOrgSid.includes(this.testResultDetail.performedOrganization) && 
      this.customService.isHPUser() &&
      form
    ){
      Object.keys(form.controls).forEach(key =>{
        form.controls[key].disable();
      })
      this.fieldsToEnableWhenHPEditingCCREsult.forEach(key => {
        if(this.defaultNodeIds[key] && form.controls[this.defaultNodeIds[key]]){
          form.controls[this.defaultNodeIds[key]].enable();
        }
      });
    }
  }

  setPossibleValuesOfMultilevelDropdown(multilevelDropdownKey, parentValue:any){
    let key = multilevelDropdownKey;
    let childNode = null;
    if(key.includes('__THEIR_DATA__')){
      key = key.replace('__THEIR_DATA__','');
      childNode = this.multiDropdownFields[key];
      parentValue = childNode ? parentValue[childNode.parentField] : parentValue;
    }else{
      childNode = this.multiDropdownFields[key];
    }
   
    if(parentValue && childNode){
        const multiDropItem = childNode.multiLevelDropDownValues.find(i => i.key == parentValue)
        const possible_values = multiDropItem ? multiDropItem.options : '';
        this.local[multilevelDropdownKey].options = this.getPossibleValues(possible_values,false);
    }
  }

  getDefaultNodeIds(formNodes) {
    const defaultSections = [].concat(formNodes[0].children).concat(formNodes[formNodes.length - 1].children);
    defaultSections.forEach(child => {
      this.defaultNodeIds[child.name] = child.id;
    });
    if(this.defaultNodeIds['Number of Passes']){
      this.errorCheckFields.push(this.defaultNodeIds['Number of Passes']);
    }

  }
  

  loadFormConfiguration() {
    let rootItems = []
    if (this.formNodes) {
      this.getDefaultNodeIds(this.formNodes);
      rootItems = this.getConfig(this.formNodes)
    }
    let formConfig = {
      initCallback: this.formInitComplete.bind(this),
      collapsible: false,
      submit: 'saveBasicDetails',
      class: 'no-margin form-elements-group',
      securityEvaluation: false,
      staticSecurityJson: null,
      disabledForm: true,
      showPrimarySectionWizard: this.splitSection,
      columns: 100,
      groups: rootItems
    }
    this.formConfig = formConfig;
    console.log(formConfig);
  }

  getConfig(nodes: any[]) {
    let sectionNodes = [];
    for (let index = 0; index < nodes.length; index++) {
      let node = nodes[index];
      this.setRepeatable(nodes, index);
      if (node.type == "SECTION") {
        let section: any = this.getSectionConfig(node);
        if (!section) {
          console.error("unable to create configuration for node: " + JSON.stringify(node))
        }
        if (node.children && node.children.length > 0) {
          section.items = this.getConfig(node.children);
        }
        sectionNodes.push(section);
      } else {
        const fieldConfig = this.getFieldConfig(node,);
        this.local[fieldConfig.id] = fieldConfig;
        const mergeFieldConfig:any = Object.assign(JSON.parse(JSON.stringify(fieldConfig)), {
          name: node.id+'__THEIR_DATA__',
          id: node.id+'__THEIR_DATA__',
          customClass : 'MERGE',
          merge:true,
          myData:false,
          theirdata:true,
          enableComment: false,
          enableAttachment: false,
          disabled: true,
        }) 
        sectionNodes.push(fieldConfig);
        sectionNodes.push(mergeFieldConfig);
        this.local[mergeFieldConfig.id] = mergeFieldConfig;
        sectionNodes.push({
          security_code: 'comment',
          label: '',
          type: 'textarea',
          name: node.id+'__MY_COMMENT__',
          placeholder: '',
          infoBubble: '',
          myData : true,
          customClass : 'conflicted-comment',
          commentControl : true,
          merge:true,
          values: [],
          options: [],
          disabled: true,
          rows : 3,
          tableConfig: {
            columns: [],
            name: 'locationName'
          },
          toolbar: false,
          displayonly: false,
          validations: {
            required: false,
          },
        });
        sectionNodes.push({
          security_code: 'comment',
          label: '',
          type: 'textarea',
          name: node.id+'__THEIR_COMMENT__',
          placeholder: '',
          customClass : 'conflicted-comment their-comment',
          commentControl : true,
          infoBubble: '',
          values: [],
          options: [],
          theirdata:true,
          merge:true,
          disabled: true,
          rows : 3,
          tableConfig: {
            columns: [],
            name: 'locationName'
          },
          toolbar: false,
          displayonly: false,
          validations: {
            required: false,
          },
        });
        sectionNodes.push({
          security_code: 'attachment',
          label: '',
          type: 'file',
          name: node.id+'__MY_ATTACHMENT__',    
          customClass : 'conflicted-comment',    
          attachmentControl : true,
          placeholder: '',
          infoBubble: '',
          multivalue: true,
          values: [],
          options: [],
          disabled: true,
          tableConfig: {
            columns: [],
            name: 'locationName'
          },
          toolbar: false,
          displayonly: false,
          validations: {
            required: false,
          },
          myData : true,
           merge:true,
        });
        sectionNodes.push({
          security_code: 'attachment',
          label: '',
          type: 'file',
          name: node.id+'__THEIR_ATTACHMENT__',
          customClass : 'conflicted-comment their-comment',
          placeholder: '',
          infoBubble: '',
          multivalue: true,
          values: [],
          options: [],
          disabled: true,
          theirdata:true,
          tableConfig: {
            columns: [],
            name: 'locationName'
          },
          toolbar: false,
          displayonly: false,
          validations: {
            required: false,
          },
          attachmentControl : true,
          merge:true,
        });
        this.setMultiDropdown(node);
      }
    }
    return sectionNodes;
  }

  setRepeatable(nodes, index) {
    if (nodes[index].repetitionIndex && nodes[index + 1] && nodes[index + 1].repetitionIndex
      && nodes[index].repetitionIndex + 1 != nodes[index + 1].repetitionIndex) {
      // sets repeatible = true if the repeation index of current index is one less thaan current index + 1
      nodes[index].repeatable = true;
    }
  }

  getSectionConfig(node: any) {
    return {
      groupHeaderClass: 'form-group-subheader',
      groupContentClass: 'paddingZero',
      help_text: node.description,
      repeatable: node.repeatable,
      removable: !!node.repetitionIndex,
      attachments: node.attachments,
      disabled: this.isFormDisabled,
      collapsible: true,
      id: node.id,
      columns: '1',
      repetitionIndex: node.repetitionIndex,
      label: node.name,
      enableComment: false,
      description: node.description,
      disableRights: false,
      columnsWidth: 12,
      items: []
    }
  }

  getFieldConfig(node: any) {
    let config = null;
    //this.isFormDisabled = true;
  
    switch (node.fieldType) {
      case "TEXT":
        config = {
          security_code: node.id,
          label: node.name,
          enableComment: true,
          type: 'text',
          name: node.id,
          enableAttachment: true,
          id: node.id,
          placeholder: '',
          infoBubble: node.description,
          values: [],
          options: [],
          disabled: this.isFormDisabled,
          toolbar: true,
          displayonly: false,
          validations: {
            required: node.required,
          },
          formAttributes: {
            change: this.handleCustomEvents.bind(this)
          },
        }
        break;
      case "TEXT_MULTILINE":
        config = {
          security_code: node.id,
          label: node.name,
          enableComment: true,
          enableAttachment: true,
          disabled: this.isFormDisabled,
          type: 'textarea',
          name: node.id,
          id: node.id,
          placeholder: '',
          infoBubble: node.description,
          values: [],
          options: [],
          toolbar: true,
          displayonly: false,
          validations: {
            required: node.required,
          },
          formAttributes: {
            change: this.handleCustomEvents.bind(this)
          },
        }
        break;
      case "INTEGER":
        config = {
          security_code: node.id,
          label: node.name,
          enableComment: true,
          type: 'customNumber',
          name: node.id,
          enableAttachment: true,
          id: node.id,
          placeholder: '',
          infoBubble: node.description,
          values: [],
          showValidationWithoutSubmit: true,
          options: [],
          disabled: this.isFormDisabled,
          toolbar: true,
          displayonly: false,
          customMin: node.min,
          customMax: node.max,
          multiple: node.multiple,
          format : this.defaultIntegerFormat,
          validations: {
            required: node.required,
            decimal: 0
          },
          formAttributes: {
            change: this.handleCustomEvents.bind(this)
          },
        }
        break;
      case "DECIMAL":
        config = {
          security_code: node.id,
          label: node.name,
          enableComment: true,
          type: 'customNumber',
          name: node.id,
          enableAttachment: true,
          id: node.id,
          placeholder: '',
          infoBubble: node.description,
          showValidationWithoutSubmit: true,
          values: [],
          options: [],
          disabled: this.isFormDisabled,
          toolbar: true,
          displayonly: false,
          customMin: node.min,
          customMax: node.max,
          multiple: node.multiple,
          format:this.defaultNumberFormat,
          validations: {
            required: node.required,
            decimal: 1000
          },
          formAttributes: {
            change: this.handleCustomEvents.bind(this)
          },
        }
        break;
      case "DROP_DOWN":
        config = {
          security_code: node.id,
          label: node.name,
          enableComment: true,
          type: 'select',
          name: node.id,
          enableAttachment: true,
          id: node.id,
          placeholder: '',
          infoBubble: node.description,
          values: [],
          options: this.getPossibleValues(node.possibleValues, false),
          disabled: this.isFormDisabled,
          toolbar: true,
          displayonly: false,
          validations: {
            required: node.required,
          },
          formAttributes: {
            change: this.handleCustomEvents.bind(this)
          },
        }
        this.dropdownFields[node.id] = node;
        break;
      case "MULTILEVEL_DROP_DOWN":
          config = {
            security_code: node.id,
            label: node.name,
            enableComment: true,
            type: 'select',
            name: node.id,
            enableAttachment: true,
            id: node.id,
            placeholder: '',
            infoBubble: node.description,
            values: [],
            options: this.getPossibleValues(node.possibleValues, false),
            disabled: this.isFormDisabled,
            toolbar: true,
            displayonly: false,
            validations: {
              required: node.required,
            },
            formAttributes: {
              change: this.handleCustomEvents.bind(this)
            },
          }
          
          break;
      case "RADIO_BUTTON":
        config = {
          security_code: node.id,
          label: node.name,
          enableComment: true,
          type: 'radio',
          name: node.id,
          enableAttachment: true,
          id: node.id,
          placeholder: '',
          infoBubble: node.description,
          values: this.getPossibleValues(node.possibleValues, false),
          options: [],
          disabled: this.isFormDisabled,
          toolbar: true,
          displayonly: false,
          validations: {
            required: node.required,
          },
          formAttributes: {
            change: this.handleCustomEvents.bind(this)
          },
        }
        break;
      case "CHECK_BOX":
        config = {
          security_code: node.id,
          label: node.name,
          enableComment: true,
          type: 'checkboxgroup',
          name: node.id,
          enableAttachment: true,
          id: node.id,
          placeholder: '',
          infoBubble: node.description,
          values: this.getPossibleValues(node.possibleValues, false),
          options: [],
          disabled: this.isFormDisabled,
          toolbar: true,
          displayonly: false,
          validations: {
            required: node.required,
          },
          formAttributes: {
            change: this.handleCustomEvents.bind(this)
          },
        }
        break;
      case "LOOKUP":
        const lookupConfigs: object = this.getLookupConfiguration(node.lookupTable)
        config = {
          security_code: node.id,
          label: node.name,
          enableComment: true,
          type: 'autosuggest',
          name: node.id,
          enableAttachment: true,
          id: node.id,
          placeholder: '',
          infoBubble: node.description,
          options: [],
          disabled: this.isFormDisabled,
          toolbar: true,
          displayonly: false,
          validations: {
            required: node.required,
          },
          formAttributes: {
            change: this.handleCustomEvents.bind(this)
          },
          multiple: false,
          ...lookupConfigs
        }
        break;
      case "DATE":
        config = {
          security_code: node.id,
          label: node.name,
          enableComment: true,
          type: 'date',
          name: node.id,
          enableAttachment: true,
          id: node.id,
          placeholder: '',
          infoBubble: node.description,
          values: [],
          options: [],
          disabled: this.isFormDisabled,
          toolbar: true,
          displayonly: false,
          validations: {
            required: node.required,
          },
          formAttributes: {
            change: this.handleCustomEvents.bind(this)
          },
        }
        break;
      case "FILE_UPLOAD":
        config = {
          security_code: node.id,
          label: node.name,
          enableComment: true,
          type: 'file',
          name: node.id,
          enableAttachment: true,
          id: node.id,
          placeholder: '',
          infoBubble: node.description,
          multivalue: true,
          disabled: this.isFormDisabled,
          toolbar: true,
          displayonly: false,
          validations: {
            required: node.required,
          },
          formAttributes: {
            change: this.handleCustomEvents.bind(this)
          },
        }
        break;
      case "TABLE":
          config = {
            security_code: node.id,
            label: node.name,
            enableComment: true,
            type: 'table',
            name: node.id,
            enableAttachment: true,
            id: node.id,
            min: node.min,
            max: node.max,
            multiple: node.multiple,
            placeholder: '',
            infoBubble: node.description,
            multivalue: true,
            disabled: this.isFormDisabled,
            tableRequired: node.required,
            formAttributes: {
              change: this.handleCustomEvents.bind(this)
            },
            tableConfig: {
              rows: node.rows.split(","),
              columns: this.getTableColumns(node)
            }
          }
        break;
      default:
        break;
    }
    config.myData = true;
    this.formItems[node.name] = config;
    return config;
  }

  getTableColumns(node){
    
    let columnPrototype:any = {};
    switch (node.cellType) {
      case "TEXT":
        columnPrototype = {
          type: 'text',
          width:'100px',
        }
        break;
        case "INTEGER":
          columnPrototype = {
            type: 'integer',
            width:'100px',
            multiple: Math.abs(node.multiple),
            format : this.defaultIntegerFormat,
          }
          break;
          case "DECIMAL":
            columnPrototype = {
              type: 'decimal',
              width:'100px',
              multiple: Math.abs(node.multiple),
              format : this.defaultNumberFormat,
        }
        break;
      case "DROP_DOWN":
        columnPrototype = {
          type: 'dropdown',  
          width:'100px',         
          options:this.getPossibleValues(node.possibleValues, false)
        }
        break;
    }
    let output = [];
    for(let column of node.columns.split(",")){
      let columnConfig = Object.assign({}, columnPrototype)
      columnConfig.label = column;
      columnConfig.name = column;
      output.push(columnConfig)
    }
    return output;
  }

  getLookupConfiguration(lookupTable, params: any = {}): object {
    let url = ''
    const currentUser: any = this.auth.getcurrentUser();
    const currentUserOrgId = currentUser.organization ? (currentUser.organization.id || '') : '';
    const isSPecificOrgUser = this.customService.isSPecificOrgUser();
    switch (lookupTable) {
      ///rest/materials/autosuggest?q=&manufacturerid={OrgSId}&pgNo=0&pgLen=50
      case "MATERIAL":
        url = 'materials/autosuggestmanufacturerid?';

        if (params.id) {
          url += 'manufacturerid=' + params.id
        }else if(isSPecificOrgUser.rolandUser){
          url += 'manufacturerid='
        }

        return {
          url: url,
          displayField: 'productModelName',
          valueField: 'sid',
          lookupFields: ["id", "productModelName"]
        }
      case "MATERIAL_VARIATION":
        url = `materialvariations/${isSPecificOrgUser.rolandUser ? 'autosuggestrolandservice' : 'autosuggestonuseraccess'}`;
        return {
          url: url,
          displayField: 'partNumber',
          valueField: 'sid',
          lookupFields: ["id", "manufacturer", "partNumber"]
        }
      case "ADHESIVE_ACTIVATION_METHOD":
        return {
          url: 'adhesiveactivationmethods/autosuggestservice',
          displayField: "name",
          valueField: 'sid',
          lookupFields: ["id", "name"]
        }
      case "ADHESIVE_FORMULATION":
        return {
          url: 'adhesiveformulations/autosuggestservice',
          displayField: "name",
          valueField: 'sid',
          lookupFields: ["id", "name"]
        }
      case "APPLICATION":
        return {
          url: 'applications/autosuggestservice',
          displayField: "name",
          valueField: 'sid',
          lookupFields: ["id", "name"]
        }
      case "COATED_SIDE":
        return {
          url: 'coatedsides/autosuggestservice',
          displayField: 'side',
          valueField: 'sid',
          lookupFields: ["id", "side"]
        }
      case "COLOR":
        return {
          url: 'colors/autosuggestservice',
          displayField: "name",
          valueField: 'sid',
          lookupFields: ["id", "name"]
        }
      case "CORE_FIXATION":
        return {
          url: 'corefixations/autosuggestservice',
          displayField: "name",
          valueField: 'sid',
          lookupFields: ["id", "name"]
        }
      case "FINISHES":
        return {
          url: 'finishes/autosuggestservice',
          displayField: "name",
          valueField: 'sid',
          lookupFields: ["id", "name"]
        }
      case "LINER_SUBSTANCE":
        return {
          url: 'linersubstances/autosuggestservice',
          displayField: "name",
          valueField: 'sid',
          lookupFields: ["id", "name"]
        }
      case "SURFACE_SHAPE_APPLICATION":
        return {
          url: 'surfaceshapeapplications/autosuggestservice',
          displayField: "name",
          valueField: 'sid',
          lookupFields: ["id", "name"]
        }
      case "TEST_METHOD":
        return {
          url: 'testmethods/autosuggestservice',
          displayField: "name",
          valueField: 'sid',
          lookupFields: ["id", "name"]
        }
      case "INK_TECHNOLOGY":
        return {
          url: 'inktechnologies/autosuggestservice',
          displayField: "name",
          valueField: 'sid',
          lookupFields: ["id", "name"]
        }
      case "ORGANIZATION":
        return {
          url: 'organizations/autosuggestorgtypeforpreview?type=MATERIAL_MANUFACTURER',
          displayField: "displayName",
          valueField: 'sid',
          lookupFields: ["id", "legalName", "displayName", "tradingName", "organizationTypes"]
        }
      case "CBL_APPLICATION_USER":
        
        return {
          url: `appuserprivileges/hpautosuggestservice/${currentUserOrgId}`,
          displayField: "firstName + ' ' + lastName",
          valueField: 'sid',
          lookupFields: ['id','firstName','lastName','email']
        }
      case "LABS":
       
        return {
          url: 'labs/autosuggestservicelabs?orgSid=' + currentUserOrgId,
          displayField: "labName",
          valueField: 'sid',
          lookupFields: ["id", "labName"]
        }
      case "LAB_PRINTERS":
        url = `labs/labprinters?testSuiteSid=${this.testSuiteId || ''}&&labSid=${ params.labId || ''}`
        return {
          url: url,
          displayField: "manufacturer.value.displayName + ' ' + printerModel.value.name + (nickName ? (' - ' + nickName) : '')",
          valueField: 'jid',
          lookupFields: ["jid", "manufacturer", "printerModel", "nickName"]
        }
    }
  }

  getPossibleValues(possibleValues, addChooseOption: boolean) {
    let options = []
    possibleValues = possibleValues || '';
    if(possibleValues){
      possibleValues.split(",").forEach(value => {
        options.push({ value: value, label: value })
      });
    }
    if(addChooseOption){
      options.splice(0,0,{
        value:"",
        label:"Choose option"
      })
    }
    return options;
  }

  setMultiDropdown(node){
    if(node.fieldType == 'MULTILEVEL_DROP_DOWN'){
      this.multiDropdownFields[node.id] = node;
      this.parentDropdowns[node.parentField] = this.parentDropdowns[node.parentField] || [];
      if(!this.parentDropdowns[node.parentField].includes(node.id)){
        this.parentDropdowns[node.parentField].push(node.id);
      }

    }
  }

  Test(){
    this.local['subrate_4e6d84aa_d9e8_4bfe_a2a1_b95d4d0c3589'].options = [
      {label : 'aaa', value : 'aaa'},
      {label : 'bbb', value : 'bbb'}
    ]
    debugger;
  }

}
