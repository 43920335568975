import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/auth/authentication.service';
import { BaseService } from '@app/core/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabsCustomService {

  private readonly _fieldTypes = [
    {
      label : 'TEXT',
      value : 'TEXT'
    },
    {
      label : 'TEXT_MULTILINE',
      value : 'TEXT_MULTILINE'
    },
    {
      label : 'INTEGER',
      value : 'INTEGER'
    },
    {
      label : 'DECIMAL',
      value : 'DECIMAL'
    },
    {
      label : 'DROP_DOWN',
      value : 'DROP_DOWN'
    },
    {
      label : 'MULTILEVEL_DROP_DOWN',
      value : 'MULTILEVEL_DROP_DOWN'
    },
    {
      label : 'RADIO_BUTTON',
      value : 'RADIO_BUTTON'
    },
    {
      label : 'CHECK_BOX',
      value : 'CHECK_BOX'
    },
    {
      label : 'LOOKUP',
      value : 'LOOKUP'
    },
    {
      label : 'DATE',
      value : 'DATE'
    },
    {
      label : 'FILE_UPLOAD',
      value : 'FILE_UPLOAD'
    },
    {
      label : 'TABLE',
      value : 'TABLE'
    }
  ];

  private readonly _cellTypes = [
    {
      label : 'TEXT',
      value : 'TEXT'
    },
    {
      label : 'INTEGER',
      value : 'INTEGER'
    },
    {
      label : 'DECIMAL',
      value : 'DECIMAL'
    },
    {
      label : 'DROP_DOWN',
      value : 'DROP_DOWN'
    }
  ]
  private readonly _printerModels = [    
    {
      label: 'LATEX_R1000',
      value: 'LATEX_R1000'
    },{
      label: 'LATEX_R2000',
      value: 'LATEX_R2000'
    },{
      label: 'LATEX_700',
      value: 'LATEX_700'
    },{
      label: 'LATEX_700_W',
      value: 'LATEX_700_W'
    },{
      label: 'LATEX_800',
      value: 'LATEX_800'
    },{
      label: 'LATEX_800_W',
      value: 'LATEX_800_W'
    },{
      label: 'LATEX_315',
      value: 'LATEX_315'
    },{
      label: 'LATEX_335',
      value: 'LATEX_335'
    },{
      label: 'LATEX_365',
      value: 'LATEX_365'
    },{
      label: 'LATEX_375',
      value: 'LATEX_375'
    },{
      label: 'LATEX_115',
      value: 'LATEX_115'
    },{
      label: 'LATEX_3000',
      value: 'LATEX_3000'
    },{
      label: 'LATEX_360',
      value: 'LATEX_360'
    },{
      label: 'LATEX_370',
      value: 'LATEX_370'
    },{
      label: 'LATEX_310',
      value: 'LATEX_310'
    },{
      label: 'LATEX_330',
      value: 'LATEX_330'
    },{
      label: 'LATEX_3100',
      value: 'LATEX_3100'
    },{
      label: 'LATEX_3500',
      value: 'LATEX_3500'
    }
  ];
  constructor(
    private http: HttpClient,
    private baseService: BaseService,
    private router :Router,
    public auth: AuthenticationService
  ) { }

  get fieldTypes(){
    return this._fieldTypes;
  }
  get cellTypes(){
    return this._cellTypes;
  }
  get printerModels(){
    return this._printerModels;
  }

  public saveFormBuilder(...args): any {
    const id= args[1];
    const serviceOpts:any = {
      url: `testsuites/testsuites/${id}/formbuilder`,
      method: 'POST',
      showloading: true,
      headers : new HttpHeaders().set('content-type', 'multipart/form-data')
    };

    const params= args[0];
  
    if(args[1]){
      //serviceOpts.showloading = false;
      //serviceOpts.liteMessage = true;
    }
    return this.http.post(
      serviceOpts.url,
      params
    );
    const subject = new Observable(observer => {
      this.baseService[ serviceOpts.method.toLowerCase() ](serviceOpts,params).subscribe((response) => {
        observer.next(response);
      },
      (err) => {
        observer.error(err);
      });
    });

    return subject;
  }

  isLabsDisabled(): boolean {
    const currentUser: any = this.auth.getcurrentUser();
    return !currentUser.devadmin && !currentUser.ccSystemAdmin && !currentUser.colorbaseLabsAdmin;
  }

  isLabsChangeLogDisabled(): boolean {
    const currentUser: any = this.auth.getcurrentUser();
    return !currentUser.devadmin && !currentUser.ccSystemAdmin;
  }

  public getTestResultBySid(...args): any {
    const serviceOpts=  {
      url: 'testresults/sid/{sid}',
      method: 'GET',
      showloading: true
    };
    const params= args[0];   
    const subject = new Observable(observer => {
      this.baseService[ serviceOpts.method.toLowerCase() ](serviceOpts,params).subscribe((response) => {
        observer.next(response);
      },
      (err) => {
        observer.error(err);
      });
    });

    return subject;
  }
  public getLatestTestResultByTestSuiteCode(testSuiteCode): any {
    const serviceOpts=  {
      url: `testsuites/latest/${testSuiteCode}`,
      method: 'GET',
      showloading: false
    };
    const params= {};   
    const subject = new Observable(observer => {
      this.baseService[ serviceOpts.method.toLowerCase() ](serviceOpts,params).subscribe((response) => {
        observer.next(response);
        observer.complete();
      },
      (err) => {
        observer.error(err);
      });
    });

    return subject;
  }
  public completeTest(testResultId): any {
    const serviceOpts=  {
      url:   `testresults/${testResultId}/complete`,
      method: 'PUT',
      showloading: true
    };   
    const subject = new Observable(observer => {
      this.baseService[ serviceOpts.method.toLowerCase() ](serviceOpts,{}).subscribe((response) => {
        observer.next(response);
      },
      (err) => {
        observer.error(err);
      });
    });

    return subject;
  }
  public abortTest(testResultId): any {
    const serviceOpts=  {
      url:   `testresults/${testResultId}/abort`,
      method: 'PUT',
      showloading: true
    };   
    const subject = new Observable(observer => {
      this.baseService[ serviceOpts.method.toLowerCase() ](serviceOpts,{}).subscribe((response) => {
        observer.next(response);
      },
      (err) => {
        observer.error(err);
      });
    });

    return subject;
  }
  public approveTest(testResultId): any {
    const serviceOpts=  {
      url:   `testresults/${testResultId}/approve`,
      method: 'PUT',
      showloading: true
    };   
    const subject = new Observable(observer => {
      this.baseService[ serviceOpts.method.toLowerCase() ](serviceOpts,{}).subscribe((response) => {
        observer.next(response);
      },
      (err) => {
        observer.error(err);
      });
    });

    return subject;
  }
  public disApproveTest(testResultId): any {
    const serviceOpts=  {
      url:   `testresults/${testResultId}/disapprove`,
      method: 'PUT',
      showloading: true
    };   
    const subject = new Observable(observer => {
      this.baseService[ serviceOpts.method.toLowerCase() ](serviceOpts,{}).subscribe((response) => {
        observer.next(response);
      },
      (err) => {
        observer.error(err);
      });
    });

    return subject;
  }
}
