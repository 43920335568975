import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UnitConverterService } from './unit-converter.service';

@Injectable({
  providedIn: 'root'
})
export class PriceListUtilsService {

	

  constructor(
	  private translate:TranslateService,
	  private unitConverter:UnitConverterService,
  ) { }
  
  	getCusotmGridData(dTable){
		const data = [];
		dTable.rows().every((index)=>{
			data.push(dTable.row(index).data());
		});
		return data;
	}
  	getCustomGridRowData(dTable, $target){	
		const $row = $target.closest('tr');
		const rowId = $row.attr('id') || $target.attr('row-id');
		const VPLData = this.getCusotmGridData(dTable);
		const data = VPLData.find(item => item.sid == rowId);
		const priceValue = $row.find('.priceValue').val();

		Object.assign(data,{
			setting : $row.find('.setting option:selected').val(),
			costUnit :  $row.find('.cost-unit option:selected').val(),
			price :  priceValue ? Number(priceValue) : null,
		});
		return data;
	}

	attachEventLister($elem,event, callback){
		$elem.unbind(event)
		$elem.on(event, function(val){
			callback(val);
		});
	}

	
	createSelectElem(currentValue, typeClass, isEditable, defaultOption, optionList:any){
		const $selectElem =$('<select />')
			.css('width','150px')
			.addClass('form-control form-control-sm input-elem ')
			.addClass(typeClass);
		if(defaultOption){
			$selectElem.append($('<option>').attr('value' , '').text(''));
		}

		if(!isEditable){
			$selectElem.attr('disabled','disabled');
		}		
		
		optionList.forEach(option => {
			const label = option.label ? this.translate.instant(option.label) : ''
			$selectElem.append(
				$('<option>')
					.attr({
						'value' : option.value,
						'selected' : currentValue == option.value ? 'selected' : false
					})
					.text(label),
			)
		});
		$selectElem.val(currentValue);
		return $selectElem;
	}
	createPricePerUnitElem(data, currency, row){
		currency = currency ? this.translate.instant(currency): '';
	
		const price = (data || data === 0) ? this.unitConverter.precision(data) :  this.calculatePricePerunit(
			row.costUnit, 
			row.price, 
			{
				unit : row.rollLengthUnit,
				value : row.rollLength,
			},
			{
				unit : row.rollWidthUnit,
				value : row.rollWidth
			});
		return $('<div>')
		.append(
			$('<td>')
			.addClass('pricePerUnit vs-ordering')
			.append(
				$('<span>').css('margin-right', '5px').text(currency),
				$('<span>').addClass('pricePerUnitValue').text(price)
			)
		).html();
	}

	createPriceElem(currency,data, row, meta, isDisabled){
		currency = currency ? this.translate.instant(currency): '';
		const dataValue = (data || data === 0) ? data : '';

		const $input = $('<input>')
		.val(dataValue)
		.attr({
			'type':'number',
			'min':0,
			'class':'form-control priceValue input-elem '+meta.row,
			'aria-label':'Username',
			'aria-describedby':"basic-addon1",
			'value' : (dataValue)
		});
		this.setRowId($input, row, meta);

		if(isDisabled){
			$input.attr('readonly','readonly')
		}
		return $('<div>').append(
			$('<div>')
			.css('width','150px')
			.addClass('input-group')
			.append(
				$('<div>')
				.addClass('input-group-prepend')
				.append(
					$('<span>')
					.addClass('input-group-text')
					.attr('id','basic-addon1')
					.html(currency)
				),
				$input
			)
		).html();
	}

	getRowControl($target, accessor){
		const $row = $target.closest('tr');
		return $row.find(accessor);
	}	
	

	setRowId(elem, row, meta){
		elem.attr({
			'row-id': row.sid,
			'row-num': meta.row
		})
	}

	
	calculateAndSetUnitPrice($target, comp, dTable){
		const rowData = this.getCustomGridRowData(dTable, $target);
		const pricePerUnitValue = this.calculatePricePerunit(
			rowData.costUnit, 
			rowData.price, 
			{
				unit : rowData.rollLengthUnit,
				value : rowData.rollLength,
			},
			{
				unit : rowData.rollWidthUnit,
				value : rowData.rollWidth
			});
		Object.assign(rowData, {pricePerUnit : pricePerUnitValue})
		this.getRowControl($target, '.pricePerUnitValue').text(pricePerUnitValue);
	}

	calculatePricePerunit(costUnit, unitPrice, rollLength, rollWidth ){
	
		if(costUnit == 'PER_UNIT'){
			return unitPrice;
		}else{
            let costPersheet:any = null
			const rollLengthUnit = rollLength.unit;
			const rollLengthValue = Number(rollLength.value);
	
			const rollWidthUnit = rollWidth.unit;
			const rollWidthValue =  Number(rollWidth.value);
			// rollWidth
			// rollWidthUnit
            
            if(
                (rollLengthUnit && rollWidthUnit) && (unitPrice || unitPrice === 0)
            ){
                const convertedRollLength = this.unitConverter.convertToM(rollLengthUnit, rollLengthValue);
                const convertedRollWidth = this.unitConverter.convertToM(rollWidthUnit, rollWidthValue);
                const totalArea = convertedRollLength * convertedRollWidth;
                costPersheet = this.unitConverter.precision(totalArea * unitPrice);
            }
	
			return costPersheet;
		}
	}

	getAccountViewType(authUser):any{

		const accountViewConfig = {
			displayFields : 'SELLER',
			edit : true,
		};

		if(authUser.devAdmin || authUser.ccFinance || authUser.ccSupport || authUser.ccSystemAdmin){
			if(authUser.ccFinance && !authUser.ccSupport && !authUser.ccSystemAdmin){
				accountViewConfig.edit = false;
			}
			accountViewConfig.displayFields = 'ALL';
		}else if(authUser.cbxBuyer){
			accountViewConfig.displayFields = 'BUYER';
			accountViewConfig.edit = false;
		}

		return accountViewConfig;
	}

	isNumber(value,){
		if(typeof value === 'string' && value.length){
			value = Number(value);
		}
        if(typeof value == 'number'){
            return true;
        }
        return false
    }

	isValidQuantity(value){
		if(this.isNumber(value) && value <= 0){
			return false;
		}
		return  true;

	}

  
}
