/** This class is to maintain the api service properties in a single place.
 * example service object
 * "serviceName": {
 *      url: string;
 *      method: string;
 *      showSpinner?: boolean;
 *      showSuccessMsg?: boolean;
 *      showErrorMsg?: boolean;
 *      successMsg?: string;
 *      errorMsg?: string;
 *      headers?: any;
 * }
 */
import { BaseApiConstants } from './core/base-api-constants';

 export class ApiConstants extends BaseApiConstants {
    public static readonly login: any = {
        url: 'appuserprivileges/authenticate',
        method: 'GET',
        showloading: true
    };
    public static readonly fslogin: any = {
        url: 'firebase/usertoken',
        method: 'GET',
        showloading: true
    };
        public static readonly getInvoiceBySid: any = {
        url: 'invoices/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteInvoice: any = {
        url: 'invoices/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getInvoiceBy_OrderSid: any = {
        url: 'invoices/ordersid/{ordersid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createInvoice: any = {
        url: 'invoices',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsInvoice: any = {
        url: 'invoices/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getInvoiceBy_InvoiceNumber: any = {
        url: 'invoices/invoicenumber/{invoicenumber}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsInvoice: any = {
        url: 'invoices/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateInvoice: any = {
        url: 'invoices',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteOrganizationDunsInfo: any = {
        url: 'organizationdunsinfos/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateOrganizationDunsInfo: any = {
        url: 'organizationdunsinfos',
        method: 'PUT',
        showloading: true
    };
    public static readonly getOrganizationDunsInfoBySid: any = {
        url: 'organizationdunsinfos/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsOrganizationDunsInfo: any = {
        url: 'organizationdunsinfos/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsOrganizationDunsInfo: any = {
        url: 'organizationdunsinfos/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getOrganizationDunsInfoBy_OrganizationId: any = {
        url: 'organizationdunsinfos/organizationid/{organizationid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createOrganizationDunsInfo: any = {
        url: 'organizationdunsinfos',
        method: 'POST',
        showloading: true
    };
    public static readonly updateBrightness: any = {
        url: 'brightnesses',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsBrightness: any = {
        url: 'brightnesses/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteBrightness: any = {
        url: 'brightnesses/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly createBrightness: any = {
        url: 'brightnesses',
        method: 'POST',
        showloading: true
    };
    public static readonly getBrightnessBySid: any = {
        url: 'brightnesses/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsBrightness: any = {
        url: 'brightnesses/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateMaterials: any = {
        url: 'materials',
        method: 'PUT',
        showloading: true
    };
    public static readonly getMaterialsAll_Materials: any = {
        url: 'materials/materials/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsMaterials: any = {
        url: 'materials/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createMaterials: any = {
        url: 'materials',
        method: 'POST',
        showloading: true
    };
    public static readonly getMaterialsBySid: any = {
        url: 'materials/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteMaterials: any = {
        url: 'materials/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly autoSuggestService_Materials: any = {
        url: 'materials/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsMaterials: any = {
        url: 'materials/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsProductionRuns: any = {
        url: 'productionruns/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createProductionRuns: any = {
        url: 'productionruns',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteProductionRuns: any = {
        url: 'productionruns/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsProductionRuns: any = {
        url: 'productionruns/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateProductionRuns: any = {
        url: 'productionruns',
        method: 'PUT',
        showloading: true
    };
    public static readonly getProductionRunsBySid: any = {
        url: 'productionruns/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_Languages: any = {
        url: 'languages/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly getLanguagesBy_NameInLowerCase: any = {
        url: 'languages/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly createLanguages: any = {
        url: 'languages',
        method: 'POST',
        showloading: true
    };
    public static readonly getLanguagesBy_Name: any = {
        url: 'languages/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsLanguages: any = {
        url: 'languages/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateLanguages: any = {
        url: 'languages',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsLanguages: any = {
        url: 'languages/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getLanguagesBySid: any = {
        url: 'languages/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getLanguagesAll_Languages: any = {
        url: 'languages/languages/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteLanguages: any = {
        url: 'languages/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateSurfaceShapeApplication: any = {
        url: 'surfaceshapeapplications',
        method: 'PUT',
        showloading: true
    };
    public static readonly getSurfaceShapeApplicationBy_Name: any = {
        url: 'surfaceshapeapplications/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly getSurfaceShapeApplicationBySid: any = {
        url: 'surfaceshapeapplications/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createSurfaceShapeApplication: any = {
        url: 'surfaceshapeapplications',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteSurfaceShapeApplication: any = {
        url: 'surfaceshapeapplications/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getSurfaceShapeApplicationBy_NameInLowerCase: any = {
        url: 'surfaceshapeapplications/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_SurfaceShapeApplication: any = {
        url: 'surfaceshapeapplications/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsSurfaceShapeApplication: any = {
        url: 'surfaceshapeapplications/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsSurfaceShapeApplication: any = {
        url: 'surfaceshapeapplications/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getSurfaceShapeApplicationAll_SurfaceShapeApplication: any = {
        url: 'surfaceshapeapplications/surfaceshapeapplications/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateMeasurementDevices: any = {
        url: 'measurementdevices',
        method: 'PUT',
        showloading: true
    };
    public static readonly getMeasurementDevicesAll_MeasurementDevices: any = {
        url: 'measurementdevices/measurementdevices/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly getMeasurementDevicesBySid: any = {
        url: 'measurementdevices/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createMeasurementDevices: any = {
        url: 'measurementdevices',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsMeasurementDevices: any = {
        url: 'measurementdevices/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsMeasurementDevices: any = {
        url: 'measurementdevices/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteMeasurementDevices: any = {
        url: 'measurementdevices/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly createAdhesiveActivationMethod: any = {
        url: 'adhesiveactivationmethods',
        method: 'POST',
        showloading: true
    };
    public static readonly getAdhesiveActivationMethodBy_Name: any = {
        url: 'adhesiveactivationmethods/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsAdhesiveActivationMethod: any = {
        url: 'adhesiveactivationmethods/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getAdhesiveActivationMethodAll_AdhesiveActivationMethod: any = {
        url: 'adhesiveactivationmethods/adhesiveactivationmethods/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteAdhesiveActivationMethod: any = {
        url: 'adhesiveactivationmethods/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getAdhesiveActivationMethodBySid: any = {
        url: 'adhesiveactivationmethods/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsAdhesiveActivationMethod: any = {
        url: 'adhesiveactivationmethods/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateAdhesiveActivationMethod: any = {
        url: 'adhesiveactivationmethods',
        method: 'PUT',
        showloading: true
    };
    public static readonly getAdhesiveActivationMethodBy_NameInLowerCase: any = {
        url: 'adhesiveactivationmethods/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_AdhesiveActivationMethod: any = {
        url: 'adhesiveactivationmethods/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly updateSubstances: any = {
        url: 'substances',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteSubstances: any = {
        url: 'substances/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsSubstances: any = {
        url: 'substances/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getSubstancesAll_Substances: any = {
        url: 'substances/substances/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly autoSuggestService_Substances: any = {
        url: 'substances/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly getSubstancesBySid: any = {
        url: 'substances/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsSubstances: any = {
        url: 'substances/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getSubstancesBy_NameInLowerCase: any = {
        url: 'substances/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly getSubstancesBy_Name: any = {
        url: 'substances/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly createSubstances: any = {
        url: 'substances',
        method: 'POST',
        showloading: true
    };
    public static readonly getFinishesBy_Name: any = {
        url: 'finishes/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly getFinishesAll_Finishes: any = {
        url: 'finishes/finishes/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly autoSuggestService_Finishes: any = {
        url: 'finishes/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFinishes: any = {
        url: 'finishes/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsFinishes: any = {
        url: 'finishes/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsFinishes: any = {
        url: 'finishes/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getFinishesBySid: any = {
        url: 'finishes/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createFinishes: any = {
        url: 'finishes',
        method: 'POST',
        showloading: true
    };
    public static readonly getFinishesBy_NameInLowerCase: any = {
        url: 'finishes/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFinishes: any = {
        url: 'finishes',
        method: 'PUT',
        showloading: true
    };
    public static readonly createGlossiness: any = {
        url: 'glossinesses',
        method: 'POST',
        showloading: true
    };
    public static readonly updateGlossiness: any = {
        url: 'glossinesses',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsGlossiness: any = {
        url: 'glossinesses/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsGlossiness: any = {
        url: 'glossinesses/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteGlossiness: any = {
        url: 'glossinesses/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getGlossinessBySid: any = {
        url: 'glossinesses/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getExactAccountBySid: any = {
        url: 'exactaccounts/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateExactAccount: any = {
        url: 'exactaccounts',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteExactAccount: any = {
        url: 'exactaccounts/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsExactAccount: any = {
        url: 'exactaccounts/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsExactAccount: any = {
        url: 'exactaccounts/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createExactAccount: any = {
        url: 'exactaccounts',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteFieldsEmailDomains: any = {
        url: 'emaildomains/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createEmailDomains: any = {
        url: 'emaildomains',
        method: 'POST',
        showloading: true
    };
    public static readonly updateEmailDomains: any = {
        url: 'emaildomains',
        method: 'PUT',
        showloading: true
    };
    public static readonly getEmailDomainsBySid: any = {
        url: 'emaildomains/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteEmailDomains: any = {
        url: 'emaildomains/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsEmailDomains: any = {
        url: 'emaildomains/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsStockItemByMaterial: any = {
        url: 'stockitembymaterials/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateStockItemByMaterial: any = {
        url: 'stockitembymaterials',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteStockItemByMaterial: any = {
        url: 'stockitembymaterials/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getStockItemByMaterialBySid: any = {
        url: 'stockitembymaterials/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createStockItemByMaterial: any = {
        url: 'stockitembymaterials',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsStockItemByMaterial: any = {
        url: 'stockitembymaterials/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createMaterialVariations: any = {
        url: 'materialvariations',
        method: 'POST',
        showloading: true
    };
    public static readonly autoSuggestService_MaterialVariations: any = {
        url: 'materialvariations/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsMaterialVariations: any = {
        url: 'materialvariations/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getMaterialVariationsAll_MaterialVariations: any = {
        url: 'materialvariations/materialvariations/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateMaterialVariations: any = {
        url: 'materialvariations',
        method: 'PUT',
        showloading: true
    };
    public static readonly getMaterialVariationsBySid: any = {
        url: 'materialvariations/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteMaterialVariations: any = {
        url: 'materialvariations/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsMaterialVariations: any = {
        url: 'materialvariations/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deletePrinterResolution: any = {
        url: 'printerresolutions/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsPrinterResolution: any = {
        url: 'printerresolutions/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createPrinterResolution: any = {
        url: 'printerresolutions',
        method: 'POST',
        showloading: true
    };
    public static readonly getPrinterResolutionBySid: any = {
        url: 'printerresolutions/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsPrinterResolution: any = {
        url: 'printerresolutions/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updatePrinterResolution: any = {
        url: 'printerresolutions',
        method: 'PUT',
        showloading: true
    };
    public static readonly getPrinterResolutionBy_NameInLowerCase: any = {
        url: 'printerresolutions/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly getTestSuitesBySid: any = {
        url: 'testsuites/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_TestSuites: any = {
        url: 'testsuites/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsTestSuites: any = {
        url: 'testsuites/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteTestSuites: any = {
        url: 'testsuites/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateTestSuites: any = {
        url: 'testsuites',
        method: 'PUT',
        showloading: true
    };
    public static readonly getTestSuitesAll_TestSuites: any = {
        url: 'testsuites/testsuites/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly createTestSuites: any = {
        url: 'testsuites',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsTestSuites: any = {
        url: 'testsuites/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteStatus: any = {
        url: 'statuses/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateStatus: any = {
        url: 'statuses',
        method: 'PUT',
        showloading: true
    };
    public static readonly createStatus: any = {
        url: 'statuses',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteFieldsStatus: any = {
        url: 'statuses/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsStatus: any = {
        url: 'statuses/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getStatusBySid: any = {
        url: 'statuses/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteTensileStrength: any = {
        url: 'tensilestrengths/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsTensileStrength: any = {
        url: 'tensilestrengths/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateTensileStrength: any = {
        url: 'tensilestrengths',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsTensileStrength: any = {
        url: 'tensilestrengths/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getTensileStrengthBySid: any = {
        url: 'tensilestrengths/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createTensileStrength: any = {
        url: 'tensilestrengths',
        method: 'POST',
        showloading: true
    };
    public static readonly updateCbxGeneralSettingsLocation: any = {
        url: 'cbxgeneralsettingslocations',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteCbxGeneralSettingsLocation: any = {
        url: 'cbxgeneralsettingslocations/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsCbxGeneralSettingsLocation: any = {
        url: 'cbxgeneralsettingslocations/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getCbxGeneralSettingsLocationBySid: any = {
        url: 'cbxgeneralsettingslocations/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createCbxGeneralSettingsLocation: any = {
        url: 'cbxgeneralsettingslocations',
        method: 'POST',
        showloading: true
    };
    public static readonly getCbxGeneralSettingsLocationAll_CBXGeneralSettingsLocation: any = {
        url: 'cbxgeneralsettingslocations/cbxgeneralsettingslocations/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsCbxGeneralSettingsLocation: any = {
        url: 'cbxgeneralsettingslocations/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteTestResults: any = {
        url: 'testresults/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsTestResults: any = {
        url: 'testresults/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsTestResults: any = {
        url: 'testresults/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getTestResultsBySid: any = {
        url: 'testresults/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createTestResults: any = {
        url: 'testresults',
        method: 'POST',
        showloading: true
    };
    public static readonly updateTestResults: any = {
        url: 'testresults',
        method: 'PUT',
        showloading: true
    };
    public static readonly getTestResultsAll_TestResults: any = {
        url: 'testresults/testresults/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly createRegions: any = {
        url: 'regions',
        method: 'POST',
        showloading: true
    };
    public static readonly getRegionsBySid: any = {
        url: 'regions/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsRegions: any = {
        url: 'regions/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getRegionsBy_NameInLowerCase: any = {
        url: 'regions/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly getRegionsAll_Regions: any = {
        url: 'regions/regions/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateRegions: any = {
        url: 'regions',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsRegions: any = {
        url: 'regions/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getRegionsBy_Name: any = {
        url: 'regions/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteRegions: any = {
        url: 'regions/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly autoSuggestService_Regions: any = {
        url: 'regions/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsThickness: any = {
        url: 'thicknesses/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createThickness: any = {
        url: 'thicknesses',
        method: 'POST',
        showloading: true
    };
    public static readonly updateThickness: any = {
        url: 'thicknesses',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteThickness: any = {
        url: 'thicknesses/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getThicknessBySid: any = {
        url: 'thicknesses/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsThickness: any = {
        url: 'thicknesses/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateWeavePatterns: any = {
        url: 'weavepatterns',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsWeavePatterns: any = {
        url: 'weavepatterns/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getWeavePatternsBy_NameInLowerCase: any = {
        url: 'weavepatterns/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsWeavePatterns: any = {
        url: 'weavepatterns/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getWeavePatternsBySid: any = {
        url: 'weavepatterns/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createWeavePatterns: any = {
        url: 'weavepatterns',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteWeavePatterns: any = {
        url: 'weavepatterns/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsShortDescriptions: any = {
        url: 'shortdescriptions/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateShortDescriptions: any = {
        url: 'shortdescriptions',
        method: 'PUT',
        showloading: true
    };
    public static readonly getShortDescriptionsBySid: any = {
        url: 'shortdescriptions/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsShortDescriptions: any = {
        url: 'shortdescriptions/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createShortDescriptions: any = {
        url: 'shortdescriptions',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteShortDescriptions: any = {
        url: 'shortdescriptions/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateWhiteness: any = {
        url: 'whitenesses',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsWhiteness: any = {
        url: 'whitenesses/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsWhiteness: any = {
        url: 'whitenesses/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getWhitenessBySid: any = {
        url: 'whitenesses/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteWhiteness: any = {
        url: 'whitenesses/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly createWhiteness: any = {
        url: 'whitenesses',
        method: 'POST',
        showloading: true
    };
    public static readonly updateOrganization: any = {
        url: 'organizations',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsOrganization: any = {
        url: 'organizations/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteOrganization: any = {
        url: 'organizations/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getOrganizationBySid: any = {
        url: 'organizations/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_Organization: any = {
        url: 'organizations/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly getOrganizationBy_NameInLowerCase: any = {
        url: 'organizations/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly getOrganizationBy_LegalName: any = {
        url: 'organizations/legalname/{legalname}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsOrganization: any = {
        url: 'organizations/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getOrganizationAll_Organization: any = {
        url: 'organizations/organizations/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly createOrganization: any = {
        url: 'organizations',
        method: 'POST',
        showloading: true
    };
    public static readonly getPriceListAll_PriceLists: any = {
        url: 'pricelists/pricelists/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsPriceList: any = {
        url: 'pricelists/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getPriceListBySid: any = {
        url: 'pricelists/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsPriceList: any = {
        url: 'pricelists/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createPriceList: any = {
        url: 'pricelists',
        method: 'POST',
        showloading: true
    };
    public static readonly deletePriceList: any = {
        url: 'pricelists/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updatePriceList: any = {
        url: 'pricelists',
        method: 'PUT',
        showloading: true
    };
    public static readonly autoSuggestService_PriceList: any = {
        url: 'pricelists/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly getPriceListAll_RelationshipPricingList: any = {
        url: 'pricelists/relationshippricinglists/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly getStretchRatioBySid: any = {
        url: 'stretchratios/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteStretchRatio: any = {
        url: 'stretchratios/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsStretchRatio: any = {
        url: 'stretchratios/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsStretchRatio: any = {
        url: 'stretchratios/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createStretchRatio: any = {
        url: 'stretchratios',
        method: 'POST',
        showloading: true
    };
    public static readonly updateStretchRatio: any = {
        url: 'stretchratios',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsLinerWeight: any = {
        url: 'linerweights/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteLinerWeight: any = {
        url: 'linerweights/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsLinerWeight: any = {
        url: 'linerweights/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateLinerWeight: any = {
        url: 'linerweights',
        method: 'PUT',
        showloading: true
    };
    public static readonly getLinerWeightBySid: any = {
        url: 'linerweights/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createLinerWeight: any = {
        url: 'linerweights',
        method: 'POST',
        showloading: true
    };
    public static readonly getHeadSpeedBySid: any = {
        url: 'headspeeds/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getHeadSpeedBy_NameInLowerCase: any = {
        url: 'headspeeds/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteHeadSpeed: any = {
        url: 'headspeeds/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateHeadSpeed: any = {
        url: 'headspeeds',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsHeadSpeed: any = {
        url: 'headspeeds/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createHeadSpeed: any = {
        url: 'headspeeds',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsHeadSpeed: any = {
        url: 'headspeeds/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createOpacity: any = {
        url: 'opacities',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsOpacity: any = {
        url: 'opacities/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteOpacity: any = {
        url: 'opacities/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getOpacityBySid: any = {
        url: 'opacities/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsOpacity: any = {
        url: 'opacities/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateOpacity: any = {
        url: 'opacities',
        method: 'PUT',
        showloading: true
    };
    public static readonly getRegistrationTokenBySid: any = {
        url: 'registrationtokens/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateRegistrationToken: any = {
        url: 'registrationtokens',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsRegistrationToken: any = {
        url: 'registrationtokens/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createRegistrationToken: any = {
        url: 'registrationtokens',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteRegistrationToken: any = {
        url: 'registrationtokens/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsRegistrationToken: any = {
        url: 'registrationtokens/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsCart: any = {
        url: 'carts/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createCart: any = {
        url: 'carts',
        method: 'POST',
        showloading: true
    };
    public static readonly getCartBySid: any = {
        url: 'carts/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsCart: any = {
        url: 'carts/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateCart: any = {
        url: 'carts',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteCart: any = {
        url: 'carts/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteInkSetup: any = {
        url: 'inksetups/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getInkSetupBySid: any = {
        url: 'inksetups/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsInkSetup: any = {
        url: 'inksetups/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsInkSetup: any = {
        url: 'inksetups/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createInkSetup: any = {
        url: 'inksetups',
        method: 'POST',
        showloading: true
    };
    public static readonly updateInkSetup: any = {
        url: 'inksetups',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteCoreFixation: any = {
        url: 'corefixations/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getCoreFixationBySid: any = {
        url: 'corefixations/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateCoreFixation: any = {
        url: 'corefixations',
        method: 'PUT',
        showloading: true
    };
    public static readonly createCoreFixation: any = {
        url: 'corefixations',
        method: 'POST',
        showloading: true
    };
    public static readonly getCoreFixationBy_Name: any = {
        url: 'corefixations/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsCoreFixation: any = {
        url: 'corefixations/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getCoreFixationBy_NameInLowerCase: any = {
        url: 'corefixations/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly getCoreFixationAll_CoreFixation: any = {
        url: 'corefixations/corefixations/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteFieldsCoreFixation: any = {
        url: 'corefixations/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly autoSuggestService_CoreFixation: any = {
        url: 'corefixations/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteMasterDataModified: any = {
        url: 'masterdatamodifieds/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsMasterDataModified: any = {
        url: 'masterdatamodifieds/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateMasterDataModified: any = {
        url: 'masterdatamodifieds',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsMasterDataModified: any = {
        url: 'masterdatamodifieds/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createMasterDataModified: any = {
        url: 'masterdatamodifieds',
        method: 'POST',
        showloading: true
    };
    public static readonly getMasterDataModifiedBySid: any = {
        url: 'masterdatamodifieds/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsTestResultsFormNodes: any = {
        url: 'testresultsformnodes/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsTestResultsFormNodes: any = {
        url: 'testresultsformnodes/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteTestResultsFormNodes: any = {
        url: 'testresultsformnodes/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly createTestResultsFormNodes: any = {
        url: 'testresultsformnodes',
        method: 'POST',
        showloading: true
    };
    public static readonly updateTestResultsFormNodes: any = {
        url: 'testresultsformnodes',
        method: 'PUT',
        showloading: true
    };
    public static readonly getTestResultsFormNodesBySid: any = {
        url: 'testresultsformnodes/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createStockItemVariation: any = {
        url: 'stockitemvariations',
        method: 'POST',
        showloading: true
    };
    public static readonly updateStockItemVariation: any = {
        url: 'stockitemvariations',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsStockItemVariation: any = {
        url: 'stockitemvariations/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getStockItemVariationBySid: any = {
        url: 'stockitemvariations/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteStockItemVariation: any = {
        url: 'stockitemvariations/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsStockItemVariation: any = {
        url: 'stockitemvariations/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createCBLGeneralSetting: any = {
        url: 'cblgeneralsettings',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsCBLGeneralSetting: any = {
        url: 'cblgeneralsettings/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getCBLGeneralSettingBySid: any = {
        url: 'cblgeneralsettings/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteCBLGeneralSetting: any = {
        url: 'cblgeneralsettings/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateCBLGeneralSetting: any = {
        url: 'cblgeneralsettings',
        method: 'PUT',
        showloading: true
    };
    public static readonly getCBLGeneralSettingAll_CBLGeneralSetting: any = {
        url: 'cblgeneralsettings/cblgeneralsettings/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteFieldsCBLGeneralSetting: any = {
        url: 'cblgeneralsettings/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsLabPrinters: any = {
        url: 'labprinters/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteLabPrinters: any = {
        url: 'labprinters/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateLabPrinters: any = {
        url: 'labprinters',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsLabPrinters: any = {
        url: 'labprinters/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getLabPrintersBySid: any = {
        url: 'labprinters/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createLabPrinters: any = {
        url: 'labprinters',
        method: 'POST',
        showloading: true
    };
    public static readonly getActivationTokenBySid: any = {
        url: 'activationtokens/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteActivationToken: any = {
        url: 'activationtokens/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateActivationToken: any = {
        url: 'activationtokens',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsActivationToken: any = {
        url: 'activationtokens/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createActivationToken: any = {
        url: 'activationtokens',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsActivationToken: any = {
        url: 'activationtokens/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsMarketingPhrases: any = {
        url: 'marketingphrases/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getMarketingPhrasesBySid: any = {
        url: 'marketingphrases/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteMarketingPhrases: any = {
        url: 'marketingphrases/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly createMarketingPhrases: any = {
        url: 'marketingphrases',
        method: 'POST',
        showloading: true
    };
    public static readonly updateMarketingPhrases: any = {
        url: 'marketingphrases',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsMarketingPhrases: any = {
        url: 'marketingphrases/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly autoSuggestService_Labs: any = {
        url: 'labs/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly getLabsBySid: any = {
        url: 'labs/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateLabs: any = {
        url: 'labs',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteLabs: any = {
        url: 'labs/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsLabs: any = {
        url: 'labs/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createLabs: any = {
        url: 'labs',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsLabs: any = {
        url: 'labs/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getLabsAll_Labs: any = {
        url: 'labs/labs/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteParticipantBuyersWithRelationships: any = {
        url: 'participantbuyerswithrelationships/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsParticipantBuyersWithRelationships: any = {
        url: 'participantbuyerswithrelationships/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsParticipantBuyersWithRelationships: any = {
        url: 'participantbuyerswithrelationships/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createParticipantBuyersWithRelationships: any = {
        url: 'participantbuyerswithrelationships',
        method: 'POST',
        showloading: true
    };
    public static readonly updateParticipantBuyersWithRelationships: any = {
        url: 'participantbuyerswithrelationships',
        method: 'PUT',
        showloading: true
    };
    public static readonly getParticipantBuyersWithRelationshipsBySid: any = {
        url: 'participantbuyerswithrelationships/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getCountriesBy_NameInLowerCase: any = {
        url: 'countries/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateCountries: any = {
        url: 'countries',
        method: 'PUT',
        showloading: true
    };
    public static readonly getCountriesBySid: any = {
        url: 'countries/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createCountries: any = {
        url: 'countries',
        method: 'POST',
        showloading: true
    };
    public static readonly getCountriesBy_Name: any = {
        url: 'countries/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteCountries: any = {
        url: 'countries/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getCountriesAll_Countries: any = {
        url: 'countries/countries/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly autoSuggestService_Countries: any = {
        url: 'countries/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsCountries: any = {
        url: 'countries/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsCountries: any = {
        url: 'countries/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createMaterialRequests: any = {
        url: 'materialrequests',
        method: 'POST',
        showloading: true
    };
    public static readonly getMaterialRequestsBySid: any = {
        url: 'materialrequests/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getMaterialRequestsAll_MaterialRequestsBuyer: any = {
        url: 'materialrequests/materialrequestsbuyers/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsMaterialRequests: any = {
        url: 'materialrequests/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteMaterialRequests: any = {
        url: 'materialrequests/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getMaterialRequestsAll_MaterialRequests: any = {
        url: 'materialrequests/materialrequests/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateMaterialRequests: any = {
        url: 'materialrequests',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsMaterialRequests: any = {
        url: 'materialrequests/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createLinerSubstances: any = {
        url: 'linersubstances',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteLinerSubstances: any = {
        url: 'linersubstances/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateLinerSubstances: any = {
        url: 'linersubstances',
        method: 'PUT',
        showloading: true
    };
    public static readonly autoSuggestService_LinerSubstances: any = {
        url: 'linersubstances/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsLinerSubstances: any = {
        url: 'linersubstances/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsLinerSubstances: any = {
        url: 'linersubstances/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getLinerSubstancesBy_Name: any = {
        url: 'linersubstances/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly getLinerSubstancesBy_NameInLowerCase: any = {
        url: 'linersubstances/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly getLinerSubstancesBySid: any = {
        url: 'linersubstances/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getLinerSubstancesAll_LinerSubstances: any = {
        url: 'linersubstances/linersubstances/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateCartItem: any = {
        url: 'cartitems',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsCartItem: any = {
        url: 'cartitems/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createCartItem: any = {
        url: 'cartitems',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsCartItem: any = {
        url: 'cartitems/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getCartItemBySid: any = {
        url: 'cartitems/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getCartItemBy_CartSid: any = {
        url: 'cartitems/cartsid/{cartsid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteCartItem: any = {
        url: 'cartitems/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateAppUserPrivileges: any = {
        url: 'appuserprivileges',
        method: 'PUT',
        showloading: true
    };
    public static readonly getCurrentUser: any = {
        url: 'appuserprivileges/authenticate',
        method: 'GET',
        showloading: true
    };
    public static readonly getAppUserPrivilegesBySid: any = {
        url: 'appuserprivileges/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getAppUserPrivilegesByEmail: any = {
        url: 'appuserprivileges/email/{email}',
        method: 'GET',
        showloading: true
    };
    public static readonly getAppUserPrivilegesAll_AppUserPrivileges: any = {
        url: 'appuserprivileges/appuserprivileges/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteAppUserPrivileges: any = {
        url: 'appuserprivileges/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateActualUser: any = {
        url: 'appuserprivileges/updateactualuser/{actualuser}',
        method: 'GET',
        showloading: true
    };
    public static readonly createAppUserPrivileges: any = {
        url: 'appuserprivileges',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsExchangeInvitation: any = {
        url: 'exchangeinvitations/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsExchangeInvitation: any = {
        url: 'exchangeinvitations/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createExchangeInvitation: any = {
        url: 'exchangeinvitations',
        method: 'POST',
        showloading: true
    };
    public static readonly updateExchangeInvitation: any = {
        url: 'exchangeinvitations',
        method: 'PUT',
        showloading: true
    };
    public static readonly getExchangeInvitationBySid: any = {
        url: 'exchangeinvitations/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteExchangeInvitation: any = {
        url: 'exchangeinvitations/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsTestMethod: any = {
        url: 'testmethods/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createTestMethod: any = {
        url: 'testmethods',
        method: 'POST',
        showloading: true
    };
    public static readonly updateTestMethod: any = {
        url: 'testmethods',
        method: 'PUT',
        showloading: true
    };
    public static readonly getTestMethodBySid: any = {
        url: 'testmethods/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_TestMethod: any = {
        url: 'testmethods/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly getTestMethodBy_Name_Type: any = {
        url: 'testmethods/nametype/{name}/{type}',
        method: 'GET',
        showloading: true
    };
    public static readonly getTestMethodAll_TestMethod: any = {
        url: 'testmethods/testmethods/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteTestMethod: any = {
        url: 'testmethods/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getTestMethodBy_Name: any = {
        url: 'testmethods/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsTestMethod: any = {
        url: 'testmethods/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createManufacturerInfo: any = {
        url: 'manufacturerinfos',
        method: 'POST',
        showloading: true
    };
    public static readonly updateManufacturerInfo: any = {
        url: 'manufacturerinfos',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsManufacturerInfo: any = {
        url: 'manufacturerinfos/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsManufacturerInfo: any = {
        url: 'manufacturerinfos/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteManufacturerInfo: any = {
        url: 'manufacturerinfos/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getManufacturerInfoBySid: any = {
        url: 'manufacturerinfos/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsVacuum: any = {
        url: 'vacuums/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getVacuumBySid: any = {
        url: 'vacuums/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createVacuum: any = {
        url: 'vacuums',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsVacuum: any = {
        url: 'vacuums/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteVacuum: any = {
        url: 'vacuums/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateVacuum: any = {
        url: 'vacuums',
        method: 'PUT',
        showloading: true
    };
    public static readonly getVacuumBy_NameInLowerCase: any = {
        url: 'vacuums/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly createInkSeries: any = {
        url: 'inkseries',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteInkSeries: any = {
        url: 'inkseries/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateInkSeries: any = {
        url: 'inkseries',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsInkSeries: any = {
        url: 'inkseries/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getInkSeriesBySid: any = {
        url: 'inkseries/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsInkSeries: any = {
        url: 'inkseries/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getInkSeriesBy_NameInLowerCase: any = {
        url: 'inkseries/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly getNamedColorBy_NameInLowerCase: any = {
        url: 'namedcolors/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteNamedColor: any = {
        url: 'namedcolors/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateNamedColor: any = {
        url: 'namedcolors',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsNamedColor: any = {
        url: 'namedcolors/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getNamedColorBySid: any = {
        url: 'namedcolors/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getNamedColorAll_NamedColor: any = {
        url: 'namedcolors/namedcolors/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsNamedColor: any = {
        url: 'namedcolors/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createNamedColor: any = {
        url: 'namedcolors',
        method: 'POST',
        showloading: true
    };
    public static readonly autoSuggestService_NamedColor: any = {
        url: 'namedcolors/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly getNamedColorBy_Name: any = {
        url: 'namedcolors/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsRegistration: any = {
        url: 'registrations/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getRegistrationBySid: any = {
        url: 'registrations/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateRegistration: any = {
        url: 'registrations',
        method: 'PUT',
        showloading: true
    };
    public static readonly createRegistration: any = {
        url: 'registrations',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteRegistration: any = {
        url: 'registrations/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsRegistration: any = {
        url: 'registrations/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getInkColorBy_NameInLowerCase: any = {
        url: 'inkcolors/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateInkColor: any = {
        url: 'inkcolors',
        method: 'PUT',
        showloading: true
    };
    public static readonly getInkColorBySid: any = {
        url: 'inkcolors/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createInkColor: any = {
        url: 'inkcolors',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsInkColor: any = {
        url: 'inkcolors/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsInkColor: any = {
        url: 'inkcolors/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteInkColor: any = {
        url: 'inkcolors/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getInkDropModeBy_NameInLowerCase: any = {
        url: 'inkdropmodes/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteInkDropMode: any = {
        url: 'inkdropmodes/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsInkDropMode: any = {
        url: 'inkdropmodes/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getInkDropModeBySid: any = {
        url: 'inkdropmodes/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createInkDropMode: any = {
        url: 'inkdropmodes',
        method: 'POST',
        showloading: true
    };
    public static readonly updateInkDropMode: any = {
        url: 'inkdropmodes',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsInkDropMode: any = {
        url: 'inkdropmodes/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsShipments: any = {
        url: 'shipments/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsShipments: any = {
        url: 'shipments/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteShipments: any = {
        url: 'shipments/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getShipmentsBySid: any = {
        url: 'shipments/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createShipments: any = {
        url: 'shipments',
        method: 'POST',
        showloading: true
    };
    public static readonly updateShipments: any = {
        url: 'shipments',
        method: 'PUT',
        showloading: true
    };
    public static readonly createOrder: any = {
        url: 'orders',
        method: 'POST',
        showloading: true
    };
    public static readonly getOrderBySid: any = {
        url: 'orders/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getOrderAll_Order: any = {
        url: 'orders/orders/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsOrder: any = {
        url: 'orders/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsOrder: any = {
        url: 'orders/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateOrder: any = {
        url: 'orders',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteOrder: any = {
        url: 'orders/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly createRecommendedOverlaminates: any = {
        url: 'recommendedoverlaminates',
        method: 'POST',
        showloading: true
    };
    public static readonly updateRecommendedOverlaminates: any = {
        url: 'recommendedoverlaminates',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsRecommendedOverlaminates: any = {
        url: 'recommendedoverlaminates/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getRecommendedOverlaminatesBySid: any = {
        url: 'recommendedoverlaminates/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsRecommendedOverlaminates: any = {
        url: 'recommendedoverlaminates/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteRecommendedOverlaminates: any = {
        url: 'recommendedoverlaminates/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly createMaterialFamilies: any = {
        url: 'materialfamilies',
        method: 'POST',
        showloading: true
    };
    public static readonly getMaterialFamiliesBySid: any = {
        url: 'materialfamilies/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getMaterialFamiliesBy_Name: any = {
        url: 'materialfamilies/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly getMaterialFamiliesBy_NameInLowerCase: any = {
        url: 'materialfamilies/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteMaterialFamilies: any = {
        url: 'materialfamilies/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsMaterialFamilies: any = {
        url: 'materialfamilies/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateMaterialFamilies: any = {
        url: 'materialfamilies',
        method: 'PUT',
        showloading: true
    };
    public static readonly getMaterialFamiliesAll_MaterialFamilies: any = {
        url: 'materialfamilies/materialfamilies/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteFieldsMaterialFamilies: any = {
        url: 'materialfamilies/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsRollTotalDiameter: any = {
        url: 'rolltotaldiameters/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateRollTotalDiameter: any = {
        url: 'rolltotaldiameters',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsRollTotalDiameter: any = {
        url: 'rolltotaldiameters/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteRollTotalDiameter: any = {
        url: 'rolltotaldiameters/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getRollTotalDiameterBySid: any = {
        url: 'rolltotaldiameters/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createRollTotalDiameter: any = {
        url: 'rolltotaldiameters',
        method: 'POST',
        showloading: true
    };
    public static readonly createMediaPreset: any = {
        url: 'mediapresets',
        method: 'POST',
        showloading: true
    };
    public static readonly updateMediaPreset: any = {
        url: 'mediapresets',
        method: 'PUT',
        showloading: true
    };
    public static readonly getMediaPresetBySid: any = {
        url: 'mediapresets/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsMediaPreset: any = {
        url: 'mediapresets/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteMediaPreset: any = {
        url: 'mediapresets/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsMediaPreset: any = {
        url: 'mediapresets/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getMediaPresetBy_NameInLowerCase: any = {
        url: 'mediapresets/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_VariationPriceList: any = {
        url: 'variationpricelists/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly getVariationPriceListBySid: any = {
        url: 'variationpricelists/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getVariationPriceListAll_VariationPriceList: any = {
        url: 'variationpricelists/variationpricelists/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteVariationPriceList: any = {
        url: 'variationpricelists/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsVariationPriceList: any = {
        url: 'variationpricelists/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsVariationPriceList: any = {
        url: 'variationpricelists/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createVariationPriceList: any = {
        url: 'variationpricelists',
        method: 'POST',
        showloading: true
    };
    public static readonly updateVariationPriceList: any = {
        url: 'variationpricelists',
        method: 'PUT',
        showloading: true
    };
    public static readonly createColor: any = {
        url: 'colors',
        method: 'POST',
        showloading: true
    };
    public static readonly getColorBy_NameInLowerCase: any = {
        url: 'colors/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly getColorBySid: any = {
        url: 'colors/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getColorBy_Name: any = {
        url: 'colors/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly getColorAll_Color: any = {
        url: 'colors/colors/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteFieldsColor: any = {
        url: 'colors/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteColor: any = {
        url: 'colors/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsColor: any = {
        url: 'colors/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly autoSuggestService_Color: any = {
        url: 'colors/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly updateColor: any = {
        url: 'colors',
        method: 'PUT',
        showloading: true
    };
    public static readonly getPrinterStatusBySid: any = {
        url: 'printerstatuses/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsPrinterStatus: any = {
        url: 'printerstatuses/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsPrinterStatus: any = {
        url: 'printerstatuses/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getPrinterStatusBy_NameInLowerCase: any = {
        url: 'printerstatuses/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly deletePrinterStatus: any = {
        url: 'printerstatuses/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updatePrinterStatus: any = {
        url: 'printerstatuses',
        method: 'PUT',
        showloading: true
    };
    public static readonly createPrinterStatus: any = {
        url: 'printerstatuses',
        method: 'POST',
        showloading: true
    };
    public static readonly getMeasurementDirectionBy_Name: any = {
        url: 'measurementdirections/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsMeasurementDirection: any = {
        url: 'measurementdirections/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsMeasurementDirection: any = {
        url: 'measurementdirections/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getMeasurementDirectionBy_NameInLowerCase: any = {
        url: 'measurementdirections/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteMeasurementDirection: any = {
        url: 'measurementdirections/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateMeasurementDirection: any = {
        url: 'measurementdirections',
        method: 'PUT',
        showloading: true
    };
    public static readonly getMeasurementDirectionBySid: any = {
        url: 'measurementdirections/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_MeasurementDirection: any = {
        url: 'measurementdirections/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly getMeasurementDirectionAll_MeasurementDirection: any = {
        url: 'measurementdirections/measurementdirections/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly createMeasurementDirection: any = {
        url: 'measurementdirections',
        method: 'POST',
        showloading: true
    };
    public static readonly createSizes: any = {
        url: 'sizes',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteSizes: any = {
        url: 'sizes/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getSizesBySid: any = {
        url: 'sizes/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsSizes: any = {
        url: 'sizes/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateSizes: any = {
        url: 'sizes',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsSizes: any = {
        url: 'sizes/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsCoatedSide: any = {
        url: 'coatedsides/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteCoatedSide: any = {
        url: 'coatedsides/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsCoatedSide: any = {
        url: 'coatedsides/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getCoatedSideBy_SideInLowerCase: any = {
        url: 'coatedsides/sideinlowercase/{sideinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_CoatedSide: any = {
        url: 'coatedsides/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly createCoatedSide: any = {
        url: 'coatedsides',
        method: 'POST',
        showloading: true
    };
    public static readonly updateCoatedSide: any = {
        url: 'coatedsides',
        method: 'PUT',
        showloading: true
    };
    public static readonly getCoatedSideBy_Side: any = {
        url: 'coatedsides/side/{side}',
        method: 'GET',
        showloading: true
    };
    public static readonly getCoatedSideBySid: any = {
        url: 'coatedsides/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getCoatedSideAll_CoatedSide: any = {
        url: 'coatedsides/coatedsides/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly getChangelog: any = {
        url: 'changelogs/datatable/{entityName}/{entityId}/{fieldName}/{fromModifiedDate}',
        method: 'POST',
        showloading: true
    };
    public static readonly createPrinterModel: any = {
        url: 'printermodels',
        method: 'POST',
        showloading: true
    };
    public static readonly getPrinterModelBySid: any = {
        url: 'printermodels/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsPrinterModel: any = {
        url: 'printermodels/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updatePrinterModel: any = {
        url: 'printermodels',
        method: 'PUT',
        showloading: true
    };
    public static readonly deletePrinterModel: any = {
        url: 'printermodels/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsPrinterModel: any = {
        url: 'printermodels/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteOrderItem: any = {
        url: 'orderitems/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateOrderItem: any = {
        url: 'orderitems',
        method: 'PUT',
        showloading: true
    };
    public static readonly getOrderItemBySid: any = {
        url: 'orderitems/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createOrderItem: any = {
        url: 'orderitems',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsOrderItem: any = {
        url: 'orderitems/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getOrderItemBy_OrderSId: any = {
        url: 'orderitems/ordersid/{ordersid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsOrderItem: any = {
        url: 'orderitems/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createCountrySubdivisions: any = {
        url: 'countrysubdivisions',
        method: 'POST',
        showloading: true
    };
    public static readonly getCountrySubdivisionsAll_CountrySubdivisions: any = {
        url: 'countrysubdivisions/countrysubdivisions/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateCountrySubdivisions: any = {
        url: 'countrysubdivisions',
        method: 'PUT',
        showloading: true
    };
    public static readonly autoSuggestService_CountrySubdivisions: any = {
        url: 'countrysubdivisions/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly getCountrySubdivisionsBySid: any = {
        url: 'countrysubdivisions/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsCountrySubdivisions: any = {
        url: 'countrysubdivisions/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsCountrySubdivisions: any = {
        url: 'countrysubdivisions/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteCountrySubdivisions: any = {
        url: 'countrysubdivisions/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteRecommendedFinishings: any = {
        url: 'recommendedfinishings/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateRecommendedFinishings: any = {
        url: 'recommendedfinishings',
        method: 'PUT',
        showloading: true
    };
    public static readonly getRecommendedFinishingsBySid: any = {
        url: 'recommendedfinishings/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createRecommendedFinishings: any = {
        url: 'recommendedfinishings',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsRecommendedFinishings: any = {
        url: 'recommendedfinishings/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsRecommendedFinishings: any = {
        url: 'recommendedfinishings/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteLongDescriptions: any = {
        url: 'longdescriptions/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsLongDescriptions: any = {
        url: 'longdescriptions/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getLongDescriptionsBySid: any = {
        url: 'longdescriptions/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsLongDescriptions: any = {
        url: 'longdescriptions/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateLongDescriptions: any = {
        url: 'longdescriptions',
        method: 'PUT',
        showloading: true
    };
    public static readonly createLongDescriptions: any = {
        url: 'longdescriptions',
        method: 'POST',
        showloading: true
    };
    public static readonly getUserBySid: any = {
        url: 'users/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_User: any = {
        url: 'users/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsUser: any = {
        url: 'users/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getUserAll_Users: any = {
        url: 'users/users/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteUser: any = {
        url: 'users/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsUser: any = {
        url: 'users/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateUser: any = {
        url: 'users',
        method: 'PUT',
        showloading: true
    };
    public static readonly createUser: any = {
        url: 'users',
        method: 'POST',
        showloading: true
    };
    public static readonly updateGrammageOrWeight: any = {
        url: 'grammageorweights',
        method: 'PUT',
        showloading: true
    };
    public static readonly createGrammageOrWeight: any = {
        url: 'grammageorweights',
        method: 'POST',
        showloading: true
    };
    public static readonly getGrammageOrWeightBySid: any = {
        url: 'grammageorweights/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteGrammageOrWeight: any = {
        url: 'grammageorweights/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsGrammageOrWeight: any = {
        url: 'grammageorweights/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsGrammageOrWeight: any = {
        url: 'grammageorweights/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteExchangeRate: any = {
        url: 'exchangerates/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateExchangeRate: any = {
        url: 'exchangerates',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsExchangeRate: any = {
        url: 'exchangerates/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getExchangeRateBySid: any = {
        url: 'exchangerates/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsExchangeRate: any = {
        url: 'exchangerates/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createExchangeRate: any = {
        url: 'exchangerates',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsSheetSizes: any = {
        url: 'sheetsizes/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createSheetSizes: any = {
        url: 'sheetsizes',
        method: 'POST',
        showloading: true
    };
    public static readonly updateSheetSizes: any = {
        url: 'sheetsizes',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteSheetSizes: any = {
        url: 'sheetsizes/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsSheetSizes: any = {
        url: 'sheetsizes/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getSheetSizesBySid: any = {
        url: 'sheetsizes/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly relationshipsFormInviteBuyer: any = {
        url: 'relationships/form/relationships/invitebuyer/{id}',
        method: 'POST',
        showloading: true
    };
    public static readonly autoSuggestService_Relationships: any = {
        url: 'relationships/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly createRelationships: any = {
        url: 'relationships',
        method: 'POST',
        showloading: true
    };
    public static readonly updateRelationships: any = {
        url: 'relationships',
        method: 'PUT',
        showloading: true
    };
    public static readonly getRelationshipsBySid: any = {
        url: 'relationships/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getRelationshipsAll_Relationships: any = {
        url: 'relationships/relationships/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteRelationships: any = {
        url: 'relationships/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsRelationships: any = {
        url: 'relationships/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly relationshipsFormViewPendingInvitations: any = {
        url: 'relationships/form/relationships/viewpendinginvitations/{id}',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteFieldsRelationships: any = {
        url: 'relationships/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateTestResultsExports: any = {
        url: 'testresultsexports',
        method: 'PUT',
        showloading: true
    };
    public static readonly getTestResultsExportsAll_TestResultsExports: any = {
        url: 'testresultsexports/testresultsexports/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly getTestResultsExportsBySid: any = {
        url: 'testresultsexports/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createTestResultsExports: any = {
        url: 'testresultsexports',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteFieldsTestResultsExports: any = {
        url: 'testresultsexports/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteTestResultsExports: any = {
        url: 'testresultsexports/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsTestResultsExports: any = {
        url: 'testresultsexports/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly autoSuggestService_InkTechnology: any = {
        url: 'inktechnologies/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly createInkTechnology: any = {
        url: 'inktechnologies',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteInkTechnology: any = {
        url: 'inktechnologies/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateInkTechnology: any = {
        url: 'inktechnologies',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsInkTechnology: any = {
        url: 'inktechnologies/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsInkTechnology: any = {
        url: 'inktechnologies/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getInkTechnologyBySid: any = {
        url: 'inktechnologies/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getInkTechnologyBy_NameInLowerCase: any = {
        url: 'inktechnologies/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly getInkTechnologyBy_Name: any = {
        url: 'inktechnologies/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly getInkTechnologyAll_InkTechnology: any = {
        url: 'inktechnologies/inktechnologies/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly getAdhesiveFormulationAll_AdhesiveFormulation: any = {
        url: 'adhesiveformulations/adhesiveformulations/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly getAdhesiveFormulationBySid: any = {
        url: 'adhesiveformulations/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsAdhesiveFormulation: any = {
        url: 'adhesiveformulations/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsAdhesiveFormulation: any = {
        url: 'adhesiveformulations/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createAdhesiveFormulation: any = {
        url: 'adhesiveformulations',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteAdhesiveFormulation: any = {
        url: 'adhesiveformulations/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getAdhesiveFormulationBy_NameInLowerCase: any = {
        url: 'adhesiveformulations/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_AdhesiveFormulation: any = {
        url: 'adhesiveformulations/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly updateAdhesiveFormulation: any = {
        url: 'adhesiveformulations',
        method: 'PUT',
        showloading: true
    };
    public static readonly getAdhesiveFormulationBy_Name: any = {
        url: 'adhesiveformulations/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsCbxGeneralSettings: any = {
        url: 'cbxgeneralsettings/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getCbxGeneralSettingsAll_CBXGeneralSettings: any = {
        url: 'cbxgeneralsettings/cbxgeneralsettings/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly autoSuggestService_CbxGeneralSettings: any = {
        url: 'cbxgeneralsettings/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteCbxGeneralSettings: any = {
        url: 'cbxgeneralsettings/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updateFieldsCbxGeneralSettings: any = {
        url: 'cbxgeneralsettings/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateCbxGeneralSettings: any = {
        url: 'cbxgeneralsettings',
        method: 'PUT',
        showloading: true
    };
    public static readonly createCbxGeneralSettings: any = {
        url: 'cbxgeneralsettings',
        method: 'POST',
        showloading: true
    };
    public static readonly getCbxGeneralSettingsBySid: any = {
        url: 'cbxgeneralsettings/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsPrintModes: any = {
        url: 'printmodes/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getPrintModesBySid: any = {
        url: 'printmodes/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsPrintModes: any = {
        url: 'printmodes/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getPrintModesBy_NameInLowerCase: any = {
        url: 'printmodes/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly createPrintModes: any = {
        url: 'printmodes',
        method: 'POST',
        showloading: true
    };
    public static readonly deletePrintModes: any = {
        url: 'printmodes/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly updatePrintModes: any = {
        url: 'printmodes',
        method: 'PUT',
        showloading: true
    };
    public static readonly getApplicationsAll_Applications: any = {
        url: 'applications/applications/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly getApplicationsBySid: any = {
        url: 'applications/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly getApplicationsBy_Name: any = {
        url: 'applications/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsApplications: any = {
        url: 'applications/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createApplications: any = {
        url: 'applications',
        method: 'POST',
        showloading: true
    };
    public static readonly updateApplications: any = {
        url: 'applications',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsApplications: any = {
        url: 'applications/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteApplications: any = {
        url: 'applications/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly autoSuggestService_Applications: any = {
        url: 'applications/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly getApplicationsBy_NameInLowerCase: any = {
        url: 'applications/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly createStockItem: any = {
        url: 'stockitems',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsStockItem: any = {
        url: 'stockitems/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly autoSuggestService_StockItem: any = {
        url: 'stockitems/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly getStockItemBySid: any = {
        url: 'stockitems/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateStockItem: any = {
        url: 'stockitems',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsStockItem: any = {
        url: 'stockitems/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteStockItem: any = {
        url: 'stockitems/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly createComments: any = {
        url: 'comments',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteComments: any = {
        url: 'comments/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsComments: any = {
        url: 'comments/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateComments: any = {
        url: 'comments',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsComments: any = {
        url: 'comments/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getCommentsBySid: any = {
        url: 'comments/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateCoreOuterDiameter: any = {
        url: 'coreouterdiameters',
        method: 'PUT',
        showloading: true
    };
    public static readonly createCoreOuterDiameter: any = {
        url: 'coreouterdiameters',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteCoreOuterDiameter: any = {
        url: 'coreouterdiameters/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsCoreOuterDiameter: any = {
        url: 'coreouterdiameters/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getCoreOuterDiameterBySid: any = {
        url: 'coreouterdiameters/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsCoreOuterDiameter: any = {
        url: 'coreouterdiameters/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteHeaters: any = {
        url: 'heaters/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getHeatersBySid: any = {
        url: 'heaters/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateHeaters: any = {
        url: 'heaters',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsHeaters: any = {
        url: 'heaters/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getHeatersBy_NameInLowerCase: any = {
        url: 'heaters/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteFieldsHeaters: any = {
        url: 'heaters/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly createHeaters: any = {
        url: 'heaters',
        method: 'POST',
        showloading: true
    };
    public static readonly createHeadHeight: any = {
        url: 'headheights',
        method: 'POST',
        showloading: true
    };
    public static readonly updateHeadHeight: any = {
        url: 'headheights',
        method: 'PUT',
        showloading: true
    };
    public static readonly getHeadHeightBySid: any = {
        url: 'headheights/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly updateFieldsHeadHeight: any = {
        url: 'headheights/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteFieldsHeadHeight: any = {
        url: 'headheights/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getHeadHeightBy_NameInLowerCase: any = {
        url: 'headheights/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteHeadHeight: any = {
        url: 'headheights/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getBuyerSignupsBySid: any = {
        url: 'buyersignups/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly buyerSignups_detailFormApprove: any = {
        url: 'buyersignups/form/buyersignups_detail/approve/{id}',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteFieldsBuyerSignups: any = {
        url: 'buyersignups/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getBuyerSignupsAll_BuyerSignups: any = {
        url: 'buyersignups/buyersignups/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly createBuyerSignups: any = {
        url: 'buyersignups',
        method: 'POST',
        showloading: true
    };
    public static readonly updateBuyerSignups: any = {
        url: 'buyersignups',
        method: 'PUT',
        showloading: true
    };
    public static readonly buyerSignups_detailFormDisapprove: any = {
        url: 'buyersignups/form/buyersignups_detail/disapprove/{id}',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsBuyerSignups: any = {
        url: 'buyersignups/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly deleteBuyerSignups: any = {
        url: 'buyersignups/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly deleteFieldsOpacityLevel: any = {
        url: 'opacitylevels/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getOpacityLevelBySid: any = {
        url: 'opacitylevels/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly createOpacityLevel: any = {
        url: 'opacitylevels',
        method: 'POST',
        showloading: true
    };
    public static readonly getOpacityLevelBy_NameInLowerCase: any = {
        url: 'opacitylevels/nameinlowercase/{nameinlowercase}',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteOpacityLevel: any = {
        url: 'opacitylevels/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getOpacityLevelAll_OpacityLevel: any = {
        url: 'opacitylevels/opacitylevels/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly updateOpacityLevel: any = {
        url: 'opacitylevels',
        method: 'PUT',
        showloading: true
    };
    public static readonly updateFieldsOpacityLevel: any = {
        url: 'opacitylevels/updatefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly getOpacityLevelBy_Name: any = {
        url: 'opacitylevels/name/{name}',
        method: 'GET',
        showloading: true
    };
    public static readonly autoSuggestService_OpacityLevel: any = {
        url: 'opacitylevels/autosuggestservice',
        method: 'GET',
        showloading: true
    };
    public static readonly deleteInviteBuyer: any = {
        url: 'invitebuyers/{ids}',
        method: 'DELETE',
        showloading: true
    };
    public static readonly getInviteBuyerAll_PendingInvitations: any = {
        url: 'invitebuyers/pendinginvitations/datatable',
        method: 'POST',
        showloading: true
    };
    public static readonly getInviteBuyerBySid: any = {
        url: 'invitebuyers/sid/{sid}',
        method: 'GET',
        showloading: true
    };
    public static readonly pendingInvitationsRowReject: any = {
        url: 'invitebuyers/row/pendinginvitations/reject/{id}',
        method: 'POST',
        showloading: true
    };
    public static readonly createInviteBuyer: any = {
        url: 'invitebuyers',
        method: 'POST',
        showloading: true
    };
    public static readonly updateInviteBuyer: any = {
        url: 'invitebuyers',
        method: 'PUT',
        showloading: true
    };
    public static readonly pendingInvitationsRowResendInvitation: any = {
        url: 'invitebuyers/row/pendinginvitations/resendinvitation/{id}',
        method: 'POST',
        showloading: true
    };
    public static readonly deleteFieldsInviteBuyer: any = {
        url: 'invitebuyers/deletefields',
        method: 'PUT',
        showloading: true
    };
    public static readonly pendingInvitationsRowAccept: any = {
        url: 'invitebuyers/row/pendinginvitations/accept/{id}',
        method: 'POST',
        showloading: true
    };
    public static readonly pendingInvitationsRowRemove: any = {
        url: 'invitebuyers/row/pendinginvitations/remove/{id}',
        method: 'POST',
        showloading: true
    };
    public static readonly updateFieldsInviteBuyer: any = {
        url: 'invitebuyers/updatefields',
        method: 'PUT',
        showloading: true
    };
 }
