import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-color-picklist',
  templateUrl: './color-picklist.component.html',
  styleUrls: ['./color-picklist.component.scss']
})
export class ColorPicklistComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
