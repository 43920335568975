import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { Observable, of, Subject } from 'rxjs';
import { NodeService } from './node.service';

@Component({
  selector: 'app-vs-tree',
  templateUrl: './vs-tree.component.html',
  styleUrls: ['./vs-tree.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VsTreeComponent),
    multi: true
  }]
})
export class VsTreeComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input() treeConfig: any;
  loading: boolean;
  _data: TreeNode[] = [];
  selectedNodes: TreeNode[] = [];
  isDev = false;
  _dirty = false;

/*   {
    "key": "1b09d847-9d08-4387-8a93-88e30813edf1",
    data : {
      "id": "1b09d847-9d08-4387-8a93-88e30813edf1",
      "value": {
          "name": "Japan",
          "alpha2": "JA",
          "id": 3,
          "region": "Asia"
      }
  }
  } */

  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;

  constructor(private http: HttpClient, private nodeService: NodeService) { }

  disabled = false;

  set data(val:any){
    val = val || [];
    this._data = val;
  }

  get data() {
    return this._data;
  }
  set dirty(val:any){
    this._dirty = val;
  }

  get dirty() {
    return this._dirty;
  }

  setData(data){
    if(data && !this.treeConfig.fireOnload){
      const dataConfig = this.treeConfig.getDataConfig(null);
      const selecteddata = data.map(item => {
        if(item.selected){
          return {
            'key' :item[dataConfig.idField]
          }
        }
      }).filter(item => item);
      this.selectedNodes = this.selectedNodes.concat(selecteddata)
      
      data = this.formatter(
        data, 
        dataConfig,
        null, 
        (this.treeConfig.singleLevel || false),
        null, 

      )
      this.data = data;
      
    }
    this.loading = false;
  }

  setSelected(data, idField){
    let selected = data;
    if(idField){
      selected = this.frameKey(data, idField);
    }
    this.selectedNodes = this.selectedNodes.concat(selected)
  }

  setChildData(data, parentKeyField){
    this.data.forEach(item => {
      const parentKey = item.key;
      const childrens = data.filter(ch => ch[parentKeyField] === parentKey);

      parentKeyField
    });
  }

  frameKey(data, idField){
    const keys = [];
    data.forEach(item => {
      keys.push({
        key : item[idField],
        data : item
      });
    });

    return keys;
  }

  writeValue(value: TreeNode[]): void {
    if(value){
      this.data = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  nodeExpand(event) {
    // return;
    if (event.node && !event.node.leaf && !this.treeConfig.fireChildsOnload) {
      const parent = (event.node.parent || -1) + 1;     
      let childUrl = this.treeConfig.getUrl;
      let childConfig:any = {};
      let childsSelected = event.node.data.selected;

      if(this.treeConfig.getDataConfig){
        childConfig = this.treeConfig.getDataConfig(event.node);
        if(childConfig.url) {
          childUrl = childConfig.url
        }
      }
      
      this.loading = true;
      if(this.isDev){
        of([{"sid":"1b09d847-9d08-4387-8a93-88e30813edf1","createdBy":"cshibin@vanenburg.com","createdDate":1628848529495,"modifiedBy":"cshibin@vanenburg.com","modifiedDate":1628848529495,"recDeleted":false,"id":3,"name":"Japan","alpha2":"JA","alpha3":"JAP","numeric":1,"region":{"id":"a78d4315-00e2-489f-98ba-b1832ddbe24d","value":{"name":"Asia","id":1}},"currency":["JPY"],"nameInLowerCase":"japan"},{"sid":"8b96e3a9-4aa7-4988-92c7-782ebce49b83","createdBy":"cshibin@vanenburg.com","createdDate":1628848582500,"modifiedBy":"cshibin@vanenburg.com","modifiedDate":1628848582500,"recDeleted":false,"id":4,"name":"Singapore","alpha2":"SI","alpha3":"SIN","numeric":1,"region":{"id":"a78d4315-00e2-489f-98ba-b1832ddbe24d","value":{"name":"Asia","id":1}},"currency":["SGD"],"nameInLowerCase":"singapore"},{"sid":"a2631fb5-a50f-4380-8f80-70b3d410a45d","createdBy":"rarun@vanenburg.com","createdDate":1628839697364,"modifiedBy":"rarun@vanenburg.com","modifiedDate":1628839697364,"recDeleted":false,"id":1,"name":"India","alpha2":"IN","alpha3":"Ind","numeric":23,"region":{"id":"a78d4315-00e2-489f-98ba-b1832ddbe24d","value":{"name":"Asia","id":1}},"currency":["RUP"],"nameInLowerCase":"india"},{"sid":"ddcb6af8-5605-4136-8803-c364fbf0e410","createdBy":"cshibin@vanenburg.com","createdDate":1628848452884,"modifiedBy":"cshibin@vanenburg.com","modifiedDate":1628848452884,"recDeleted":false,"id":2,"name":"China","alpha2":"CH","alpha3":"CHI","numeric":1,"region":{"id":"a78d4315-00e2-489f-98ba-b1832ddbe24d","value":{"name":"Asia","id":1}},"currency":["CNE"],"nameInLowerCase":"china"}])
        .subscribe(nodes => {
          
          event.node.children = this.formatter(
            nodes, 
            childConfig,
            parent, 
            (childConfig.leafNode || false),
            event.node,
            childsSelected
          )
          this.loading = false;
        }); 
      }else{
        this.nodeService.getData(childUrl).then(nodes => {
  
          event.node.children = this.formatter(
            nodes, 
            childConfig,
            parent, 
            (childConfig.leafNode || false),
            event.node,
            childsSelected
          )
          this.loading = false;
        }); 

      }
    }
  }

  getData(config):Observable<any> {
    const obsr$ = new Subject<any>();
    this.loading = true;
    if(this.isDev){
      this.nodeService.getDummyData().subscribe(files =>{
         this.data = this.formatter(
           files, 
           config,
           null, 
           (this.treeConfig.singleLevel || false),
           null
         );
         this.loading = false;
         setTimeout(() => {
           obsr$.next()
           obsr$.complete()
           
         }, 30);
       })
     }else{
       this.nodeService.getData(config.url).then(files => {
         this.data = this.formatter(
           files, 
           config,
           null, 
           (this.treeConfig.singleLevel || false),
           null
         );
         this.loading = false;
         obsr$.next()
         obsr$.complete()         
       });
     }
    return obsr$.asObservable()
  }

  ngOnInit() {
    this.isDev = localStorage.getItem('isDev') ==  "true";
    if(this.treeConfig){
      this.treeConfig.refresh = this.refresh.bind(this);
      if(this.treeConfig.fireOnload){
        this.refresh().subscribe(res=>{
          if(this.treeConfig.fireChildsOnload){
            this.treeConfig.getAllChilds(this.data).subscribe(
              res => {
                setTimeout(()=>{
                  this.treeConfig.onAfterLoadData && this.treeConfig.onAfterLoadData();
                },100);
              }
            )
          }else{
            setTimeout(()=>{
              this.treeConfig.onAfterLoadData && this.treeConfig.onAfterLoadData();
            },100);
          }
        });
      }
    }    
  }

  getAllChilds(){

  }
  
  ngAfterViewInit(){
    if(this.treeConfig.afterInit){
      this.treeConfig.afterInit(this);
    }
  }


  refresh():Observable<any>{
    const dataConfig = this.treeConfig.getDataConfig(null);
    return this.getData(dataConfig);
  }

  formatter(data, dataConfig, parent, leaf, parentNode, selected = false){
    if(dataConfig.displayField || dataConfig.getDisplayLabel){
      data = data.map(item => {
        let label = '';
        if(dataConfig.dataFormatter){
          item = dataConfig.dataFormatter(item);
        }
        if(dataConfig.getDisplayLabel){
          label = dataConfig.getDisplayLabel(item);
        }else{
          label = item[dataConfig.displayField]
        }
        if(parentNode && parentNode.data.selected){
          this.selectedNodes.push({
            'key' :item[dataConfig.idField]
          });
        }

        const included = this.selectedNodes.find(selItem => selItem.key === item[dataConfig.idField]);

        if(selected && !included){
          this.selectedNodes.push({'key' :item[dataConfig.idField]});
        }
        item.selected = selected || (included ? true : false)

        return { 
          'label': label,
          'data': item,
          'leaf' : leaf,
          'parent' : parent,
          'key' :item[dataConfig.idField],
          'children': [],
          'selectable' : this.treeConfig.selectable,          
        }
      });
    }
    return  data;
  }

  onNodeSelect (event){

    event.node.data.selected = true;
    this.setAllChildren(event.node, true);
    this.setAllParent(event.node);
    this.dirty = true;

    //if()
  }
  onNodeUnselect(event){
    event.node.data.selected = false;
    this.setAllChildren(event.node, false);
    this.setAllParent(event.node);
   /*  event.node.children.forEach(child => {
      child.data.selected = false
    }); */

    this.dirty = true;
  }  

  setAllChildren(node, selectedValue){
    if(node.children && node.children.length){
      node.children.forEach(child => {
        child.data.selected = selectedValue;
        if(!selectedValue){
          this.selectedNodes = this.selectedNodes.filter(item => item.key != child.key);
        }
        this.setAllChildren(child,selectedValue);
      });
      
    }
  }

  setAllParent(node){
    const parent = node.parent;
    if(parent){
      if(parent.children && parent.children.length){
        const selectedNodes = parent.children.filter(item => !item.data.selected);
        if(selectedNodes.length){
          parent.data.selected = false;
        }else{
          parent.data.selected = true;
        }
      }
      this.setAllParent(parent);
    }
  }

  enableTree(){
    this.setNodeDisabledOrEnable(this.data, true);
  }

  disableTree(){
    this.setNodeDisabledOrEnable(this.data, false);
  }

  setNodeDisabledOrEnable(data, selectableValue){
    //data = data
    if(data && data.length){
      data.forEach(node => {
        node.selectable = selectableValue;
        this.setNodeDisabledOrEnable(node.children, selectableValue);
      });
    }
  }
}
