import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  constructor(private http: HttpClient) { }

  getFiles(url?) {
    return this.http.get<any>(url)
      .toPromise()
      .then(res => <TreeNode[]>res.data);
  }

  getLazyFiles() {
    return this.http.get<any>('assets/data/treeData.json')
      .toPromise()
      .then(res => <TreeNode[]>res.data);
  }

  getData(url?){
    return this.http.get<any>(url)
      .toPromise()
      .then(res => <TreeNode[]>(res || []));
  }

  getDummyData(){
    return  of([
      {
          "sid": "4e46db02-8564-4a6c-a211-bdf5acf028b7",
          "createdBy": "cshibin@vanenburg.com",
          "createdDate": 1628848416148,
          "modifiedBy": "cshibin@vanenburg.com",
          "modifiedDate": 1628848416148,
          "recDeleted": false,
          "id": 6,
          "name": "South America"
      },
      {
          "sid": "c4913ce4-2a48-42f7-9cae-77cd3bdf7dbc",
          "createdBy": "cshibin@vanenburg.com",
          "createdDate": 1628848406465,
          "modifiedBy": "cshibin@vanenburg.com",
          "modifiedDate": 1628848406465,
          "recDeleted": false,
          "id": 5,
          "name": "North America"
      },
      {
          "sid": "2182a936-bd06-4ac5-9f4b-7e4420db6f83",
          "createdBy": "cshibin@vanenburg.com",
          "createdDate": 1628848393592,
          "modifiedBy": "cshibin@vanenburg.com",
          "modifiedDate": 1628848393592,
          "recDeleted": false,
          "id": 4,
          "name": "Africa"
      },
      {
          "sid": "637f0ba6-dd17-42e8-aee8-3755c58467f9",
          "createdBy": "cshibin@vanenburg.com",
          "createdDate": 1628848377337,
          "modifiedBy": "cshibin@vanenburg.com",
          "modifiedDate": 1628848377337,
          "recDeleted": false,
          "id": 3,
          "name": "Middle East"
      },
      {
          "sid": "5dcd7097-2527-4033-b9da-f109aca8eb29",
          "createdBy": "cshibin@vanenburg.com",
          "createdDate": 1628848365270,
          "modifiedBy": "cshibin@vanenburg.com",
          "modifiedDate": 1628848365270,
          "recDeleted": false,
          "id": 2,
          "name": "Europe"
      },
      {
          "sid": "a78d4315-00e2-489f-98ba-b1832ddbe24d",
          "createdBy": "rarun@vanenburg.com",
          "createdDate": 1628839669184,
          "modifiedBy": "rarun@vanenburg.com",
          "modifiedDate": 1628839669184,
          "recDeleted": false,
          "id": 1,
          "name": "Asia"
      }
    ]);
  }
}
