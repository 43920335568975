import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[tableMin]',
  providers: [{ provide: NG_VALIDATORS, useExisting: TableMinDirective, multi: true }]
})
export class TableMinDirective implements Validator {
  @Input()
  tableMin: number;

  validate(c: FormControl): { [key: string]: any } {
    const v = c.value;

    c.valueChanges.subscribe(val => {
      let existingErrors = c.errors ? c.errors : {};
      let minError = this.checkAndSetError(val);
      existingErrors = this.getUpdatedError(minError, existingErrors);
      c.setErrors(existingErrors);
    });

    let existingErrors = c.errors ? c.errors : {};
    let minError = this.checkAndSetError(v);
    return this.getUpdatedError(minError, existingErrors);
  }

  checkAndSetError(val){
    if (this.tableMin != null && val && val.length > 0) {
      for (let row of val) {
        for (let col of Object.keys(row)) {
          if (col != "key" && row[col] != null && typeof row[col] == 'number') {
            return { 'tableMin': row[col] as unknown as number < this.tableMin ?  true : null } 
          }
        }
      }
    }
    return {};
  }

  getUpdatedError(minError: { tableMin: boolean; } | { tableMin?: undefined; }, existingErrors) {
    if (minError.tableMin) {
      existingErrors.tableMin = true;
    } else {
      if (existingErrors.tableMin) {
        delete existingErrors.tableMin;
      }
    }
    if (Object.keys(existingErrors).length == 0) {
      existingErrors = null;
    }
    return existingErrors;
  }
}
