import {Component, Injectable} from '@angular/core';
import {NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class VsDateRangeAdapter extends NgbDateAdapter<number> {

  fromModel(value: number | null): NgbDateStruct | null {
    if (value) {
      const date = new Date(value);
      return { 
        day: date.getDate(), 
        month: date.getMonth() + 1, 
        year: date.getFullYear()
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): number | null {
    return date ? new Date(date.day, date.month, date.year).getTime() : null;
  }
}