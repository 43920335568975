import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FsBaseService } from '@app/core/fs-base.service';

@Injectable({
  providedIn: 'root'
})

export class VsSpreadsheetService {

  constructor(public fsService: FsBaseService) { }

  public getDocumentList(servicOpts) {
    const subject = new Observable(observer => {
      const service = this.fsService.fireService(servicOpts).subscribe((res) => {
        observer.next(res);
      });
    });
    return subject;
  }
}