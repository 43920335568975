import { Component, OnInit,Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-show-all-viewers',
  templateUrl: './show-all-viewers.component.html',
  styleUrls: ['./show-all-viewers.component.scss']
})
export class ShowAllViewersComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }
  @Input() sheetViewer;
  ngOnInit(): void {
  }
  public closeModal() {
    this.activeModal.dismiss(false);
  }
}
