import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DropNotificationService } from './drop-notification.service';
import { debounce } from 'lodash';

import moment from 'moment';
import { FsBaseService } from '@app/core/fs-base.service';
import { AppGlobalService } from '@app/app-global.service';
import notification from '@assets/data/notification.json';


@Component({
  selector: 'app-drop-notification',
  templateUrl: './drop-notification.component.html',
  styleUrls: ['./drop-notification.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0.5 }),
            animate('.5s ease-out',
              style({ height: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300, opacity: 1 }),
            animate('.5s ease-in',
              style({ height: 0, opacity: 0.5 }))
          ]
        )
      ]
    )
  ]
})
export class DropNotificationComponent implements OnInit, OnChanges {

  @Input() dnConfig: any = {};
  @Output() nEvent: any = new EventEmitter();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!(this.eRef.nativeElement.contains(event.target))) {
      this.showNotifications = false;
    } 
  }

  public showNotifications: boolean;
  public isLoading: boolean;
  public notificationCount = 0;
  public dnData: any[] = [];
  public iconMap: any = {};
  public isScrolled: boolean;
  private lastSyncedTime = null;
  private countSubscription: any = null;
  private currentUser: string = '';
  private appInitialized = false;
  private lastDtResultLength = 0;
  public notificationMap: any = {};
  private dtRequest: any = {
    draw: 0,
    start: 0,
    length: 10
  }
  public openNotificationCount = 0;

  constructor(
    private fsService: FsBaseService,
    private dnService: DropNotificationService,
    private appGlobal: AppGlobalService,
    private eRef: ElementRef
  ) { }

  setDefaultProperties() {
    this.notificationMap = notification;
  }

  listenForSyncTime() {
    const queryObj = {
      collectionName: 'UserNotificationSyncInfo',
      filter: [
        {
          field: 'user',
          operator: '==',
          value: this.currentUser
        }
      ]
    }
    this.fsService.fsValueChanges(queryObj).subscribe((d: any[]) => {

      if (!d || d.length == 0) {
        this.listenForNotificationCount();
      } else {
        const data = d[0].payload.doc.data();
        this.lastSyncedTime = new Date(data.lastSyncTime);
        this.listenForNotificationCount();
      }
    })
  }

  listenForNotificationCount() {
    //TODO: should remove this
    this.notificationCount = 5;
    this.openNotificationCount = 5;
    return;

    // const queryObj = {
    //   collectionName: 'AppNotification',
    //   filter: [
    //     {
    //       field: 'users',
    //       operator: 'array-contains',
    //       value: this.currentUser
    //     }
    //   ]
    // }

    // if (this.lastSyncedTime) {
    //   queryObj.filter.push({
    //     field: 'createdDate',
    //     operator: '>',
    //     value: this.lastSyncedTime
    //   })
    // }

    // if (this.countSubscription) {
    //   this.countSubscription.unsubscribe();
    // }

    // this.countSubscription = this.fsService.fireService(queryObj).subscribe(d => {
    //   this.notificationCount = d.length;
    // })
  }

  handleBadgeClick() {
    this.showNotifications = !this.showNotifications;
    if (this.showNotifications) {
      this.getNotifications();
      setTimeout(() => {
        this.resetSyncTime();
      }, 500);
    } else {
      //this.dnData = [];
    }
  }


  resetSyncTime() {
    this.dnService.updateSynctime().subscribe((d: any) => {
      console.log('LAST SYNC TIME: ', d);
      // this.lastSyncedTime = new Date(d);
    })
  }

  getNotifications() {
    this.isLoading = true;
    //this.resetDtRequestHeaders();
    console.log('GETTING NOTIFICATIONS');

    //TODO: should remove
    this.dtRequest = this.notificationCount;
    if(this.dtRequest == 0) {
      this.isLoading = false;
      this.openNotificationCount = 0;
      return;
    }

     this.dnService.getDummyNotifications(this.dtRequest).subscribe((data: any = {}) => {
      this.dnData = data.results;
      this.notificationCount = 0;
      this.isLoading = false;
      this.lastDtResultLength = this.dnData.length;
    }, err => {
      this.isLoading = false;
    });
  }

  debouncedGet = debounce(() => {
    this.getNotifications();
  }, 1000);

  setDtRequestHeaders() {
    this.dtRequest.draw += 1;
    this.dtRequest.start += 1;
    this.dtRequest.length = 10;
  }

  resetDtRequestHeaders() {
    this.dtRequest.draw = 0;
    this.dtRequest.start = 0;
    this.dtRequest.length = 10;
  }

  handleScroll(e) {
    const el = e.currentTarget;
    this.isScrolled = el.scrollTop > 10 ? true : false;

    if ((el.scrollTop >= (el.scrollHeight - el.offsetHeight) - 5) && this.lastDtResultLength > 10) {
      console.log('SCROLLED TO BOTTOM');
      this.setDtRequestHeaders();
      this.debouncedGet();
      e.stopPropagation();
    }
  }

  getRelativeTime(t) {
    return moment(t).fromNow();
  }

  handleNotificationClick(e, notification) {
    this.nEvent.emit({
      type: 'notificationClick',
      event: e,
      notification
    })
  }

  viewNewNotifications() {
    this.getNotifications();
    document.getElementById('notificationList').scrollTop = 0;
  }

  setCurrentUser() {
    this.currentUser = 'tom@smail.com';
    //this.currentUser = this.appGlobal.get('currentUser') && this.appGlobal.get('currentUser').email;
  }

  initializeApp() {
    if (!this.appInitialized) {
      this.setDefaultProperties();
      this.setCurrentUser();
      if (!this.currentUser) {
        return;
      }
      //this.listenForSyncTime();
      this.listenForNotificationCount();
      this.appInitialized = true;
    }
  }

  getImgSrc(image) {
    return 'custom/assets/images/' + image;
  }

  ngOnInit() {
    //TODO: should remove
    if(!this.dnConfig) this.dnConfig = {};
    this.dnConfig.iconFrom = 'FontAwesome';
    this.initializeApp();
    /* 
    if (this.dnConfig && Object.keys(this.dnConfig).length > 0) {
      this.initializeApp();
    } */
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dnConfig && Object.keys(this.dnConfig).length > 0) {
      this.initializeApp();
    }
  }

}
