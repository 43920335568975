import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { CustomService } from '@custom/services/custom.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[tableMultiple]',
  providers: [{ provide: NG_VALIDATORS, useExisting: TableMultipleDirective, multi: true }]
})
export class TableMultipleDirective implements Validator {
  @Input()
  tableMultiple: number;
  constructor(private customService:CustomService){
    
  }

  validate(c: FormControl): { [key: string]: any } {
    const v = c.value;

    c.valueChanges.subscribe(val => {
      let existingErrors = c.errors ? c.errors : {};
      let multipleError = this.checkAndSetError(val);
      existingErrors = this.getUpdatedError(multipleError, existingErrors);
      c.setErrors(existingErrors);
    });

    // tslint:disable-next-line:object-literal-key-quotes
    let existingErrors = c.errors ? c.errors : {};
    let multipleError = this.checkAndSetError(v);
    return this.getUpdatedError(multipleError, existingErrors);
  }

  checkAndSetError(val) {
    if (this.tableMultiple != null && val && val.length > 0) {
      for (let row of val) {
        for (let col of Object.keys(row)) {
          if (col != "key" && row[col] != null && typeof row[col] == 'number') {
            return { 'tableMultiple': this.customService.isMultiple(row[col] as unknown as number,this.tableMultiple ) ?  null : true } 
          }
        }
      }
    }
    return {};
  }

  getUpdatedError(multipleError: { tableMultiple: boolean; } | { tableMultiple?: undefined; }, existingErrors) {
    if (multipleError.tableMultiple) {
      existingErrors.tableMultiple = true;
    } else {
      if (existingErrors.tableMultiple) {
        delete existingErrors.tableMultiple;
      }
    }
    if (Object.keys(existingErrors).length == 0) {
      existingErrors = null;
    }
    return existingErrors;
  }
}

