import { Component, OnInit, Input, ViewEncapsulation, ViewChild, Injector, ApplicationRef, ComponentFactoryResolver, OnDestroy, SecurityContext } from '@angular/core';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { Subject, Observable, fromEvent, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UtilService } from '@app/core/util.service';
import { NotificationService } from '@shared/services/notification.service';

import { VsSearchService } from '../vs-search/vs-search.service';
import { AppConstants } from '@custom/core/app-constants';
import { VsFormComponent } from '../vs-form/vs-form.component';
import { join } from 'lodash';

import { MaskApplierService } from 'ngx-mask';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { findIndex } from 'lodash';
import { ModalPopupService } from '@app/shared/services/modal-popup.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-vs-grid',
  templateUrl: './vs-grid.component.html',
  styleUrls: ['./vs-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VsGridComponent implements OnInit, OnDestroy {
  public dtOptions: any = {};
  private dtInstance: any;
  private clickedRowData: any;
  private clickedRow: any;
  public currentRowOptions: any = [];
  private serviceInprogress = false;
  private draw = 0;
  private start = 0;
  private length: any;
  private params: any = {};
  private total: any;
  private filtered: any;
  private sortDirection: 'asc' | 'desc';
  private sortColumn: number;
  private tracker: any;
  public showLoading = false;
  private searchInitiated = {
    global: false,
    adv: false
  };
  private subscriptions: Subscription[] = [];

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  private originalOrder: number[] = [];
  public dtTrigger: Subject<any> = new Subject();
  private scrollSubscription: any;

  public snapshotFormConfig: any = {};
  public snapshotData: any = {};
  public currentPage: any;


  @Input() contextMenu: ContextMenuComponent;
  @Input() gridConfig: any;

  // @ViewChild(VsFormComponent) child;

  constructor(
    private util: UtilService,
    private notification: NotificationService,
    private contextMenuService: ContextMenuService,
    private http: HttpClient,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private app: ApplicationRef,
    private search: VsSearchService,
    private maskService: MaskApplierService,
    private ts: TranslateService,
    private acRoute: ActivatedRoute,
    private modal: ModalPopupService
  ) {
    this.findPage();
  }

  findPage() {
    this.currentPage = this.acRoute.snapshot.data.label || this.acRoute.snapshot.routeConfig.path;
  }

  ngOnInit(): void {
    const self = this;
    // To fix translation issue
    const col_first = this.gridConfig && this.gridConfig.columns && this.gridConfig.columns[0].title;
    this.ts.get(col_first || 'TEST').subscribe(d => {

      this.attachSearchEvents();

      // tslint:disable-next-line: no-string-literal
      $.fn.DataTable['ext'].errMode = 'throw';

      const options: any = {
        scrollX: true,
        bFilter: false,
        id: 'datatables_' + Math.floor(this.util.getRandomNum() * 100) + 1,
        rowId: this.gridConfig.uniqueIdField ? this.gridConfig.uniqueIdField : 'sId',
        scrollY: this.gridConfig.scrollY ? this.gridConfig.scrollY : (window.innerHeight - 250),
        responsive: false,
        scrollCollapse: true,
        columns: this.getColumns(),
        ordering: this.gridConfig.ordering ? this.gridConfig.ordering : false,
        aaSorting: [],
        order: [],
        paging: false,
        deferRender: this.gridConfig.deferRender ? this.gridConfig.deferRender : true,
        pageLength: this.gridConfig.pageLength ? this.gridConfig.pageLength : 50,
        columnDefs: this.gridConfig.columnDefs ? this.gridConfig.columnDefs : [],
        fixedColumns: this.gridConfig.fixedColumns ? this.gridConfig.fixedColumns : '',
        colReorder: this.gridConfig.colReorder ? this.gridConfig.colReorder : false,
        colResize: this.gridConfig.colResize ? this.gridConfig.colResize : false,
        dom: 'Rlfrtip',
        "language": {
          "emptyTable": this.ts.instant(this.gridConfig.emptyTableMsg ? this.gridConfig.emptyTableMsg : "No data available in table")
        },
        complexHeader: this.gridConfig.complexHeader ? this.gridConfig.complexHeader : '',
        rowCallback: (row: Node, data: any[] | object, index: number) => {
          self.onRowCallback(row, data, index);
        },
        drawCallback: (settings: object) => {
          // const api = this.api();
          self.onDrawCallback(settings);
        },
        initComplete: (settings: any, json: any) => {
          this.dtInstance = settings.oInstance.api();
          this.dtInstance.refreshGrid = this.refreshGrid.bind(this);
          self.onInitComplete(this.dtInstance, settings, json);
        },
        colReorderResizeCallback: (dt) => {
          self.onReorderResizeCallback(dt);
        }
      };

      if (!this.gridConfig.disableSelection) {
        options.select = {
          style: 'multi',
          selector: 'td:first-child'
        };
      }

      if (this.gridConfig.rowMenuOptions && this.gridConfig.rowMenuOptions.length) {
        options.columns.push({
          data: '',
          className: 'grid_context_menu',
          title: '',
          orderable: false,
          render: (data: any, type: any, row: any, meta: any) => {

            if (this.gridConfig.rowMenuOptions.length === 1) {
              return `<button class="btn btn-primary btn-sm contextmenubtn ${this.getSpaceTrimmed(this.gridConfig.rowMenuOptions[0].label)}">
              <em class="${this.gridConfig.rowMenuOptions[0].icon}"></em>
              <span>${this.ts.instant(this.gridConfig.rowMenuOptions[0].label)}</span>
              </button>`;
            } else {
              return '<div class="grid_context_menu_icon"><em class="fa fa-ellipsis-v"></em></div>';
            }
          }
        });
      }

      if (this.gridConfig.data) {

        // tslint:disable-next-line: no-string-literal
        $.fn.DataTable['ext'].errMode = 'none';
        this.dtOptions = $.extend(options, {
          data: this.gridConfig.data
        });

        setTimeout(() => {
          self.dtTrigger.next();
        }, 100);

      } else {

        this.dtOptions = $.extend(options, {});
        this.length = this.gridConfig.length ? this.gridConfig.length : 50;
        if (!this.showLoading) {
          this.getParams();
          this.getData();
        }
      }
    });



  }

  /*Method to get the initial column order of the table*/
  private getOriginalColumnOrder(dtInstance, settings, json) {
    const originalColumns = this.gridConfig.columns;
    const changedColumns = settings.aoColumns;
    originalColumns.map((col) => {
      let index = findIndex(changedColumns, (e) => {

        return !e.data ? 0 : e.data == col.data;

      }, 0);

      this.originalOrder.push(index);
    });
  }

  private onReorderResizeCallback(settings) {
    if (!settings) return;
    const th = $(settings.s.dt.nTable).find('tr th')
    const colStore = {};
    colStore['t-w'] = $(settings.s.dt.nTable).width();
    $.map(settings.s.dt.aoColumns, (o, i) => {
      colStore[o.data] = {
        visible: o.bVisible
      }
      if (this.gridConfig.colReorder) {
        colStore[o.data].order = o.idx;
      }
      if (this.gridConfig.colResize) {
        colStore[o.data].size = $(th[i]).width();
      }
    })
    localStorage.setItem(this.currentPage, JSON.stringify(colStore));
  }
  private getSpaceTrimmed(label) {
    if (!label) {
      return '';
    }
    return label.replace(/ /g, '').toLowerCase();
  }

  private getColumns(): any {
    const self = this;
    const columns = [];
    const colStore = JSON.parse(localStorage.getItem(this.currentPage));

    for (const col of this.gridConfig.columns) {
      col.className = col.className ? (col.className + ' ' + col.data) : col.data;
      col.orderable = (col.orderable === false ? false : (this.gridConfig.orderable ? this.gridConfig.orderable : true));
      if (!col.orderable) {
        col.className += ' vs-ordering-disabled';
      } else {
        col.className += ' vs-ordering';
      }

      col.visible = this.checkIfColVisible(col);

      /*if (i === 0 && !this.gridConfig.disableSelection) {
        col.className = col.className + ' ' + 'select-checkbox';
      }*/

      const confRender = col.render;
      if (col.type === 'date' && !confRender) {
        col.render = (data: any, type: any, row: any, meta: any) => {
          return this.util.domSanitizer.sanitize(SecurityContext.HTML, self.util.formatDate(data, col.format ? col.format : null));
        };
      } else if (col.type === 'datetime' && !confRender) {
        col.render = (data: any, type: any, row: any, meta: any) => {
          return this.util.domSanitizer.sanitize(SecurityContext.HTML, self.util.formatDateTime(data, col.format ? col.format : null));
        };
      } else if (col.type === 'currency' && !confRender) {
        col.render = (data: any, type: any, row: any, meta: any) => {
          let cCode: any;
          const curFormatField = col.data + 'CurrencyFormat';
          if (row[curFormatField]) {
            cCode = row[curFormatField];
          } else {
            cCode = col.currencyCode ? col.currencyCode : null;
          }

          const cDigits = col.currencyDigits ? col.currencyDigits : null;
          const cSymbol = col.currencySymbol ? col.currencySymbol : null;
          return this.util.domSanitizer.sanitize(SecurityContext.HTML, self.util.formatCurrency(data, cCode, cDigits));
        };
      } else if (col.conditionalColor && !confRender) {
        col.render = (data: any, type: any, row: any, meta: any) => {
          const conditionalClrData = row[col.conditionalColorField];
          return '<div class="conditional_color_column ' + col.conditionalColor[conditionalClrData] + '">' 
              + this.util.domSanitizer.sanitize(SecurityContext.HTML, data) + '</div>';
        };
      } else if (col.numberFormat && !confRender) {
        col.render = (data: any, type: any, row: any, meta: any) => {
          // return self.util.formatNumber(data, col.numberFormat ? col.numberFormat : null);
          return col.numberFormat ? self.maskService.applyMask(data.toString(), col.numberFormat) :
             this.util.domSanitizer.sanitize(SecurityContext.HTML, data);
        };
      } else {
        col.render = (data: any, type: any, row: any, meta: any) => {
          if (row && !row.hasOwnProperty(col.data)) {
            row[col.data] = "";
            data = row[col.data];
          }
          if (confRender) {
            if(col.skipSanitize){
              return confRender(data, type, row, meta);
            } else {
              return this.util.domSanitizer.sanitize(SecurityContext.HTML, confRender(data, type, row, meta));
            }
          }
          if(typeof data == "string"){
            data = this.util.domSanitizer.sanitize(SecurityContext.HTML, data);
          } else if(typeof data == "object"){
            this.util.removeUnSafeParams(data);
          }
          return data;
        };
      }
      if (this.gridConfig.colReorder && colStore && colStore[col.data]) {
        col.order = colStore[col.data].order;
      }
      /* self.ts.get(col.title).subscribe((text: string) => {
        //col.title = this.ts.instant(col.title);
        col.title = text;
        console.log(text);
      }); */
      col.title = self.ts.instant(col.title)

      columns.push(col);
    }

    if (!this.gridConfig.disableSelection) {
      columns.unshift({
        data: '',
        orderable: false,
        className: 'select-checkbox',
        render: (data: any, type: any, row: any, meta: any) => {
          return ' <em class="fa fa-chevron-down expand-row">&nbsp;</em>';
        }
      });
    }
    if (this.gridConfig.colReorder && colStore) {
      columns.sort(function (a, b) { return a.order - b.order });
    }
    return columns;
  }

  public refreshGrid(): void {
    this.getData(false, false, true);
  }

  private getData(drawNextPage?: boolean, fromSorting?: boolean, fromRefresh?: boolean): void {
    this.showLoading = true;
    const params = this.params;
    if (this.gridConfig.fnServerParams) {
      if (typeof params.search.value === "string")
        params.search.value = JSON.parse(params.search.value);
      params.search.value = JSON.stringify(this.gridConfig.fnServerParams(params.search.value));
    }
    if (fromSorting) {
      params.start = 0;
      params.length = this.gridConfig.pageLength || 50;
      params.order = [{
        column: _.findIndex(params.columns, (obj) => { return obj.name === this.getColumns()[this.sortColumn].name }),
        dir: this.sortDirection
      }];
    } else if (fromRefresh) {
      params.start = 0;
      params.length = this.gridConfig.pageLength || 50;
      params.order = [];
    } else if (drawNextPage) {
      params.start = this.filtered;
      params.length = this.gridConfig.pageLength || 50;
    }

    let subject;
    if (this.gridConfig.method && this.gridConfig.method.toLowerCase() === 'get') {
      subject = this.http.get(this.gridConfig.ajaxUrl);
    } else {
      subject = this.http.post(this.gridConfig.ajaxUrl, $.param(params), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
    }


    const dataSubscription = subject.subscribe((data: any) => {
      this.total = data.total ? data.total : '';
      this.filtered = data.filtered ? data.filtered : '';
      this.serviceInprogress = false;
      if (!drawNextPage) {
        if (this.dtInstance) {
          this.tracker.scroll(0, 0);
          this.dtInstance.clear();
          this.dtInstance.rows.add(data.results).draw();
        } else {
          this.dtOptions.data = data.results;
          this.dtTrigger.next();
        }
      } else {
        this.dtInstance.rows.add(data.results).draw();
      }
      if (this.gridConfig.onAfterServiceRequest) {
        this.gridConfig.onAfterServiceRequest(data);
      }
    }, (err) => {
      this.showLoading = false;
      const data = {
        results: [],
        total: 1,
        filtered: 1
      }
      this.dtOptions.data = data.results;
      this.dtTrigger.next();
    });
    this.subscriptions.push(dataSubscription);
  }

  private getParams(): void {
    const params: any = {};

    params.draw = this.draw;
    params.start = this.start;
    params.length = this.length;

    params.search = {};
    params.search.value = {};
    params.search.regex = false;

    params.columns = [];
    for (const col of this.gridConfig.columns) {
      const column: any = {};
      column.data = col.data;
      column.name = col.name;
      column.searchable = true;
      column.orderable = col.orderable === false ? false : (this.gridConfig.orderable ? this.gridConfig.orderable : true);

      column.search = {};
      column.search.value = {};
      column.search.regex = false;

      params.columns.push(column);
    }

    params.columns.order = [];

    this.params = params;
  }

  private checkIfColVisible(col) {
    const colVisiblityMap = JSON.parse(localStorage.getItem(this.currentPage)) || {};
    return colVisiblityMap[col.data] ? colVisiblityMap[col.data].visible : true;
  }

  private createContextMenu(dtInstance: any, event: any) {
    const cols = dtInstance.settings().init().columns;
    const resetBtn = `<button class="btn btn-link btn-sm" id="resetGridSettings">${this.ts.instant("RESET_COLUMN_ORDER")}</button>`;
    let ul = "<ul>";
    const indexStart = this.gridConfig.disableSelection ? 0 : 1;
    for (let i = indexStart; i <= cols.length; i++) {
      if (cols[i] && cols[i].title) {
        const visMap = JSON.parse(localStorage.getItem(this.currentPage)) || {};
        let checked = 'checked';
        if (visMap && Object.keys(visMap).length) {
          checked = visMap[cols[i].data].visible ? 'checked' : '';
        }
        ul += `<li data="${cols[i].data}">
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="${cols[i].data}" value="${cols[i].data}" ${checked}>
            <label class="form-check-label" for="${cols[i].data}">${this.ts.instant(cols[i].title)}</label>
          </div>
        </li>`;
      }
    }
    ul += "</ul>";
    const actions = $(`<div class='btn-group'>
      <button type='button' class="btn btn-secondary btn-sm" id="gridCtxMenuCancel">Cancel</button>
      <button type='button' class="btn btn-primary btn-sm" id="gridCtxMenuOkay">Ok</button>
    </div>`);
    const ctxMenu = $(`<div class='tbl-ctx-menu'>${resetBtn}${ul}</div>`);
    $(ctxMenu).append(actions);
    const offset = $(event.currentTarget).offset();
    $(ctxMenu).css({
      top: offset.top + 10,
      left: offset.left + 10
    });
    $('body').append(ctxMenu);

    $('#gridCtxMenuCancel').off('click').on('click', (e) => {
      this.hideContextMenu();
    });

    $('#gridCtxMenuOkay').off('click').on('click', (e) => {
      this.saveContextMenu(dtInstance);
      this.hideContextMenu();
    });

    $('#resetGridSettings').off('click').on('click', (e) => {
      this.modal.openConfirmationModal('CONFIRM', 'CONFIRM_GRID_RESET', null, null, null).subscribe(d => {
        if (d) {
          this.resetColumnSettings(dtInstance);
          this.hideContextMenu();
        }
      })
    });


  }

  private resetColumnSettings(dtInstance) {
    const tbl = dtInstance.table();
    const settings = dtInstance.settings()[0];
    const table = $(settings.nTable);
    const colStore = {};
    colStore['t-w'] = '100%';

    $('.tbl-ctx-menu input').each(function (i, input) {
      const colClass = ($(this).val()).toString();
      $(input).prop('checked', true);
      colStore[colClass] ? colStore[colClass].visible = true : colStore[colClass] = { visible: true };
      tbl.columns('.' + colClass).visible(true);
    });

    this.gridConfig.columns.map((col, i) => {
      delete colStore[col.data].width;
      delete colStore[col.data].order;
      delete colStore[col.data].size;
    });

    localStorage.setItem(this.currentPage, JSON.stringify(colStore));

    /* Logic to reset the order */
    if (this.originalOrder.indexOf(0) == -1) {
      this.originalOrder.unshift(0);
    }
    tbl.colReorder.reset();
    tbl.colReorder.order(this.originalOrder);

    /* Logic to reset the size */
    const width = { 'width': '100%' };
    $('.dataTables_scroll .dataTable').css(width);
    $('.dataTables_scroll .dataTables_scrollHeadInner').css(width);
    $('.dataTables_scroll th, .dataTables_scroll td').css({ 'width': 'auto' });
  }

  private saveContextMenu(dtInstance) {
    const tbl = dtInstance.table();
    let colStore = JSON.parse(localStorage.getItem(this.currentPage));
    if (!colStore) { colStore = {}; }

    if ($('.tbl-ctx-menu input:checked').length == 0) {
      this.notification.warn('Atleast one column should be visible');
      return;
    }

    $('.tbl-ctx-menu input').each(function (input) {
      const colClass = ($(this).val()).toString();
      if ($(this).is(':checked')) {
        tbl.columns('.' + colClass).visible(true);
        colStore[colClass] ? colStore[colClass].visible = true : colStore[colClass] = { visible: true };
      } else {
        colStore[colClass] ? colStore[colClass].visible = false : colStore[colClass] = { visible: false };
        tbl.columns('.' + colClass).visible(false);
      }
    });
    tbl.columns.adjust().draw(false);
    localStorage.setItem(this.currentPage, JSON.stringify(colStore));

  }

  private hideContextMenu() {
    $('.tbl-ctx-menu').remove();
  }

  private attachContextMenu(dtInstance: any, settings: any, json: any) {
    let th = $(settings.nTHead);
    th.off().on('contextmenu', 'th', (event) => {
      this.hideContextMenu();
      this.createContextMenu(dtInstance, event);

      event.preventDefault();
      event.stopPropagation();
    });

    $(document).off('click').on('click', (event) => {
      const ct = $(event.target);
      if (!(ct.hasClass('tbl-ctx-menu') || ct.parents('.tbl-ctx-menu').length)) {
        this.hideContextMenu();
      }
    });
  }

  private onInitComplete(dtInstance: any, settings: any, json: any): void {

    const self = this;

    if (this.gridConfig.toggleColumns) {
      this.attachContextMenu(dtInstance, settings, json);
    }

    dtInstance.on('select', (e: any, dt: any, type: any, ix: any) => {
      const selected = dt.rows({ selected: true });
      const maxRows = self.gridConfig.maxRowsAllowedForSelection ? self.gridConfig.maxRowsAllowedForSelection : 10;
      if (selected.count() > maxRows) {
        self.notification.warn('MAX_ALLOWED_ROWS', { maxRows });
        dt.rows(ix).deselect();
      } else {
        this.gridConfig.onRowSelect(selected, ix);
      }
    });

    dtInstance.on('deselect', (e: any, dt: any, type: any, ix: any) => {

      setTimeout(() => {
        const selected = dt.rows({ selected: true });
        self.gridConfig.onRowDeselect(selected);
      }, 100);

    });

    this.attachInfiniteScroll(dtInstance, settings);
    if (this.gridConfig.ajaxUrl && this.gridConfig.customOrdering) {
      this.attachSorting(dtInstance);
    }

    this.getOriginalColumnOrder(dtInstance, settings, json);

    if (this.gridConfig.onInitComplete) {
      this.gridConfig.onInitComplete(dtInstance, settings, json);
    }
  }

  private attachInfiniteScroll(dtInstance: any, settings: any) {

    this.tracker = document.getElementsByClassName('dataTables_scrollBody')[0];

    const windowYOffsetObservable = fromEvent(this.tracker, 'scroll').map(() => {
      return this.tracker.scrollTop;
    });

    this.scrollSubscription = windowYOffsetObservable.subscribe((scrollPos) => {
      const limit = (this.tracker.scrollHeight - this.tracker.clientHeight) * 0.90;
      if (scrollPos >= limit && this.total > this.filtered) {
        if (!this.serviceInprogress) {
          this.serviceInprogress = true;
          this.getData(true);
        }
      }
    });
    this.subscriptions.push(this.scrollSubscription);
  }

  private attachSorting(dtInstance: any): void {
    const self = this;
    const headerEl = self.dtInstance.header()[0];

    const headNode = $(headerEl).find('th');
    headNode.off('click');
    headNode.on('click', (e) => {
      if ($(e.target).css('cursor') === 'col-resize') {
        return;
      }
      const curTarget = $(e.currentTarget);
      if (curTarget.hasClass('vs-ordering-disabled')) { return; }

      const sortDirection = curTarget.hasClass('asc') ? 'desc' : 'asc';
      const colOrder = parseInt(curTarget.attr('data-column-index'));

      this.dtOptions.columns[colOrder].sortDirection = sortDirection;
      this.sortColumn = colOrder;
      //if (this.gridConfig.disableSelection)
      //this.sortColumn = colOrder - 1;
      this.sortDirection = sortDirection;

      headNode.removeClass('asc desc');
      if (sortDirection == 'asc') {
        $(curTarget).addClass('asc');
      } else {
        $(curTarget).addClass('desc');
      }

      this.getData(false, true);

    });
  }

  private rowClickHandler(info: any, event: any, row: any, presstype?: any): void {
    const targetEl = $(event.currentTarget);
    const contextMenuEl = $(event.target);
    const target = $(event.target);
    if (target.hasClass('expand-row')) {
      if (target.hasClass('expand-row-active')) {
        targetEl.parent('tr').removeClass('row-expanded');
        target.removeClass('expand-row-active');
      } else {
        targetEl.parent('tr').addClass('row-expanded');
        target.addClass('expand-row-active');
      }
      event.stopPropagation();
      return;
    }
    if ((targetEl.hasClass('select-checkbox') || targetEl.hasClass('grid_context_menu')) && !AppConstants.isMobile) {
      return;
    }
    if ((targetEl.hasClass('select-checkbox') && contextMenuEl.hasClass('grid_context_menu_icon')) && AppConstants.isMobile) {
      this.onRowMenuOptions(event, info, row);
      return;
    }
    if (targetEl.hasClass('select-checkbox') && presstype === 'longpress' && AppConstants.isMobile) {
      if ($(event.currentTarget.parentElement).hasClass('selected')) {
        this.dtInstance.row(event.currentTarget.parentElement).deselect();
      } else {
        this.dtInstance.row(event.currentTarget.parentElement).select();
      }
      return;
    }


    /* if (this.gridConfig.type === 'snapshot') {
      this.showSnapshot(info);
      return;
    } */
    if (this.gridConfig.onRowClick) {
      const uniqueIdField = this.gridConfig.uniqueIdField ? this.gridConfig.uniqueIdField : 'sid';
      this.gridConfig.onRowClick(event, info[uniqueIdField]);
    }
  }

  public onContextMenu($event: any, item: any): void {
    setTimeout(() => {
      this.contextMenuService.show.next({
        anchorElement: $event.target,
        contextMenu: this.contextMenu,
        event: $event as any,
        item: null
      });
      $event.preventDefault();
      $event.stopPropagation();
    }, 100);

  }

  private onRowCallback(row: Node | any, data: any[] | object, index: number) {

    $('td', row).off('click');
    $('td', row).on('click', (event: any) => {
      this.rowClickHandler(data, event, row);
    });

    $('td', row).off('contextmenu');
    $('td', row).on('contextmenu', (event: any) => {
      event.preventDefault();
      this.rowClickHandler(data, event, row, 'longpress');
      event.stopPropagation();
    });

    /* $('td', row).on('', (event: any) => {
      console.log(data, event);
      this.rowClickHandler(data, event);
    }); */

    if (this.gridConfig.rowMenuOptions) {
      $('td .grid_context_menu_icon', row).off('click');
      $('td .grid_context_menu_icon', row).on('click', (e) => {
        if (this.gridConfig.beforeRowMenuShow) {
          this.currentRowOptions = $.extend(true, [], this.gridConfig.rowMenuOptions);
          for (const option of this.currentRowOptions) {
            const extendedProps = this.gridConfig.beforeRowMenuShow(option, data, row);
            this.clickedRowData = data;
            this.clickedRow = row;
            $.extend(option, extendedProps);
          }
        }
        this.onContextMenu(e, this.currentRowOptions);
      });
      $('div .grid_context_menu_icon', row).off('click');
      const that = this;
      $('div .grid_context_menu_icon', row).on('click', (e) => {
        /* if (this.gridConfig.beforeRowMenuShow) {
          this.currentRowOptions = $.extend(true, [], this.gridConfig.rowMenuOptions);
          for (const option of this.currentRowOptions) {
            const extendedProps = this.gridConfig.beforeRowMenuShow(option, data, row);
            this.clickedRowData = data;
            this.clickedRow = row;
            $.extend(option, extendedProps);
          }
        }
        this.onContextMenu(e, this.currentRowOptions); */
        that.onRowMenuOptions(e, data, row);
      });



      $('td .contextmenubtn', row).off('click');
      $('td .contextmenubtn', row).on('click', (e) => {
        e.preventDefault();
        this.clickedRowData = data;
        this.clickedRow = row;
        this.onRowMenuOptionClick(e, this.gridConfig.rowMenuOptions[0]);
        e.stopPropagation();
      });

    }

    if (AppConstants.isMobile) {
      if (this.gridConfig.mobileTemplate) {
        const detailTarget = $($(row).children('td')[0]).get(0);
        const componentName = this.gridConfig.mobileTemplate;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentName);

        const componentRef = componentFactory.create(this.injector, [], detailTarget);

        let dataSplit = {
          mainContent: {},
          innerContent: {}
        };
        this.gridConfig.columns.map((column, i) => {
          const tItem = (column.data.replace(/\.?([A-Z]+)/g, (x, y) => '_' + y.toLowerCase()).replace(/^_/, '')).toUpperCase();
          if (i < 3) {
            dataSplit.mainContent[tItem] = {
              data: this.getDataByType(column, data)
            };
          } else {
            dataSplit.innerContent[tItem] = {
              data: this.getDataByType(column, data)
            };
          }
        });

        if (this.gridConfig.mobileContent) {
          dataSplit = this.gridConfig.mobileContent(row, data, index, dataSplit);
        }
        (componentRef.instance as any).mainContent = dataSplit.mainContent;
        (componentRef.instance as any).innerContent = dataSplit.innerContent;
        (componentRef.instance as any).gridData = { data };
        (componentRef.instance as any).rowActions = this.gridConfig.rowMenuOptions;

        this.app.attachView(componentRef.hostView);
      } else {
        const mobileContent = this.drawMobileColumn(data);

        $($(row).children('td')[0]).html(mobileContent);
      }
    }
    return row;
  }
  public onRowMenuOptions(e: any, data: any, row: any) {
    if (this.gridConfig.beforeRowMenuShow) {
      this.currentRowOptions = $.extend(true, [], this.gridConfig.rowMenuOptions);
      for (const option of this.currentRowOptions) {
        const extendedProps = this.gridConfig.beforeRowMenuShow(option, data, row);
        this.clickedRowData = data;
        this.clickedRow = row;
        $.extend(option, extendedProps);
      }
    }
    this.onContextMenu(e, this.currentRowOptions);
  }
  public getDataByType(col, data) {
    const _data = data[col.data]
    if (col.type === 'date') {
      return this.util.formatDate(_data, col.format ? col.format : null);
    } else if (col.type === 'datetime') {
      return this.util.formatDateTime(_data, col.format ? col.format : null);
    } else if (col.numberFormat) {
      return col.numberFormat ? this.maskService.applyMask(_data.toString(), col.numberFormat) : _data;
    }

    return _data;
  }

  public drawMobileColumn(data) {
    const content = [];
    const innerContent = [];
    content.push('<div class="row mobile-row">');
    content.push('<em class="fa fa-chevron-down expand-row">&nbsp;</em>');

    let i = 0;
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        const addClass = i === 0 ? 'first-elem' : key === 'status' ? 'status-col' : i > 2 ? 'hidden-cols' : '';
        const toInsert = `<div class="col-6 mobile-cols ${addClass}"><span>${element}</span><label translate>${key}</label></div>`;

        if (i < 4) {
          content.push(toInsert);
        } else {
          innerContent.push(toInsert);
        }
        i++;
      }
    }

    content.push(`<div class='inner-div col-12'><div class='row'>${innerContent.join('')}</div></div>`);
    content.push('</div>');
    return content.join('');
  }

  public onRowMenuOptionClick(event: any, item: any) {
    if (this.gridConfig.onRowMenuClick && !item.disabled) {
      this.gridConfig.onRowMenuClick(item, this.clickedRow, this.clickedRowData);
    }
  }
  private resizeColumn(settings) {
    setTimeout(() => {
      const colStore = JSON.parse(localStorage.getItem(this.currentPage)) || {};
      const table = $(settings.nTableWrapper);
      let totWidth: number = 0;
      $.map(settings.aoColumns, (o, i) => {
        if (o.data && colStore[o.data] && Object.keys(colStore[o.data]).indexOf('size') > -1) {
          o.width = colStore[o.data].size;
        }
        if (Object.keys(o).indexOf('width') > -1)
          table.find('tr .' + o.data).css({ 'width': o.width + 'px' });
        
      });
      if (colStore['t-w']){
        table.find('table').width(colStore['t-w']);
        table.find('.dataTables_scroll .dataTables_scrollHeadInner').width(colStore['t-w']);
      }
      else{
        table.find('tr:nth-child(1) td').each((i, td)=>{
          totWidth = totWidth + ($(td).outerWidth());
        })
        table.find('table').width(totWidth);
      }
    })
  }
  private onDrawCallback(settings: any) {
    if (this.gridConfig.drawCallback) {
      this.gridConfig.drawCallback(settings);
    }
    this.showLoading = false;
    if (this.gridConfig.colResize)
      this.resizeColumn(settings);
  }

  private fireSearch(searchObj, type) {
    if (this.gridConfig.ajaxUrl) {
      this.params.search = {};
      this.params.search.value = (!Object.keys(searchObj).length
        || !Object.values(searchObj).length
        || !join(Object.values(searchObj)).length)
        ? JSON.stringify({})
        : JSON.stringify(searchObj);
      this.params.search.regex = false;

      if (this.searchInitiated[type] || type === 'adv') {
        this.getData(false, false, true);
      } else {
        this.searchInitiated[type] = true;
      }

    } else {
      if (this.dtInstance) {
        this.dtInstance.search(searchObj).draw();
      }
    }
  }

  private attachSearchEvents() {
    const gSearchSub = this.search.gSearchObj.subscribe(key => {
      const searchVal = {
        _global: key
      };
      this.fireSearch(searchVal, 'global');
    });
    this.subscriptions.push(gSearchSub);

    const advSearchSub = this.search.advSearchObj.subscribe(key => {
      if (!key)
        return
      const searchVal = key;
      this.fireSearch(searchVal, 'adv');
    });
    this.subscriptions.push(advSearchSub);

  }

  private showSnapshot(data: any) {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.dtTrigger.unsubscribe();
  }

  //
}
