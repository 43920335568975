import { Component, OnInit, forwardRef, Input, HostListener, ViewChild, ElementRef, AfterViewInit, Renderer } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import expressions from 'angular-expressions';
import { SearchService } from './search.service';
import 'rxjs/add/operator/withLatestFrom';
import 'rxjs/add/operator/map';
import { Subject, of } from 'rxjs';
import { element } from '@angular/core/src/render3';
import { UtilService } from '@app/core/util.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { BaseService } from '@app/core/base.service';
import { ApiConstants } from '@app/api-constants';
import { AppConstants } from '@custom/core/app-constants';

@Component({
  selector: 'app-vs-autocomplete',
  templateUrl: './vs-autocomplete.component.html',
  styleUrls: ['./vs-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VsAutocompleteComponent),
      multi: true
    }
  ]
})
export class VsAutocompleteComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input() url: string;
  @Input() options: Array<any>;
  @Input() displayField: string;
  @Input() valueField: string;
  @Input() id: string;
  @Input() placeholder: string;
  @Input() isDisabled: boolean | string = false;
  @Input() isMultiple: boolean | string = false;
  @Input() hasCheckbox: boolean | string = false;
  @Input() searchOnFocus: boolean | string = true;
  @Input() infiniteScroll: boolean | string = true;
  @Input() valueType: string;
  @Input() element: any;
  @Input() onResponse: any;
  

  // tslint:disable-next-line:variable-name
  private _model: any;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;
  showMenuOnLod: any;
  @ViewChild('singlequeryField') singlequeryFieldElem:ElementRef;
  

  results: Array<any> = [];
  displayFormatter;
  valueFormatter;
  selectedValue: string = null;
  multiSelectedValue: string = null;
  selectedValues: Array<any> = [];
  queryField: FormControl = new FormControl();
  showDropdown = false;
  disabled: any;
  parsedFields: any = null;
  displayValue: {};
  userKeyed = false;
  sortColumn: String = null;
  sortDirection: String = null;

  /**
   * default queryparams for search request
   */
  pgNo = 0;
  pgLen = 50;
  searchText: string;
  enableScroll: boolean;
  valuechages: boolean;
  showSpinner: boolean;
  searchSub: any;

  constructor(private searchService: SearchService, private utilService: UtilService, private notification: NotificationService,
    private renderer: Renderer, private elementRef: ElementRef, private baseService: BaseService) { }

  /* @HostListener('document:click', ['$event'])
  onclick(e: Event) {
    if (!(e.target as HTMLInputElement).closest('.vs-autocomplete')) {
      this.showDropdown = false;
      this.resetValues();
    }
  } */
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.userKeyed = true;
  }

  get model() {
    return this._model;
  }

  writeValue(value: any): void {
    this.setValuesToAutoComplete(value);
    if (!value && this.isMultiple) {
      value = [];
    }
    this._model = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  set(value: any) {
    this._model = value;
    this.onChange(this._model);
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setElementProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    this.disabled = isDisabled;
  }

  resetValues() {
    this.multiSelectedValue = null;
  }

  
  doubleqcheck(url, q){
    //url = this.frameQuery(url)
    if (url.indexOf('?') > -1) {
      q = '&' + q;
    } else {
      q = '?' + q;
    }
    return q;
  }

  attachListenerForValueChanges() {
    if (this.url) {
      this.queryField.valueChanges
        .debounceTime(200)
        .distinctUntilChanged()
        .switchMap((query) => {
          if(!this.userKeyed)
            return of({});
          this.searchText = query;
          this.pgNo = 0;
          let q = 'q=' + (this.searchText || '') + '&pgNo=' + this.pgNo + '&pgLen=' + this.pgLen;

          q = this.doubleqcheck(this.url, q);

          if (AppConstants.lookupSortField != null) {
            q += '&sortColumn=' + AppConstants.lookupSortField + '&sortOrder=' + AppConstants.lookupSortOrder;
          }
          this.showSpinner = true; this.showDropdown = true;
          return this.searchService.search(this.url, q);
        })
        .subscribe((result: any) => {
          this.showSpinner = false;
          if (result.status === 400) {
            return;
          }
          this.results = result.data || result;
          if (this.results && this.searchText && this.userKeyed) {
            this.results.forEach((option: any) => {
              if (typeof option[this.valueField] === "undefined" && typeof option['sid'] !== "undefined") {
                option.id = option.sid;
              }
            })
            this.listenForOutsideClick();
            this.userKeyed = false;
          }
        }, (result: any) => {         
          this.showSpinner = false;
        });
    } else if (this.options) {
      this.queryField.valueChanges
        .distinctUntilChanged()
        .subscribe((val: any) => {
          this.results = this.options.filter((option: any) => {
            const regex = new RegExp(val, 'gi');
            const values = Object.values(option);
            return (values.filter((value: any) => {
              return (value as string).match(regex);
            })).length > 0;
          });
          this.showDropdown = Boolean(this.results && this.results.length && this.userKeyed);
          if (this.showDropdown) {
            this.listenForOutsideClick();
          }
          this.userKeyed = false;
        });
    }
    return true;
  }

  validateNormalField(value): boolean {
    switch (this.valueType) {
      case 'email':
        return this.utilService.isValidEmail(value);
      case 'number':
        return this.utilService.isValidNumber(value);
      case 'url':
        return this.utilService.isValidUrl(value);
      default: return true;
    }
    //return true;
  }

  keyDown(event) {
    if (!this.valuechages)
      this.valuechages = this.attachListenerForValueChanges();
  }

  updateNormalField(event) {
    event.preventDefault();
    const value = event.currentTarget.value;
    if (this.validateNormalField(value)) {
      this.selectedValues.push(value);
      this.multiSelectedValue = '';
    } else {
      this.notification.error('Value not matching its field type');
    }
    event.stopPropagation();
  }

  attachListenerForResponse(type) {
    if (this.url) {
      this.searchText = type === 'focus' ? (this.selectedValue || "") : this.searchText;
      if(
        !this.searchText && 
        !this.isMultiple && 
        this.showMenuOnLod && 
        this.singlequeryFieldElem && 
        this.singlequeryFieldElem.nativeElement.value 
      ){
        this.searchText = this.singlequeryFieldElem.nativeElement.value;
      }
      this.pgNo = type === 'focus' ? 0 : this.pgNo + 1;
      let q = 'q=' + (this.searchText || '') + '&pgNo=' + this.pgNo + '&pgLen=' + this.pgLen;
      q = this.doubleqcheck(this.url, q);
      this.enableScroll = false;
      if (type !== 'scroll')
        this.results = [];
      this.showSpinner = true; this.showDropdown = true;
      if(this.searchSub){
        this.searchSub.unsubscribe();
      }
      this.searchSub = this.searchService.search(this.url, q)
        .subscribe((result: any) => {
          if (result.status === 400) {
            return;
          }
          this.showSpinner = false;
          if (result && result.length > 0) {
            if (this['onResponse']) {
              this['onResponse'](result);
            }
            result.map((option: any) => {
              if (typeof option[this.valueField] === "undefined" && typeof option['sid'] !== "undefined") {
                option.id = option.sid;
              }
            })
            this.listenForOutsideClick();
            this.results = this.results.concat(result);
            if (result.length === this.pgLen && this.infiniteScroll) {
              this.enableScroll = true;
            }
          }
        }, () => {
          this.showSpinner = false;
        });
    } else if (this.options) {
      this.results = this.options;
    }
    this.showDropdown = true;
    this.listenForOutsideClick();
  }

  updateModel() {
    this._model = this.selectedValues.map((val) => {
      // return this.formatValueField(val);
      return {
        id: this.formatValueField(val) || val.id,
        value: this.getAllLookupFieldValues(val)
        //value: this.getDisplayValues(val)
      };
    });

    console.log('updateModel ', this._model);
    this.showDropdown = false;
  }

  getDisplayValues(item) {
    const returnItem = {};
    if (this.parsedFields) {
      this.parsedFields.forEach(field => {
        returnItem[field] = item[field];
        return field;
      });
    }
    return returnItem;
  }

  getAllLookupFieldValues(item) {
    const retVal = {};
    if (item && item.value)
      item = item.value
    if (this.element.lookupFields) {
      this.element.lookupFields.forEach(field => {
        retVal[field] = item[field];
      });
    }
    return retVal;
  }

  selectItem(item) {
    if (this.isMultiple) {
      if(!item){
        this.searchText = ""
        return;
      }
      if (!this.checkForDuplicateItem(item)) {
        this.selectedValues.push(item);
        this.updateModel();
      }
    } else {
      this.selectedValue = this.formatDisplayName(item);
      this.displayValue = this.getDisplayValues(item);
      // this._model = this.formatValueField(item);
      this._model = {
        id: this.formatValueField(item),
        value: this.getAllLookupFieldValues(item)
        //value: this.displayValue
      };
      if(!item)
        this._model = null;
    }
    this.showDropdown = false;
    this.resetValues();
    this.onChange(this._model);
  }

  removeItemClick(e, item) {
    this.removeItem(item);
    e.stopPropagation();
  }

  removeItem(item) {
    this.selectedValues = this.selectedValues.filter((val) => {
      if (this.url || this.options) {
        return val['id'] !== item['id'];
      }
      return val !== item;
    });
    this.updateModel();
    this.onChange(this._model);
  }

  checkForDuplicateItem(item) {
    const uniqueField = (this.element && this.element.uniqueField) ? this.element.uniqueField : 'id';
    return Boolean((this.selectedValues.filter((val) => {
      return val[uniqueField] === item[uniqueField];
    })).length);
  }

  formatDisplayName = (option) => {
    if (this.url || this.options) {
      if(this.showMenuOnLod && (!option)){
        return this.searchText;
      }
      return this.displayFormatter((option && option.value) || option);
    }
    return option;
  }

  formatValueField = (option) => {
    if (this.url || this.options) {
      return this.valueFormatter(option);
    }
    return option;
  }

  handleFocus(event: Event) {
    const input = $(event.currentTarget).find('input');
    if (input.is(':disabled') || this.disabled)
      return;
    input.focus();
    input.off().on('blur', (e) => {
      setTimeout(() => {
        if (!this.isMultiple && this.showDropdown) {
          if (this.selectedValue === "") {
            this._model = null;
            this.onChange(this._model);
          } else
            this.selectedValue = this.formatDisplayName(this._model);
        }
        
        if (!$(e.target).closest('.vs-autocomplete').length){
            this.showDropdown = false;
        }
      });
      
    })
    if (!this.searchOnFocus) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    this.attachListenerForResponse('focus');
  }

  handleScroll = (e) => {
    const elem = e.target;
    if (this.enableScroll && elem.scrollTop + elem.clientHeight >= elem.scrollHeight - (elem.scrollHeight * 10 / 100)) {
      this.attachListenerForResponse('scroll');
    }
  }

  handleCheckboxChange = (e, item) => {
    if (e.currentTarget.checked) {
      this.selectItem(item);
    } else {
      this.removeItem(item);
    }
    this.showDropdown = true;
    this.listenForOutsideClick();
    e.stopPropagation();
  }

  isChecked = (item) => {
    if (!item) {
      return false;
    }
    const found = this.selectedValues.some((res) => {
      return item[this.valueField] === res[this.valueField];
    });

    return found;
  }

  fireSearchServiceToLoadData(item, type) {
    this.showSpinner = true; this.showDropdown = true;
    this.searchService.search(this.url, item).subscribe(res => {
      if (type === 'multiple') {
        this.selectedValues.push(res);
      } else {
        this.selectedValue = this.formatDisplayName(res);
      }
    }, () => {
      this.showSpinner = false; this.showDropdown = false;
    });
  }

  setLookupValueToField(item, type) {
    if (type === 'multiple') {
      if (!this.checkForDuplicateItem(item)) {
        this.selectedValues.push(item);
      }
    } else {
      this.selectedValue = this.formatDisplayName(item.value);
    }
  }

  setValuesToAutoComplete(items) {
    if (!items) {
      if (this.isMultiple && this.selectedValues && this.selectedValues.length > 0) {
        this.selectedValues = []
      } else if (this.selectedValue) {
        this.selectedValue = "";
      }
      return;
    }
    if (this.url) {
      if (this.isMultiple && typeof items === "object") {
        this.selectedValues = []
        items.forEach(item => {
          // this.fireSearchServiceToLoadData(item, 'multiple');
          this.setLookupValueToField(item, 'multiple');
        });
      } else {
        // this.fireSearchServiceToLoadData(items, 'single');

        this.setLookupValueToField(items, 'single');
      }
    } else if (this.options) {
      if (this.isMultiple) {
        this.options.forEach(option => {
          if (items.map(item=>item[this.valueField]).indexOf(option[this.valueField]) > -1) {
            this.selectedValues.push(option);
          }
        });
      } else {
        this.options.forEach(option => {
          if (items[this.valueField] === option[this.valueField]) {
            this.selectedValue = this.formatDisplayName(option);
          }
        });
      }
    } else {
      this.selectedValues = items;
    }
    setTimeout(() => {
      this.showDropdown = false;
    }, 500);
  }

  getParsedExpression(field) {
    if (!field) { return; }
    const parsed = field.matchAll(/([a-zA-Z0-9_]*)+/g);
    const keys = [];
    let done = false;
    while (!done) {
      const cur = parsed.next();
      done = cur.done;
      if (cur.value && cur.value[0]) {
        keys.push(cur.value[0]);
      }
    }
    return keys;
  }

  listenForOutsideClick() {
    $(document).off().on('click', (e) => {
      console.log($(e.target));
      
      if (!$(e.target).closest('.vs-autocomplete').length) {
        this.showDropdown = false;
        this.resetValues();
        $(document).off('click');
      }
    });
  }

  ngOnInit() {
    this.searchOnFocus = this.searchOnFocus === undefined ? true : this.searchOnFocus;
    this.infiniteScroll = this.infiniteScroll === undefined ? true : this.infiniteScroll;
    this.disabled = this.isDisabled;
    this.showMenuOnLod = this.element.showMenuOnLod;
    if (this.url || (this.options && this.options.length)) {
      this.parsedFields = this.getParsedExpression(this.displayField);
      this.displayFormatter = expressions.compile(this.displayField);
      this.valueFormatter = expressions.compile(this.valueField);
    } else {
      //this.updateNormalField = new Subject();
    }
    // this.attachListenerForValueChanges();
    this.showDropdown = false;

    if (AppConstants.lookupSortField != null && AppConstants.lookupSortOrder != null) {
      this.sortColumn = AppConstants.lookupSortField;
      this.sortDirection = AppConstants.lookupSortOrder;
    }

  }

  ngAfterViewInit() {
  }

}
