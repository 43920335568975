import { Component, OnInit, forwardRef, Input, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Options } from 'ng5-slider';

@Component({
  selector: 'vs-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true
    }
  ]
})
export class SliderComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() element: any = {};

  private _model: any;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;

  sValue: any;
  isEnabled: boolean;
  value: number;
  options: Options = {
    floor: 0,
    ceil: 1,
    step: 1,
    showTicks: true,
    showTicksValues: true,
    disabled: false
  };

  constructor() { }


  get model() {
    return this._model;
  }

  writeValue(value: any): void {
    console.log('write value: ', value);

    this.setValueToField(value);
    this._model = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  set(value: any) {
    this._model = value;
    this.onChange(this._model);
  }

  setValueToField(value) {
    if (value) {
      this.value = value;
    }
  }

  onSChange = (event) => {
    this._model = event;
    this.onChange(this._model);
  }

  setUserOptions() {
    if (this.element && this.element.validations) {
      if (this.element.validations.min_value) {
        this.options.floor = this.element.validations.min_value;
      }
      if (this.element.validations.max_value) {
        this.options.ceil = this.element.validations.max_value;
      }
      if (this.element.step) {
        this.options.step = this.element.step;
      }
      if (this.element.disabled) {
        this.options.disabled = this.element.disabled;
      }
    }
    if (this.element.sliderOptions) {
      this.options.step = this.element.sliderOptions.step ? this.element.sliderOptions.step : this.options.step;
      this.options.showTicks = this.element.sliderOptions.showTicks ? this.element.sliderOptions.showTicks : this.options.showTicks;
      this.options.showTicksValues = this.element.sliderOptions.showTicksValues ? this.element.sliderOptions.showTicksValues : this.options.showTicksValues;
    }
  }

  ngOnInit() {
    this.setUserOptions();
  }

  ngAfterViewInit(): void {


  }

}
