import { Component, OnInit, Input} from '@angular/core';
import { CustomService } from '@custom/services/custom.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { AppGlobalService } from '@app/app-global.service';

@Component({
	selector: 'app-product-status',
	templateUrl: './product-status.component.html',
	styleUrls: ['./product-status.component.scss'],
})

export class ProductStatusComponent implements OnInit {

	@Input() currentForm: any;
	@Input() currentElement: any;

    constructor(
        private customService: CustomService,
        public sanitizer: DomSanitizer,
        public appGlobalService: AppGlobalService
    ) {}

    public color = {
        "IN_DEVELOPMENT": "#3B7DDD",
        "LAUNCHED": "#28A745",
        "END_OF_PRODUCTION": "#6F42C1",
        "END_OF_SALE": "#FD7E14",
        "END_OF_SUPPORT": "#DC3545"
      }

    public valueLabelMap = {
        "IN_DEVELOPMENT" : 'In Development',
        "LAUNCHED" : 'Launched',
        "END_OF_PRODUCTION" : 'End of Production',
        "END_OF_SALE" : 'End of Sale',
        "END_OF_SUPPORT": 'End of Support'
    }

    public items = [
        {name: 'IN_DEVELOPMENT', hex: this.color['IN_DEVELOPMENT']},
        {name: 'LAUNCHED', hex: this.color['LAUNCHED']},
        {name: 'END_OF_PRODUCTION', hex: this.color['END_OF_PRODUCTION']},
        {name: 'END_OF_SALE', hex: this.color['END_OF_SALE']},
        {name: 'END_OF_SUPPORT', hex: this.color['END_OF_SUPPORT']}
    ];
    public selectControl = new FormControl();

    ngOnInit() {
        this.selectControl.setValue('IN_DEVELOPMENT');
        this.customService.registerProductStatusObj(this);
        this.selectControl.valueChanges.subscribe(value=> {
            this.currentForm.patchValue({productStatus: value});
        })

        this.currentForm.get('productStatus').valueChanges.subscribe(value => {
            if(!value){
                value = 'IN_DEVELOPMENT';
            }
            if(this.selectControl.value !== value){
                this.setProductStatus(value);
            }
        })
    }

    public getProductStatus(): string {
        return this.selectControl.value;
    }

    public setProductStatus(status: string) {
        this.selectControl.setValue(status);
    }

    public style(data: string): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(data);
    }
}

