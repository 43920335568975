import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShoppingcartService } from '../shoppingcart.service';

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss']
})
export class CompareComponent implements OnInit, AfterViewChecked {

  cartConfig: any = {};

  keys: any[] = [];
  products: any[] = [];
  init = false;
  compareOrder = [];
  currency = 'USD';

  constructor(
    public activeModal: NgbActiveModal,
    private sService: ShoppingcartService
  ) { }

  extractKeys(product) {
    this.keys = Object.keys(product);
    console.log(this.keys);
  }

  constructModel(products) {
    const arr = [];
    for (let i = 0; i < this.keys.length; i++) {
      const key = this.keys[i];
      var obj = {
        key
      }
      for (let j = 0; j < products.length; j++) {
        const product = products[j];
        obj[product.sid] = product[key];
      }
      arr.push(obj);
    }
    console.log(arr);
    this.products = arr;
  }

  getProductsToCompare() {
    this.sService.compareChanges.subscribe(d => {

      this.extractKeys(d[0]);
      //this.constructModel(d);
      this.products = d;
    });
  }

  goToProductsList() {
    if (this.activeModal) {
      this.activeModal.dismiss();
    }
    //this.sService.changeNavigation('productslist');
  }

  setCompareOrder() {
    let order = ["price", "surfaceComposition", "finish", "thickness", "opacity", "applications", "inkTechnology", "minimumOrder", "maximumOrder", "availableQty", "samplesAvailable"];
    if (this.cartConfig && this.cartConfig.callbacks && this.cartConfig.callbacks.setCompareOrder) {
      order = this.cartConfig.callbacks.setCompareOrder(order);
    }
    return order;
  }


  listenForCurrencyChanges(){
    this.sService.currencyChanges.subscribe(d => {
      this.currency = d;
    });
  }
  ngOnInit() {
    this.listenForCurrencyChanges();
    this.compareOrder = this.setCompareOrder()
    this.getProductsToCompare();
  }


  ngAfterViewChecked(): void {
    if (!this.init) {
      const options: any = {
        "sDom": "t",
        "ordering": false
      };
      //$('#tbl').DataTable(options);
      this.init = true;
    }
  }

}
