import { Injector } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { CustomService } from "@custom/services/custom.service";

export function multipleValidator(multiple:number): ValidatorFn {

    let injector = Injector.create([ { provide: CustomService, useClass:CustomService,deps: []}])
    let customService = injector.get(CustomService);
    
    return (control: AbstractControl): ValidationErrors | null => {
        let invalid = false;
        let errorKey = 'multiple';

        let inputValue = control.value;
        if(typeof inputValue == 'number' && inputValue !== 0 && ! customService.isMultiple(inputValue, multiple)){
            invalid = true;

        }

        return invalid ? { ['multiple'] : {value: inputValue}} : null;
    };
}