import { Injectable } from '@angular/core';
import { CustomUtilsService } from '@custom/services/custom-utils.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConstants } from '@custom/core/app-constants';

@Injectable({
  providedIn: 'root'
})
export class CustomFileUploaderService {

  constructor(private http: HttpClient) { }

  public uploadFile(files: any, url: string, formParams: any, isPut = false) {
    const headers = new HttpHeaders({
      'Content-Type': null
    });
    const options = {
      headers
    };
    if (!url) {
      url = `${AppConstants.apihost}/attachments/upload`;
    }
    const formData = new FormData();
    for (let key of Object.keys(files)) {
      if (files[key]){
        if(Array.isArray(files[key])){
          files[key].forEach(file => {
            formData.append(key, file);       
          });
        }else{
          formData.append(key, files[key]); 
        }
      }

    }
    for (let key of Object.keys(formParams)) {
      formData.append(key, formParams[key]);
    }
    if(isPut){
      return this.http.put(
        url,
        formData
      );
    }else{
      return this.http.post(
        url,
        formData
      );
    }
    
  }
}
