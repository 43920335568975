import { Component, OnInit, Input } from '@angular/core';
import { CustomService } from '@custom/services/custom.service';

@Component({
	selector: 'app-duns-ownership-insights-level-2',
	templateUrl: './duns-ownership-insights-level-2.component.html',
	styleUrls: ['./duns-ownership-insights-level-2.component.scss']
})
export class DunsOwnershipInsightsLevel2Component implements OnInit {

	@Input() currentForm: any;
	@Input() currentElement: any;
	data : any = {};
	constructor(
		private customService: CustomService,
	) { }

	ngOnInit() {
		this.currentForm.valueChanges.subscribe(data => {
			if(this.currentForm.value.dunsOwnershipInsightsLevel2){
				this.data = JSON.parse(this.currentForm.value.dunsOwnershipInsightsLevel2);
			}
		});
		this.customService.registerDunsOwnerInsigtsLevel2Component(this);
	}

	public setValue(value: string) {
		if(value){
			this.data = JSON.parse(value);
		}
    }
}
