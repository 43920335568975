import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { VsMenuItem } from './vs-menu-item';
import { BreadcrumbInterfaceService } from './breadcrumb-interface.service';



@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, AfterViewChecked {
  static readonly ROUTE_DATA_BREADCRUMB = 'label';
  readonly home = { icon: 'pi pi-home', url: '#/home' };
  public menuItems: any[] = [];
  private pageId: string = '';

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private translate: TranslateService, private bcService: BreadcrumbInterfaceService) {
    this.watchRouteChanges();
    translate.use('en');
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs: VsMenuItem[] = []): VsMenuItem[] {

    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      let routeURL: string;
      routeURL = child.snapshot.url.map(segment => segment.path).join('/');

      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const usePageId = child.snapshot.data.usePageId ? true : false;

      let label = (child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB] ? 
                  child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB] : 
                  child.snapshot.routeConfig.path).toUpperCase();
      
      this.translate.get(label).subscribe(l => {
        if (!isNullOrUndefined(label)) {
          breadcrumbs.push({ label: l, url, usePageId });
        }
      })

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  watchRouteChanges() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
      });
  }

  watchBreadcrumbChanges() {
    this.bcService.pageIdChanges.subscribe(d => {
      if (this.menuItems.length) {
        const menuItems = this.menuItems.map(mi => {
          if (mi.usePageId) {
            mi.label = d;
          }
          return mi;
        });
        this.menuItems = menuItems;
      } else {
        this.pageId = d;
      }
    });
  }
  watchBreadcrumbConfChanges() {
    this.bcService.breadcrumbCustomConfChanges.subscribe(item => {
      this.menuItems;
      if(item){
        if(this.menuItems && this.menuItems.length){
  
          this.menuItems.forEach((br, index) => {
            const newItem = item[index];
            if(
              newItem.label != br.label || 
              newItem.url != br.url || 
              newItem.usePageId != br.usePageId
            ){
              Object.assign(br, newItem);
            }
          });
        }

      }else{
        this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
      }
    });
  }

  ngOnInit() {
    
  }

  ngAfterViewChecked(): void {
    this.watchBreadcrumbChanges();
    this.watchBreadcrumbConfChanges();
  }

}
