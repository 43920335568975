import { Component, OnInit } from '@angular/core';
import { BaseService } from '@app/core/base.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss']
})
export class PrivacyModalComponent implements OnInit {

  constructor(private baseService: BaseService,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  scrollHandler(e){
    const element = e.target;
    let scrollY = $(e.target).scrollTop();
    let scrollerWidth = $('#id1_1').height();
    
    if((element.scrollHeight - Math.ceil(element.scrollTop + 20)) <= element.clientHeight){
        $('.terms-accept').removeAttr('disabled');
    }
}
  public acceptTerms(...args){
    this.activeModal.dismiss();
    const serviceOpts = {
        url: 'appuserprivileges/acceptterms',
        method: 'POST',
        showloading: true,
        showMessage : false
      };
      const params= args[0];
       new Observable(observer => {
        this.baseService[ serviceOpts.method.toLowerCase() ](serviceOpts,params).subscribe((response) => {
          observer.next(response);
          observer.complete();
        },
        (err) => {
          observer.error(err);
        });
      }).subscribe();
}
}
