import { BaseAppConstants } from '@app/base-app-constants';
/**
 * Write all your custom app constants here
 */
export class AppConstants extends BaseAppConstants {
    public static dateFormat = 'dd-MMM-yyyy';

    /** Lookup sorting */
	public static lookupSortField = 'name';
	public static lookupSortOrder = 'ASC';
    public static dateTimeFormat = 'dd-MMM-yyyy hh:mm a';
    public static EU_COUNTRIES =["Austria", "Italy", "Belgium", "Latvia",
    "Bulgaria", "Lithuania", "Croatia", "Luxembourg", "Cyprus", "Malta", "Czechia", "Netherlands", "Denmark",
    "Poland", "Estonia", "Portugal", "Finland", "Romania", "France", "Slovakia", "Germany", "Slovenia",
    "Greece", "Spain", "Hungary", "Sweden", "Ireland"];
    public static notificationTimeInterval = 10000;
}