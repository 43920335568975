import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VsFormComponent } from '../vs-form/vs-form.component';
import { UtilService } from '@app/core/util.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnInit {
  @Output() saveModalData: EventEmitter<any> = new EventEmitter();
  @Input() modaldata;
  @ViewChild(VsFormComponent) formComp: VsFormComponent;

  public modalheader = '';
  public message = '';
  public modalformConfig: any;
  public dataChanged: any;
  public diableButton: any;
  primaryBtnText: any;
  secondaryBtnText: any;
  buttons:any = {};
  public scrollLock;
  scrollEnded = false;

  constructor(public activeModal: NgbActiveModal, public util: UtilService, private elementRef: ElementRef) { }

  savePopup() {
    const submitButton: HTMLButtonElement = this.formComp.submitButton.nativeElement;
    submitButton.click();
    if (!this.formComp.form.valid) {
      return;
    }
    this.saveModalData.emit(this.formComp.form.value);
    this.activeModal.dismiss(true);
  }
  closePopup() {
    this.saveModalData.emit(false);
    this.activeModal.dismiss(false);
  }

  okConfirmPopup() {
    this.saveModalData.emit(true);
    this.activeModal.dismiss('Closed');

  }

  closeConfirmPopup() {
    this.saveModalData.emit(false);
    this.activeModal.dismiss('Closed');
  }

  ngOnInit() {
  }
  scrolling(event){
    if(event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight){
      this.scrollEnded = true;
    }
  }

}
