import { Component, OnInit, Input } from '@angular/core';
import { CustomService } from '@custom/services/custom.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { AppGlobalService } from '@app/app-global.service';

@Component({
	selector: 'app-colorbase-status',
	templateUrl: './colorbase-status.component.html',
	styleUrls: ['./colorbase-status.component.scss']
})

export class ColorbaseStatusComponent implements OnInit {

	@Input() currentForm: any;
	@Input() currentElement: any;

    constructor(
        private customService: CustomService,
        public sanitizer: DomSanitizer,
        public appGlobalService: AppGlobalService
    ) {}
    public selectControl = new FormControl();

    getOptions(){
        let options = Object.values(this.currentElement.options)
        return options.filter((option :any)=>!option.hidden)
    }
    
    ngOnInit() {
        this.customService.registerColorBaseStatusObj(this);
        this.selectControl.valueChanges.subscribe(value=> {
            this.currentForm.patchValue({colorbaseStatus: value});
        })

        this.currentForm.get('colorbaseStatus').valueChanges.subscribe(value => {
            if(this.selectControl.value !== value){
                this.setColorBaseStatus(value);
            }
        })

        // let user = this.appGlobalService.get('currentUser');

        // if(!user.devadmin && !user.ccSystemAdmin && !user.ccContentModerator) {
        //     this.statusDisabled = true;
        // }
    }

    public getColorBaseStatus(): string {
        return this.selectControl.value;
    }

    public setColorBaseStatus(status: string) {
        this.selectControl.setValue(status);
    }

    public style(data: string): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(data);
    }
}

