import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { type } from 'os';
import { CustomElementService } from '../custom-element.service';

@Component({
	selector: 'app-form-builder-dynamic-options',
	templateUrl: './form-builder-dynamic-options.component.html',
	styleUrls: ['./form-builder-dynamic-options.component.scss'],
})
export class FormBuilderDynamicOptionsComponent implements OnInit {

	@Input() currentForm: any;
	@Input() currentElement: any;
	errorMessage:any = '';
	isErrorBoolean:any = false;
	disabled = false
	onPasteEvent = false;
	placeholder = 'Add Options';
	optionsConfig = [];

	testData = {
		subrateType1 : 'subrateType1,subrateType5',
		subrateType2 : 'subrateType2',
		subrateType3 : 'subrateType3',
		subrateType4 : 'subrateType4'
	}

	constructor(
		private custElementService : CustomElementService,
		private translateService : TranslateService
	) { }

	ngOnInit() {
	//	this.custElementService.fbOptionsComponent = this;
		const _that = this;
		this.custElementService.componentFactory = {
			name : _that.currentElement.name,
			comp : _that
		};

		//this.createOptionsConfig();
		//setTimeout(()=>{this.setValue()},50)
		
	}

	createOptionsConfig(data){
		if(data){	
			
			try{
				data.forEach(item => {
					this.optionsConfig.push({
						[item.key] : item.options,
						label : item.key,
						subLabelText : 'POSSIBLE_VALUES',
						name : item.key,
					})
				});
			}catch(e){
			}
		}
		
	}
	
	setValue(value?:any){
		this.optionsConfig.splice(0);
		this.createOptionsConfig(value);
		setTimeout(()=>{
			this.optionsConfig.forEach(item => {
				this.custElementService.componentFactory[item.name].setValue(item[item.name]);
			});
		},50);
	}
	setError(value){
		this.errorMessage = value;
		this.isErrorBoolean = typeof this.errorMessage == 'boolean';
	}
	getValue(){
		const values = [];
		this.optionsConfig.forEach(item => {
			if( this.custElementService.componentFactory[item.name]){
				values.push({
					key: item.name,
					options : this.custElementService.componentFactory[item.name].getValue().join(',') || null
				});
			}
		});

		return values;
	}

	setDisabled(value){
		this.disabled = value;
	}



	
}
