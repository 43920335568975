import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[tableRequired]',
  providers: [{ provide: NG_VALIDATORS, useExisting: TableRequiredDirective, multi: true }]
})
export class TableRequiredDirective implements Validator {
  @Input()
  tableRequired: number;

  validate(c: FormControl): { [key: string]: any } {
    const v = c.value;

    c.valueChanges.subscribe(val => {
      let existingErrors = c.errors ? c.errors : {};
      let currentError = this.checkAndSetError(val);
      existingErrors = this.getUpdatedError(currentError, existingErrors);
      c.setErrors(existingErrors);
    });

    // tslint:disable-next-line:object-literal-key-quotes
    let existingErrors = c.errors ? c.errors : {};
    let currentError = this.checkAndSetError(v);
    return this.getUpdatedError(currentError, existingErrors);
  }

  checkAndSetError(val) {
    if (this.tableRequired != null && this.tableRequired && (val == null || val.length == 0)) {
      return {required:true};
    }else{
      return {required:false};
    }
  }

  getUpdatedError(cutomError: { required: boolean; } | { required?: undefined; }, existingErrors) {
    if (cutomError.required) {
      existingErrors.required = true;
    } else {
      if (existingErrors.required) {
        delete existingErrors.required;
      }
    }
    if (Object.keys(existingErrors).length == 0) {
      existingErrors = null;
    }
    return existingErrors;
  }
}
