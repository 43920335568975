import { Injectable } from '@angular/core';
import { AppGlobalService } from '@app/app-global.service';
import { AuthenticationService } from '@app/auth/authentication.service';
import { BaseService } from '@app/core/base.service';
import { envInfo } from 'firebase/firebase';
import { BehaviorSubject, config, Observable } from 'rxjs';
import { CustomUtilsService } from './custom-utils.service';

@Injectable({
  providedIn: 'root'
})

export class TestSutieServices {

  public readonly TEXT_CONST = {
    DEF_LAB_SEC  : 'General Setup',
    DEF_OVERSCORE_SEC  : 'Conclusion',
    CERTIFICATION_RESULT : 'Certification Result', 
    PUBLISHED_IN_MSL : 'Published in MSL', 
    MSL_ID : 'MSL ID'
  };

  private formNodesSubject = new BehaviorSubject('');
  public formNodesObserver = this.formNodesSubject.asObservable();
  public formNodes: any;

  private testDataSubject = new BehaviorSubject('');
  public testDataObserver = this.testDataSubject.asObservable();
  public testData: any;

  constructor(
    private customUtilServices: CustomUtilsService,
    public auth: AuthenticationService,
    public appGlobalService: AppGlobalService,
    public baseServices: BaseService
  ) { }

  public setFormNodes(formNodes: any) {
    this.formNodes = formNodes;
    this.formNodesSubject.next(formNodes);
  }

  public settestData(testData: any) {
    this.testData = testData;
    this.testDataSubject.next(testData);
  }
  /**
   * Generated a field id for the given name by appending a UUID at the end of the name
   * @param name name of the node
   * @returns id of the field with UUID appended
   */
  public getFormNodeId(name) {
    return (name.toLowerCase() + "_" + this.customUtilServices.getUniqueId(5)).replace(/^[0-9]+/, "_").replace(/[^a-zA-Z_0-9]/g, "_");
  }

 
  isTestSuiteDisabled(): boolean {
    const currentUser: any = this.auth.getcurrentUser();
    return !currentUser.devadmin && !currentUser.ccSystemAdmin && !currentUser.colorbaseLabsAdmin && !currentUser.colorbaseLabsEngineer
  }
  isTestSuiteEditDisabled(testsuite:any = {}, isHPUser = false): boolean {
    const currentUser: any = this.auth.getcurrentUser();

    if(testsuite.shareWithCC){
      if(isHPUser){
        return false
      }
      return true
    }else if(currentUser.devadmin || currentUser.ccSystemAdmin || currentUser.colorbaseLabsAdmin || (currentUser.colorbaseLabsEngineer && !testsuite.template)){           
        return false;
    }

    return true;
  }

  isSharedTestSuiteForCC(testsuite:any){
    if(testsuite.shareWithCC){
     return true 
    }
    return false;
  }

  isTestSuiteTemplateDisabled(): boolean {
    const currentUser: any = this.auth.getcurrentUser();
    return !currentUser.devadmin && !currentUser.ccSystemAdmin && !currentUser.colorbaseLabsAdmin;
  }
  isTestSuiteChangeLogDisabledDisabled(): boolean {
    const currentUser: any = this.auth.getcurrentUser();
    return !currentUser.devadmin && !currentUser.ccSystemAdmin;
  }

  isTestSuitePerformDisabled(): boolean {
    const currentUser: any = this.auth.getcurrentUser();
    return (!currentUser.devadmin && !currentUser.ccSystemAdmin && !currentUser.colorbaseLabsAdmin 
      && !currentUser.colorbaseLabsEngineer && !currentUser.colorbaseLabsTechnician)
  }
  isShareWithCCDisabled(isHPUser): boolean {
    const currentUser: any = this.auth.getcurrentUser();
    return !(currentUser.colorbaseLabsAdmin && isHPUser)   
  }

  public performTestSuite(testSuiteId : string): any {
    const serviceOpts= {
      url: 'testsuites/{testSuiteId}/perform',
      method: 'POST',
      showloading: true,
      showMessage: true
    };
    let params = {
      testSuiteId : testSuiteId
    }
    const subject = new Observable(observer => {
      this.baseServices[ serviceOpts.method.toLowerCase() ](serviceOpts,params).subscribe((response) => {
        observer.next(response);
      },
      (err) => {
        observer.error(err);
      });
    });
    return subject;
  }

  getOrganization(orgType, orgTypeToAvoid = 'NIL'){
		let defaultOrg:any = ''
		const user = this.appGlobalService.get('currentUser');
		if(user.organization && user.organization && user.organization.value){
			const organizationTypes = user.organization.value.organizationTypes || [];
			if(organizationTypes.includes(orgType) && !organizationTypes.includes(orgTypeToAvoid)){
				defaultOrg = user.organization;
			}
		}
		return defaultOrg
	}
  filterOverallScoreOnOrg(fieldConfigs, isHpOrganization, isShared){
    
    const hpSpecificFields = [
      this.TEXT_CONST.CERTIFICATION_RESULT,
      this.TEXT_CONST.PUBLISHED_IN_MSL,
      this.TEXT_CONST.MSL_ID,
    ];

    if(fieldConfigs[0] && fieldConfigs[0].children && fieldConfigs[0].children.length && !isHpOrganization && !isShared){
      fieldConfigs[0].children = fieldConfigs[0].children.filter(item => !hpSpecificFields.includes(item.name))
    }

    return fieldConfigs;
  }

 
  isTestSuiteAvailableForThisAccount(){
    return true;
  }
  isShareWithCCVisible(testsuite:any = {},isHPUser = false){
    if(isHPUser || testsuite.shareWithCC){
      return true;
    }

    return false;
  }
  isTestResultAvailableForThisAccount(){
    return true;
  }

  getProtoTypeData(){
    return {
      "sid": "0073ab56-3dec-4d3d-8891-e31cd98cdb7a",
      "createdBy": "SYSTEM",
      "createdDate": 1662109422201,
      "modifiedBy": "SYSTEM",
      "modifiedDate": 1662109440533,
      "recDeleted": false,
      "id": 221,
      "testSuite": {
          "id": "68cc0615-47b2-4a4d-945f-9c0f2a8f5ba0",
          "value": {
              "testSuiteCode": 97,
              "name": "concurrent testing",
              "version": 5
          }
      },
      "testerOrganization": {
          "id": "5e6bca1d-0cb5-4bd3-8dcb-bea3152e8faf",
          "value": {
              "organizationTypes": [
                  "EXCHANGE_SELLER",
                  "PROFILING_LAB",
                  "COLORBASE_LABS_SUBSCRIBER"
              ],
              "tradingName": "",
              "legalName": "Interpol",
              "displayName": "Interpol",
              "id": 37
          }
      },
      "ownerOrganization": {
          "id": "c7f8aa0e-ebef-4ffc-9aab-edb26598f7b9",
          "value": {
              "legalName": "Color Concepts for NA",
              "organizationTypes": [
                  "COLORBASE_LABS_SUBSCRIBER",
                  "EXCHANGE_ADMIN"
              ],
              "tradingName": "",
              "displayName": "Color Concepts for NA",
              "id": 64
          }
      },
      "completionStatus": "INCOMPLETE",
      "performedBy": "spavithra@vanenburg.com",
      "performedByFirstName": "Pavithra",
      "performedByLastName": "S",
      "performedDate": 1662109421838,
      "ownerDummy": false,
      "testerDummy": false,
      "userModifiedBy": "spavithra@vanenburg.com",
      "userModifiedDate": 1662109440501,
      "userModifiedByFirstName": "Pavithra",
      "userModifiedByLastName": "S",
      "shareWithCC": false,
      "performedOrganization": "5e6bca1d-0cb5-4bd3-8dcb-bea3152e8faf",
      "inShareWithCC": false,
      "resultData": {
          "comments": {},
          "data": {
              "lab_68a97e74_1327_4ef5_9395_d6f9d6e1e901": {
                  "id": "327f8c45-dbdd-4de5-beeb-a8fb5ed2048f",
                  "value": {
                      "id": 25,
                      "labName": "Test Printer"
                  }
              },
              "printer_0d1ee356_a0c9_40da_a3bc_02120ede0a3b": {
                  "id": "50820e94-5e3d-40ad-8b2f-651af4bc1622",
                  "value": {
                      "jid": "50820e94-5e3d-40ad-8b2f-651af4bc1622",
                      "manufacturer": {
                          "id": "862ba99b-0a3e-4ba4-8bbb-40436fa98c69",
                          "value": {
                              "displayName": "test org 1"
                          }
                      },
                      "printerModel": {
                          "id": "a20af85c-3f6c-4a78-a75f-252cf4c08dd3",
                          "value": {
                              "name": "Latex 800 W"
                          }
                      }
                  }
              },
              "material_manufacturer_690fdfc3_d346_431d_92f3_13ab8dd3595d": {
                  "id": "3294f16d-32cc-4c5f-8d03-9b98ef6a0681",
                  "value": {
                      "id": 1,
                      "legalName": "VB",
                      "displayName": "Trading VB2",
                      "tradingName": "Trading VB2",
                      "organizationTypes": [
                          "EXCHANGE_BUYER",
                          "MATERIAL_MANUFACTURER"
                      ]
                  }
              },
              "material_20d44b60_fd51_4103_99aa_43edcc7bca8a": {
                  "id": "092891a4-fd1a-4e38-b76c-07df8ca23442",
                  "value": {
                      "id": 22,
                      "productModelName": "sedfsdf"
                  }
              },
              "test_purpose_d06cd3fd_a345_49b6_b125_a577688358df": "Investigation",
              "drop_8eb0f409_82a1_4d1d_bf16_e833eccc8eb5": "aa",
              "multiple_dropdown_27bdf707_1229_4591_83f5_f19dc7e052bd": "bb2",
              "field_1_7d7aa5d5_19f9_43ce_9604_1115c9e909c0": "",
              "score_4c98d64e_e065_42f0_bf26_4d7e777e3727": "",
              "comment_ca29796b_5237_4483_976c_604fae8e30f2": "",
              "attachment_e7054a30_c7a7_4eb3_811e_a61405b1209b": ""
          },
          "attachments": {}
      },
      "formNodes": [
          {
              "id": "general_setup_f0a13a86_5a54_4629_8742_29ff681c879b",
              "type": "SECTION",
              "name": "General Setup",
              "fieldType": null,
              "lookupTable": null,
              "possibleValues": null,
              "required": false,
              "attachments": [],
              "description": "The lab where the test suite is performed and the individual printer and material it is performed with.",
              "repeatable": false,
              "defaultNode": true,
              "children": [
                  {
                      "id": "lab_68a97e74_1327_4ef5_9395_d6f9d6e1e901",
                      "type": "FIELD",
                      "name": "Lab",
                      "fieldType": "LOOKUP",
                      "lookupTable": "LABS",
                      "possibleValues": null,
                      "required": true,
                      "attachments": [],
                      "description": "",
                      "repeatable": false,
                      "defaultNode": false,
                      "children": [],
                      "repetitionIndex": null,
                      "rows": null,
                      "columns": null,
                      "cellType": null,
                      "min": null,
                      "max": null,
                      "repeatedFromNode": null,
                      "parentField": null,
                      "multiLevelDropDownValues": null,
                      "multiple": null
                  },
                  {
                      "id": "printer_0d1ee356_a0c9_40da_a3bc_02120ede0a3b",
                      "type": "FIELD",
                      "name": "Printer",
                      "fieldType": "LOOKUP",
                      "lookupTable": "LAB_PRINTERS",
                      "possibleValues": null,
                      "required": true,
                      "attachments": [],
                      "description": "",
                      "repeatable": false,
                      "defaultNode": false,
                      "children": [],
                      "repetitionIndex": null,
                      "rows": null,
                      "columns": null,
                      "cellType": null,
                      "min": null,
                      "max": null,
                      "repeatedFromNode": null,
                      "parentField": null,
                      "multiLevelDropDownValues": null,
                      "multiple": null
                  },
                  {
                      "id": "material_manufacturer_690fdfc3_d346_431d_92f3_13ab8dd3595d",
                      "type": "FIELD",
                      "name": "Material Manufacturer",
                      "fieldType": "LOOKUP",
                      "lookupTable": "ORGANIZATION",
                      "possibleValues": null,
                      "required": true,
                      "attachments": [],
                      "description": "",
                      "repeatable": false,
                      "defaultNode": false,
                      "children": [],
                      "repetitionIndex": null,
                      "rows": null,
                      "columns": null,
                      "cellType": null,
                      "min": null,
                      "max": null,
                      "repeatedFromNode": null,
                      "parentField": null,
                      "multiLevelDropDownValues": null,
                      "multiple": null
                  },
                  {
                      "id": "material_20d44b60_fd51_4103_99aa_43edcc7bca8a",
                      "type": "FIELD",
                      "name": "Material",
                      "fieldType": "LOOKUP",
                      "lookupTable": "MATERIAL",
                      "possibleValues": null,
                      "required": true,
                      "attachments": [],
                      "description": "",
                      "repeatable": false,
                      "defaultNode": false,
                      "children": [],
                      "repetitionIndex": null,
                      "rows": null,
                      "columns": null,
                      "cellType": null,
                      "min": null,
                      "max": null,
                      "repeatedFromNode": null,
                      "parentField": null,
                      "multiLevelDropDownValues": null,
                      "multiple": null
                  },
                  {
                      "id": "test_purpose_d06cd3fd_a345_49b6_b125_a577688358df",
                      "type": "FIELD",
                      "name": "Test Purpose",
                      "fieldType": "RADIO_BUTTON",
                      "lookupTable": null,
                      "possibleValues": "Investigation,Certification",
                      "required": true,
                      "attachments": [],
                      "description": "",
                      "repeatable": false,
                      "defaultNode": false,
                      "children": [],
                      "repetitionIndex": null,
                      "rows": null,
                      "columns": null,
                      "cellType": null,
                      "min": null,
                      "max": null,
                      "repeatedFromNode": null,
                      "parentField": null,
                      "multiLevelDropDownValues": null,
                      "multiple": null
                  }
              ],
              "repetitionIndex": null,
              "rows": null,
              "columns": null,
              "cellType": null,
              "min": null,
              "max": null,
              "repeatedFromNode": null,
              "parentField": null,
              "multiLevelDropDownValues": null,
              "multiple": null
          },
          {
              "id": "multiple_user_d445ab96_4a8c_471e_8c96_d02708ca8cf4",
              "type": "SECTION",
              "name": "multiple user",
              "fieldType": null,
              "lookupTable": null,
              "possibleValues": null,
              "required": false,
              "attachments": [],
              "description": "",
              "repeatable": false,
              "defaultNode": false,
              "children": [
                  {
                      "id": "drop_8eb0f409_82a1_4d1d_bf16_e833eccc8eb5",
                      "type": "FIELD",
                      "name": "drop",
                      "fieldType": "DROP_DOWN",
                      "lookupTable": null,
                      "possibleValues": "aa,bb",
                      "required": false,
                      "attachments": [],
                      "description": "",
                      "repeatable": false,
                      "defaultNode": false,
                      "children": [],
                      "repetitionIndex": null,
                      "rows": null,
                      "columns": null,
                      "cellType": null,
                      "min": null,
                      "max": null,
                      "repeatedFromNode": null,
                      "parentField": null,
                      "multiLevelDropDownValues": null,
                      "multiple": null
                  },
                  {
                      "id": "multiple_dropdown_27bdf707_1229_4591_83f5_f19dc7e052bd",
                      "type": "FIELD",
                      "name": "multiple dropdown",
                      "fieldType": "MULTILEVEL_DROP_DOWN",
                      "lookupTable": null,
                      "possibleValues": null,
                      "required": false,
                      "attachments": [],
                      "description": "",
                      "repeatable": false,
                      "defaultNode": false,
                      "children": [],
                      "repetitionIndex": null,
                      "rows": null,
                      "columns": null,
                      "cellType": null,
                      "min": null,
                      "max": null,
                      "repeatedFromNode": null,
                      "parentField": "drop_8eb0f409_82a1_4d1d_bf16_e833eccc8eb5",
                      "multiLevelDropDownValues": [
                          {
                              "key": "aa",
                              "options": "aa1,aa2"
                          },
                          {
                              "key": "bb",
                              "options": "bb1,bb2"
                          }
                      ],
                      "multiple": null
                  }
              ],
              "repetitionIndex": null,
              "rows": null,
              "columns": null,
              "cellType": null,
              "min": null,
              "max": null,
              "repeatedFromNode": null,
              "parentField": null,
              "multiLevelDropDownValues": null,
              "multiple": null
          },
          {
              "id": "users_b44fc4ac_dc4b_49ae_ae73_a6bda9d4684a",
              "type": "SECTION",
              "name": "Users",
              "fieldType": null,
              "lookupTable": null,
              "possibleValues": null,
              "required": false,
              "attachments": [],
              "description": "bv",
              "repeatable": true,
              "defaultNode": false,
              "children": [
                  {
                      "id": "field_1_7d7aa5d5_19f9_43ce_9604_1115c9e909c0",
                      "type": "FIELD",
                      "name": "Field 1",
                      "fieldType": "TEXT",
                      "lookupTable": null,
                      "possibleValues": null,
                      "required": false,
                      "attachments": [],
                      "description": "",
                      "repeatable": false,
                      "defaultNode": false,
                      "children": [],
                      "repetitionIndex": null,
                      "rows": null,
                      "columns": null,
                      "cellType": null,
                      "min": null,
                      "max": null,
                      "repeatedFromNode": null,
                      "parentField": null,
                      "multiLevelDropDownValues": null,
                      "multiple": null
                  }
              ],
              "repetitionIndex": null,
              "rows": null,
              "columns": null,
              "cellType": null,
              "min": null,
              "max": null,
              "repeatedFromNode": null,
              "parentField": null,
              "multiLevelDropDownValues": null,
              "multiple": null
          },
          {
              "id": "conclusion_bd9d38a3_5c85_4e9c_a92b_786d287853a6",
              "type": "SECTION",
              "name": "Conclusion",
              "fieldType": null,
              "lookupTable": null,
              "possibleValues": null,
              "required": false,
              "attachments": [],
              "description": "Score for the test as a whole.",
              "repeatable": false,
              "defaultNode": true,
              "children": [
                  {
                      "id": "score_4c98d64e_e065_42f0_bf26_4d7e777e3727",
                      "type": "FIELD",
                      "name": "Score",
                      "fieldType": "DROP_DOWN",
                      "lookupTable": null,
                      "possibleValues": "yes",
                      "required": true,
                      "attachments": [],
                      "description": "",
                      "repeatable": false,
                      "defaultNode": false,
                      "children": [],
                      "repetitionIndex": null,
                      "rows": null,
                      "columns": null,
                      "cellType": null,
                      "min": null,
                      "max": null,
                      "repeatedFromNode": null,
                      "parentField": null,
                      "multiLevelDropDownValues": null,
                      "multiple": null
                  },
                  {
                      "id": "comment_ca29796b_5237_4483_976c_604fae8e30f2",
                      "type": "FIELD",
                      "name": "Comment",
                      "fieldType": "TEXT_MULTILINE",
                      "lookupTable": null,
                      "possibleValues": null,
                      "required": false,
                      "attachments": [],
                      "description": "",
                      "repeatable": false,
                      "defaultNode": false,
                      "children": [],
                      "repetitionIndex": null,
                      "rows": null,
                      "columns": null,
                      "cellType": null,
                      "min": null,
                      "max": null,
                      "repeatedFromNode": null,
                      "parentField": null,
                      "multiLevelDropDownValues": null,
                      "multiple": null
                  },
                  {
                      "id": "attachment_e7054a30_c7a7_4eb3_811e_a61405b1209b",
                      "type": "FIELD",
                      "name": "Attachment",
                      "fieldType": "FILE_UPLOAD",
                      "lookupTable": null,
                      "possibleValues": null,
                      "required": false,
                      "attachments": [],
                      "description": "",
                      "repeatable": false,
                      "defaultNode": false,
                      "children": [],
                      "repetitionIndex": null,
                      "rows": null,
                      "columns": null,
                      "cellType": null,
                      "min": null,
                      "max": null,
                      "repeatedFromNode": null,
                      "parentField": null,
                      "multiLevelDropDownValues": null,
                      "multiple": null
                  }
              ],
              "repetitionIndex": null,
              "rows": null,
              "columns": null,
              "cellType": null,
              "min": null,
              "max": null,
              "repeatedFromNode": null,
              "parentField": null,
              "multiLevelDropDownValues": null,
              "multiple": null
          }
      ]
  }
  }
  


}