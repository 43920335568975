import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateServiceService {

  constructor(public updates: SwUpdate) {

    if (updates.isEnabled) {
      interval(6000).subscribe(() => updates.checkForUpdate()
        .then(() => console.log('checking for updates')));
    }
  }
  public checkForUpdates(): void {
    this.updates.available.subscribe(event => this.promptUser());
  }

  private promptUser(): void {
    this.updates.activateUpdate().then(() => {
      alert('Update available');
      document.location.reload();
    });
  }
}
