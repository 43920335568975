import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthCanLoadGuard } from './auth/_guards';
import { CanDeactivateGuard } from './auth/_guards/auth.can-deactivate-guard.service';
import { HomeComponent } from './home/home.component';
import {extendAppRoutingModuleRoutes } from '@custom/core/app-routing-module-handler';
import { AuthenticationResolver } from './auth/authentication.resolver';

let routes: Routes = [  
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },  
  {
    path: 'home',
    component: HomeComponent,
    canLoad: [ AuthCanLoadGuard ],
    data: {
    	breadcrumb: 'Home',
    	label: 'Home'
    },
	resolve:{
		authResolver:AuthenticationResolver
	},
    children: [
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full'
      }, {
        path: 'landing',
        loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
        data: {
    		breadcrumb: 'Landing',
    		label: 'Landing'
      },
      },
      {
		path: 'devcenter',
		canLoad: [ AuthCanLoadGuard ],
		data: {
			expectedRoles: [ 999 ],
			breadcrumb: 'Design Studio',
			label: 'Design Studio'
		},
    loadChildren: () => import('./dev-center/dev-center.module').then(m => m.DevCenterModule)
	  },
	  	  {
	    path: 'labs',
      loadChildren: () => import('./labs/labs.module').then(m => m.LabsModule),
	    data: {
      		label: 'LABS'
		}
	  },
	  {
	    path: 'countries',
      loadChildren: () => import('./countries/countries.module').then(m => m.CountriesModule),
	    data: {
      		label: 'COUNTRIES'
		}
	  },
	  {
	    path: 'materials',
      loadChildren: () => import('./materials/materials.module').then(m => m.MaterialsModule),
	    data: {
      		label: 'MATERIALS'
		}
	  },
	  {
	    path: 'materialrequests',
      loadChildren: () => import('./material-requests/material-requests.module').then(m => m.MaterialRequestsModule),
	    data: {
      		label: 'MATERIAL_REQUESTS'
		}
	  },
	  {
	    path: 'languages',
      loadChildren: () => import('./languages/languages.module').then(m => m.LanguagesModule),
	    data: {
      		label: 'LANGUAGES'
		}
	  },
	  {
	    path: 'linersubstances',
      loadChildren: () => import('./liner-substances/liner-substances.module').then(m => m.LinerSubstancesModule),
	    data: {
      		label: 'LINER_SUBSTANCES'
		}
	  },
	  {
	    path: 'surfaceshapeapplication',
      loadChildren: () => import('./surface-shape-application/surface-shape-application.module').then(m => m.SurfaceShapeApplicationModule),
	    data: {
      		label: 'SURFACE_SHAPE_APPLICATION'
		}
	  },
	  {
	    path: 'measurementdevices',
      loadChildren: () => import('./measurement-devices/measurement-devices.module').then(m => m.MeasurementDevicesModule),
	    data: {
      		label: 'MEASUREMENT_DEVICES'
		}
	  },
	  {
	    path: 'adhesiveactivationmethod',
      loadChildren: () => import('./adhesive-activation-method/adhesive-activation-method.module').then(m => m.AdhesiveActivationMethodModule),
	    data: {
      		label: 'ADHESIVE_ACTIVATION_METHOD'
		}
	  },
	  {
	    path: 'appuserprivileges',
      loadChildren: () => import('./app-user-privileges/app-user-privileges.module').then(m => m.AppUserPrivilegesModule),
	    data: {
      		label: 'APP_USER_PRIVILEGES'
		}
	  },
	  {
	    path: 'substances',
      loadChildren: () => import('./substances/substances.module').then(m => m.SubstancesModule),
	    data: {
      		label: 'SUBSTANCES'
		}
	  },
	  {
	    path: 'finishes',
      loadChildren: () => import('./finishes/finishes.module').then(m => m.FinishesModule),
	    data: {
      		label: 'FINISHES'
		}
	  },
	  {
	    path: 'testmethod',
      loadChildren: () => import('./test-method/test-method.module').then(m => m.TestMethodModule),
	    data: {
      		label: 'TEST_METHOD'
		}
	  },
	  {
	    path: 'namedcolor',
      loadChildren: () => import('./named-color/named-color.module').then(m => m.NamedColorModule),
	    data: {
      		label: 'NAMED_COLOR'
		}
	  },
	  {
	    path: 'materialvariations',
      loadChildren: () => import('./material-variations/material-variations.module').then(m => m.MaterialVariationsModule),
	    data: {
      		label: 'MATERIAL_VARIATIONS'
		}
	  },
	  {
	    path: 'testsuites',
      loadChildren: () => import('./test-suites/test-suites.module').then(m => m.TestSuitesModule),
	    data: {
      		label: 'TEST_SUITES'
		}
	  },
	  {
	    path: 'order',
      loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
	    data: {
      		label: 'ORDER'
		}
	  },
	  {
	    path: 'materialfamilies',
      loadChildren: () => import('./material-families/material-families.module').then(m => m.MaterialFamiliesModule),
	    data: {
      		label: 'MATERIAL_FAMILIES'
		}
	  },
	  {
	    path: 'cbxgeneralsettingslocation',
      loadChildren: () => import('./cbx-general-settings-location/cbx-general-settings-location.module').then(m => m.CbxGeneralSettingsLocationModule),
	    data: {
      		label: 'CBX_GENERAL_SETTINGS_LOCATION'
		}
	  },
	  {
	    path: 'testresults',
      loadChildren: () => import('./test-results/test-results.module').then(m => m.TestResultsModule),
	    data: {
      		label: 'TEST_RESULTS'
		}
	  },
	  {
	    path: 'variationpricelist',
      loadChildren: () => import('./variation-price-list/variation-price-list.module').then(m => m.VariationPriceListModule),
	    data: {
      		label: 'VARIATION_PRICE_LIST'
		}
	  },
	  {
	    path: 'color',
      loadChildren: () => import('./color/color.module').then(m => m.ColorModule),
	    data: {
      		label: 'COLOR'
		}
	  },
	  {
	    path: 'regions',
      loadChildren: () => import('./regions/regions.module').then(m => m.RegionsModule),
	    data: {
      		label: 'REGIONS'
		}
	  },
	  {
	    path: 'measurementdirection',
      loadChildren: () => import('./measurement-direction/measurement-direction.module').then(m => m.MeasurementDirectionModule),
	    data: {
      		label: 'MEASUREMENT_DIRECTION'
		}
	  },
	  {
	    path: 'coatedside',
      loadChildren: () => import('./coated-side/coated-side.module').then(m => m.CoatedSideModule),
	    data: {
      		label: 'COATED_SIDE'
		}
	  },
	  {
	    path: 'countrysubdivisions',
      loadChildren: () => import('./country-subdivisions/country-subdivisions.module').then(m => m.CountrySubdivisionsModule),
	    data: {
      		label: 'COUNTRY_SUBDIVISIONS'
		}
	  },
	  {
	    path: 'organization',
      loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
	    data: {
      		label: 'ORGANIZATION'
		}
	  },
	  {
	    path: 'user',
      loadChildren: () => import('./user/user.module').then(m => m.UserModule),
	    data: {
      		label: 'USER'
		}
	  },
	  {
	    path: 'pricelist',
      loadChildren: () => import('./price-list/price-list.module').then(m => m.PriceListModule),
	    data: {
      		label: 'PRICE_LIST'
		}
	  },
	  {
	    path: 'relationships',
      loadChildren: () => import('./relationships/relationships.module').then(m => m.RelationshipsModule),
	    data: {
      		label: 'RELATIONSHIPS'
		}
	  },
	  {
	    path: 'testresultsexports',
      loadChildren: () => import('./test-results-exports/test-results-exports.module').then(m => m.TestResultsExportsModule),
	    data: {
      		label: 'TEST_RESULTS_EXPORTS'
		}
	  },
	  {
	    path: 'inktechnology',
      loadChildren: () => import('./ink-technology/ink-technology.module').then(m => m.InkTechnologyModule),
	    data: {
      		label: 'INK_TECHNOLOGY'
		}
	  },
	  {
	    path: 'adhesiveformulation',
      loadChildren: () => import('./adhesive-formulation/adhesive-formulation.module').then(m => m.AdhesiveFormulationModule),
	    data: {
      		label: 'ADHESIVE_FORMULATION'
		}
	  },
	  {
	    path: 'corefixation',
      loadChildren: () => import('./core-fixation/core-fixation.module').then(m => m.CoreFixationModule),
	    data: {
      		label: 'CORE_FIXATION'
		}
	  },
	  {
	    path: 'cbxgeneralsettings',
      loadChildren: () => import('./cbx-general-settings/cbx-general-settings.module').then(m => m.CbxGeneralSettingsModule),
	    data: {
      		label: 'CBX_GENERAL_SETTINGS'
		}
	  },
	  {
	    path: 'applications',
      loadChildren: () => import('./applications/applications.module').then(m => m.ApplicationsModule),
	    data: {
      		label: 'APPLICATIONS'
		}
	  },
	  {
	    path: 'cblgeneralsetting',
      loadChildren: () => import('./cbl-general-setting/cbl-general-setting.module').then(m => m.CBLGeneralSettingModule),
	    data: {
      		label: 'CBL_GENERAL_SETTING'
		}
	  },
	  {
	    path: 'buyersignups',
      loadChildren: () => import('./buyer-signups/buyer-signups.module').then(m => m.BuyerSignupsModule),
	    data: {
      		label: 'BUYER_SIGNUPS'
		}
	  },
	  {
	    path: 'opacitylevel',
      loadChildren: () => import('./opacity-level/opacity-level.module').then(m => m.OpacityLevelModule),
	    data: {
      		label: 'OPACITY_LEVEL'
		}
	  },
	  {
	    path: 'invitebuyer',
      loadChildren: () => import('./invite-buyer/invite-buyer.module').then(m => m.InviteBuyerModule),
	    data: {
      		label: 'INVITE_BUYER'
		}
	  },
	 ]
  },
  {
	path: '**',
	redirectTo: 'login',
  }
];

const config = {
  useHash: true
};

routes = extendAppRoutingModuleRoutes(routes);

@NgModule({
  imports: [ RouterModule.forRoot(routes, config) ],
  exports: [ RouterModule ],
  providers: [CanDeactivateGuard ]
})
export class AppRoutingModule { }