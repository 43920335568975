import { AppGlobalService } from '@app/app-global.service';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { VsMenuService } from '@app/widget/vs-menu/vs-menu.service';
import { AuthenticationService } from '@app/auth/authentication.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { UtilService } from '@app/core/util.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { CustomService } from '@custom/services/custom.service';
import { Title } from '@angular/platform-browser';
import { VsTabmenuIntegrationService } from '@app/widget/vs-tabmenu-integration.service';
import { AppConstants } from '@custom/core/app-constants';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { envInfo } from '../../../../firebase/firebase';

import { ElementRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@app/core/base.service';
import { PrivacyModalComponent } from './privacy-modal/privacy-modal.component';

export class HomeComponentHandler {
    @ViewChild('termscontent') termsContent: ElementRef;
    menuSerivice: any;
    userName: String;
    organization: String;
    userRole: string;
    userImgSrc: string;
    closeResult: string;
    constructor(
        public menuService: VsMenuService,
        public translate: TranslateService,
        public auth: AuthenticationService,
        public route: ActivatedRoute,
        public router: Router,
        public appGlobalService: AppGlobalService,
        public utilService: UtilService,
        public notification: NotificationService,
		public customService: CustomService,
        public tmIntegration: VsTabmenuIntegrationService,
        public titleService: Title,
		public modalService: NgbModal,
        public baseService: BaseService
    ) { }

    public logoutAction() {
        window.location.href = window.location.origin + '/logout';
        localStorage.removeItem('password');
    }

    public extendLogoutConfig(data) {
        data.action = () => {
            this.logoutAction();
        }
        $(()=>{
           $('app-drop-notification').remove();
        })
        return data;
    }

    public extendUserData(data) {
        this.userName = data.firstName || 'UserName';
        if(data.organization && data.organization.value && data.organization.value.name){
            this.organization = data.organization.value.name;
        }

        this.userRole = data.organization && data.organization.value  &&  data.organization.value.displayName ? data.organization.value.displayName : '';
        this.userImgSrc = data.profilePicture && data.profilePicture[0] && data.profilePicture[0].id  ? ( '/' + AppConstants.apihost +'/attachments/download/attachment/' + data.profilePicture[0].id ) : '../../custom/assets/images/profile.jpg'; 
  
        setTimeout(()=>{
            $('.main-menu').css({'max-height': window.innerHeight-236 + 'px', 'overflow-y':'auto'});
        });

        setTimeout(() => {
            if(this.appGlobalService.get('currentUser').ccContentModerator) {
                this.router.navigate([ '/home/materials/materialslist', {} ], { relativeTo: this.route });
            }
        }, 100);

        if(!data.cbxTermsOfUseAcceptedOn || envInfo.config.terms_updated_date > data.cbxTermsOfUseAcceptedOn){
          //  this.openPrivacyContent();
          //envInfo.config.hpOrgId
        }

        this.attachUserIconDropdowns();
        return data;
    }

    openPrivacyContent() {	
        this.modalService.open(PrivacyModalComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
	}

    attachUserIconDropdowns() {
        let currentUser = this.appGlobalService.get('currentUser');
        $('.app-topbar-user-image').off('click').on('click', function(e){
            const $topList = $('#userProfileOptions');
            if($topList.length){
                $topList.show();
            }else{
                const $list = $('<ul>')
                    .addClass('list-group')
                    .append(
                        $('<li>')
                            .addClass('list-group-item')
                            .append(
                                $('<a>')
                                    .addClass('btn btn-link')
                                    .attr('href', '#/home/appuserprivileges/myprofile;id=' + currentUser.sid)
                                    .text('View Profile')
                            )
                            .on('click', ()=>{
                               // $('#userProfileOptions').hide();
                            }),
                        $('<li>')
                            .addClass('list-group-item')
                            .append(
                                $('<a>')
                                    .addClass('btn btn-link')
                                    .attr('id', 'userLogout')
                                    .text('Logout')
                                    .on('click',()=>{
                                        //$('#userProfileOptions').hide();
                                        window.location.href = window.location.origin + '/logout';
                                        localStorage.removeItem('password');
                                    })
                            )
                            
                    )
                const $container = $('<div>')
                    .addClass('up-options')
                    .attr('id','userProfileOptions')
                    .append($list);
                
    
                $('body').append($container);               
               
            }

            $(document).off('click').on('click',(docE)=>{
                if(!$(docE.target).closest('span').hasClass('app-topbar-user-image')){
                    $('#userProfileOptions').hide();
                }
            })
        });       

    }
}